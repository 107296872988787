import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { ArticleCustomerModel, ArticleCustomerRelationsBluePrint } from './ArticleCustomerTypes';
import useArticleCustomer from './useArticleCustomer';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ArticleCustomerTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useArticleCustomer(parentId);


    const rows = useMemo((): TableColumns<ArticleCustomerModel, ArticleCustomerRelationsBluePrint>[] => [
        {
            header: {
                children: 'Klant',
                sortCol: 'company'
            },
            column: (item) => ({
                children: item.company
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aantal artikelen',
                sortCol: 'quantity'
            },
            column: (item) => ({
                children: item.quantity
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Totale kosten',
                sortCol: 'total_cost'
            },
            column: (item) => ({
                children: item.total_cost
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Totaal opgebracht',
                sortCol: 'total_spent'
            },
            column: (item) => ({
                children: item.total_spent
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Laatste artikel prijs',
                sortCol: 'last_price'
            },
            column: (item) => ({
                children: item.last_price
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Laatst besteld op',
                sortCol: 'last_ordered_at'
            },
            column: (item) => ({
                children: item.last_ordered_at
            }),
            type: 'date'
        }
    ], []);

    return <>
        <Table
            id={ 'customers' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/customers/${ item.customer_id }`
            } }
            http={ {
                hook: httpHook,
                sortCol: 'last_ordered_at',
                sortDir: 'DESC'
            } }
        />
    </>;
};

export default ArticleCustomerTable;