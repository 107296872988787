/**
 * Base field properties for all input types.
 * A component might add props.
 * Props should not be omitted!
 * @deprecated
 */
export type FieldProps = {
    name: string,
    value: string|number|undefined,
    label?: string|JSX.Element,
    id?: string,
    required?: true,
    disabled?: boolean
    invalid?: boolean
    onChange?: (name: string, value: string) => void
    className?: string,
    wrapperClassName?: string
}

/**
 * Option for (multi) select (search)
 * @deprecated
 */
export type Option = {
    value: string|number,
    displayName: string,
    selected?: boolean,
    disabled?: boolean
}

/** @deprecated */
export const defaultOption: Option = {
    value: 10,
    displayName: 'Selecteer een optie',
    disabled: true,
    selected: false
}
