
export const parseDuplicateErrorMessage = (conflictingValue: string) => {
    return `Een veld met de waarde: ${conflictingValue} bestaat al.`
}

export const parseErrorMessage = (statusCode?: number) => {
    console.error({statusCode})
    switch (statusCode) {
        case 400: return 'Verkeerde veldwaarde. (400)';
        case 401: return 'Niet ingelogd. (401)'; // log out
        case 403: return 'Geen toegang. (403)'; // RBAC
        case 405: return 'Actie niet toegestaan. (405)'; // Might be caused by invalid id
        case 409: return 'Resource bestaat al. (409)';
        case 413: return 'Bestand te groot. (413)';
        case 422: return 'Verkeerd data type. (422)';
        case 429: return 'Teveel aanvragen. (429)';
        case 500: return 'Kritieke error. (500)';
        case 504: return 'Timeout, server vindt het te lang duren.. (504)';
        default : return 'Onbekende error.';
    }
}