import React, {FC} from "react";
import RepackingTable from "./RepackingTable";
import IndexPage from "../../templates/pages/IndexPage";
import RepackingForm from "./RepackingForm";


const RepackingIndex: FC = (): JSX.Element => <IndexPage
    title={"Uitvul opdrachten"}
    Table={RepackingTable}
    FormModal={RepackingForm}
    preventRedirect={true}
/>
export default RepackingIndex;