import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';


const ChargeProductColumn: FC<{
    hideProduct?: boolean,
    productId: number,
    productName: string,
    articleNumber: string
}> = ({ hideProduct = false, productId, productName, articleNumber }) => (
    <span className={ 'text-graphite' }>
        { !hideProduct && <Link to={ `/products/${ productId }?current-tab=charge-assessments` } className={ 'font-medium block underline' }>
            { productName }
        </Link> }
        <span className={classNames(hideProduct && 'font-medium block')}>{ articleNumber }</span>
    </span>
);
export default ChargeProductColumn