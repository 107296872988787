import React, {ChangeEvent, useEffect} from "react";
import {Field} from "formik"
import Label from "../Caption/Label";
import ErrorMessage from "../Caption/ErrorMessage";
import useFormikField from "../Effects/useFormikField";
import Skeleton from "../../Skeleton/Skeleton";
import useInput from "../Effects/useInput";
import {classNames} from "../../../modules/Parse/String";
import {requiredFieldClassname} from "../Support/FieldSupport";

type Props = Omit<JSX.IntrinsicElements['textarea'], 'name'> & {
    label: string;
    name: string;
    defaultValue?: string,
    description?: string,
    handleValueChange?: (value: string|boolean|number) => void,
}

const TextArea: React.FC<Props> = ({
    label,
    name,
    required,
    className,
    disabled,
    description,
    defaultValue,
    handleValueChange,
    ...props
}): JSX.Element => {

    const {field, meta, invalid} = useFormikField(name)
    const [value, setValue] = useInput(name, 'text', field.value ?? '')
    useEffect(() => {
        setValue(field.value)
    }, [field.value]);

    return value !== undefined ? <>
        <Label invalid={invalid} htmlFor={name}>{label}{required && '*'}</Label>
        {description && <span className={'text-sm text-graphite'}>{description}</span>}
        <div className="mt-2">
            <Field
                {...props}
                {...field}
                id={name}
                value={value}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.currentTarget.value)}
                as={'textarea'}
                rows={4}
                data-testid={field.name}
                required={required}
                disabled={disabled}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleValueChange && handleValueChange(e.target.value)}
                className={classNames(
                    "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6",
                    invalid? 'ring-red-700' : 'ring-gray-300',
                    required && requiredFieldClassname,
                    disabled && 'hover:cursor-not-allowed opacity-60',
                    className
                )}
            />
            <ErrorMessage meta={meta} />
        </div>
    </>: <Skeleton type={"textArea"} />

}
export default TextArea;
