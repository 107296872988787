import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import BaseElementTable from '../../element/BaseElementTable';

type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ExcipientElementTable: FC<Props> = ({ parentId }) => {

    return <BaseElementTable
        forType={ 'reference' }
        hideType={ true }
        hideExtension={true}
        extraFilter={ {
            column: 'excipient_id',
            operator: '=',
            value: parentId
        } }
    />;
};

export default ExcipientElementTable;
