import React, {FC} from "react";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import useAsyncInit from "../../lib/samfe/components/Form/Effects/useAsyncInit";
import Yup from "../../lib/samfe/components/Form/Yup";
import useProductType from "./useProductType";
import {ProductTypeDto, ProductTypeModel, ProductTypeRelationsBluePrint} from "./ProductTypeTypes";
import {useForm} from "../../lib/samfe/components/Form";
import {FormModal} from "../../lib/samfe/components/Modal";


const ProductTypeForm: FC<ExtendFormModalProps<ProductTypeDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    /**
     *
     */
    const shape = (): Shape<ProductTypeDto> => ({
        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text'),

        plural: Yup.string()
            .required()
            .label('Meervoud')
            .description('Denk aan pillen, tabletten etc.')
            .controlType('input')
            .inputType('text'),

    });


    /**
     *
     */
    const {validationSchema, setShape} = useSchema<ProductTypeDto>(shape());

    const initializer = async () => {
        setShape(shape())
    }

    /**
     *
     */
    const { formikConfig, formFields } = useForm<ProductTypeModel, ProductTypeDto, ProductTypeRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useProductType,
        onSuccess: onSuccess
    });


    return <FormModal
        id={id}
        resource={'Producttype'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
};

export default ProductTypeForm;
