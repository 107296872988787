import React, {FC, useEffect, useState} from "react";
import {FormModal} from "../../lib/samfe/components/Modal";
import {ArticleModel} from "../../resources/article/ArticleTypes";
import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import Yup from "../../lib/samfe/components/Form/Yup";
import {useForm} from "../../lib/samfe/components/Form";
import useAsyncInit from "../../lib/samfe/components/Form/Effects/useAsyncInit";
import {UseHttp} from "../../lib/samfe/modules/Http/useHttp";
import {MinimumModel} from "../../lib/samfe/types/ModelTypes";
import { batchCodeLabel } from '../../resources/charge/ChargeFunctions';
import {ChargeModel} from "../../resources/charge/ChargeTypes";
import {ProductModel} from "../../resources/product/ProductTypes";
import {WithCount} from "../../resources/generic/ModelTypeExtensions";
import {SaleRelations} from "../../resources/sale/SaleTypes";
import useProduct from "../../resources/product/useProduct";
import useArticle from "../../resources/article/useArticle";
import useCharge from "../../resources/charge/useCharge";
import {jsDateToSqlDate} from "../../lib/samfe/modules/Parse/Date";


type TestRelationsBlueprint = ''

type TestFields = {
    product_id: number
    article_id: number
    charge_id: number
}

type TestDto = Partial<TestFields>

type TestRelations = {
    product?: ProductModel,
    article?: ArticleModel,
    charge?: ChargeModel
}

type TestModel = MinimumModel & TestDto & TestRelations & WithCount<Partial<SaleRelations>>


/**
 * Mock a hook.
 */
const useTestHook = (): UseHttp<TestModel, TestDto, TestRelationsBlueprint> & {
    product?: ProductModel,
    article?: ArticleModel,
    charge?: ChargeModel
} => {

    const product = useProduct();
    const article = useArticle();
    const charge = useCharge();

    const [currentProduct, setCurrentProduct] = useState<ProductModel|undefined>(undefined);
    const [currentArticle, setCurrentArticle] =  useState<ArticleModel|undefined>(undefined);
    const [currentCharge, setCurrentCharge] =  useState<ChargeModel|undefined>(undefined);

    const model: TestModel = {
        id: 1,
        product_id: currentProduct?.id,
        article_id: currentArticle?.id,
        charge_id: currentCharge?.id,
        product: currentProduct,
        article: currentArticle,
        charge: currentCharge,
        created_at: jsDateToSqlDate(),
        updated_at: jsDateToSqlDate(),
    }

    const getModel = async () => {
        return  await charge.getList({
            limit: 1,
            filter: 'amount>0'
        }).then(async (charges) => {
            const charge = charges[0];
            return await article.getItem(charge.article_id)
                .then(async (article) => {
                    return await product.getItem(article?.product_id).then(product => {

                        return {
                            id: 1,
                            product_id: product?.id,
                            article_id: article?.id,
                            charge_id: charge?.id,
                            product,
                            article,
                            charge,
                            created_at: jsDateToSqlDate(),
                            updated_at: jsDateToSqlDate(),
                        }
                    })
                })
        })
    }


    useEffect(() => {
        charge.getList({
            limit: 1,
            filter: 'amount>0'
        }).then(charges => {
            const charge = charges[0];
            setCurrentCharge(charge)
            article.getItem(charge.article_id)
                .then(article => {
                    setCurrentArticle(article)
                    product.getItem(article?.product_id).then(setCurrentProduct)
                })
        })
    }, []);

    return {
        getItem: async (id) => {
            console.log({id, getItem: model})
            return await getModel();
        },

        getList: async () => {
            console.log({getList: [model]})
            return [model];
        },
        create: async (payload) =>  {
            console.log({createPayload: payload})
            return {id: model.id!};
        },

        update: async (id, payload) => {
            console.log({id, updatePayload: payload})
            return {id};
        },

        destroy: async (id) => {
            console.log({destroyId: id})
            return {id};
        },

        pagination: {
            pagination: {
                perPage: 10,
                totalItems:10,
                currentPage: 0,
                sortDir: "ASC"
            },
            setPagination: () => {}
        }
    }
}


/**
 * Mock form with value chaining of product -> article -> charge.
 * @param id
 * @param open
 * @param setOpen
 * @param onSuccess
 * @constructor
 */
const FormikTest: FC<ExtendFormModalProps<TestDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {


    const charge = useCharge();

    const [initialProduct, setInitialProduct] = useState<ProductModel|undefined>(undefined);
    const [currentProduct, setCurrentProduct] = useState<ProductModel|undefined>(undefined);

    const [initialArticle, setInitialArticle] = useState<ArticleModel|undefined>(undefined);
    const [currentArticle, setCurrentArticle] = useState<ArticleModel|undefined>(undefined);

    const [initialCharge, setInitialCharge] =  useState<ChargeModel|undefined>(undefined);


    /**
     *
     * @param productModel
     */
    const articleFilter = (productModel?: ProductModel) => {
        const record = (currentProduct??productModel);
        return record ?`,product_id=${record.id}`:''
    }

    /**
     *
     * @param articleModel
     */
    const chargeFilter = (articleModel?: ArticleModel) => {
        const record = (currentArticle??articleModel);
        return record ?`,article_id=${record.id}`:''
    }


    /**
     *
     */
    const shape = (charge?: ChargeModel): Shape<TestDto> => ({

        product_id: Yup.number()
            .required()
            .label('Product')
            .controlType('selectSearch')
            .selectSearchConfig({
                initialModel: charge?.product ?? initialProduct,
                expectsInitialModel: id != undefined,
                useHook: useProduct,
                onChange: setCurrentProduct,
                searchOptions: {
                    searchCols: ["name"],
                    valueCol: "id",
                    displayName: model => `${model.name}`
                }
            }),

        article_id: Yup.number()
            .required()
            .label('Artikel')
            .controlType('selectSearch')
            .selectSearchConfig({
                initialModel: charge?.article ?? initialArticle,
                expectsInitialModel: id != undefined,
                useHook: useArticle,
                onChange: setCurrentArticle,
                searchOptions: {
                    searchCols: ["number"],
                    valueCol: "id",
                    filter: `active=1${articleFilter(charge?.product)}`,
                    displayName: model => `${model.number}`
                }
            }),

        charge_id: Yup.number()
            .required()
            .label('Charge')
            .controlType('selectSearch')
            .selectSearchConfig({
                initialModel: charge ?? initialCharge,
                expectsInitialModel: id != undefined,
                useHook: useCharge,
                searchOptions: {
                    searchCols: ["batchcode"],
                    valueCol: "id",
                    filter: `amount>0,status=processed${chargeFilter(charge?.article)}`,
                    displayName: model => `${batchCodeLabel(model)}`
                }
            })
    });


    /**
     *
     */
    const {validationSchema, setShape} = useSchema<TestDto>(shape());


    useEffect(() => {
        setShape(shape())
    }, [currentProduct, currentArticle]);

    const initializer = async () => {

        let initCharge: ChargeModel|undefined;

        if (id) {
            await charge.getList({
                limit: 100,
                filter: 'amount>500,status=processed',
                orderBy: 'amount',
                order: 'ASC',
                with: ["article.product"]
            }).then(charges => {

                const charge = charges.find(charge => charge.article?.active === true || charge.article?.active === 1);
                if (!charge) {
                    console.log('no charge')
                    return;
                }

                initCharge = charge;
                setInitialCharge(charge)
                setInitialArticle(charge.article)
                setInitialProduct(charge.article?.product)

            });
        }
        setShape(shape(initCharge));
    }

    /**
     *
     */
    const { formikConfig, formFields } = useForm<TestModel, TestDto, TestRelationsBlueprint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useTestHook,
        onSuccess: onSuccess
    });


    return <FormModal
        id={id}
        resource={'Formik test'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
};

export default FormikTest;