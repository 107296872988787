import React, {FC} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {routes} from "../../../../Router";
import Redirect from "./Redirect";


const Router: FC = (): JSX.Element => <Routes>
    <>
        {/* App routes */}
        {routes.map((route, index) =>
            <Route
                key={index}
                path={route.path}
                element={<route.element />}
            />
        )}
        {/* Fallback if not logged in */}
        <Route path={'/'} element={<Navigate to={'/login'} />}  />
        <Route path={'/*'} element={<Redirect />} />
    </>
</Routes>

export default Router;