import React, { FC, useEffect, useState } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import { sqlDateTimeToDate } from '../../lib/samfe/modules/Parse/Date';
import CustomerForm from '../customer/CustomerForm';
import useCustomer from '../customer/useCustomer';
import { SaleDto, SaleModel, SaleRelationsBluePrint, SaleStatus } from './SaleTypes';
import useSale from './useSale';


const SaleForm: FC<ExtendFormModalProps<SaleDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    const sale = useSale();
    const [ currentSale, setCurrentSale ] = useState<SaleModel>();

    /**
     *
     */
    const shape = (): Shape<SaleDto> => ({
        wc_sale_id: Yup.number()
            .inputType('number')
            .hidden(true)
            .controlType('input')
            .label('WooCommerce ordernummer'),

        wc_import_id: Yup.number()
            .inputType('number')
            .hidden(true)
            .controlType('input')
            .label('WooCommerce import id'),

        customer_id: Yup.number()
            .label('Klant')
            .required()
            .hidden(id !== undefined)
            .controlType('selectSearch')
            .selectSearchConfig({
                expectsInitialModel: false,
                useHook: useCustomer,
                searchOptions: {
                    searchCols: [ 'company', 'number' ],
                    valueCol: 'id',
                    limit: 'all',
                    filter: 'archived=0', // @fixme monkey patch limit all
                    displayName: model => `${ model.company }`,
                    FormModal: CustomerForm
                }
            }),

        status: Yup.string()
            .controlType('input')
            .label('Status')
            .hidden(true)
            .inputType('text')
            .defaultValue((!id ?'to send' :currentSale?.status ?? 'to send') as SaleStatus)
            .required(),


        discount: Yup.number()
            .inputType('number')
            .controlType('input')
            .label('Korting in Euro\'s')
            .required()
            .defaultValue(currentSale?.discount ?? 0),

        ordered_at: Yup.string()
            .inputType('date')
            .controlType('input')
            .label('Besteldatum')
            .defaultValue(sqlDateTimeToDate(currentSale?.ordered_at))
            .required(),

        shipped_at: Yup.string()
            .inputType('date')
            .controlType('input')
            .label('Verzenddatum')
            .defaultValue(sqlDateTimeToDate(currentSale?.shipped_at))
            .required(),


        customer_note: Yup.string()
            .label('Klant notitie')
            .controlType('textArea'),

        customer_reference: Yup.string()
            .controlType('input')
            .label('Klant referentienummer')
            .optional()
            .inputType('text')
            .defaultValue(currentSale?.customer_reference ?? ''),
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<SaleDto>(shape());

    const initializer = async() => {
        setCurrentSale(await sale.getItem(id))
        setShape(shape());
    };

    useEffect(() => {
        setShape(shape());
    }, [currentSale]);

    /**
     *
     */
    const { formikConfig, formFields } = useForm<SaleModel, SaleDto, SaleRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useSale,
        onSuccess: onSuccess
    });

    return <FormModal
        id={ id }
        resource={ 'Verkooporder' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default SaleForm;
