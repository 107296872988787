import React, { FC, useMemo } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { sqlDateDiffToString } from '../../../lib/samfe/modules/Parse/Date';
import { batchCodeLabel } from '../../charge/ChargeFunctions';
import { PurchaseRowModel, PurchaseRowRelationsBluePrint } from '../../purchase/pivot/purchaseRow/PurchaseRowTypes';
import { usePurchaseRowResource } from '../../purchase/pivot/purchaseRow/usePurchaseRow';
import { PurchaseStatusTranslation } from '../../purchase/PurchaseTypes';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ArticlePurchaseRowTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = usePurchaseRowResource();


    const rows = useMemo((): TableColumns<PurchaseRowModel, PurchaseRowRelationsBluePrint>[] => [
        {
            header: {
                children: 'Ordernummer',
                sortCol: 'purchase_id'
            },
            column: (item) => ({
                children: item.purchase_id
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Producent'
            },
            column: (item) => ({
                children: item.purchase?.producer?.name ?? 'onbekend'
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Batchcode'
            },
            column: (item) => ({
                children: batchCodeLabel(item.charge)
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Prijs p/s',
                sortCol: 'price_per_amount'
            },
            column: (item) => ({
                children: item.price_per_amount
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Aantal ontvangen',
                sortCol: 'booked_quantity'
            },
            column: (item) => ({
                children: item.booked_quantity
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Regel totaal'
            },
            column: (item) => ({
                children: (item.price_per_amount ?? 0) * (item?.booked_quantity ?? 0)
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Besteldatum'
            },
            column: (item) => ({
                children: item.purchase?.order_date
            }),
            type: 'date'
        },
        {
            header: {
                children: 'TTM'
            },
            column: (item) => ({
                children: sqlDateDiffToString(item?.charge?.received_date, item?.purchase?.order_date).replace('.', ',')
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Status',
                sortCol: 'status'
            },
            column: (item) => ({
                children: PurchaseStatusTranslation(item?.status)
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'purchase-rows' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/purchases/${ item.purchase_id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: [ 'charge.batchcode', 'purchase.producer.name' ],
                with: [ 'charge', 'purchase.producer' ],
                sortCol: 'purchase_id',
                sortDir: 'DESC',
                filter: {
                    column: 'article_id',
                    operator: '=',
                    value: parentId
                },
                filters: [
                    {
                        displayName: 'Klaar voor bestelling',
                        column: 'status',
                        value: 'open'
                    },
                    {
                        displayName: 'In bestelling',
                        column: 'status',
                        value: 'sent'
                    },
                    {
                        displayName: 'Te verwerken',
                        column: 'status',
                        value: 'received'
                    },
                    {
                        displayName: 'Ingeboekt',
                        column: 'status',
                        value: 'booked'
                    }

                ]
            } }
        />
    </>;
};

export default ArticlePurchaseRowTable;