import React, { FC, ReactNode, useEffect, useState } from 'react';
import { classNames } from '../../../modules/Parse/String';
import ErrorMessage from '../Caption/ErrorMessage';
import Label from '../Caption/Label';
import { requiredFieldClassname } from '../Support/FieldSupport';
import { InvalidField } from './GenericFieldTypes';


type Props = {
    label?: ReactNode,
    name: string,
    description?: ReactNode,
    checked?: boolean,
    invalid?: InvalidField,
    required?: boolean
    disabled?: boolean
    onChange?: (active: boolean) => void,
    className?: string
}
const Checkbox: FC<Props> = ({ 
    label,
    checked = false,
    onChange,
    name,
    invalid ,
    required = false,
    disabled = false,
    description,
    className
}): JSX.Element => {

    const [ checkedState, setCheckedState ] = useState(checked);
    useEffect(() => {
        setCheckedState(checked);
    }, [ checked ]);


    const handleChange = (isChecked: boolean) => {
        setCheckedState(isChecked);
        onChange?.(isChecked);
    };

    return <div className={ classNames('mt-2', className) }>
        <div className="relative flex items-start">

            <div className="flex h-6 items-center">
                <input
                    required={ required }
                    onChange={ () => handleChange(!checkedState) }
                    checked={ checkedState }
                    id={ name }
                    disabled={ disabled }
                    type="checkbox"
                    className={ classNames(
                        'h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 hover: cursor-pointer',
                        disabled && '!hover:cursor-not-allowed opacity-60',
                        required && requiredFieldClassname
                    ) }
                />
            </div>

            { (label !== undefined || description != undefined) && <div className="ml-3 text-sm leading-6 h-min">
                { label && <span className={ 'hover:cursor-pointer all-children-hover:cursor-pointer' }>
                    <Label invalid={ invalid?.isInvalid } htmlFor={ name }>
                        { label }{ required && '*' }
                    </Label>
                </span> }
                { description && <span className="text-gray-500 font-normal">
                    <span className="sr-only">{ label }</span>
                    <span>{ description }</span>
                </span> }
            </div> }
        </div>
        <ErrorMessage message={invalid?.message}/>
    </div>;
};
export default Checkbox;