import { useMemo, useState } from 'react';
import { AssessmentHistory } from '../types/AssessmentTypes';
import { assessmentIsNotApplicable } from './useAssessmentIsNotApplicable';


const useNotApplicableAssessments = (items: AssessmentHistory[] = []) => {
    const [ internalItems, setInternalItems ] = useState(items);
    return {
        setList: setInternalItems,
        items: useMemo(() => {
            return internalItems.filter(assessmentIsNotApplicable);
        }, [ internalItems ])
    };
};

export default useNotApplicableAssessments;
