import React, { FC, useMemo } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { SaleModel, SaleRelationsBluePrint, SaleStatusTranslation } from '../../sale/SaleTypes';
import useSale from '../../sale/useSale';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const CustomerSaleTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useSale();


    const rows = useMemo((): TableColumns<SaleModel, SaleRelationsBluePrint>[] => [
        {
            header: {
                children: 'Ordernummer',
                sortCol: 'id'
            },
            column: (item) => ({
                children: item.id
            }),
            type: 'id'
        },
        {
            header: {
                children: 'WC order ID',
                sortCol: 'wc_sale_id'
            },
            column: (item) => ({
                children: item.wc_sale_id
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Aantal order regels'
            },
            column: (item) => ({
                children: item.saleRows_count
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Inkoopprijs'
            },
            column: (item) => ({
                children: item.financialData?.purchase_price
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Verkoopprijs'
            },
            column: (item) => ({
                children: item.financialData?.total
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Besteldatum',
                sortCol: 'ordered_at'
            },
            column: (item) => ({
                children: item.ordered_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Verzenddatum',
                sortCol: 'shipped_at'
            },
            column: (item) => ({
                children: item.shipped_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Status',
                sortCol: 'status'
            },
            column: (item) => ({
                children: SaleStatusTranslation(item.status)
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'sales' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/sales/${ item.id }`
            } }
            http={ {
                hook: httpHook,
                sortCol: 'ordered_at',
                sortDir: 'DESC',
                with: [ 'saleRows.charge', 'financialData' ],
                withCount: [ 'saleRows' ],
                filter: {
                    column: 'customer_id',
                    operator: '=',
                    value: parentId
                }
            } }
        />
    </>;
};

export default CustomerSaleTable;