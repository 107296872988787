import { FieldMetaProps } from 'formik';
import React from 'react';


type Props = {
    message?: string
    meta?: FieldMetaProps<any>
}

const ErrorMessage: React.FC<Props> = ({ message, meta }): JSX.Element => {

    const getErrorMessage = () => {
        if (meta !== undefined && meta.touched) {
            return meta.error;
        }
        return message;
    };


    return <p className="mt-2 text-sm text-red-700">
        { getErrorMessage() }
    </p>;
};
export default ErrorMessage;
