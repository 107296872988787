import React, { CSSProperties, ReactNode } from 'react';
import { datetimeToLocale } from '../../../lib/samfe/modules/Parse/Date';
import { thousandSeparator, toEuroString, toPercentageString } from '../../../lib/samfe/modules/Parse/Number';
import { ucFirst } from '../../../lib/samfe/modules/Parse/String';
import { TableColumnType } from '../types/TableColumn';


export const getDataType = (dataType?: TableColumnType): TableColumnType => dataType ?? 'text';

export const getColStyle = (dataType: TableColumnType|'action'): CSSProperties => {
    switch (dataType) {
        case 'id':
        case 'text':
        case 'tel':
        case 'mail':
        case 'element':
            return { textAlign: 'left' };
        case 'date':
        case 'icon':
            return { textAlign: 'center' };
        default:
            return { textAlign: 'right' };
    }
};

const parseTel = (tel: string) => <a className={'underline decoration-aqua text-aqua underline-offset-2 decoration-[0.03125rem]'} href={`tel:${tel.replace(' ', '')}`}>{tel}</a>;
const parseMail = (mail: string) => <a className={'underline decoration-aqua text-aqua underline-offset-2 decoration-[0.03125rem]'} href={`mailto:${mail.toLowerCase()}`}>{mail.toLowerCase()}</a>;



export const castData = (item: ReactNode, type: TableColumnType|'action'|'sort'): ReactNode => {

    if (!item && typeof item !== 'boolean') {
        switch (type) {
            case 'currency':
                return toEuroString(0);
            case 'percentage':
                return toPercentageString(0);
            case 'numeric':
                return 0;
            default:
                return '-';
        }
    }

    if (typeof item === 'boolean' || typeof item == 'object') {
        return item;
    }

    const isNumeric = !isNaN(parseFloat(`${ item }`));

    switch (type) {
        case 'numeric':
            return isNumeric ?thousandSeparator(item as number) :item;
        case 'percentage':
            return isNumeric ?toPercentageString(item as number) :item;
        case 'currency':
            return toEuroString(item);
        case 'date':
            return typeof item == 'string' ?datetimeToLocale(item) :item;
        case 'text':
            return typeof item == 'string' ?ucFirst(item as string) :item;
        case 'tel':
            return typeof item == 'string' ?parseTel(item as string) :item;
        case 'mail':
            return typeof item == 'string' ?parseMail(item as string) :item;
        case 'id':
        default:
            return item;
    }


};
