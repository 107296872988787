import { useMemo, useState } from 'react';
import { AssessmentHistory } from '../types/AssessmentTypes';
import { assessmentIsOptional } from './useAssessmentIsOptional';


const useOptionalAssessments = (items: AssessmentHistory[] = []) => {
    const [ internalItems, setInternalItems ] = useState(items);
    return {
        setList: setInternalItems,
        items: useMemo(() => {
            return internalItems.filter(assessmentIsOptional);
        }, [ internalItems ])
    };
};

export default useOptionalAssessments;
