import React, { useMemo } from 'react';
import useDocumentDimensions from '../../../../hooks/useDocumentDimensions';
import Select from '../../../../lib/samfe/components/Form/Generic/Select/Select';
import { SelectOption } from '../../../../lib/samfe/components/Form/Generic/Select/useSelect';
import { SoftDeleteModel } from '../../../../lib/samfe/types/ModelTypes';
import useTableFilter from '../../hooks/useTableFilter';
import useTableScrollToTop from '../../hooks/useTableScrollToTop';


const FilterSelectComponent = <T extends SoftDeleteModel, R extends string>() => {

    const {
        filters,
        filterValues,
        setCurrentFilter,
        currentFilterValue
    } = useTableFilter<T, R>();

    const scrollToTop = useTableScrollToTop()


    const selectOptions = useMemo(() => {
        return filters.map((filter, i) => ({
            displayName: filter.displayName,
            value: filterValues[i],
            selected: currentFilterValue ?currentFilterValue == filterValues[i] :i == 0
        }) as SelectOption);
    }, [ currentFilterValue, filters, filterValues ]);


    const handleChange = (option: SelectOption) => {
        const filterIndex = filterValues.findIndex(filter => filter == option.value);
        const selectedFilter = filterIndex>=0 ?filters[filterIndex] :undefined;
        if (selectedFilter) {
            setCurrentFilter(selectedFilter);
        }
        scrollToTop()
    };


    /**
     * Calculate max string size of set and return width in rems + check icon margin.
     *
     * @type {number}
     */
    const filterWidth: number = useMemo(() => {
        const text = filters.length == 0 ?'' :filters.reduce((a, b) =>
            `${ a.displayName }`.length<`${ b.displayName }`.length ?b :a
        ).displayName.toString();

        const context = (document.createElement('canvas')).getContext('2d')!;
        context.font = getComputedStyle(document.body).font;
        return Math.ceil(context!.measureText(text).width / 16) + 3.5;
    }, [ filters ]);


    const {dimension: {width}} = useDocumentDimensions()

    return (
        <>
            { filters.length > 0 && <Select
                label={ '' }
                name={ '' }
                onChange={ handleChange }
                options={ selectOptions }
                style={ { width: width>=768 ?`${ filterWidth }rem` :'100%' } }
                buttonClassName={ '!h-[2rem] !pt-1 -mt-0.5 mb-4 md:mb-0' }
                maxWidth={ '!inline-block !text-left' }
            /> }
        </>
    );
};

export default FilterSelectComponent;
