import { Switch as HeadlessUiSwitch } from '@headlessui/react';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { classNames } from '../../../modules/Parse/String';


type Props = {
    label?: ReactNode,
    name: string,
    checked?: boolean,
    onChange?: (active: boolean) => void
}
const Switch: FC<Props> = ({ label, checked = false, onChange, name }): JSX.Element => {

    const [ checkedState, setCheckedState ] = useState(checked);
    useEffect(() => {
        setCheckedState(checked);
    }, [ checked ]);


    const handleChange = (isChecked: boolean) => {
        setCheckedState(isChecked);
        onChange?.(isChecked);
    };

    return <div className="text-left flex items-start">
        <div className="flex items-center h-5 w-full">
            { label && <div className="ml text-sm w-[calc(100%-2rem)] lg:hidden">
                <label htmlFor={ name } className="font-medium text-graphite">
                    { label }
                </label>
            </div> }
            <HeadlessUiSwitch
                id={ name }
                checked={ checkedState }
                onChange={ handleChange }
                className={ classNames(checkedState ?'bg-aqua' :'bg-gray-200', 'relative inline-flex h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky focus:ring-offset-2') }
            >
                <span className="sr-only">{ name }</span>
                <span aria-hidden="true" className={ classNames(checkedState ?'translate-x-6' :'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out') }>
                    <span className={ classNames(checkedState ?'opacity-100' :'opacity-0', 'material-icons pt-0.5 text-white right-6 absolute transition duration-200 ease-in-out') }>
                        done
                    </span>

                    <span className={ classNames(!checkedState ?'opacity-100' :'opacity-0', 'material-icons pt-0.5 text-fire left-6 absolute transition duration-200 ease-in-out') }>
                        close
                    </span>
                </span>
            </HeadlessUiSwitch>

            { label && <div className="ml-3 text-sm hidden lg:block">
                <label htmlFor={ name } className="font-medium text-graphite">
                    { label }
                </label>
            </div> }
        </div>
    </div>;
};
export default Switch;