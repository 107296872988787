import moment from 'moment/moment';
import { useMemo } from 'react';
import { TableFilterType } from '../../../../components/table/types/TableFilter';
import { AssessmentModel, GetAssessmentStatusName } from '../../types/AssessmentTypes';


export type AssessmentTableFilter = TableFilterType<AssessmentModel>;

type FilterKey = 'all'|'openActions'|'shouldBeTested'|'testRequested'|'approved'|'shouldBeTestedExp1YearTillNow'|'blocked'
type FilterItems = {
    [K in FilterKey]: AssessmentTableFilter
}

const useAssessmentTableFilters = (defaultActive: FilterKey = 'all'): FilterItems => {
    return useMemo((): FilterItems => ({
        all: {
            displayName: 'Alles weergeven',
            column: 'id',
            operator: '>',
            value: '0',
            default: defaultActive == 'all'
        },
        openActions: {
            displayName: 'Alle open acties',
            column: 'assessment_status',
            value: 'to_test,assessment_status=test_requested',
            default: defaultActive == 'openActions'
        },
        shouldBeTestedExp1YearTillNow: {
            displayName: `${ GetAssessmentStatusName('to_test') } (THT > 1 jaar geleden)`,
            column: 'assessment_status',
            value: `to_test,expiration_date>=${ moment().subtract(1, 'year').format('Y-MM-DD') }`,
            default: true
        },
        shouldBeTested: {
            displayName: GetAssessmentStatusName('to_test'),
            column: 'assessment_status',
            value: 'to_test',
            default: defaultActive == 'shouldBeTested'
        },
        blocked: {
            displayName: 'Geblokkeerd',
            column: 'blocked',
            value: true,
            default: defaultActive == 'blocked'
        },
        testRequested: {
            displayName: GetAssessmentStatusName('test_requested'),
            column: 'assessment_status',
            value: 'test_requested',
            default: defaultActive == 'testRequested'
        },
        approved: {
            displayName: GetAssessmentStatusName('approved'),
            column: 'assessment_status',
            value: 'approved',
            default: defaultActive == 'approved'
        }
    }), [ defaultActive ]);
};

export const useAssessmentFilters = (): AssessmentTableFilter[] => {
    const filters = useAssessmentTableFilters('openActions');
    return useMemo((): AssessmentTableFilter[] => [
        filters.openActions,
        filters.shouldBeTested,
        filters.testRequested,
        filters.shouldBeTestedExp1YearTillNow,
        filters.blocked
    ], [ filters.openActions, filters.shouldBeTested, filters.testRequested, filters.blocked ]);
};

export const useProductAssessmentFilters = (): AssessmentTableFilter[] => {
    const filters = useAssessmentTableFilters('all');
    return useMemo((): AssessmentTableFilter[] => [
        filters.openActions,
        filters.shouldBeTested,
        filters.testRequested,
        filters.approved
    ], [ filters.openActions, filters.shouldBeTested, filters.testRequested, filters.approved ]);
};
