import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import TopButton from '../../lib/samfe/components/Button/TopButton';
import IndexPage from '../../templates/pages/IndexPage';
import ElementCompoundForm from './pivot/elementCompound/ElementCompoundForm';
import ElementCompoundTable from './pivot/elementCompound/ElementCompoundTable';
import ElementExcipientForm from './pivot/elementExcipient/ElementExcipientForm';
import ElementExcipientTable from './pivot/elementExcipient/ElementExcipientTable';


export const ElementCompoundIndex: FC = (): JSX.Element => {
    const navigate = useNavigate();

    return <IndexPage
        title={ 'Actieve stoffen' }
        Table={ ElementCompoundTable }
        FormModal={ ElementCompoundForm }
        actionButton={ <TopButton
            style={ 'secondary' }
            icon={ '' }
            text={ 'Hulpstoffen' }
            onClick={ () => navigate('/settings/excipient-elements') }
        />
        }/>;
};

export const ElementExcipientIndex: FC = (): JSX.Element => {
    const navigate = useNavigate();

    return <IndexPage
        title={ 'Hulpstoffen' }
        Table={ ElementExcipientTable }
        FormModal={ ElementExcipientForm }
        actionButton={ <TopButton
            style={ 'secondary' }
            icon={ '' }
            text={ 'Actieve stoffen' }
            onClick={ () => navigate('/settings/compound-elements') }
        />
        }/>;
};