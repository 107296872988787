import React, {useEffect, useState} from 'react';
import validator from 'validator';
import {useNavigate} from "react-router-dom";
import ResearchPng from "../../assets/images/research.png";
import GaLogo from "../../assets/images/great-applications-logo.png";
import {ActionButton} from "../../lib/samfe/components/Button";
import useAuth from "../../resources/user/useAuth";



/**
 *
 */
type InputField = {
    id: string,
    type: string,
    value?: string,
    placeholder?: string,
    errorMessage: string
}


/**
 *
 */
type InputFieldState = InputField & {element?: HTMLInputElement}


/**
 *
 */
type LoginState = {
    email: InputFieldState,
    password: InputFieldState,
    loadingScreenActive: boolean
}


/**
 * @see https://medium.com/technology-hits/laravel-react-fullstack-application-with-passport-redux-and-material-ui-frontend-5c39cf6d7ba4
 */
const Login: React.FC = (): JSX.Element =>
{

    const [state, setState] = useState({

        email: {
            id: 'email',
            type: 'email',
            placeholder: 'E-mailadres',
            errorMessage: '',
        },
        password: {
            id: 'password',
            type: 'password',
            placeholder: 'Wachtwoord',
            errorMessage: '',
        },
        loadingScreenActive: false
    } as LoginState);

    const navigate = useNavigate();
    const {login} = useAuth()


    useEffect(() => {
        setState((prevState: LoginState) => {
            const email = {...prevState.email}
            email.element = document.getElementById(email.id)! as HTMLInputElement;

            const password = {...prevState.password}
            password.element = document.getElementById(password.id)! as HTMLInputElement;


            const newState: LoginState = {
                email: email,
                password: password, loadingScreenActive:
                state.loadingScreenActive
            }

            return newState;
        });
    }, []);


    const emailInputIsValid = (): boolean => {
        const isInvalid = ! validator.isEmail(state.email.element!.value);
        setState( (prevState: LoginState) => {
            const email = {...prevState.email};
            email.errorMessage = isInvalid ? 'Vul een geldig email adres in!' : '';
            return {
                email: email,
                password: prevState.password,
                loadingScreenActive: prevState.loadingScreenActive
            };
        });
        return ! isInvalid;
    }


    const passwordInputIsValid = (): boolean => {
        // @todo configure password FE validation
        // const isInvalid = ! validator.isStrongPassword(state.password.element!.value);

        const isInvalid  = state.password.element!.value.length < 4;

        setState( (prevState: LoginState) => {
            const password = {...prevState.password};
            password.errorMessage = isInvalid ? 'Vul een geldig wachtwoord in!' : '';
            return {
                email: prevState.email,
                password,
                loadingScreenActive: prevState.loadingScreenActive
            };
        });
        return ! isInvalid;
    }


    /**
     *
     */
    const validateInput = async (): Promise<void> => {
        const emailIsValid = emailInputIsValid();
        const passwordIsValid = passwordInputIsValid();

        if ( emailIsValid && passwordIsValid ) {
            login({email: state.email.element!.value, password: state.password.element!.value})
        }
    }


    return (
        <form onSubmit={(e) => e.preventDefault()} >
            <div className="flex min-h-full flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                className="w-56 company-logo"
                                src={GaLogo}
                                alt="Great Applications"
                            />
                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Inloggen
                            </h2>
                        </div>

                        <div className="mt-10">
                            <div className="space-y-6">
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        E-mailadres
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aqua sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                        Wachtwoord
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aqua sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                {/* @todo remember */}
                                <div className="flex items-center justify-between">
                                    {/*<div className="flex items-center">*/}
                                    {/*    <input*/}
                                    {/*        id="remember-me"*/}
                                    {/*        name="remember-me"*/}
                                    {/*        type="checkbox"*/}
                                    {/*        className="h-4 w-4 rounded border-gray-300 text-aqua focus:ring-aqua"*/}
                                    {/*    />*/}
                                    {/*    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">*/}
                                    {/*        Remember me*/}
                                    {/*    </label>*/}
                                    {/*</div>*/}
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        onClick={validateInput}
                                        className="flex w-full justify-center rounded-md bg-aqua px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-aqua-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aqua"
                                    >
                                        Inloggen
                                    </button>
                                </div>
                                <ActionButton
                                    className={'!w-auto'}
                                    style={'tertiary'}
                                    text={'Wachtwoord vergeten'}
                                    icon={''}
                                    onClick={() => {
                                        navigate('/forgot-password')
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden justify-center w-0 flex-1 lg:flex lg:h-screen">
                    <img
                        className="w-full object-contain"
                        src={ResearchPng}
                        alt="auth"
                    />
                </div>
            </div>
        </form>
    )

}

export default Login;