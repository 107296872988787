import React, {FC} from "react";
import IndexPage from "../../templates/pages/IndexPage";
import WooCommerceTable from "./WooCommerceTable";



const WooCommerceIndex: FC = (): JSX.Element => <IndexPage
    title={"WooCommerce import"}
    Table={WooCommerceTable}
/>
export default WooCommerceIndex;