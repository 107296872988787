import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { createContext, PropsWithChildren, SetStateAction, useContext, useEffect, useRef } from 'react';
import { API_URL, PRODUCTION_MODE } from '../../../../config/config';
import useRedirectToLogout from '../../../../resources/user/useRedirectToLogout';
import useLoadingScreen from '../../components/LoadingScreen/useLoadingScreen';
import { ToasterProps } from '../../components/Toaster';
import useToaster from '../../components/Toaster/useToaster';
import useToken from '../Cookie/useToken';
import { parseDuplicateErrorMessage, parseErrorMessage } from './HttpFunctions';
import { RequestParams } from './useHttp';


declare module 'axios' {
    interface AxiosResponse<T = any> extends Promise<T> {}
}

interface AxiosContextType {
    incrementActiveRequests: () => void;

    decrementActiveRequests: () => void;

    isTest?: boolean;
}

const initialAxiosContext: AxiosContextType = {
    incrementActiveRequests: () => {
    },
    decrementActiveRequests: () => {
    },
    isTest: false
};

export const AxiosContext = createContext<AxiosContextType>(initialAxiosContext);

type AxiosProviderProps = {
    children: PropsWithChildren<any>,
    isTest?: boolean
}

export const AxiosProvider: React.FC<AxiosProviderProps> = ({ children, isTest }) => {
    const activeRequests = useRef(0);
    const { setLoadingScreenProps } = useLoadingScreen();


    const incrementActiveRequests = () => {
        activeRequests.current++;
        setLoadingScreenProps({ show: true });
    };

    const decrementActiveRequests = () => {
        activeRequests.current--;
        if (activeRequests.current === 0) {
            setLoadingScreenProps({ show: false });
        }
    };

    useEffect(() => {
        return () => {
            activeRequests.current = 0;
        };
    }, []);

    return (
        <AxiosContext.Provider
            value={ {
                isTest,
                incrementActiveRequests,
                decrementActiveRequests
            } }
        >
            { children }
        </AxiosContext.Provider>
    );
};

export const useAxiosContext = (): AxiosContextType => useContext(AxiosContext);


export type RelationSearch = {
    relationCol: string,
    value: string // @todo generic relation model injection for column support
}

type ErrorBody = {
    message?: string
    exception?: string
}

type ErrorResponseBody = {
    error?: ErrorBody
}

export type WhereInHttpParam = {
    key: string,
    operator: '='|'!='
    values: (string|number)[]
}


export type WhereCountHttpParam<RelationBlueprint extends string> = `${ RelationBlueprint }${ '>'|'<'|'' }${ '='|'' }${ number }`
export type Params<Entity, RelationBlueprint extends string> = {
    select?: (keyof Entity|`${RelationBlueprint}.${string}`)[],
    orderBy?: string,
    order?: 'ASC'|'DESC',
    page?: number,
    limit?: number|'all',
    filter?: string,
    searchCols?: string[],
    search?: string,
    debug?: boolean
    with?: RelationBlueprint[]
    withCount?: RelationBlueprint[],
    relationSearch?: RelationSearch[],
    sortCount?: string,
    doesntHave?: RelationBlueprint[],
    whereCount?: `${ RelationBlueprint }${ '>'|'>='|'='|'!='|'<'|'<='}${ number }`,
    whereIn?: WhereInHttpParam,
    skipCancelToken?: boolean
};

type ReturnType = {
    get: <Entity, RelationBlueprint extends string = ''>(
        endpoint: string,
        params?: Params<Entity, RelationBlueprint>,
        config?: AxiosRequestConfig
    ) => Promise<AxiosResponse<Entity>>;
    put: <Payload, Entity>(
        endpoint: string,
        payload: Partial<Payload>
    ) => Promise<AxiosResponse<Entity>>;
    post: <Payload, Entity extends object = { id: number }>(
        endpoint: string,
        payload: Payload
    ) => Promise<AxiosResponse<Entity>>;
    delete: <Entity>(endpoint: string) => Promise<AxiosResponse<Entity>>;
}

export type AxiosContentType = 'multipart/form-data'|'application/json'

type UseAxiosProps = {
    baseUrl?: string,
    omitLoadingScreen?: boolean,
    contentType?: AxiosContentType
}
const useAxios = (props: UseAxiosProps|null = null): ReturnType => {

    const baseUrl = props?.baseUrl;
    const omitLoadingScreen = props?.omitLoadingScreen ?? false;
    const contentType = props?.contentType ?? 'application/json';

    const redirectToLogout = useRedirectToLogout();
    const { setToasterProps } = useToaster();
    const { incrementActiveRequests, decrementActiveRequests } = useAxiosContext();
    const { token } = useToken();

    const abortControllerExceptions = ['users/logout', 'users/verify-login'];

    /**
     * Hashmap of abort controllers by get request.
     */
    const abortControllers = useRef(new Map<string, AbortController>());

    const getAxiosRequestConfig = (): AxiosRequestConfig => {
        const axiosRequestConfig: AxiosRequestConfig = {
            baseURL: `${ baseUrl ?? API_URL }/api/v1`,
            headers: {
                'Content-type': contentType,
                'Accept': 'application/json'
            }
        };

        if (token) {
            axiosRequestConfig.withCredentials = true;
            axiosRequestConfig.headers!['Authorization'] = `Bearer ${ token }`;
        }

        return axiosRequestConfig;
    };
    const instance = axios.create(getAxiosRequestConfig());

    const getDuplicateErrorIfExists = (error: AxiosError<ErrorResponseBody>): SetStateAction<ToasterProps>|undefined => {
        const errorMessage: string|undefined = error.response?.data?.error?.message;

        if (errorMessage?.startsWith('DuplicateException') === true) {
            const valueLocation = errorMessage.indexOf(':') + 1;
            const value = errorMessage.slice(valueLocation).trim();

            return {
                title: error.name,
                message: parseDuplicateErrorMessage(value),
                type: 'error',
                show: true
            };
        }

        return undefined;
    };

    const handleErrorResponse = (error: AxiosError<ErrorResponseBody>): SetStateAction<ToasterProps> => {
        const duplicateErrorProps = getDuplicateErrorIfExists(error);
        if (duplicateErrorProps !== undefined) {
            return duplicateErrorProps;
        }

        let message = parseErrorMessage(error.response?.status);
        try {
            const errorBody = JSON.parse(error?.request?.response) as ErrorBody;
            if (errorBody.message?.includes("UpdateException")) {
                message = errorBody?.message ?? message;
            }
        } catch (exception) { console.error('Failed to parse error-body', exception); }

        let show = true;
        if (error.name.includes('CanceledError')) {
            show = false;
        }

        return {
            title: error.name,
            message: message,
            type: 'error',
            show: show
        };
    };


    /**
     *
     */
    instance.interceptors.request.use((config) => {
        if (!omitLoadingScreen) {
            incrementActiveRequests();
        }
        return config;
    });


    /**
     *
     */
    instance.interceptors.response.use(
        (response) => {
            if (!omitLoadingScreen) {
                decrementActiveRequests();
            }
            return response;
        },
        async(error: AxiosError<ErrorResponseBody>) => {
            if (!omitLoadingScreen) {
                decrementActiveRequests();
            }


            const props = handleErrorResponse(error);
            setToasterProps(props);

            const route = location.pathname;
            if (error.response?.status === 401) {
                if (route !== '/logout' && route !== '/login') {
                    redirectToLogout();
                }
            }
            return Promise.reject(error);
        }
    );


    /**
     *
     * @param key
     * @param values
     */
    const parseParamStringArr = (key: string, values: string[]|undefined): string => {
        let paramStr = '';

        if (values === undefined || values.length<1) {
            return paramStr;
        }

        paramStr = `${ key }=`;
        const nItems = values.length;
        values.forEach((item, index) => {
            paramStr = paramStr.concat(item);
            if (index<nItems - 1) {
                paramStr = paramStr.concat(',');
            }
        });
        return paramStr;
    };


    /**
     * Search for values in cols of relationships.
     * @param relationSearch
     */
    const parseRelationSearch = (relationSearch?: RelationSearch[]): string => {
        // @todo multi relation support
        if (relationSearch && relationSearch.length>0) {
            return `relationSearch=${ relationSearch[0].relationCol },${ relationSearch[0].value }`;
        }
        return '';
    };

    /**
     *
     * @param key
     * @param value
     */
    const parseKeyValueToString = (key: string, value?: string|number): string => value === undefined ?'' :`${ key }=${ value }`;

    const parseSortCountValue = (value?: string|undefined, defaultDir?: 'ASC'|'DESC'): string|undefined =>
        value ?
            (value.includes(',') ?value :`${ value },${ defaultDir }`)
            :undefined;


    /**
     *
     * @param params
     * @param endpoint
     * @fixme any any
     */
    const parseParams = (params?: RequestParams<Partial<any>, any>, endpoint?: string): string => {

        let paramStr = endpoint != undefined && endpoint.includes('?') ? '&' : '?';
        const parsedParams: string[] = [];
        if (!PRODUCTION_MODE) {
            const debugStr = 'debug=true';
            if (params === undefined) {
                return `${paramStr}${ debugStr }`;
            }
            parsedParams.push(debugStr);
        }


        if (params === undefined) {
            return '';
        }

        const sortCountValue = parseSortCountValue(params.sortCount, params.order);

        const hasSearch = (params.search??'').replaceAll(' ', '') != '';
        const hasSearchCols = (params.searchCols ?? []).length > 0
        const hasRelationSearchCols = (params.relationSearch ?? []).length > 0
        if(hasSearch && (hasSearchCols||hasRelationSearchCols)){
            parsedParams.push(parseKeyValueToString('search', params.search))
            if (hasSearchCols) {
                parsedParams.push(parseParamStringArr('searchCols', (params.searchCols ?? [])))
            }

            // @todo multi relation col support
            if (hasRelationSearchCols) {
                parsedParams.push(parseRelationSearch(params.relationSearch))
            }
        } else if (hasRelationSearchCols && params.relationSearch?.[0].value != undefined) {
            // check if own value was added
            parsedParams.push(parseRelationSearch(params.relationSearch))
        }

        parsedParams.push(...[
            parseParamStringArr('select', params.select as unknown as string[]),
            parseParamStringArr('with', params.with),
            parseParamStringArr('doesntHave', params.doesntHave),
            parseParamStringArr('withCount', params.withCount),

            parseKeyValueToString('sortCount', sortCountValue),
            parseKeyValueToString('whereCount', params.whereCount),
            parseKeyValueToString('orderBy', sortCountValue ?undefined :params.orderBy),
            parseKeyValueToString('order', sortCountValue ?undefined :params.order),
            parseKeyValueToString('page', params.page),
            parseKeyValueToString('limit', params.limit),
            parseKeyValueToString('filter', params.filter),

        ]);

        if (params.whereIn !== undefined && params.whereIn.values.length > 0) {
            parsedParams.push(`whereIn=${ params.whereIn.key }${ params.whereIn.operator }{${ params.whereIn.values.join(',') }}`);
        }

        parsedParams.forEach((param) => {
            if (param !== '') {
                paramStr = paramStr.concat(param).concat('&');
            }
        });
        // Rm last ampersand OR rm question mark if no params present.
        return paramStr.slice(0, -1);
    };


    /**
     *
     * @param endpoint
     * @param params
     * @param config
     */
    const get = async <Entity, Params = null>(endpoint: string, params?: Params, config?: AxiosRequestConfig): Promise<AxiosResponse<Entity>> => {
        if (!abortControllerExceptions.includes(endpoint) || (params as unknown as RequestParams<Entity, any>)?.skipCancelToken != true) {
            // Only abort if search params are present.
            const hasSearchParam = ((params as unknown as RequestParams<Entity, ''>)?.search?? '').replaceAll(' ', '') != ''
            if (params != undefined && hasSearchParam) {
                if (abortControllers.current.has(endpoint)) {
                    abortControllers.current.get(endpoint)?.abort();
                }
                if (config === undefined || config.signal === undefined) {
                    const abortController = new AbortController();
                    abortControllers.current.set(endpoint, abortController);
                    config = { ...config, signal: abortController.signal };
                }
            }
        }

        return await instance.get<Entity>(endpoint.concat(parseParams(params, endpoint)), config).catch(err => {
            console.warn(err);
            return {} as Entity
        })
    };


    /**
     *
     * @param endpoint
     * @param payload
     */
    const put = async <Payload, Entity>(endpoint: string, payload: Partial<Payload>): Promise<AxiosResponse<Entity>> => (
        await instance.put<Entity>(endpoint.concat(parseParams()), payload)
    );


    /**
     *
     * @param endpoint
     * @param payload
     */
    const post = async <Payload, Entity extends object = { id: number }>(endpoint: string, payload: Payload): Promise<AxiosResponse<Entity>> => (
        await instance.post<Entity>(endpoint.concat(parseParams()), payload)
    );


    /**
     *
     * @param endpoint
     */
    const del = async <Entity, >(endpoint: string): Promise<AxiosResponse<Entity>> => (
        await instance.delete<Entity>(endpoint.concat(parseParams()))
    );


    return {
        get,
        put,
        post,
        delete: del
    };
};

export default useAxios;
