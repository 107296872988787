import React, { FC } from 'react';
import useProductAssessment from '../http/useProductAssessment';
import BaseAssessmentTable from './BaseAssessmentTable';
import { useProductAssessmentFilters } from './hooks/useAssessmentTableFilters';


type Props = {
    productNumber: string
}

const ProductAssessmentTable: FC<Props> = ({ productNumber }) => {

    const assessmentHistoryHook = useProductAssessment(productNumber);
    const filters = useProductAssessmentFilters();

    return <BaseAssessmentTable
        httpHook={ assessmentHistoryHook }
        hideProduct={ true }
        withRequestedAt={ true }
        witApprovedAt={ true }
        filters={ filters }
    />;
};

export default ProductAssessmentTable;
