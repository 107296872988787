import React, { FC, useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import GaLogo from '../../assets/images/great-applications-logo.png'
import { SideBarDto, SideBarFields } from '../../resources/sidebar/SideBarTypes';
import useSideBar from '../../resources/sidebar/useSideBar';
import {IsCurrentNavItem, navigation} from "../../Router";
import {classNames} from "../../lib/samfe/modules/Parse/String";
import {DISABLE_SIDEBAR} from "../../config/config";

type Props = {
    onNavItemClick?: () => void
}
const SidebarMenu: FC<Props> = ({onNavItemClick}): JSX.Element => {

    const sidebar = useSideBar()
    const [ sideBarData, setSideBarData ] = useState<SideBarDto>();
    const [count, setCount] = useState(0)


    const getSideBarData = () => {
        sidebar.get().then(items => {

            const sideBarData: SideBarDto = {};
            const parsedItems = items as {[k:string]: number}
            Object.keys(parsedItems).forEach(key => {
                const item = parsedItems[key];
                if(item === 0) {
                    return;
                }
                sideBarData[key as keyof SideBarDto] = item;
            })
            setSideBarData(sideBarData)
        })
    }


    useEffect(() => {
        getSideBarData()
    }, []);

    useEffect(() => {
        if (DISABLE_SIDEBAR) {
            return () => true;
        }
        const timer = setTimeout(() => {
            setCount(count + 1)
            getSideBarData();
        }, 60_000)

        return () => clearTimeout(timer)
    }, [count])


    const getStatusItem = (dataKey?: keyof SideBarFields) => {
        if (!dataKey || !sideBarData || sideBarData[dataKey] === undefined) {
            return <></>
        }
        return <small className={'select-none w-9 text-center block py-0.5 rounded-md bg-aqua text-white text-xs absolute top-2.5 right-2.5'}>
            {sideBarData[dataKey]}
        </small>
    }

    return(
        <>
            <Link className={'block w-full items-center px-7 pt-4'} to={'/dashboard'}>
                <img src={GaLogo} alt={'Great Applications'} className={'company-logo text-center select-none object-contain h-16 w-auto'} />
            </Link>

            <div className={'overflow-y-auto  h-[calc(100vh-8.125rem)] px-2 pb-4'}>
                <div className="flex-grow flex flex-col">
                    <nav className="sidebar flex-1 px-2 pb-8">
                        {navigation.map((item, index) => (
                            <div key={index} className={index === navigation.length-1 ? 'absolute bg-white pb-2 px-4 bottom-0  left-0 right-px' : ''}>
                                <div className="relative">
                                    {item.href
                                     ?   <Link
                                         draggable={"false"}
                                         onClick={onNavItemClick}
                                         to={item.href}
                                         className={classNames(
                                             IsCurrentNavItem(item)
                                             ? 'bg-aqua bg-opacity-[7.5%] text-aqua'
                                             : 'text-graphite hover:bg-aqua hover:bg-opacity-[3.75%]',
                                             'group flex items-center px-2 py-2 text-sm rounded-md'
                                         )}
                                     >
                                         {IsCurrentNavItem(item) ? <span className={'border-l-aqua border-l-[4px] relative -left-2 p-0 h-2.5 w-0 -mr-1'}>&nbsp;</span> : <></>}

                                         <span // icon
                                             className={classNames(
                                                 IsCurrentNavItem(item)
                                                 ? 'opacity-90'
                                                 : 'group-hover:text-aqua-hover opacity-[55%] group-hover:opacity-80',
                                                 'select-none ml-2 mr-3 flex-shrink-0 text-2xl text-aqua', 'material-icons'
                                             )}
                                             aria-hidden="true"
                                         >
                                                    {item.icon}
                                                </span>
                                         <span className={classNames(IsCurrentNavItem(item) ? 'font-bold' : 'font-medium')}>
                                                        {item.name}
                                                </span>
                                         {getStatusItem(item.dataKey)}
                                     </Link>
                                     :   <span className={classNames('ml-4 pt-4 block text-sm font-bold text-aqua', IsCurrentNavItem(item) ? 'text-aqua' : 'text-graphite opacity-75')}>
                                            {item.name}
                                        </span>
                                    }

                                </div>

                            </div>
                        ))}
                    </nav>
                </div>
            </div>
        </>
    )
}

export default SidebarMenu;