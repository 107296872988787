import { Dispatch, SetStateAction, useMemo } from 'react';
import { Pagination } from '../../../lib/samfe/modules/Http/usePagination';
import { useTableContext } from '../providers/Table.provider';

type TablePaginationState<T> = [ Pagination<T>, Dispatch<SetStateAction<Pagination<T>>> ];

const useTablePagination = <T extends object, R extends string>(): TablePaginationState<T> => {

    const ctx = useTableContext<T, R>();
    return useMemo((): TablePaginationState<T> => {
        const paginationState = ctx.http.hook.pagination;
        return [
            paginationState.pagination,
            paginationState.setPagination
        ];
    }, [ ctx.http.hook.pagination ]);
};

export default useTablePagination;
