import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import {PackagePartDto, PackagePartModel, PackagePartRelationsBluePrint, PackagePartTypes} from "./PackagePartTypes";
import {FormModal} from "../../lib/samfe/components/Modal";
import React from "react";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import Yup from "../../lib/samfe/components/Form/Yup";
import {useForm} from "../../lib/samfe/components/Form";
import useAsyncInit from "../../lib/samfe/components/Form/Effects/useAsyncInit";
import usePackagePart from "./usePackagePart";
import {SelectOption} from "../../lib/samfe/components/Form/Effects/useSelect";
import {optionIsSelected} from "../../lib/samfe/components/Form/Support/FieldSupport";

const PackagePartForm: React.FC<ExtendFormModalProps<PackagePartDto>> = ({id, open, setOpen, onSuccess}): JSX.Element => {

    const packagePart = usePackagePart();

    const shape = (initialPackagePart?: PackagePartModel): Shape<PackagePartDto> => ({
        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text'),
        short_name: Yup.string()
            .label('Korte naam')
            .controlType('input')
            .max(40)
            .inputType('text'),

        type: Yup.string()
            .required()
            .label('Type')
            .controlType('select')
            .options(PackagePartTypes.map((packagePartType, i) => ({
                displayName: packagePartType,
                value: packagePartType,
                selected: optionIsSelected(initialPackagePart?.type, packagePartType, i)
            }) as SelectOption )),
    })

    const {validationSchema, setShape} = useSchema<PackagePartDto>(shape());

    const initializer = async () => {
        const initialModel = await packagePart.getItem(id)
        setShape(shape(initialModel))
    }

    const {formikConfig, formFields} = useForm<PackagePartModel, PackagePartDto, PackagePartRelationsBluePrint>({
        id: id,
        validationSchema: validationSchema,
        useHttpHook: usePackagePart,
        onSuccess,
        initializer,
        initialized: useAsyncInit(initializer, open),
    });

    return <FormModal
        id={id}
        resource={'Verpakkingsonderdeel'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
}

export default PackagePartForm;