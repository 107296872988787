import { useMemo } from 'react';
import { AssessmentHistory, AssessmentStatus } from '../types/AssessmentTypes';


const useAssessmentStatus = (item: AssessmentHistory) => {
    return useMemo((): AssessmentStatus => {
        if (([ 'test_requested', 'to_test', 'approved' ] as AssessmentStatus[]).includes(item.current.assessment.status)) {
            return item.current.assessment.status;
        }

        if (item.parent?.assessment?.status && ([ 'test_requested', 'to_test', 'approved' ] as AssessmentStatus[]).includes(item.parent.assessment.status)) {
            return item.parent.assessment.status;
        }

        if (item.previous?.assessment?.status) {
            return item.previous.assessment.status;
        }

        if (item.fallback?.assessment?.status) {
            return item.fallback.assessment.status;
        }

        return item.current.assessment?.status;
    }, [
        item.current.assessment.status,
        item.parent?.assessment.status,
        item.previous?.assessment.status,
        item.fallback?.assessment?.status
    ]);
};

export default useAssessmentStatus;
