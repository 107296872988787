import { ArticleProducerModel } from '../../../article/pivot/ArticleProducer/ArticleProducerTypes';
import {ChargeModel} from "../../../charge/ChargeTypes";
import {ArticleModel} from "../../../article/ArticleTypes";
import { PurchaseModel, PurchaseStatusTranslation } from '../../PurchaseTypes';
import {SoftDeleteModel} from "../../../../lib/samfe/types/ModelTypes";

export type PurchaseRowRelationsBluePrint = (
    'purchase'
    |'purchase.producer'
    |'articleProducer'
    |'article'
    |'article.product'
    |'article.package.parts'
    |'charge'
    |'eta'
    |'delivery_time'
    |'charge.samfeStock'
);


export type PurchaseRowStatus = 'open'|'sent'|'received'|'booked'
export const PurchaseRowStatuses: PurchaseRowStatus[] = [
    'open','sent','received','booked'
]

export const PurchaseRowStatusDisplayName = PurchaseStatusTranslation


export type PurchaseRowFields = {
    purchase_id: number,
    article_id: number,
    charge_id: number,
    quantity: number,
    price_per_amount: number,
    comments: string,
    status: PurchaseRowStatus,
}

export type PurchaseRowDto = Partial<PurchaseRowFields> & {
    sent_at?: string|null,
    received_at?: string|null
}

type SpecialParams = {
    eta: number|'-',
    delivery_time: number|'-',
    booked_quantity: number
}

export type PurchaseRowRelations = SpecialParams & {
    purchase: PurchaseModel,
    charge: ChargeModel,
    article: ArticleModel,
    articleProducer: ArticleProducerModel,
}

export type PurchaseRowModel = SoftDeleteModel
    & Partial<PurchaseRowRelations>
    & PurchaseRowDto
    & Partial<SpecialParams>
