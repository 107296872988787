import React, { FC } from 'react';
import Disclosure from '../../../../../lib/samfe/components/Disclosure/Disclosure';
import Icon from '../../../../../lib/samfe/components/Icon/Icon';


const AssessmentRequestGuide: FC<{batchCode?: string}> = ({batchCode}) => {
    return (
        <Disclosure title={ 'Uitleg' } variation={ 'info' } className={ '!-mx-6 lg:!-mx-4 2xl:!-mx-6 -mt-5' } toggle={ {
            openendState: {
                title: 'Verbergen'
            },
            closedState: {
                title: 'Weergeven'
            }
        } }>

            <Disclosure title={ 'Risico\'s' } variation={ 'descriptive' } toggle={ {
                hide: {
                    title: true
                }
            } }>
                Dit zijn de risico&apos;s van <span className={ 'font-semibold' }>{ batchCode ?`charge ${ batchCode }` :'de huidige charge' }</span>&nbsp;
                die geanalyseerd moeten worden volgens het testbeleid.
            </Disclosure>

            <Disclosure title={ 'Optioneel' } variation={ 'descriptive' } toggle={ {
                hide: {
                    title: true
                }
            } }>
                Dit zijn risico&apos;s die in oudere charges behandeld horen te worden.<br/>
                De <span className={ 'font-semibold' }>Kleurcode</span> geeft de huidige status aan.
            </Disclosure>

            <Disclosure title={ 'Niet van toepassing' } variation={ 'descriptive' } toggle={ {
                hide: {
                    title: true
                }
            } }>
                Dit zijn risico&apos;s die geen gevaar vormen volgens het testbeleid.
            </Disclosure>

            <Disclosure title={ 'Frequentie' } variation={ 'descriptive' } toggle={ {
                hide: {
                    title: true
                }
            } }>
                Dit wordt aangegeven met bijv. <span className={ 'font-semibold' }>(1/2)</span> en geeft aan hoeveel charges geleden zijn geanalyseerd.<br/>
                <ul className={ 'list-inside text-sm all-children:leading-5 leading-5 font-light my-2' }>
                    <li><span className={ 'w-12 inline-block font-medium' }>0</span>&nbsp;=&ensp;Niet van toepassing</li>
                    <li><span className={ 'w-12 inline-block font-medium' }>1</span>&nbsp;=&ensp;Huidige charge moet geanalyseerd worden</li>
                    <li><span className={ 'w-12 inline-block font-medium' }>Hoger</span>&nbsp;=&ensp;Van toepassing op oudere charges *</li>
                </ul>
                <small>* Analyses kunnen tactisch ingepland worden indien gewenst.</small>
            </Disclosure>

            <Disclosure title={ 'Kleurcodes' } variation={ 'descriptive' } toggle={ {
                hide: {
                    title: true
                }
            } }>
                <p className={ 'text-sm all-children:leading-5 leading-5 font-light my-2' }>
                    <span className={ 'w-12 inline-block font-medium text-graphite' }>Zwart</span>&nbsp;=&ensp;Geen actie benodigd<br/>
                    <span className={ 'w-12 inline-block font-medium text-sky' }>Blauw</span>&nbsp;=&ensp;Wachten op analyse uitslag<br/>
                    <span className={ 'w-12 inline-block font-medium text-green-700/90' }>Groen</span>&nbsp;=&ensp;Geborgd binnen de frequentie<br/>
                    <span className={ 'w-12 inline-block font-medium text-fire' }>Rood</span>&nbsp;=&ensp;Geen borging aanwezig
                </p>
            </Disclosure>
            <Disclosure title={ 'Gevarendriehoek' } variation={ 'descriptive' } toggle={ {
                hide: {
                    title: true
                }
            } }>
                <p className={ 'text-sm children:leading-5 leading-5 font-light my-2' }>
                    <span className={ 'w-12 inline-block font-medium text-graphite' }><Icon className={'text-carrot relative -top-0.5 pr-1'} materialIconName={'warning'}/></span>&nbsp;=&ensp;Geblokkeerd voor analyse<br/>
                </p>
            </Disclosure>
        </Disclosure>
    );
};

export default AssessmentRequestGuide;
