import React from 'react';
import Button, {ButtonProps} from "./Button";
import {EditIcon} from "../Icon";

const EditButton: React.FC<ButtonProps> = ({
    style,
    small,
    iconClassName,
    text,
    ...props
}):JSX.Element => <Button
    {...props}
    style={style}
    small={small}
    icon={<EditIcon className={iconClassName} />}
    text={text ?? 'Aanpassen'}
    textColor={'white'}
    bgColor={{
        regular: 'aqua',
        hover: 'aqua-hover',
        active: 'aqua-active'
    }}
/>
export default EditButton;
