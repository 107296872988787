import moment from 'moment';
import { generate as generateWords } from 'random-words';
import { useMemo, useState } from 'react';
import { ListResponse } from '../../../lib/samfe/modules/Http/useHttp';
import { SoftDeleteModel } from '../../../lib/samfe/types/ModelTypes';
import { ModelSearchCol, RelationSearchCol } from '../types/TableSearch';


const TABLE_TEST_PER_PAGE = 389;


export type TestTableFields = {
    product: string,
    quantity: number,
    price: number,
    profitMargin: number,
    expirationDate: string,
}

export type TestTableDto = Partial<TestTableFields>
export type TestTableModel = SoftDeleteModel&TestTableDto
export type TestTableRelationsBlueprint = ''
export type TestTableSearchCols = (ModelSearchCol<TestTableModel>|RelationSearchCol<TestTableRelationsBlueprint>)[]

const randomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

const getQuantity = () => {
    return randomBetween(8, 196);
};

const getPrice = () => {
    return parseFloat((randomBetween(495, 2495) / 100).toFixed(2));
};


const getPercentage = () => randomBetween(5, 200);

const getDate = () => {
    const year = randomBetween(2025, 2027);
    const month = randomBetween(0, 11);
    const day = randomBetween(1, 28);
    const hour = randomBetween(0, 59);
    const minute = randomBetween(0, 59);
    const second = randomBetween(0, 59);

    const randomDate = new Date(year, month, day, hour, minute, second);
    return moment(randomDate).format('Y-MM-DD HH:mm:ss');
};


const filterItems = (items: TestTableModel[], filter?: string) => {
    if (!filter) {
        return items;
    }
    console.log({splitted: filter.split(',', 2)})

    let filteredItems = [...items]
    filter.split(',', 2).forEach(filterPart => {
        let column: keyof TestTableModel|null = null;
        let operator: '='|'!='|'<'|'>'|'>='|'<='|null = null;
        let value: number|string|boolean|null = null;
        if (filterPart) {
            if (filterPart.includes('<')) {
                operator = filterPart.includes('<=') ?'<=' :'<';
            } else if (filterPart.includes('>')) {
                operator = filterPart.includes('>=') ?'>=' :'>';
            } else if (filterPart.includes('=')) {
                operator = filterPart.includes('!=') ?'!=' :'=';
            }
            if (operator) {
                const filterParts = filterPart.split(operator);
                column = filterParts[0] as keyof TestTableModel;
                value = filterParts[1];
                if (!isNaN(Number(value))) {
                    value = Number.isInteger(value) ?parseInt(value) :parseFloat(value);
                } else if (column == 'archived') {
                    value = value == 'true';
                }
            }
        }

        if (column === null || value === null || operator === null) {
            return;
        }

        filteredItems = filteredItems.filter(item => {
            if (column === null || value === null || operator === null) {
                return false;
            }

            switch (operator) {
                case '<':
                    return item[column]!<value;
                case '<=':
                    return item[column]!<=value;
                case '>':
                    return item[column]!>value;
                case '>=':
                    return item[column]!>=value;
                case '!=':
                    return item[column]! != value;
                case '=':
                    return item[column]! == value;
            }
        })

    })
    return filteredItems
};


const sortItems = (items: TestTableModel[], sortCol: keyof TestTableModel = 'id', sortDir: 'ASC'|'DESC' = 'ASC') => {
    const aMatchVal = sortDir == 'ASC' ?-1 :1;
    const bMatchVal = aMatchVal * -1;

    return items.sort((a, b) => {
        const aCol = a[sortCol];
        const bCol = b[sortCol];
        if (aCol === bCol) {
            return 0;
        }

        if (!aCol) {
            return bMatchVal;
        }

        if (!bCol) {
            return aMatchVal;
        }

        if (typeof aCol === 'string') {
            return aCol.localeCompare(bCol as string) * (sortDir == 'ASC' ?1 :-1);
        }

        return aCol<bCol ?aMatchVal :bMatchVal;
    });
};

const searchItems = (items: TestTableModel[], search: string|undefined, searchCols: TestTableSearchCols = []) => {
    if (!search || searchCols.length == 0) {
        return items;
    }

    return items.filter(item => {
        for (let i = 0; i<searchCols.length; i++) {
            const key = searchCols[i]
            const value = item[key as keyof TestTableModel]
            if (!value) {
                continue;
            }

            if (value.toString().includes(search)) {
                return true
            }
        }
        return false
    })
};


const paginateItems = (items: TestTableModel[], currentPage: number, perPage: number) => {
    const indexStart = currentPage * perPage;
    const indexEnd = indexStart + perPage;
    return items.slice(indexStart, indexEnd);
};


const useTableTestDataHook = () => {

    const initialItems: TestTableModel[] = useMemo(() => (generateWords({
        exactly: TABLE_TEST_PER_PAGE,
        seed: 'GA is tha bomb'
    }) as string[]).map((product, index) => ({
        id: index + 1,
        product,
        quantity: getQuantity(),
        price: getPrice(),
        profitMargin: getPercentage(),
        expirationDate: getDate(),
        archived: index % 13 == 1
    })), []);


    const [ allItems, setAllItems ] = useState(initialItems);

    const getItem = async (id?: number): Promise<TestTableModel|undefined> => {
        if (!id) {
            return undefined;
        }
        return allItems.find(item => item.id == id);
    };

    const getList = async(
        perPage: number,
        currentPage: number = 0,
        sortDir: 'ASC'|'DESC' = 'ASC',
        sortCol: keyof TestTableModel = 'id',
        filter: string|undefined,
        searchCols: TestTableSearchCols,
        search: string|undefined
    ): Promise<ListResponse<TestTableModel>> => {

        const filteredItems = filterItems(allItems, filter);
        const sortedItems = sortItems(filteredItems, sortCol, sortDir);
        const searchedItems = searchItems(sortedItems, search, searchCols);
        const paginatedItems = paginateItems(searchedItems, currentPage, perPage);


        console.log({mockDataTotal: sortedItems.length})
        return {
            items: paginatedItems,
            limit: perPage,
            page: currentPage,
            order: sortDir,
            orderColumn: sortCol,
            total: searchedItems.length
        };
    };


    const create = async(dto: TestTableDto): Promise<{ id: number }|undefined> => {
        const id = allItems.length + 1;
        const newItem: TestTableModel = ({
            ...dto,
            id: allItems.length + 1,
            archived: false
        });

        setAllItems([ ...allItems, newItem ]);
        return { id };
    };


    const update = async(id: number|undefined, payload: TestTableDto): Promise<TestTableModel|undefined> => {
        const itemIndex = allItems.findIndex(item => item.id == id);
        if (itemIndex<0) {
            return undefined;
        }
        const newList = [ ...allItems ];
        newList[itemIndex] = {
            ...newList[itemIndex],
            ...payload
        };

        setAllItems(newList);
        return newList[itemIndex];
    };


    const destroy = async(id: number): Promise<{ id: number }|undefined> => {
        const itemIndex = allItems.findIndex(item => item.id == id);
        if (itemIndex<0) {
            return undefined;
        }
        const newList = [ ...allItems ];
        newList[itemIndex] = {
            ...newList[itemIndex],
            archived: true
        };

        setAllItems(newList);
        return { id };
    };

    return {
        getItem,
        getList,
        create,
        update,
        destroy
    };
};

export default useTableTestDataHook;
