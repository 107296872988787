import React, { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';


/**
 * Context state.
 *
 * @todo Create state
 */
type FormModalContext = {
    omitCreateAnotherOne: boolean
}

/**
 * Context type.
 *
 * @todo Set context type
 */
type FormModalContextCtx = {
    formModalContext: FormModalContext;
    setFormModalContext: Dispatch<SetStateAction<FormModalContext>>;
};

/**
 * Default context as fallback.
 *
 * @todo Set default context
 */
const defaultFormModalContextCtx: FormModalContextCtx = {
    formModalContext: {
        omitCreateAnotherOne: false
    },
    setFormModalContext: (_) => null
};

/**
 * Context.
 *
 * @todo Rename context object
 */
const formModalContextCtx = createContext<FormModalContextCtx>(defaultFormModalContextCtx);

/**
 * Context accessor hook.
 *
 * @todo Rename/
 */
export const useFormModalContextCtx = (): FormModalContextCtx => {
    const context = useContext(formModalContextCtx);
    if (!context) {
        throw new Error('useFormModalContextCtx must be used within a FormModalContextProvider');
    }
    return context;
};

/**
 * Context provider properties.
 *
 * @todo Set provider props
 */
type FormModalContextProviderProps = {
    children: ReactNode,
    ctx: FormModalContextCtx
}

/**
 * Context provider.
 *
 * @todo Alter/Rename State provider
 */
const FormModalContextProvider: FC<FormModalContextProviderProps> = ({ children, ctx }): JSX.Element => {
    const [ formModalContext, setFormModalContext ] = useState<FormModalContext>(ctx.formModalContext);
    return (
        <formModalContextCtx.Provider value={ { formModalContext, setFormModalContext } }>
            { children }
        </formModalContextCtx.Provider>
    );
};

export default FormModalContextProvider;
