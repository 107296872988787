import { Combobox } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import React, { CSSProperties, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { LocationSearchResult } from '../../../../../../resources/location/ChargeLocationTypes';
import useAxios from '../../../../modules/Http/useAxios';
import { ListResponse } from '../../../../modules/Http/useHttp';
import Label from '../../Caption/Label';
import { DynamicSearchConfig } from './SelectSearchTypes';


/**
 * Card list of selected items by user with cancel button
 */
const SelectedList: FC<{
    results: LocationSearchResult[],
    handleRemove: (index: number) => void
}> = ({
    results,
    handleRemove
}): JSX.Element => (
    <>
        { results.length>0 && <div className={ 'grid gap-y-2 bg-aqua/5 p-3 rounded-b-md' }>
            { results.map((value, index) => (
                <div key={ index } className={ 'inline-flex justify-between items-center w-full border px-2.5 py-1.5 leading-6 rounded-md border-gray-300 bg-white' }>
                    <span className={ 'text-graphite font-medium text-sm' }>{ value?.display }</span>
                    <button onClick={ () => handleRemove(index) }>
                        <XMarkIcon className="h-6 w-6 text-fire" aria-hidden="true"/>
                    </button>
                </div>
            )) }
        </div> }
    </>
);


type DynamicSearchProps = {
    // @feature generics for reusing component
    config: DynamicSearchConfig<LocationSearchResult>;
    label: string;
    singular?: boolean
}

const DynamicSearch: FC<DynamicSearchProps> = ({ config, label, singular = false }): JSX.Element => {

    const axios = useAxios();
    const inputSize = useResizeDetector();
    const inputRef = useRef<HTMLInputElement>(null);

    const [ query, setQuery ] = React.useState('');
    const [ currentValue, setCurrentValue ] = useState<LocationSearchResult>();
    const [ searchResults, setSearchResults ] = useState<LocationSearchResult[]>([]);
    const [ abortController, setAbortController ] = useState<AbortController>();

    /**
     * Search endpoint
     */
    const endpoint: string = useMemo(() => config.searchResource, [ config.searchResource ]);


    /**
     * State management
     */
    const [ selectedValues, setSelectedValues ] = useMemo(() => {
        return [ config.values ?? [], config.setValues ];
    }, [ config.values, config.setValues ]);


    /**
     * Handle initial display value
     */
    useEffect(() => {
        setQuery(config.initialDisplay ?? '');
        if (inputRef?.current) {
            inputRef.current.value = config.initialDisplay ?? '';
        }
    }, [ config.initialDisplay ]);


    const fetchSearchResults = async() => {
        if (abortController) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        return await axios.get<ListResponse<LocationSearchResult>>(endpoint, { search: query }, {
            signal: newAbortController.signal
        }).then(r => {
            setAbortController(undefined);
            return r.data?.items ?? [];
        });
    };

    /**
     * Handle search value change
     */
    useEffect(() => {
        fetchSearchResults().then(setSearchResults);
    }, [ endpoint ]);

    const [ prevQuery, setPrevQuery ] = useState('');
    const filteredList = useMemo(() => {
        setPrevQuery(query);
        if (query.replaceAll(' ', '') == '') {
            return searchResults;
        }

        const filtered = searchResults.filter(sr => sr.display.toLowerCase().includes(query.toLowerCase()));
        if (filtered.length == 0 && searchResults.length>0 && query != prevQuery) {
            fetchSearchResults().then(setSearchResults);
        }
        return filtered;
    }, [ query, searchResults, prevQuery ]);


    /**
     * List of filtered item to prevent duplicate selections.
     */
    const availableOptions: LocationSearchResult[] = useMemo(() => {
        const selectedDisplays = selectedValues.map(item => item.display);
        const res: LocationSearchResult[] = [];
        filteredList.forEach((searchResult, i) => {
            if (!selectedDisplays.includes(searchResult.display)) {
                res.push(filteredList[i]);
            }
        });
        return res;
    }, [ selectedValues, filteredList ]);


    /**
     * Add current option to selected list
     */
    useEffect(() => {
        if (!currentValue) {
            return;
        }
        // No duplicates
        if (!selectedValues.find(value => value.display === currentValue.display)) {
            if (singular) {
                setSelectedValues([ currentValue ]);
            } else {
                setSelectedValues([ ...selectedValues, currentValue ]);
            }
        }
        // unset to change display value back into user input
        if (!singular) {
            setCurrentValue(undefined);
        }
    }, [ currentValue ]);


    /**
     * Remove item from selected values.
     * @param {number} currentIndex
     */
    const handleRemoveValue = (currentIndex: number): void => {
        setSelectedValues(selectedValues.filter((_, index) => index !== currentIndex));
    };


    /**
     * Adjust height and width of drop down to match items and input size
     * @type {{width: string, height: string}}
     */
    const comboOptionsStyle: CSSProperties = useMemo(() => {
        const nItems = filteredList.length;
        const baseHeight = 2.3125;
        const margin = 0.5;

        let height = baseHeight;
        if (nItems>=10) {
            height = 10 * baseHeight;
        } else if (nItems>0 && nItems<10) {
            height = nItems * baseHeight;
        }

        return {
            width: `${ (inputSize.width ?? 0) / 16 }rem`,
            height: `${ height + margin }rem`
        };
    }, [ filteredList.length, inputSize.width ]);


    return <>
        <Combobox value={ currentValue } onChange={ setCurrentValue }>
            <Label>{ label }</Label>
            <div ref={ inputSize.ref }>
                <Combobox.Input
                    ref={ inputRef }
                    name={ 'dynamicSearch' }
                    value={ query }
                    displayValue={ (model?: LocationSearchResult) => model !== undefined ?model.display :query }
                    onChange={ (e) => setQuery(e.target.value) }
                    placeholder={ 'Zoeken...' }
                    className={ 'relative w-full border-0 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset hover:cursor-text ring-gray-300 focus:outline-none focus-visible:ring-inset focus:ring-1 focus:ring-blue-600 sm:text-sm sm:leading-6' }
                />
            </div>
            <Combobox.Options
                style={ comboOptionsStyle }
                className={ 'fixed z-[1] mt-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm' }
            >
                { availableOptions.map(result => <Combobox.Option
                    key={ result.display }
                    value={ result }
                    className={ 'text-graphite hover:bg-aqua hover:bg-opacity-[3.75%] relative cursor-default select-none py-2 pl-3 pr-9 border-b border-b-white hover:cursor-pointer' }
                >
                    { result.display }
                </Combobox.Option>) }

                { !searchResults && <>Loading...</> }

                { availableOptions.length == 0 && <span className={ 'text-graphite relative cursor-default select-none px-3 pt-3 block' }>
                    Geen resultaten beschikbaar
                </span> }

            </Combobox.Options>
        </Combobox>

        { !singular && <SelectedList results={ selectedValues } handleRemove={ handleRemoveValue }/> }
    </>;
};

export default DynamicSearch;
