import {useEffect, useState} from 'react';


type Callback<T> = () => Promise<T>;
type ValueOrUndefined<T, V> = V extends T ? T : T | undefined
const useAsyncMemo = <T, R = T | undefined>(
    callback: Callback<T>,
    dependencies: unknown[],
    defaultValue?: R
): ValueOrUndefined<T, R> => {

    type DefaultValue = ValueOrUndefined<T, R>;
    const [data, setData] = useState<DefaultValue>(defaultValue as DefaultValue)
    useEffect(() => {
        callback()
            .then(r => setData(r as DefaultValue))
            .catch(() => setData(defaultValue as DefaultValue))
    }, [...dependencies])
    return data
};

export default useAsyncMemo;
