import React, { FC, useCallback, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import { thousandSeparator } from '../../lib/samfe/modules/Parse/Number';
import { classNames } from '../../lib/samfe/modules/Parse/String';
import ToOrderForm from './ToOrderForm';
import { ToOrderModel, ToOrderRelationsBluePrint } from './ToOrderTypes';
import useToOrder from './useToOrder';


type Props = Required<ExtendTableProps>


const ToOrderTable: FC<Props> = (): JSX.Element => {

    const httpHook = useToOrder();


    const rows = useMemo((): TableColumns<ToOrderModel, ToOrderRelationsBluePrint>[] => [
        {
            header: {
                children: 'Artikel'
            },
            column: (item) => ({
                children: <>
                    <p>{ item.tableArticle.article_number }</p>
                    <p className={ '!text-xs !font-normal !no-underline' }>{ item.product_name }</p>
                </>,
                linkTo: (item) => `/articles/${ item.tableArticle.article_id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Gem. verkoop per dag'
            },
            column: (item) => ({
                children: item.tableArticle.avg_sales_per_day
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Gem. verkoop per maand'
            },
            column: (item) => ({
                children: Math.round(item.tableArticle.avg_sales_per_day * 30)
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Rekvoorraad'
            },
            column: (item) => ({
                children: item.tableArticle.rack
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Te verzenden'
            },
            column: (item) => ({
                children: item.tableArticle.open_sale_amount
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'In bestelling bij leverancier'
            },
            column: (item) => ({
                children: item.tableArticle.open_purchase_amount
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Uitvul opdrachten'
            },
            column: (item) => ({
                children: item.tableArticle.open_repack_amount
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Levertijd in dagen'
            },
            column: (item) => ({
                children: item.tableArticle.expected_delivery_time_in_days
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Aantal dagen voorraad'
            },
            column: (item) => ({
                children: item.tableArticle.expected_lifetime_in_days
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Aantal dagen om optijd te bestellen'
            },
            column: (item) => ({
                children: item.tableArticle.time_to_buy_in_days
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Voorraad indicator',
                // @ts-ignore BE supports this
                sortCol: 'stock_indicator'
            },
            column: (item) => ({
                children: <div className={ 'text-right' }>
                    <span className={ classNames(
                        'font-medium',
                        item.tableArticle.stock_indicator<=0 && 'text-fire',
                        item.tableArticle.stock_indicator>0 && item.tableArticle.stock_indicator<=50 && 'text-carrot-hover',
                        item.tableArticle.stock_indicator>50 && 'text-emerald'
                    ) }>
                            { item.tableArticle.avg_sales_per_day === 0 && item.tableArticle.stock_indicator === 100 ?'-' :`${ thousandSeparator(item.tableArticle.stock_indicator) }%` }
                        </span>
                </div>
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Besteladvies voor 6 mnd voorraad'
            },
            column: (item) => ({
                children: item.tableArticle.advised_purchase_amount_6_months
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Besteladvies voor 6 mnd in stuks'
            },
            column: (item) => ({
                children: item.tableArticle.advised_purchase_amount_6_months * item.tableArticle.article_amount
            }),
            type: 'numeric'
        }
    ], []);


    const postProcessedData = useCallback((items: ToOrderModel[]) => {
        const newItems: ToOrderModel[] = [];
        items.forEach((item, index) => {
            const parsedItem = { ...item };
            parsedItem.articles = [];

            item.articles.sort((a, b) => {
                const aToLower = a.avg_sales_per_day === 0 && a.stock_indicator === 100 ?1 :0;
                const bToLower = b.avg_sales_per_day === 0 && b.stock_indicator === 100 ?1 :0;
                if (aToLower === bToLower) {
                    return 0;
                }

                return aToLower>bToLower ?1 :-1;
            }).sort((a, b) => {
                const aIsBulk = a.article_amount === 1 ?1 :0;
                const bIsBulk = b.article_amount === 1 ?1 :0;
                if (aIsBulk === bIsBulk) {
                    return 0;
                }

                return aIsBulk>bIsBulk ?1 :-1;
            }).sort((a, b) => {
                const aIsExcluded = a.is_excluded ?1 :0;
                const bIsExcluded = b.is_excluded ?1 :0;
                if (aIsExcluded === bIsExcluded) {
                    return 0;
                }

                return aIsExcluded>bIsExcluded ?1 :-1;
            }).forEach(tableArticle => {
                newItems.push({
                    ...parsedItem,
                    tableArticle,
                    rowIndex: index
                });
            });
        });
        return newItems;
    }, []);


    //noinspection PointlessBooleanExpressionJS
    return <Table
        id={ 'to-order' }
        rows={ rows }
        rowDisabled={ (item) => item.tableArticle.is_excluded === true }
        rowClassName={ (item) => {
            //const rowIndex = rowIdentifiers.findIndex(identifier => identifier.productId == item.product_id);
            return item.rowIndex % 2 == 0 ?'!bg-aqua !bg-opacity-[3%] hover:!bg-aqua/5'
                                          :'!bg-white hover:!bg-aqua hover:!bg-opacity-[7%]';
        } }
        http={ {
            hook: httpHook,
            searchCols: [],
            with: [],
            //@ts-ignore BE supports this
            sortCol: 'stock_indicator',
            sortDir: 'ASC',
            filters: [
                {
                    displayName: 'Voorraad < 50%',
                    //@ts-ignore BE supports this
                    column: 'stock_indicator',
                    operator: '<=',
                    value: 50,
                    default: true
                }
            ]
        } }
        forms={ {
            actions: [
                {
                    title: 'Bestellen',
                    id: (item) => item.tableArticle?.article_id,
                    Form: ToOrderForm
                }
            ]
        } }
        styling={ {
            variation: 'buttons'
        } }
        postProcessData={ postProcessedData }
    />;
};

export default ToOrderTable;