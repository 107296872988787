import React, {FC} from "react";
import GroupTable from "./GroupTable";
import IndexPage from "../../templates/pages/IndexPage";
import GroupForm from "./GroupForm";


const GroupIndex: FC = (): JSX.Element => <IndexPage
    title={"Groepen"}
    Table={GroupTable}
    FormModal={GroupForm}
/>
export default GroupIndex;