import React from "react";
import {FileDto, FileModel, FileRelationsBluePrint} from "./FileTypes";
import useHttp from "../../lib/samfe/modules/Http/useHttp";


const useFile = () => useHttp<FileModel, FileDto, FileRelationsBluePrint>({
    contentType: 'multipart/form-data',
    endpoint: 'file'
});
export default useFile;
