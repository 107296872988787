import React from "react";
import {useField} from "formik";


export const useFormikField = (name: string) => {
    const [field, meta] = useField(name);
    return {
        field,
        meta,
        invalid: meta.touched && meta.error !== undefined
    }
}
export default useFormikField;
