import React, {useEffect, useState} from "react";

export type SelectOption = {
    displayName: string|number,
    value: string|number,
    selected?: boolean,
    disabled?: boolean
};


export const useSelect = (
    name:string,
    initialOptions?: SelectOption[],
    multiSelect:boolean = false
) => {


    /**
     *
     */
    const [selected, setSelected] = useState<SelectOption|SelectOption[]|undefined>(undefined);
    const [initialized, setInitialized] = useState(false);


    /**
     * @fixme duplicate function "initSelected" in Effects/useSelect
     * @feature TPGA-1476
     * @param currentOptions
     */
    const initSelected = (currentOptions: SelectOption[]) => {
        let selectedIndex = currentOptions.findIndex((opt) => opt.selected === true);
        if (selectedIndex < 0) {
            return;
        }
        const currentOption = currentOptions[selectedIndex ?? 0];
        setSelected(multiSelect ? [currentOption]: currentOption);
    }

    /**
     *
     */
    useEffect(() => {initSelected(initialOptions ?? [])}, []);
    useEffect(() => {initSelected(initialOptions ?? [])}, [initialOptions]);

    /**
     *
     */
    useEffect(() => {
        if (selected === undefined) {
            return;
        }
        setInitialized(true);
    }, [selected]);


    return {
        selected, setSelected, initialized
    };
};
export default useSelect;
