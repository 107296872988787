import React from 'react';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import { TableActionInlineButton, TableDestructionInlineButton } from '../../../button';
import { TableActionProps } from '../../types/TableAction';


const TableActionInline = <T extends object>({
    disabled = false,
    onClick,
    children,
    variation = 'primary',
    item,
    className
}: TableActionProps<T>) => {

    return (
        <div>

            { variation == 'primary' && <TableActionInlineButton
                { ...item }
                className={ classNames('w-full', className) }
                disabled={ disabled }
                onClick={ async() => {
                    if (!disabled) {
                        await onClick(item);
                    }
                } }
            >{ children }</TableActionInlineButton> }

            { variation == 'destructive' && <TableDestructionInlineButton
                { ...item }
                disabled={ disabled }
                className={ classNames('w-full', className) }
                onClick={ async() => {
                    if (!disabled) {
                        await onClick(item);
                    }
                } }>{ children }</TableDestructionInlineButton> }
        </div>
    );
};

export default TableActionInline;
