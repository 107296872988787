import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import useFormikField from "./useFormikField";

export type SelectOption = {
    displayName: string|number|JSX.Element,
    value: string|number|undefined,
    selected?: boolean,
    disabled?: boolean
};


export const useSelect = (
    name:string,
    initialOptions?: SelectOption[],
    multiSelect:boolean = false
) => {

    const [ options, setOptions ] = useState(initialOptions);

    /**
     *
     */
    const [selected, setSelected] = useState<SelectOption|SelectOption[]|undefined>(undefined);
    const [initialized, setInitialized] = useState(false);

    /**
     *
     */
    let formikField = useFormikField(name);
    const formikCtx = useFormikContext();

    /**
     * @feature TPGA-1476
     * @param currentOptions
     */
    const initSelected = (currentOptions: SelectOption[]) => {
        let selectedIndex = currentOptions.findIndex((opt) => opt.selected === true);
        if (selectedIndex < 0) {
            selectedIndex = 0
        }
        let currentOption: SelectOption|SelectOption[]|undefined = multiSelect ? [] : undefined;
        if (currentOptions.length > 0) {
            currentOption = currentOptions[selectedIndex]
        }
        setSelected(multiSelect ? [currentOption as SelectOption]: currentOption);
    }

    /**
     *
     */
    useEffect(() => {initSelected(options ?? [])}, []);
    useEffect(() => {initSelected(options ?? [])}, [options]);

    /**
     *
     */
    useEffect(() => {
        if (selected === undefined) {
            return;
        }
        Array.isArray(selected)
            ? formikCtx.setValues({...formikCtx.values as object, [name]: selected.map(select => select.value) })
            : formikCtx.setFieldValue(name, selected.value)
        setInitialized(true);
    }, [selected]);


    return {
        selected, setSelected, initialized,
        setOptions,
        ...formikField
    };
};
export default useSelect;
