import React, {FC, useEffect, useState} from "react";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import useAsyncInit from "../../lib/samfe/components/Form/Effects/useAsyncInit";
import Yup from "../../lib/samfe/components/Form/Yup";
import { GroupModel } from '../group/GroupTypes';
import {useLocation} from "./useLocation";
import {LocationDto, LocationModel, LocationRelationsBluePrint} from "./LocationTypes";
import {useForm} from "../../lib/samfe/components/Form";
import {FormModal} from "../../lib/samfe/components/Modal";
import useGroup from "../group/useGroup";


/**
 * @var {number|undefined} parentId Group ID
 */
const LocationForm: FC<ExtendFormModalProps<LocationDto>> = ({ id, open, setOpen, onSuccess, parentId }): JSX.Element => {

    const location = useLocation();
    const group = useGroup();

    const [ initialGroup, setInitialGroup ] = useState<GroupModel>();
    const [currentLocation, setCurrentLocation] = useState<LocationModel|undefined>(undefined);

    /**
     *
     */
    const shape = (): Shape<LocationDto> => ({
        group_id: Yup.number()
            .label('Pand')
            .required()
            .controlType('selectSearch')
            .selectSearchConfig({
                useHook: useGroup,
                initialModel: currentLocation?.group ?? initialGroup,
                expectsInitialModel: (currentLocation ?? initialGroup) != undefined,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    limit: 'all',
                    filter: `archived=0${parentId != undefined ? `,id=${parentId}`: ''}`, // @todo why is this needed to get groups???
                    displayName: model => `${ model.name }`,
                }
            }),

        name: Yup.string()
            .description('A-Z')
            .required()
            .label('Locatie naam')
            .controlType('input')
            .inputType('text'),

        levels: Yup.number()
            .description('0 - 5')
            .inputType('number')
            .label('Hoogtes')
            .min(0)
            .max(5)
            .controlType('input'),
    });

    /**
     *
     */
    const {validationSchema, setShape} = useSchema<LocationDto>(shape());

    useEffect(() => {
        setShape(shape());
    }, [currentLocation, initialGroup]);

    const initializer = async () => {
        if (id) {
            location.getItem(id, { with: [ 'group' ] }).then(setCurrentLocation);
        } else if (parentId) {
            group.getItem(parentId).then(setInitialGroup)
        }
        setShape(shape())
    }

    /**
     *
     */
    const { formikConfig, formFields } = useForm<LocationModel, LocationDto, LocationRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useLocation,
        onSuccess: onSuccess
    });

    return <FormModal
        id={id}
        resource={'Locatie'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
};

export default LocationForm;
