import {ElementModel} from "./ElementTypes";

/**
 * Switch between compound and excipient target.
 *
 * @description Compound will be default if none specified.
 *
 * @param {ElementModel|undefined} element Current model
 * @param {boolean} isExcipient default false.
 * @returns {string} Element URI
 */
export const getElementUri = (element?: ElementModel, isExcipient: boolean = false): string => (
    `settings/${
        !isExcipient ? 'compound' : 'excipient'
    }-elements${
        element?.id != undefined ? `/${element.id}`: ''
    }`
);