import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import { sortByNumber } from '../../lib/samfe/modules/Parse/Number';
import { SortObjectArray } from '../../lib/samfe/modules/Parse/Object';
import { batchCodeLabel, expirationDateLabel } from '../charge/ChargeFunctions';
import { ChargeModel, ChargeRelationsBluePrint, ChargeStatusDisplayName } from '../charge/ChargeTypes';
import useCharge from '../charge/useCharge';
import { getLocationLevel } from './LocationFunctions';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const LocationChargesTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useCharge();


    const rows = useMemo((): TableColumns<ChargeModel, ChargeRelationsBluePrint>[] => [
        {
            header: {
                children: 'Hoogte',
            },
            column: (item) => ({
                children: (item.chargeLocations ?? [])
                    .filter(cl => cl.location_id == parentId)
                    .sort((a, b) => sortByNumber(a.level, b.level))
                    .map(getLocationLevel)
                    .join(', ')
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Product',
            },
            column: (item) => ({
                children: item.product?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Batchcode',
                sortCol: 'batchcode'
            },
            column: (item) => ({
                children: batchCodeLabel(item)
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Artikel',
            },
            column: (item) => ({
                children: item.article?.number
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Rekvoorraad',
            },
            column: (item) => ({
                children: item.samfeStock?.rack
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'THT',
                sortCol: 'expiration_date'
            },
            column: (item) => ({
                children: expirationDateLabel(item.expiration_date, true)
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Ontvangstdatum',
                sortCol: 'received_date'
            },
            column: (item) => ({
                children: item.received_date
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Status',
                sortCol: 'status'
            },
            column: (item) => ({
                children: ChargeStatusDisplayName(item.status)
            }),
            type: 'text'
        },
    ], [ parentId ]);

    return (
        <Table
            id={ 'charges' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/charges/${ item.id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: [ 'batchcode', 'product.name', 'article.number' ],
                with: ['product', 'article', 'chargeLocations', 'samfeStock'],
                sortCol: 'id',
                sortDir: 'DESC',
                disableDeArchiving: true,
                filter: {
                    column: 'chargeLocations.location_id',
                    operator: '=',
                    value: `${ parentId },amount>0`
                }
            } }
        />
    )
};


export default LocationChargesTable;