import React, { FC, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { SaveButton } from '../../lib/samfe/components/Button';
import Disclosure from '../../lib/samfe/components/Disclosure/Disclosure';
import TextArea from '../../lib/samfe/components/Form/Generic/TextArea';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import Skeleton from '../../lib/samfe/components/Skeleton/Skeleton';
import Stats from '../../lib/samfe/components/Stats';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import useId from '../../lib/samfe/modules/Router/useId';
import ShowPage from '../../templates/pages/ShowPage';
import { getProductTypeUnit } from '../productType/ProductTypeFunctions';
import ArticleForm from './ArticleForm';
import { ArticleModel } from './ArticleTypes';
import ArticleChargeOverviewTable from './pivot/ArticleChargeOverviewTable';
import ArticleChargeTable from './pivot/ArticleChargeTable';
import ArticleCustomerTable from './pivot/ArticleCustomer/ArticleCustomerTable';
import ArticleProducerForm from './pivot/ArticleProducer/ArticleProducerForm';
import ArticleProducerTable from './pivot/ArticleProducer/ArticleProducerTable';
import ArticlePurchaseTable from './pivot/ArticlePurchaseTable';
import ArticleSaleRowTable from './pivot/ArticleSaleTable';
import useArticle from './useArticle';


const ArticleCommentField: FC<{ articleId: number }> = ({ articleId }) => {

    const articleHook = useArticle();
    const commentRef = useRef<HTMLTextAreaElement>(null);

    const comment = useAsyncMemo(async() => {
        return await articleHook.getItem(articleId, {
            select: [ 'comments' ]
        }).then(res => res?.comments);
    }, [ articleId ]);

    return (
        <>
            <TextArea
                className={ '-mt-2' }
                ref={ commentRef }
                label={ '' }
                name={ 'comments' }
                value={ comment }
            />
            <div className={ 'w-full flex justify-end pt-2 mb-4' }>
                <SaveButton
                    disabled={ commentRef.current == null }
                    onClick={ async() => {
                        await articleHook.update(articleId, {
                            comments: commentRef.current!.value
                        });
                    } }
                />
            </div>
        </>
    );
};


const ArticleShow: FC = (): JSX.Element => {

    const id = useId();
    const [ currentArticle, setCurrentArticle ] = useState<ArticleModel|undefined>(undefined);


    const productName = useMemo(() => {
        return currentArticle?.product?.name ?? 'Gearchiveerd';
    }, [ currentArticle?.product?.name ]);

    const title = useMemo(() => {
        if (!currentArticle) {
            return <Skeleton type={ 'stat.body' }/>;
        }
        return <>
            <Link className={ 'underline underline-offset-4 decoration-aqua' } to={ `/products/${ currentArticle.product_id }` }>
                { productName }
            </Link>
            &nbsp;→&nbsp;
            { currentArticle.number }
        </>;
    }, [ productName, currentArticle ]);

    const isBulk = useMemo(() => {
        return currentArticle?.is_bulk === true;
    }, [ currentArticle?.is_bulk ]);

    const contentsType = useMemo(() => {
        return isBulk ?'Type' :'Inhoud';
    }, [ isBulk ]);


    const amount = useMemo(() => {
        return currentArticle?.amount ?? 0;
    }, [ currentArticle?.amount ]);

    const unit = useMemo(() => {
        return getProductTypeUnit(currentArticle?.product?.productType, amount != 1);
    }, [ currentArticle?.product?.productType, amount ]);

    const contents = useMemo(() => {
        return isBulk ?'Bulk' :`${ amount } ${ unit }`;
    }, [ isBulk, amount, unit ]);

    return (
        <ShowPage
            title={ title }
            breadcrumb={ currentArticle?.number }
            setCurrentModel={ setCurrentArticle }
            resourceIdentifier={ 'number' }
            FormModal={ ArticleForm }
            httpHook={ useArticle }
            relations={ [
                'product.productType',
                'package',
                'files'
            ] }

            formActions={ [
                {
                    name: 'Producent toevoegen',
                    tabId: 'producers',
                    icon: 'add',
                    form: ArticleProducerForm
                }
            ] }

            upload={ {
                modelName: 'ArticleModel',
                files: currentArticle?.files,
                fileTypes: [
                    'Certificaat',
                    'Referentie afbeelding',
                    'Overig'
                ]
            } }

            tabs={ currentArticle ?[
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <Stats stats={ [
                            {
                                name: contentsType,
                                value: contents
                            },
                            {
                                name: 'Product',
                                value: productName
                            },
                            {
                                name: 'Verpakking',
                                value: currentArticle?.package?.name
                            },
                            {
                                name: 'Actief',
                                value: currentArticle?.active ?'Ja' :'Nee'
                            }
                        ] }/>

                        <ArticleChargeOverviewTable
                            parentId={ id }
                            htmlBefore={ <>
                                { currentArticle.id != undefined && <Disclosure title={ 'Notities' } variation={ 'base' }>
                                    <ArticleCommentField articleId={ currentArticle.id }/>
                                </Disclosure> }
                            </> }
                        />
                        <ExampleGraph/>
                    </>
                },
                {
                    name: 'Inkoop',
                    id: 'purchases',
                    element: <ArticlePurchaseTable parentId={ id }/>
                },
                {
                    name: 'Verkoop',
                    id: 'sales',
                    element: <ArticleSaleRowTable parentId={ id }/>
                },
                {
                    name: 'Producenten',
                    id: 'producers',
                    element: <ArticleProducerTable parentId={ id }/>
                },
                {
                    name: 'Klanten',
                    id: 'customers',
                    element: <ArticleCustomerTable parentId={ id }/>
                },
                {
                    name: 'Charges',
                    id: 'charges',
                    element: <ArticleChargeTable parentId={ id }/>
                }
            ] :[] }
        />
    );
};
export default ArticleShow;