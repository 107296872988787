import React, { FC } from 'react';
import { classNames } from '../../../lib/samfe/modules/Parse/String';


type Props = JSX.IntrinsicElements['table']
const TableComponent: FC<Props> = ({
    children,
    className,
    ...props
}) => {
    return (
        <table
            { ...props }
            className={ classNames('border-separate border-spacing-0 text-sm', className) }
        >
            { children }
        </table>
    );
};

export default TableComponent;
