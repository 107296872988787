import React, {FC} from "react";
import TopButton from '../../lib/samfe/components/Button/TopButton';
import IndexPage from "../../templates/pages/IndexPage";
import PackageTable from "./PackageTable";
import PackageForm from "./PackageForm";
import {useNavigate} from "react-router-dom";

const PackageIndex: FC = (): JSX.Element => {
    const navigate = useNavigate();

    return <IndexPage
        title={'Verpakkingen'}
        Table={PackageTable}
        FormModal={PackageForm}
        actionButton={<TopButton
            style={'secondary'}
            icon={''}
            text={'Onderdelen'}
            onClick={() => navigate('/package-parts')}
        />}
    />
}
export default PackageIndex;