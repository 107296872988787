import {ProductModel} from "../../ProductTypes";
import {SoftDeleteModel} from "../../../../lib/samfe/types/ModelTypes";
import useHttp from "../../../../lib/samfe/modules/Http/useHttp";

export type VersionLogRelationsBluePrint = 'product'

export type VersionLogFields = {
    internal_comment: string,
    external_comment: string,
    impact: 'low'|'medium'|'high',
    product_id: number
}

export type VersionLogDto = Partial<VersionLogFields>

export type VersionLogRelations = {
    product: ProductModel,
}

export type VersionLogModel = SoftDeleteModel & Partial<VersionLogRelations> & VersionLogDto

const useProductVersionLog = () => useHttp<VersionLogModel, VersionLogDto, VersionLogRelationsBluePrint>({
    endpoint: 'product-versions'
});
export default useProductVersionLog;
