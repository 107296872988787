import React, {FC} from "react";
import IndexPage from "../../templates/pages/IndexPage";
import PurchaseTable from "./PurchaseTable";
import PurchaseForm from "./PurchaseForm";


const PurchaseIndex: FC = (): JSX.Element => <IndexPage
    title={'Inkooporders'}
    Table={PurchaseTable}
    FormModal={PurchaseForm}
/>

export default PurchaseIndex;
