import React, { FC } from 'react';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import useRiskType from '../../../riskType/useRiskType';
import BaseElementRiskResourceTable from '../BaseElementRiskResourceTable';
import useElementRiskType from './useElementRiskType';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ElementRiskTypeTable: FC<Props> = ({ parentId }): JSX.Element => (
    <BaseElementRiskResourceTable
        resourceName={ 'Risico type' }
        usePivotHook={ useElementRiskType }
        foreignKey={ 'risk_type_id' }
        useResourceHook={ useRiskType }
        parentId={ parentId }
    />
);

export default ElementRiskTypeTable;
