import React, { FC } from 'react';
import Pill from '../../../../../lib/samfe/components/Pill/Pill';


type Props = {
    fileCount: number
}
const FileUploadPill: FC<Props> = ({ fileCount }) => {
    return (
        <div className={ 'pr-4 inline-flex' }>
            <Pill
                hideDot={ true }
                state={ fileCount>0 ?'success' :'danger' }
                title={ `${ fileCount } ${ fileCount == 1 ?'bestand' :'bestanden' } geupload` }
            />
        </div>
    );
};

export default FileUploadPill;
