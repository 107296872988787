import { useMemo, useState } from 'react';
import { AssessmentHistory } from '../types/AssessmentTypes';
import { assessmentIsRequired } from './useAssessmentIsRequired';


const useRequiredAssessments = (items: AssessmentHistory[] = []) => {
    const [ internalItems, setInternalItems ] = useState(items);
    return {
        setList: setInternalItems,
        items: useMemo(() => {
            return internalItems.filter(assessmentIsRequired)
        }, [internalItems])
    }
};

export default useRequiredAssessments;
