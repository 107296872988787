import React, { FC } from 'react';
import { classNames } from '../../../lib/samfe/modules/Parse/String';


type Props = {
    direction: 'back'|'next',
    onClick: () => void|Promise<void>,
    disabled?: boolean
}
const PaginationNavigationButtonComponent: FC<Props> = ({
    direction,
    onClick,
    disabled = false
}) => {
    return (
        <button
            onClick={ onClick }
            disabled={disabled}
            className={ classNames('relative rounded-md text-xs w-8 h-8 text-center bg-milk hover:shadow-md active:shadow-none hover:text-aqua',
                disabled && 'opacity-35 hover:cursor-not-allowed'
                ) }
        >
            <span className={ 'absolute material-icons p-0 left-0 right-0 top-2.5 text-aqua' }>
                { direction == 'back' ?'arrow_back_ios' :'arrow_forward_ios' }
            </span>
        </button>
    );
};

export default PaginationNavigationButtonComponent;
