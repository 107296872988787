import { Disclosure as HeadlessUiDisclosure } from '@headlessui/react';
import { ChevronUpIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import React, { forwardRef, ReactNode, useEffect, useMemo, useState } from 'react';
import { classNames } from '../../modules/Parse/String';


type ToggleState<T extends ReactNode|boolean> = {
    title: T,
    icon: T
}

type ToggleItem = {
    hide: ToggleState<boolean>
    openendState: ToggleState<ReactNode>,
    closedState: ToggleState<ReactNode>,
}

export type ExtendDisclosureProps = {
    title: string,
    children?: ReactNode
    variation?: 'info'|'warning'|'base'|'descriptive',
    defaultOpen?: boolean,

}
export type DisclosureProps = ExtendDisclosureProps&{
    className?: string,
    titleClassName?: string
    contentClassName?: string
    toggle?: Partial<{ [K in keyof ToggleItem]-?: Partial<ToggleItem[K]> }>,
    onClick?: () => void
}


const Disclosure = forwardRef<HTMLDivElement, DisclosureProps>(
    ({
        title,
        variation = 'base',
        children,
        defaultOpen = false,
        className,
        titleClassName,
        contentClassName,
        onClick,
        toggle
    }, ref) => {

        const { hide, openendState, closedState } = useMemo((): ToggleItem => ({
            hide: {
                title: toggle?.hide?.title ?? false,
                icon: toggle?.hide?.icon ?? false
            },
            openendState: {
                title: toggle?.openendState?.title ?? 'Minder weergeven',
                icon: toggle?.openendState?.icon ?? <ChevronUpIcon className={ 'inline h-5 w-5' }/>
            },
            closedState: {
                title: toggle?.closedState?.title ?? 'Meer weergeven',
                icon: toggle?.closedState?.icon ?? <ChevronUpIcon className={ 'inline h-5 w-5 rotate-180 transform' }/>
            }
        }), [
            toggle?.hide?.title,
            toggle?.hide?.icon,
            toggle?.openendState?.title,
            toggle?.openendState?.icon,
            toggle?.closedState?.title,
            toggle?.closedState?.icon
        ]);

        const [ isOpen, setIsOpen ] = useState(defaultOpen);
        const [ openedBefore, setOpenedBefore ] = useState(defaultOpen);
        useEffect(() => {
            if (isOpen && !openedBefore) {
                setOpenedBefore(true)
            }
        }, [isOpen]);

        const handleClick = (open: boolean) => {
            onClick?.();
            //close()
            setIsOpen(open);
            //setCloseFunc(close)
        };

        useEffect(() => {
            if (!defaultOpen && isOpen) {
                setIsOpen(false);
            }

            if (defaultOpen && !isOpen) {
                setIsOpen(true);
            }
        }, [ defaultOpen ]);


        return <div ref={ ref } className={ classNames(
            '-mx-4 sm:-mx-10 lg:-mx-12', variation == 'descriptive' ?'border-t' :'border-y',
            variation === 'base' && 'bg-white',
            variation === 'warning' && 'bg-yellow-300 bg-opacity-25',
            className
        ) }>
            <div className={ 'mx-4 sm:mx-10 lg:mx-12' }>
                <HeadlessUiDisclosure defaultOpen={ isOpen }>
                    { ({ open }) => (
                        <>
                            <HeadlessUiDisclosure.Button onClick={ () => handleClick(!open) } className={ classNames(
                                'flex relative w-full justify-between text-left text-sm font-medium text-aqua focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
                                variation == 'descriptive' ?'pt-2' :'py-4',
                                !isOpen && variation == 'descriptive' && 'pb-2',
                                !isOpen && variation != 'descriptive' && 'pb-4',
                                !children && 'cursor-default',
                                titleClassName
                            ) }>
                                { variation != 'descriptive' && <div className={ classNames('absolute top-0 bottom-0 z-[-1]',
                                    '-left-4 sm:-left-10 lg:-left-12',
                                    '-right-4 sm:-right-10 lg:-right-12',
                                    variation === 'info' && 'bg-blue-300 bg-opacity-25',
                                ) }/> }
                                <h3 className={ classNames('inline',
                                    variation != 'descriptive' ?'text-base font-semibold leading-6' :'font-medium text-[0.9rem]'
                                ) }>
                                    { variation != 'base' && <span className={ 'mr-2' }>
                                        { variation === 'info' && <InformationCircleIcon className={ 'h-6 w-6 text-blue-600 inline' } aria-hidden="true"/> }
                                        { variation === 'warning' && <ExclamationTriangleIcon className={ 'h-6 w-6 text-orange-600 inline' } aria-hidden="true"/> }
                                    </span> }
                                    <span className="inline w-fit">{ title }</span>

                                </h3>
                                { children != undefined && <div className="inline-block float-right">
                                    { !hide.title && <span className={ 'inline text-right mr-2' }>{ isOpen ?openendState?.title :closedState.title }</span> }
                                    { !hide.icon && <>
                                        { isOpen ?openendState.icon :closedState.icon }
                                    </> }
                                </div> }
                            </HeadlessUiDisclosure.Button>
                            { children != undefined && <HeadlessUiDisclosure.Panel static={ true } className={ classNames('sm:p-0',
                                variation == 'descriptive' ?'mb-2' :'py-5'
                            ) }>
                                { (isOpen || openedBefore) && <dl className={ classNames(
                                    variation == 'descriptive' ?'px-2 text-sm pt-1' :'sm:divide-y sm:divide-gray-200 pt-6',
                                    !isOpen && openedBefore && 'hidden',
                                    contentClassName)
                                }>
                                    { children }
                                </dl> }
                            </HeadlessUiDisclosure.Panel> }
                        </>
                    ) }
                </HeadlessUiDisclosure>
            </div>
        </div>;
    }
);

Disclosure.displayName = Object.keys({ Disclosure })[0];
export default Disclosure;
