import React, { useEffect } from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import DynamicSearch from '../../../../lib/samfe/components/Form/Field/SelectSearch/DynamicSearch';
import FormModal, { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { ChargeLocationDto, LocationSearchResult } from '../../../location/ChargeLocationTypes';
import { ChargeLocationBulkItem, ChargeLocationPivotModel, useChargeLocationsPivotBulk } from '../../../location/useLocation';


/**
 * @var {number|undefined} parentId = Charge ID
 */
const ChargeLocationCreateForm: React.FC<ExtendFormModalProps<ChargeLocationDto>> = ({ id, parentId, open, setOpen, onSuccess }): JSX.Element => {


    // @todo get initial charge locations

    const chargeLocationPivotHook = useChargeLocationsPivotBulk(parentId);
    const [ selectedOptions, setSelectedOptions ] = React.useState<LocationSearchResult[]>([]);

    const shape = (): Shape<{}> => ({});

    const { validationSchema, setShape } = useSchema<{}>(shape());


    useEffect(() => {
        setShape(shape());
    }, []);

    const initializer = async() => {
        setShape(shape());
    };

    const { formikConfig, formFields } = useForm<ChargeLocationPivotModel, Partial<ChargeLocationPivotModel>, ''>({
        id: id,
        validationSchema: validationSchema,
        useHttpHook: () => chargeLocationPivotHook,
        skipInitialFetch: true,
        onSuccess,
        initializer,
        initialized: useAsyncInit(initializer, open),
        morphPayload: () => {
            return {
                locations: selectedOptions.map(item => ({
                    location_id: item.location_id,
                    level: item.level === undefined ?null :item.level
                }) as ChargeLocationBulkItem)
            };
        }
    });

    return <FormModal
        id={ parentId }
        resource={ 'Locatie' }
        hideCreateAnotherOne={ true }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        htmlAfterForm={ <DynamicSearch label={ 'Locaties' } config={
            {
                searchResource: 'search/locations',
                values: selectedOptions,
                setValues: setSelectedOptions,
                initialDisplay: undefined
            }
        }/> }/>;
};

export default ChargeLocationCreateForm;