import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import useToaster from '../../lib/samfe/components/Toaster/useToaster';
import { classNames } from '../../lib/samfe/modules/Parse/String';


type Props = {
    onDrop: (acceptedFiles: File[]) => void|Promise<void>,
    accept?: { [k: string]: string[] }
}

const DragAndDropField: FC<Props> = ({
    onDrop,
    accept = {
        'image/jpeg': [ '.jpg', '.jpeg', '.png' ],
        'application/pdf': [ '.pdf' ],
        'application/msword': [ '.doc', '.docx' ],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [ '.docx' ],
        'text/plain': [ '.txt' ],
        'application/rtf': [ '.rtf' ]
    }
}) => {

    const { setToasterProps } = useToaster();

    const handleToaster = () => {
        setToasterProps({
            type: 'error',
            title: 'Bestandtype niet ondersteund',
            message: 'Ondersteund: doc(x), jp(e)g, pdf, png, rtf en txt',
            show: true
        });
    };

    const { getRootProps, isDragActive } = useDropzone({
        accept,
        onDrop: async(acceptedFiles: File[]) => {
            if (acceptedFiles.length<1) {
                handleToaster();
                return;
            }
            await onDrop(acceptedFiles);
        }
    });

    return (
        <div { ...getRootProps() } className={ classNames('mt-8 mb-8 text-center border-[0.25rem] border-dashed p-6 rounded-md',
            isDragActive ?'border-sky bg-sky bg-opacity-10 text-sky' :'border-graphite border-opacity-10'
        ) }>
            <span className={ 'block mx-auto hover:cursor-pointer text-5xl text-sky material-icons' }>
                { isDragActive ?'arrow_drop_down_circle' :'cloud_upload' }
            </span>
            <span className={ 'block mx-auto text-center w-full font-medium text-lg' }>
                { isDragActive && 'Drop it like its hot 🔥' }
                { !isDragActive && <>
                    <span className={ 'text-sky hover:cursor-pointer' }>Upload bestanden</span>&nbsp;
                    <span className={ 'text-graphite' }>of sleep het in dit vak</span>
                </> }
            </span>
            <small className={ 'text-graphite-hover' }>PNG, JPEG, PDF, DOCX, RTF, TXT tot 64MB</small>
        </div>
    );
};

export default DragAndDropField;
