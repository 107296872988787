import React, { FC } from 'react';
import SidebarMenu from './SidebarMenu';


const DesktopSidebar: FC = (): JSX.Element => {

    return (
        <>
            {/* Static sidebar for desktop */ }
            <div className="hidden top-0 bottom-0 h-screen 2xl:fixed 2xl:inset-y-0 2xl:z-50 2xl:flex 2xl:w-72 2xl:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */ }
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white">
                    <SidebarMenu/>
                </div>
            </div>
        </>
    );
};

export default DesktopSidebar;