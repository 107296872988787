import useAxios from '../../../../lib/samfe/modules/Http/useAxios';
import useHttp from '../../../../lib/samfe/modules/Http/useHttp';
import { PurchaseRowDto, PurchaseRowModel, PurchaseRowRelationsBluePrint } from './PurchaseRowTypes';


export const usePurchaseRowResource = () => useHttp<PurchaseRowModel, PurchaseRowDto, PurchaseRowRelationsBluePrint>({
    endpoint: `purchase-rows`
});

const usePurchaseRow = (parentId?: number) => {

    const axios = useAxios();
    const { post, put } = axios;

    const http = useHttp<PurchaseRowModel, PurchaseRowDto, PurchaseRowRelationsBluePrint>({
        endpoint: `purchases/${ parentId }/rows`
    });

    const updateWithParentId = async(parentId: number, id: number, payload: PurchaseRowDto) =>
        await put(`purchases/${ parentId }/rows/${ id }`, payload);

    const createWithParentId = async(parentId: number, payload: PurchaseRowDto) =>
        await post(`purchases/${ parentId }/rows`, payload);

    const destroyWithParentId = (parentId: number) =>
        async (id: number) => await axios.delete<{id: number}|undefined>(`purchases/${ parentId }/rows/${id}`);

    return {
        ...http,
        parentId,
        updateWithParentId,
        createWithParentId,
        destroyWithParentId
    };
};
export default usePurchaseRow;