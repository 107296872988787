import React, { FC } from 'react';
import { classNames } from '../../../lib/samfe/modules/Parse/String';


type Props = JSX.IntrinsicElements['tr']&{
    forSection?: 'body'|'head'
}

const TrComponent: FC<Props> = ({
    children,
    className,
    forSection,
    ...props
}) => {
    return (
        <tr { ...props } className={ classNames(
            'divide-y divide-gray-200',
            forSection == 'head' && 'shadow-sm shadow-aqua/10 children:py-4 children:bg-white children:bg-opacity-75 children:backdrop-blur children:backdrop-filter children:border-collapse children:font-medium',
            className
        ) }>
            { children }
        </tr>
    );
};

export default TrComponent;
