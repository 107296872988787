import moment from 'moment/moment';


type MonthNotation = 'long'|'short'|'numeric';

/**
 * @feature more types of locales
 */
type DateLocale = 'nl-NL'|'en_US'

/**
 * SQL date time "YYYY-MM-DD H:i:s" to locale string.
 * @feature notation as DateTimeFormatOptions
 * @param dt
 * @param monthNotation
 * @param locale
 */
export const datetimeToLocale = (
    dt?: string,
    monthNotation: MonthNotation = 'short',
    locale: DateLocale = 'nl-NL'
): string => {
    if (!dt) {
        return '-';
    }
    return new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: monthNotation,
        day: 'numeric'
    }).format(new Date(dt));
};


/**
 * Compare two dates.
 * @example ASC && a<b=-1 | DESC a<b=1 | a==b=0
 *
 * @param {string|undefined} a
 * @param {string|undefined} b
 * @param {'ASC'|'DESC'} sort
 * @returns {number} -1|0|1
 */
export const sortByDate = (a?: string, b?: string, sort: 'ASC'|'DESC' = 'ASC'): number => {
    const aMs = new Date(a ?? '').getTime();
    const bMs = new Date(b ?? '').getTime();
    if (aMs === bMs) {
        return 0;
    }
    const order: 1|-1 = sort === 'ASC' ?1 :-1;
    return aMs<bMs ?(-1 * order) :(1 * order);
};


/**
 * Parse JS date object to SQL date in format "yyyy-mm-dd".
 *
 * @description provides current date if no parameter is given.
 * @param {Date|undefined} date
 * @returns {string} SQL date
 */
export const jsDateToSqlDate = (date?: Date): string => ((date ?? new Date())
    .toISOString()
    .slice(0, -5)
    .split('T')[0]
);


/**
 * Parse sql datetime to date.
 *
 * @description provides current date if no parameter is given.
 * @param {string|undefined} date in format "yyyy-mm-dd H:i:s"
 * @returns {string} SQL date "yyyy-mm-dd"
 */
export const sqlDateTimeToDate = (date?: string): string => (date ?? jsDateToSqlDate()).slice(0, 10);


/**
 * Parse SQL datetime to JS Date object.
 *
 * @param {string|undefined} dateTime in format "yyy-mm-dd H:i:s"
 * @returns {Date}
 */
export const sqlDateTimeToJsDate = (dateTime?: string): Date => {
    //noinspection NonBlockStatementBodyJS
    if (!dateTime) {
        return new Date();
    }

    // Split timestamp into [ Y, M, D, h, m, s ]
    const t: number[] = dateTime.split(/[- :]/).map(t => parseInt(t));

    // Apply each element to the Date function
    return new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
};

export const sqlDateToJsDate = (date?: string): Date => {
    //noinspection NonBlockStatementBodyJS
    if (!date) {
        return new Date();
    }
    return sqlDateTimeToJsDate(`${ date } 00:00:00`);
};

/**
 * @review Cognitive Complexity from 27 to the 15
 * Date diff to human-readable string (Dutch)
 * @example date diff = 40.867.200.000 ms ('1 year, 3 months, 2 weeks and 4 days') -> '1 year, 4 months'
 * @param {string} a
 * @param {string} b
 * @returns {string} '-' if any date is undefined
 */
export const sqlDateDiffToString = (a?: string, b?: string): string => {
    if (!a || !b) {
        return '-';
    }

    const aDate = new Date(a ?? '');
    const bDate = new Date(b ?? '');

    const diffMs = aDate.getTime() - bDate.getTime();
    if (diffMs === 0) {
        return 'Zelfde dag';
    }

    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

    const years = Math.floor(diffDays / 365);
    if (years>=1) {
        const remainingMonths = Math.ceil((diffDays % 365) / 30);
        const monthsToYears = Math.ceil((remainingMonths / 12) * 1000) / 1000;
        const yearRes = years + monthsToYears;
        return `${ Number.isInteger(yearRes) ?yearRes :yearRes.toFixed(1) } jaar`;
    }

    const months = Math.floor(diffDays / 30);
    if (months>=1) {
        const remainingWeeks = Math.ceil((diffDays % 30) / 7);
        const weeksToMonths = Math.ceil((remainingWeeks / (52 / 12)) * 1000) / 1000;
        const monthRes = months + weeksToMonths;
        return `${ Number.isInteger(monthRes) ?monthRes :monthRes.toFixed(1) } ${ monthRes>1 ?'maanden' :'maand' }`;
    }

    const weeks = Math.floor(diffDays / 7);
    if (weeks>=1) {
        const remainingDays = Math.ceil(diffDays % 7);
        const daysToWeeks = Math.ceil((remainingDays / (365 / 52)) * 1000) / 1000;
        const weekRes = weeks + daysToWeeks;
        return `${ Number.isInteger(weekRes) ?weekRes :weekRes.toFixed(1) } ${ weekRes>1 || weekRes == 0 ?'weken' :'week' }`;
    }

    const days = Math.ceil(diffDays);
    if (days>=1) {
        return `${ days } ${ days>1 || days == 0 ?'dagen' :'dag' }`;
    }

    const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (hours>=1) {
        return `${ hours } ${ hours>1 || hours == 0 ?'uren' :'uur' }`;
    }
    return '-';
};

/**
 * Check if sql date time is in the past.
 * @param {string} sqlDateTime
 * @returns {boolean}
 */
export const sqlDateTimeIsPast = (sqlDateTime?: string): boolean => {
    if (!sqlDateTime) {
        return true;
    }
    const inputDate = new Date(sqlDateTime.replace(' ', 'T')); // replace space with T for correct parsing
    const currentDate = new Date();
    return inputDate<currentDate;
};

export const getWeekNumber = (date?: Date): number => {
    const currentDate = date ?? new Date();
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const pastDaysOfYear = (currentDate.getTime() - firstDayOfYear.getTime()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};


export const parseExpirationSqlDateTime = (dt?: string): string => {
    if (!dt) {
        return 'Onbekend';
    }
    const jsDate = sqlDateToJsDate(dt);
    const nMonth = jsDate.getMonth() + 1;
    if (isNaN(nMonth)) {
        return dt ?? '-';
    }
    return `${ nMonth<10 ?`0${ nMonth }` :nMonth }-${ jsDate.getFullYear() }`;
};

export const currentSqlDate = () => moment().format('Y-MM-DD H:m:s');


/**
 * Get lowest or highest value.
 * Other will be returned if one of them is undefined.
 * undefined will be returned if both unparsable.
 *
 * 'a' and 'b' are expected to have format 'yyyy-mm-dd'.
 *
 * @param {string | undefined} a
 * @param {string | undefined} b
 * @param {'lowest' | 'highest'} get
 * @return {string | undefined}
 */
export const compareSqlDates = (a: string|undefined, b: string|undefined, get: 'lowest'|'highest'): string|undefined => {

    const aDate = sqlDateToJsDate(a);
    const aTime = aDate.getTime();
    const aIsNan = isNaN(aTime);

    const bDate = sqlDateToJsDate(b);
    const bTime = bDate.getTime();
    const bIsNan = isNaN(bTime);

    if (!a || aIsNan) {
        return bIsNan ?undefined :b;
    }

    if (!b || bIsNan) {
        return aIsNan ?undefined :a;
    }

    return aTime<bTime
           ?(get == 'lowest' ?a :b)
           :(get == 'highest' ?a :b);
};