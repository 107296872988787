import React, { FC, ReactNode } from 'react';

type Props = {
    title: ReactNode,
    description?: ReactNode
}
const Jumbotron: FC<Props> = ({title, description}): JSX.Element => {
    return (
        <div className="flex flex-col overflow-hidden">
            <div className="flex flex-1 flex-col justify-between bg-white">
                <div className="flex-1">
                    <div className=" block">
                        <p className="text-lg font-semibold text-aqua">{title}</p>
                        { description != undefined && <div className="mt-2 text-sm text-graphite">{ description }</div> }
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Jumbotron;