import useAxios from "../../lib/samfe/modules/Http/useAxios";

type UseLoggedIn = () => Promise<boolean>;

const useIsLoggedIn = (): UseLoggedIn => {
    const {get} = useAxios();
    return async () => await get<boolean>('users/verify-login')
        .then(res => res.status === 200 && res.data)
        .catch(() => false)
}

export default useIsLoggedIn;