import React, { FC, useState } from 'react';
import { useForm } from '../../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../../lib/samfe/components/Form/Effects/useSchema';
import { optionIsSelected } from '../../../../../lib/samfe/components/Form/Support/FieldSupport';
import Yup from '../../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { filterIds } from '../../../../../lib/samfe/modules/Parse/Url';
import { modelNotArchived } from '../../../../../lib/samfe/types/ModelTypes';
import ElementExcipientForm from '../../../../element/pivot/elementExcipient/ElementExcipientForm';
import useElement from '../../../../element/useElement';
import useProduct from '../../../useProduct';
import { CompositionProductDto, CompositionProductModel, CompositionProductRelationsBluePrint, compositionProductUnits } from '../CompositionProductTypes';
import useCompositionProduct from '../useCompositionProduct';


const ProductExcipientForm: FC<ExtendFormModalProps<CompositionProductDto>> = ({
    id,
    open,
    setOpen,
    onSuccess,
    parentId
}): JSX.Element => {

    const product = useProduct();
    const compositionProduct = useCompositionProduct(parentId);
    const [ initialCP, setInitialCP ] = useState<CompositionProductModel|undefined>(undefined);

    const currentElement = () => id ?<span className={ 'text-graphite font-medium' }>{ initialCP?.element?.name } { initialCP?.element?.excipient?.name }</span> :<></>;


    /**
     *
     */
    const shape = (occupiedElementIds?: number[]): Shape<CompositionProductDto> => ({

        element_id: Yup.number()
            .label('Hulpstof')
            .hidden(id !== undefined)
            .defaultValue(id)
            .required()
            .controlType('selectSearch')
            .selectSearchConfig({
                useHook: useElement,
                expectsInitialModel: false,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    filter: `type=hulpstof${ (occupiedElementIds ?? []).length>0 ?',' :'' }${ filterIds({ ids: occupiedElementIds, exclude: true }) ?? '' }`,
                    limit: 'all',
                    relations: [ 'excipient' ],
                    displayName: (model) => `${ model.name } ${ model.excipient?.name ?? '' }`,
                    FormModal: ElementExcipientForm
                }
            }),

        product_id: Yup.number()
            .label('Product')
            .hidden(true)
            .controlType('input')
            .inputType('number'),


        amount: Yup.number()
            .label('Inhoud')
            .required()
            .controlType('input')
            .inputType('number')
            .defaultValue(initialCP?.amount ?? 0),


        unit: Yup.string()
            .label('Eenheid')
            .required()
            .controlType('select')
            .options(compositionProductUnits.map((unit, i) => ({
                    displayName: unit,
                    value: unit,
                    selected: optionIsSelected(initialCP?.unit ?? 'mg', unit, i)
                }))
            ),

        order: Yup.number()
            .controlType('input')
            .inputType('number')
            .steps(1)
            .hidden(true)
            .label('Volgorde')
            .defaultValue(initialCP?.order ?? 0)
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<CompositionProductDto>(shape());

    // @todo reset states on re init
    const initializer = async() => {

        const occupiedElementIds: number[] = await product.getItem(parentId, { with: [ 'compositionProducts' ] })
            .then(product => (product?.compositionProducts ?? [])
                .filter(cp => modelNotArchived(cp.archived))
                .map(cp => cp.element_id!)
            );
        setShape(shape(occupiedElementIds));
    };


    const { formikConfig, formFields } = useForm<CompositionProductModel, CompositionProductDto, CompositionProductRelationsBluePrint>({
        id,
        parentId,
        parentKey: 'product_id',
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useCompositionProduct,
        onSuccess: onSuccess,
        morphPayload: (formikValues, currentDto) => {
            if (!currentDto.amount) {
                return currentDto;
            }

            const amount = parseFloat(`${ currentDto.amount ?? formikValues.amount }`);
            return {
                ...currentDto,
                amount
            };
        }
    });

    return <FormModal
        id={ id }
        parentId={ parentId }
        resource={ 'Hulpstof' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        htmlBeforeForm={ currentElement() }
    />;
};

export default ProductExcipientForm;
