import React, { FC, useState } from 'react';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import ShowPage from '../../templates/pages/ShowPage';
import IngredientForm from './IngredientForm';
import { IngredientModel } from './IngredientTypes';
import IngredientProductTable from './pivot/IngredientProductTable';
import useIngredient from './useIngredient';
import Stats from "../../lib/samfe/components/Stats";


const IngredientShow: FC = (): JSX.Element => {

    const [ currentIngredient, setCurrentIngredient ] = useState<IngredientModel>();

    return (
        <ShowPage
            title={ currentIngredient?.name! }
            breadcrumb={ currentIngredient?.name! }
            setCurrentModel={ setCurrentIngredient }
            FormModal={ IngredientForm }
            httpHook={ useIngredient }

            tabs={ [
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <Stats stats={
                            [
                                {
                                    name: 'Naam',
                                    value: currentIngredient?.name
                                },
                                {
                                    name: 'Is Allergeen',
                                    value: currentIngredient?.is_allergen ? 'Ja' : 'Nee'
                                }
                            ]
                        }/>
                        <ExampleGraph/>
                    </>
                },
                {
                    name: 'Producten',
                    id: 'products',
                    element: <>
                        {currentIngredient?.id && <IngredientProductTable parentId={ currentIngredient?.id }/>}
                    </>
                }
            ] }
        />
    );
};
export default IngredientShow;