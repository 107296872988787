import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import useToaster from "../../components/Toaster/useToaster";

const Redirect: React.FC = (): JSX.Element => {

    const navigate = useNavigate()
    const {setToasterProps} = useToaster()
    const location = useLocation();
    useEffect(() => {
        navigate('/dashboard')
        setToasterProps({
            title: 'Pagina niet gevonden',
            message: location.pathname,
            type: "error",
            show: true
        })
    }, []);

    return <></>
}
export default Redirect;