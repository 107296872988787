import {SaveButton} from "../../lib/samfe/components/Button";
import React, {Dispatch, FC, ReactElement, SetStateAction, useEffect, useState} from "react";
import Modal from "../../lib/samfe/components/Modal";
import useCharge from "../charge/useCharge";
import {ChargeModel} from "../charge/ChargeTypes";

type BlockedChargeModalProps = {
    open: boolean,
    chargeId: number|undefined,
    onClose: () => void,
    setOpen: Dispatch<SetStateAction<boolean>>,
}

export const BlockedChargeModal: FC<BlockedChargeModalProps> = ({ open, setOpen, onClose, chargeId }) => {
    const chargeHook = useCharge();
    const [charge, setCharge] = useState<ChargeModel|undefined>(undefined);

    useEffect(() => {
        if (chargeId !== undefined) {
            // Get Charge info and display modal if blocked.
            chargeHook.getItem(chargeId, {
                with: ['blockedRisks']
            }).then((charge) => {
                if (charge !== undefined) {
                    if (charge.blockedRisks !== undefined && charge.blockedRisks.length > 0) {
                        setCharge(charge);
                        return;
                    }
                }
                setOpen(false);
            }).catch(() => setOpen(false)); // Failsafe on crash
        }
    }, [ chargeId ]);

    const parseBlockedRisks = (): string|ReactElement[] => {
        if (charge === undefined || charge.blockedRisks === undefined) {
            return [<></>];
        }


        return charge.blockedRisks.map((risk) => {
            return <li key={ risk.id }>
                <i>{ risk.risk?.name }</i>
            </li>
        });
    }

    return charge === undefined || charge.blockedRisks === undefined ? <></>
        : <Modal
            open={ open }
            setOpen={ setOpen }
            onClose={ onClose }
            title={ 'Charge geblokkeerd voor analyse' }
            className={ 'sm:!w-[80vw] sm:!max-w-[42rem]' }
            footer={ <>
                <SaveButton className={ 'sm:ml-3' } onClick={ () => {
                    setOpen(false);
                    onClose();
                } }>OK</SaveButton>
            </>
            }
        >
            <b>Geblokkeerd voor de volgende risico(s)</b>
            <ul className={'list-disc ml-8 mt-4'}>
                {parseBlockedRisks()}
            </ul>
        </Modal>
}