import React, { createContext, FC, ReactNode, useContext } from 'react';

type PageType = 'index'|'show';

type PageContextType = {
    type: PageType
}

const PageContext = createContext<PageContextType>({
    type: 'index'
});


export const usePage = () => useContext(PageContext);

type PageProviderProps = {
    children: ReactNode,
    type: PageType
}
export const PageProvider: FC<PageProviderProps> = ({children, type}) => {
    return (
        <PageContext.Provider value={ {type} }>
            { children }
        </PageContext.Provider>
    );
};
