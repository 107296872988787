import React, { FC } from 'react';
import { dispatchHttpEvent } from '../../../../events/Http.event';
import Icon from '../../../../lib/samfe/components/Icon/Icon';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import { useTableContext } from '../../providers/Table.provider';


type Props = {
    id: number,
    prevId?: number,
    nextId?: number,
    index: number
}

const SortTdComponent: FC<Props> = ({
    id,
    prevId,
    nextId,
    index
}) => {

    const { rowSort } = useTableContext();

    type DnDModel = {
        [K in keyof typeof rowSort]: number
    }
    const { http: { hook } } = useTableContext<DnDModel, ''>();


    const handleBack = async() => {
        if (!prevId) {
            return;
        }

        // Update current
        await hook.update(id, {
            [rowSort!.orderColumn]: index - 1
        }).then(async() => {
            await hook.update(prevId, {
                [rowSort!.orderColumn]: index
            });
        }).finally(dispatchHttpEvent);
    };

    const handleNext = async() => {
        if (!nextId) {
            return;
        }

        // Update current
        await hook.update(id, {
            [rowSort!.orderColumn]: index + 1
        }).then(async() => {
            await hook.update(nextId, {
                [rowSort!.orderColumn]: index
            });
        }).finally(dispatchHttpEvent);
    };


    return (
        <div className={ 'flex text-left content-center items-start' }>
            <button
                onClick={ handleBack }
                disabled={ !prevId }
                className={ classNames(!prevId && 'opacity-50 hover:cursor-not-allowed') }
            >
                <Icon
                    materialIconName={ 'keyboard_arrow_up' }
                    className={ '!text-[1.5rem] !m-0 !text-aqua hover:!text-aqua-hover active:!text-aqua-active' }
                    style={ {
                        transform: 'scale(1.5,1)'
                    } }
                />
            </button>

            <button
                onClick={ handleNext }
                disabled={ !nextId }
                className={ classNames(!nextId && 'opacity-50 hover:cursor-not-allowed') }
            >
                <Icon
                    materialIconName={ 'keyboard_arrow_down' }
                    className={ '!text-[1.5rem] !m-0 !text-aqua hover:!text-aqua-hover active:!text-aqua-active' }
                    style={ {
                        transform: 'scale(1.5,1)'
                    } }
                />
            </button>
        </div>
    );
};

export default SortTdComponent;
