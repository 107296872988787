import React from "react";
import useHttp from "../../../../../lib/samfe/modules/Http/useHttp";
import {
    CompositionProductDto,
    CompositionProductModel,
    CompositionProductRelationsBluePrint
} from "../CompositionProductTypes";


const useCompositionCompound = (productId?: number) => useHttp<CompositionProductModel, CompositionProductDto, CompositionProductRelationsBluePrint>({
    endpoint: `products/${productId}/composition-compounds`
});
export default useCompositionCompound;
