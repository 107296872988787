import React, { FC, useMemo } from 'react';
import RiskForm from './RiskForm';
import { RiskModel, RiskRelationsBluePrint } from './RiskTypes';
import useRisk from './useRisk';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";


type Props = Required<ExtendTableProps>

const RiskTable: FC<Props> = () => {

    const httpHook = useRisk();

    const rows = useMemo((): TableColumns<RiskModel, RiskRelationsBluePrint>[] => [
        {
            header: {
                children: 'Naam',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Aantal grondstoffen',
                sortCount: 'elements'
            },
            column: (item) => ({
                children: item.elements_count
            }),
            type: 'numeric',
        },
    ], []);

    return (<>
        <Table
            id={ 'risks' }
            rows={ rows }
            rowActions={{
                linkTo: (item) => `${item.id}`
            }}
            http={ {
                hook: httpHook,
                searchCols: ['name'],
                withCount: ['elements'],
                sortCol: 'name',
                sortDir: 'ASC',
            } }
            forms={ {
                edit: {
                    Form: RiskForm,
                    id: (record) => record.id,
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.name }`,
                    resourceName: () => 'Risico'
                },
            } }
        />
    </>
    );
};

export default RiskTable;