import React from 'react';
import Button, {ButtonProps} from "./Button";
import {SaveIcon} from "../Icon";

const SaveButton: React.FC<ButtonProps> = ({
    style,
    small,
    text,
    ...props
}):JSX.Element => <Button
    {...props}
    type={'submit'}
    style={style}
    small={small}
    icon={<SaveIcon />}
    text={text ?? 'Opslaan'}
    textColor={'white'}
    bgColor={{
        regular: 'aqua',
        hover: 'aqua-hover',
        active: 'aqua-active'
    }}
/>
export default SaveButton;
