/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { classNames } from '../../../modules/Parse/String';
import ErrorMessage from '../Caption/ErrorMessage';
import Label from '../Caption/Label';
import { requiredFieldClassname } from '../Support/FieldSupport';
import { InvalidField } from './GenericFieldTypes';


type Props = Omit<JSX.IntrinsicElements['textarea'], 'name'|'onChange'>&{
    label: string;
    name: string;
    defaultValue?: string,
    description?: string,
    invalid?: InvalidField,
    onChange?: (value: number|string|boolean) => void,
}

const TextArea = forwardRef<HTMLTextAreaElement, Props>(({
    onChange,
    ...props
}, ref) => {


    const {
        label,
        name,
        required,
        className,
        value,
        disabled,
        description,
        invalid
    } = useMemo(() => ({
        label: props.label,
        name: props.name,
        required: props.required,
        className: props.className,
        value: props.value,
        disabled: props.disabled,
        description: props.description,
        invalid: props.invalid
    }), [ props.label, props.name, props.required, props.className, props.value, props.disabled, props.description, props.invalid ]);


    const [ internalValue, setInternalValue ] = useState(value);
    useEffect(() => {
        if (value === internalValue) {
            return;
        }
        setInternalValue(value);
    }, [ value ]);


    const handleChange = (value: string) => {
        onChange?.(value);
        setInternalValue(value);
    };

    return <div className={ classNames(disabled && 'children:cursor-not-allowed opacity-60') }>
        <Label invalid={ invalid } htmlFor={ name }>{ label }{ required && '*' }</Label>
        { description && <span className={ 'text-sm text-graphite' }>{ description }</span> }
        <div className="mt-2">
            <textarea
                ref={ ref }
                { ...props }
                id={ name }
                onInput={ (e: React.ChangeEvent<HTMLTextAreaElement>) => handleChange(e.currentTarget.value) }
                rows={ props.rows ?? 4 }
                required={ required }
                disabled={ disabled }
                value={ internalValue }
                className={ classNames(
                    'block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
                    invalid?.isInvalid ?'ring-red-700' :'ring-gray-300',
                    required && requiredFieldClassname,
                    className
                ) }
            />
            <ErrorMessage message={ invalid?.isInvalid ?invalid?.message :undefined }/>
        </div>
    </div>;
});

TextArea.displayName = 'TextArea';
export default TextArea;