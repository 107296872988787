import React, {FC} from "react";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import useAsyncInit from "../../lib/samfe/components/Form/Effects/useAsyncInit";
import Yup from "../../lib/samfe/components/Form/Yup";
import useStandard from "./useStandard";
import {StandardDto, StandardModel, StandardRelationsBluePrint} from "./StandardTypes";
import {useForm} from "../../lib/samfe/components/Form";
import {FormModal} from "../../lib/samfe/components/Modal";


const StandardForm: FC<ExtendFormModalProps<StandardDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    /**
     *
     */
    const shape = (): Shape<StandardDto> => ({
        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text'),

    });


    /**
     *
     */
    const {validationSchema, setShape} = useSchema<StandardDto>(shape());

    const initializer = async () => {
        setShape(shape())
    }

    /**
     *
     */
    const { formikConfig, formFields } = useForm<StandardModel, StandardDto, StandardRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useStandard,
        onSuccess: onSuccess
    });


    return <FormModal
        id={id}
        resource={'Producent standaard'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
};

export default StandardForm;
