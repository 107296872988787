import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import ProducerArticleForm from '../../../producer/pivot/producerArticle/ProducerArticleForm';
import { ArticleProducerModel, ArticleProducerRelationsBluePrint } from './ArticleProducerTypes';
import useArticleProducer from './useArticleProducer';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ArticleProducerTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useArticleProducer(parentId);


    const rows = useMemo((): TableColumns<ArticleProducerModel, ArticleProducerRelationsBluePrint>[] => [
        {
            header: {
                children: 'Producent'
            },
            column: (item) => ({
                children: item.producer?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Artikelnaam producent',
                sortCol: 'custom_name'
            },
            column: (item) => ({
                children: item.custom_name
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'producers' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/producers/${ item.producer_id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: [ 'custom_name', 'producer.name' ],
                with: [ 'producer' ],
                sortCol: 'custom_name',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: ProducerArticleForm,
                    parentId: (item) => item.producer_id,
                    id: (item) => item.id
                },
                archive: {
                    id: (item) => item.id,
                    itemName: (item) => item.custom_name,
                    resourceName: (item) => `Artikel van ${ item.producer?.name ?? 'producent' }`
                },
                actions: []
            } }
            callbacks={ [] }
        />
    </>;
};

export default ArticleProducerTable;