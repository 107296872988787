import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import BaseElementTable from '../../element/BaseElementTable';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const CompoundElementTable: FC<Props> = ({ parentId }): JSX.Element => {
    return <BaseElementTable
        forType={ 'reference' }
        hideType={true}
        extraFilter={ {
            column: 'compound_id',
            operator: '=',
            value: parentId
        } }
    />;
};

export default CompoundElementTable;