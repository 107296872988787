import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import { AttributeType } from '../../../attribute/AttributeTypes';
import { ParsedAttributeProductItem, useParsedAttributeProductIndex } from './useAttributeProduct';


type ItemWrapperProps = {
    value: string|null|undefined,
    isValid: boolean
}
const ItemWrapper: FC<ItemWrapperProps> = ({ value, isValid }): JSX.Element => (
    <span className={ classNames(!isValid && 'text-fire')}>
        { isValid || !!value ?value :'Geen waarde beschikbaar!' }
    </span>
);

type WrapperTypeProps = { item: ParsedAttributeProductItem }


const TextWrapper: FC<WrapperTypeProps> = ({ item }): JSX.Element => (
    <ItemWrapper
        value={ item.attribute_value }
        isValid={ item.attribute_value !== null && item.attribute_value.replace(' ', '') !== '' }
    />
);

const SelectWrapper: FC<WrapperTypeProps> = ({ item }): JSX.Element => (
    <ItemWrapper
        value={ item.attribute_option_names?.[0] }
        isValid={ !!item.attribute_option_names && item.attribute_option_names.length>0 }
    />
);

const MultiSelectWrapper: FC<WrapperTypeProps> = ({ item }) => {
    if (item.attribute_option_names == null || item.attribute_option_names.length == 0) {
        return <ItemWrapper value={ undefined } isValid={ false }/>;
    }

    return <ul className={ 'list-inside' }>
        { item.attribute_option_names.map((item, i) =>
            <li className={ 'list-disc' } key={ i }>{ item }</li>
        ) }
    </ul>;
};


type Props = ExtendTableProps

const AttributeProductTable: FC<Props> = ({ parentId, notification }): JSX.Element => {

    const httpHook = useParsedAttributeProductIndex(parentId);


    const rows = useMemo((): TableColumns<ParsedAttributeProductItem, ''>[] => [
        {
            header: {
                children: 'Eigenschap'
            },
            column: (item) => ({
                children: item.attribute_name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Waarde'
            },
            column: (item) => ({
                children: <>
                    { ([ 'number', 'text', 'textarea' ] as AttributeType[]).includes(item.attribute_type) && <TextWrapper item={ item }/> }
                    { item.attribute_type == 'select' && <SelectWrapper item={ item }/> }
                    { item.attribute_type == 'multiselect' && <MultiSelectWrapper item={ item }/> }
                </>
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Categorie'
            },
            column: (item) => ({
                children: item?.product_type_name
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'attributes' }
            rows={ rows }
            notification={notification}
            rowActions={ {
                linkTo: (item) => `/settings/attributes/${ item.attribute_id }`
            } }
            http={ {
                hook: httpHook,
                filterConfig: {
                    hideAllFilters: true
                },
                showAll: true
            } }
        />
    </>;
};

export default AttributeProductTable;