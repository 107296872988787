import React, {FC} from "react";
import IndexPage from "../../templates/pages/IndexPage";
import AccountForm from './AccountForm';
import AccountTable from './AccountTable';



const AccountIndex: FC = (): JSX.Element => <IndexPage
    title={"Accounts"}
    Table={AccountTable}
    FormModal={AccountForm}
    preventRedirect={true}
/>
export default AccountIndex;