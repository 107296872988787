import { useState } from 'react';
import useAxios from '../../../../lib/samfe/modules/Http/useAxios';
import useHttp from '../../../../lib/samfe/modules/Http/useHttp';
import { ProductRiskDto, ProductRiskFields, ProductRiskItem } from './ProductRiskTypes';

// ProductRiskTableItem
const useProductRiskForm = (productId?: number) => {

    const http = useHttp<ProductRiskItem, {}, ''>({
        endpoint: `products/${ productId }/risk-form`
    });

    const { post, put, get } = useAxios();

    const getItemWithProductId = async( productId?: number, riskId?: number) => await get<ProductRiskItem, ''>(
        `products/${ productId }/risk-form/${riskId}`);

    const create = async(productId: number, payload: ProductRiskFields) => await post(
        `products/${ productId }/risks`,
        payload
    );

    const update = async(productId: number, productRiskId: number, payload: ProductRiskDto) => await put(
        `products/${ productId }/risks/${productRiskId}`,
        payload
    );

    return {
        ...http,
        create,
        update,
        getItemWithProductId,
    };

};
export default useProductRiskForm;
