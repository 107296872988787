import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import React, { Dispatch, Fragment, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { classNames } from '../../modules/Parse/String';
import WarningModal from './WarningModal';


export type ModalState = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    onClose?: () => void
}

// Used for Modal implementations
export type ModalProps = ModalState&{
    title?: string|JSX.Element,
    children?: ReactNode
}

type Props = ModalProps&{
    className?: string
    footer?: JSX.Element
}

const Modal: React.FC<Props> = ({
    open,
    setOpen,
    title,
    children,
    className,
    footer,
    onClose
}): JSX.Element => {

    //noinspection DuplicatedCode
    const modalResizeHook = useResizeDetector();
    const contentResizeHook = useResizeDetector();


    const [ internalOpen, setInternalOpen ] = useState(open);
    const [ show, setShow ] = useState(open);
    useEffect(() => {
        setInternalOpen(open);
    }, [ open ]);

    useEffect(() => {
        if (internalOpen) {
            setShow(true);
            return;
        }
        setTimeout(() => {
            setShow(false);
        }, 350);
    }, [ internalOpen ]);

    useEffect(() => {
        if (!show) {
            setOpen(false);
        }
    }, [ show ]);

    const [ showBorder, setShowBorder ] = useState(false);
    useEffect(() => {
        const modalContainerHeightInRem = (modalResizeHook.height ?? 0) / 16;
        const contentContainerHeightInRem = (contentResizeHook.height ?? 0) / 16;
        const nett = modalContainerHeightInRem - contentContainerHeightInRem;
        setShowBorder(nett<1.25);
    }, [ modalResizeHook.height, contentResizeHook.height ]);


    const handleClose = () => {
        onClose?.();
        setInternalOpen(false);
    };


    const [ openWarningModal, setOpenWarningModal ] = useState(false);
    const handleEscape = useCallback((event: KeyboardEvent) => {
        const { key } = event;
        if (key === 'Escape') {
            setOpenWarningModal(true);
        }
    }, []);


    useEffect(() => {
        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, []);


    return <>
        { openWarningModal && <WarningModal
            open={ openWarningModal }
            setOpen={ setOpenWarningModal }
            title={ 'Wijzigingen annuleren?' }
            text={ '' } confirmCallback={ () => {
            handleClose();
        } }
        /> }
        { show && <Transition.Root show={ internalOpen } appear={ true } as={ Fragment }>
            <Dialog as="div" className="absolute left-0 right-0 top-0 bottom-0 z-50" onClose={ () => {
            } }>
                <Transition.Child
                    as={ Fragment }
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gradient-to-br from-aqua/50 to-aqua/10 backdrop-blur-[0.0416rem] transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="sm:flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={ Fragment }
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className={ classNames(
                                'relative transform rounded-xl bg-white text-left shadow-sm drop-shadow-lg transition-all h-[100vh] sm:h-auto w-full  sm:max-w-lg',
                                className
                            ) }>
                                <div className="sticky z-10 bg-white text-aqua p-6 w-full top-0 sm:rounded-t-xl border-b border-b-graphite/10">
                                    <button
                                        type="button"
                                        className="absolute top-7 right-6 rounded-md focus:outline-none focus:ring-2 focus:ring-sky focus:ring-offset-2"
                                        onClick={ handleClose }
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6 text-aqua/50" aria-hidden="true"/>
                                    </button>
                                    { title && <Dialog.Title as="h3" className="text-lg font-semibold">
                                        { title }
                                    </Dialog.Title> }
                                </div>

                                <div ref={ modalResizeHook.ref } className={ classNames('modal-container bg-white text-center sm:text-left sticky h-screen sm:h-auto max-h-[calc(100vh-4rem)] sm:max-h-[calc(100vh-12rem)] overflow-y-auto px-6') }>
                                    <div ref={ contentResizeHook.ref } className="mt-5 text-left">{ children }</div>
                                </div>

                                { footer && <div className={ classNames(showBorder && 'border-t border-t-graphite/10', 'sm:z-[-1] flex flex-row-reverse absolute sm:sticky w-full pb-6 pt-2 bottom-0 bg-white sm:rounded-b-xl px-6') }>{ footer }</div> }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root> }
    </>;
};
export default Modal;
