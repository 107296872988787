import React, {FC, useState} from "react";
import ShowPage from "../../templates/pages/ShowPage";
import ExampleGraph from "../../lib/samfe/components/Graph/ExampleGraph";
import useArticle from "../article/useArticle";
import {ArticleModel} from "../article/ArticleTypes";

const ArticleShow: FC = (): JSX.Element => {

    const [currentArticle, setCurrentArticle] = useState<ArticleModel|undefined>(undefined);

    return(
        <ShowPage
            title={currentArticle?.number!}
            breadcrumb={currentArticle?.number!}
            setCurrentModel={setCurrentArticle}
            httpHook={useArticle}
            tabs={currentArticle ? [
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <ExampleGraph />
                    </>
                }
            ]: []}
        />
    )
}
export default ArticleShow;