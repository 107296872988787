import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DragAndDropField from '../../../../document/DragAndDropField';
import { AssessmentFileIdentifier } from '../../../types/AssessmentTypes';



type Props = {
    droppedFileState: [ AssessmentFileIdentifier[], Dispatch<SetStateAction<AssessmentFileIdentifier[]>> ]
}

const AssessmentBookUpload: FC<Props> = ({
    droppedFileState
}) => {

    const [ droppedFiles, setDroppedFiles ] = useMemo(
        () => droppedFileState,
        [ droppedFileState ]
    );

    const handleDroppedFiles = (files: File[]) => {

        const parsedFiles: AssessmentFileIdentifier[] = files.map(file => ({
            uuid: uuidv4(),
            file,
            assessmentIds: []
        }));
        setDroppedFiles([ ...droppedFiles, ...parsedFiles ]);
    };

    return (
        <div>
            <DragAndDropField onDrop={ handleDroppedFiles }/>
        </div>
    );
};

export default AssessmentBookUpload;
