import React, { FC } from 'react';
import IndexPage from '../../../templates/pages/IndexPage';
import BlockedAssessmentTable from "../tables/BlockedAssessmentTable";


const BlockedAssessmentIndex: FC = (): JSX.Element => <IndexPage
    title={ 'Geblokkeerde charges voor analyse' }
    Table={ BlockedAssessmentTable }
    preventRedirect={true}
/>;
export default BlockedAssessmentIndex;