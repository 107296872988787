import React, {useState} from "react";
import {
        ActionButton,
        CancelButton,
        CreateButton,
        DeleteButton,
        EditButton,
        SaveButton
} from "../../lib/samfe/components/Button";
import TestForm from "./TestForm";
import FormikTest from "./FormikTest";



const Test: React.FC = (): JSX.Element => {

        const [formikOpen, setFormikOpen] = useState(false);
        const formikTestId: number|undefined = undefined;
        const onFormikSuccess = (id?: number) => {
            console.log({onSuccessId: id})
        }


        return (
            <>
                <div className={ 'hover:bg-opacity-10  bg-opacity-20' }></div>
                <div className={ 'hover:text-white' }></div>
                <div className={ 'bg-emerald text-emerald border-emerald hover:bg-emerald-hover hover:text-emerald-hover hover:border-emerald-hover active:bg-emerald-active active:text-emerald-active active:border-emerald-active' }></div>
                <div className={ 'bg-aqua text-aqua border-aqua hover:bg-aqua-hover hover:text-aqua-hover hover:border-aqua-hover active:bg-aqua-active active:text-aqua-active active:border-aqua-active' }></div>
                <div className={ 'bg-fire text-fire border-fire hover:bg-fire-hover hover:text-fire-hover hover:border-fire-hover active:bg-fire-active active:text-fire-active active:border-fire-active' }></div>
                <div className={ 'bg-sky text-sky border-sky hover:bg-sky-hover hover:text-sky-hover hover:border-sky-hover active:bg-sky-active active:text-sky-active active:border-sky-active' }></div>
                <div className={ 'bg-milk text-milk border-milk hover:bg-milk-hover hover:text-milk-hover hover:border-milk-hover active:bg-milk-active active:text-milk-active active:border-milk-active' }></div>
                <div className={ 'bg-carrot text-carrot border-carrot hover:bg-carrot-hover hover:text-carrot-hover hover:border-carrot-hover active:bg-carrot-active active:text-carrot-active active:border-carrot-active' }></div>
                <div className="bg-blue-50 bg-blue-100 bg-blue-200 bg-blue-300 bg-blue-400 bg-blue-500 bg-blue-600 bg-blue-700 bg-blue-800 bg-blue-900"></div>
                <div className="text-blue-50 text-blue-100 text-blue-200 text-blue-300 text-blue-400 text-blue-500 text-blue-600 text-blue-700 text-blue-800 text-blue-900"></div>
                <div className="border-blue-50 border-blue-100 border-blue-200 border-blue-300 border-blue-400 border-blue-500 border-blue-600 border-blue-700 border-blue-800 border-blue-900"></div>

                <div className="border-yellow-50 border-yellow-100 border-yellow-200 border-yellow-300 border-yellow-400 border-yellow-500 border-yellow-600 border-yellow-700 border-yellow-800 border-yellow-900"></div>
                <div className="border-orange-50 border-orange-100 border-orange-200 border-orange-300 border-orange-400 border-orange-500 border-orange-600 border-orange-700 border-orange-800 border-orange-900"></div>

                <div className="text-yellow-50 text-yellow-100 text-yellow-200 text-yellow-300 text-yellow-400 text-yellow-500 text-yellow-600 text-yellow-700 text-yellow-800 text-yellow-900"></div>
                <div className="text-orange-50 text-orange-100 text-orange-200 text-orange-300 text-orange-400 text-orange-500 text-orange-600 text-orange-700 text-orange-800 text-orange-900"></div>

                <div className="bg-yellow-50 bg-yellow-100 bg-yellow-200 bg-yellow-300 bg-yellow-400 bg-yellow-500 bg-yellow-600 bg-yellow-700 bg-yellow-800 bg-yellow-900"></div>
                <div className="bg-orange-50 bg-orange-100 bg-orange-200 bg-orange-300 bg-orange-400 bg-orange-500 bg-orange-600 bg-orange-700 bg-orange-800 bg-orange-900"></div>


                <CreateButton disabled={ true } onClick={ () => {
                } }/>&emsp;
                <CreateButton style={ 'primary' } onClick={ () => {
                } }/>&emsp;
                <CreateButton style={ 'secondary' } onClick={ () => {
                } }/>&emsp;
                <CreateButton style={ 'tertiary' } onClick={ () => {
                } }/>&emsp;

                <br/><br/>

                <CreateButton disabled={ true } small={ true } onClick={ () => {
                } }/>&nbsp;
                <CreateButton small={ true } style={ 'primary' } onClick={ () => {
                } }/>&nbsp;
                <CreateButton small={ true } style={ 'secondary' } onClick={ () => {
                } }/>&nbsp;
                <CreateButton small={ true } style={ 'tertiary' } onClick={ () => {
                } }/>&nbsp;

                <br/><br/>
                <hr/>
                <br/><br/>

                <EditButton disabled={ true } onClick={ () => {
                } }/>&emsp;
                <EditButton style={ 'primary' } onClick={ () => {
                } }/>&emsp;
                <EditButton style={ 'secondary' } onClick={ () => {
                } }/>&emsp;
                <EditButton style={ 'tertiary' } onClick={ () => {
                } }/>&emsp;

                <br/><br/>

                <EditButton disabled={ true } small={ true } onClick={ () => {
                } }/>&nbsp;
                <EditButton small={ true } style={ 'primary' } onClick={ () => {
                } }/>&nbsp;
                <EditButton small={ true } style={ 'secondary' } onClick={ () => {
                } }/>&nbsp;
                <EditButton small={ true } style={ 'tertiary' } onClick={ () => {
                } }/>&nbsp;

                <br/><br/>
                <hr/>
                <br/><br/>


                <DeleteButton disabled={ true } onClick={ () => {
                } }/>&emsp;
                <DeleteButton style={ 'primary' } onClick={ () => {
                } }/>&emsp;
                <DeleteButton style={ 'secondary' } onClick={ () => {
                } }/>&emsp;
                <DeleteButton style={ 'tertiary' } onClick={ () => {
                } }/>&emsp;

                <br/><br/>

                <DeleteButton disabled={ true } small={ true } onClick={ () => {
                } }/>&nbsp;
                <DeleteButton small={ true } style={ 'primary' } onClick={ () => {
                } }/>&nbsp;
                <DeleteButton small={ true } style={ 'secondary' } onClick={ () => {
                } }/>&nbsp;
                <DeleteButton small={ true } style={ 'tertiary' } onClick={ () => {
                } }/>&nbsp;

                <br/><br/>
                <hr/>
                <br/><br/>

                <SaveButton onClick={ () => {
                } }/>&emsp;
                <SaveButton style={ 'primary' } onClick={ () => {
                } }/>&emsp;
                <SaveButton style={ 'secondary' } onClick={ () => {
                } }/>&emsp;
                <SaveButton style={ 'tertiary' } onClick={ () => {
                } }/>&emsp;

                <br/><br/>

                <SaveButton disabled={ true } small={ true } onClick={ () => {
                } }/>&nbsp;
                <SaveButton small={ true } style={ 'primary' } onClick={ () => {
                } }/>&nbsp;
                <SaveButton small={ true } style={ 'secondary' } onClick={ () => {
                } }/>&nbsp;
                <SaveButton small={ true } style={ 'tertiary' } onClick={ () => {
                } }/>&nbsp;

                <br/><br/>
                <hr/>
                <br/><br/>

                <CancelButton disabled={ true } onClick={ () => {
                } }/>&emsp;
                <CancelButton style={ 'primary' } onClick={ () => {
                } }/>&emsp;
                <CancelButton style={ 'secondary' } onClick={ () => {
                } }/>&emsp;
                <CancelButton style={ 'tertiary' } onClick={ () => {
                } }/>&emsp;

                <br/><br/>

                <CancelButton disabled={ true } small={ true } onClick={ () => {
                } }/>&nbsp;
                <CancelButton small={ true } style={ 'primary' } onClick={ () => {
                } }/>&nbsp;
                <CancelButton small={ true } style={ 'secondary' } onClick={ () => {
                } }/>&nbsp;
                <CancelButton small={ true } style={ 'tertiary' } onClick={ () => {
                } }/>&nbsp;

                <br/><br/>
                <hr/>
                <br/><br/>

                <ActionButton icon={ '' } text={ 'action' } onClick={ () => {
                } }/>

                <br/><br/>
                <hr/>
                <br/><br/>

                <TestForm/>


                <ActionButton icon={ '' } text={ 'Formik test' } onClick={ () => setFormikOpen(true) }/>
                { formikOpen && <FormikTest
                    id={ formikTestId }
                    open={ formikOpen }
                    setOpen={ setFormikOpen }
                    onSuccess={ onFormikSuccess }
                /> }

            </>
        );
}
export default Test;