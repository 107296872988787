import { createSignal } from '@react-rxjs/utils';
import { useEffect, useState } from 'react';
import { merge } from 'rxjs';


export type HttpEvent = {
};

const [event$, setEvent] = createSignal<HttpEvent | undefined>();
const eventMap$ = merge(event$);

export const dispatchHttpEvent = (): void => {
    setEvent({});
    setTimeout(() => {
        setEvent(undefined);
    }, 100)
};

type EventState = {
    current?: HttpEvent;
};

const initialEventState: EventState = {};

export const useHttpEvent = () => {

    const [current, setCurrent] = useState(initialEventState.current);

    useEffect(() => {
        const subscription = eventMap$.subscribe(eventData => {
            setCurrent(eventData);
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [current]);

    return current;
};
