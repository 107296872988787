import React from 'react';
import Button, {ButtonProps} from "./Button";
import {CancelIcon} from "../Icon";

const CancelButton: React.FC<ButtonProps> = ({
    style,
    small,
    text,
    ...props
}):JSX.Element => <Button
    {...props}
    style={style}
    small={small}
    icon={<CancelIcon />}
    text={text ?? 'Annuleren'}
    textColor={'white'}
    bgColor={{
        regular: 'gray-800',
        hover: 'gray-700',
        active: 'gray-900'
    }}
/>

export default CancelButton;
