import React, {FC} from "react";
import IndexPage from "../../templates/pages/IndexPage";
import SaleTable from "./SaleTable";
import SaleForm from "./SaleForm";


const SaleIndex: FC = (): JSX.Element => <IndexPage
    title={"Verkooporders"}
    Table={SaleTable}
    FormModal={SaleForm}
/>

export default SaleIndex;
