import {useEffect, useState} from "react";
import useAxios from "../../lib/samfe/modules/Http/useAxios";
import {PaginatedResponse} from "../../lib/samfe/modules/Http/usePagination";


type PackageSlice = {
    number: number
}


/**
 *
 */
const useAvailablePackageNumber = (): number => {

    const {get} = useAxios();

    const bluePrint: PackageSlice = {
        number: 0
    }

    /**
     * Fetch all packageModel numbers from API.
     *
     * @returns {Promise<PackageNumberResponse>>} API response.
     */
    const fetchProductNumbers = async (): Promise<PackageSlice[]> => {


        return await get<PaginatedResponse<PackageSlice>>('packages', {
            // @ts-ignore
            select: ['number'],
            filter: 'archived=1|archived=0',
            orderBy: 'number',
            limit: "all",
        }).then(r => {
            if (r.status === 404) {
                return [bluePrint];
            }
            return r.data.items;
        }).catch(() => [bluePrint]);
    }


    /**
     * Create number list filled from min number to max number.
     *
     * @example min:2 max:6 -> [2,3,4,5,6]
     * @returns {number[]} Filled set.
     * @param _min
     * @param _max
     */
    const range = (_min:number, _max:number): number[] => {
        const min = _min >= 0 ? _min : 0;
        const max = _max >= 0 ? _max : 0;
        const isReverse = (min > max);
        const targetLength = isReverse ? (min - max) + 1 : (max - min ) + 1;
        const arr = Array(targetLength >= 0 && targetLength < Infinity ? targetLength : 0);
        const b = Array.apply(null, arr);
        const result = b.map((discard, n) => {
            return (isReverse) ? n + max : n + min;
        });
        return (isReverse) ? result.reverse() : result;
    }


    /**
     * Get the lowest packageModel number from set.
     *
     * @param {PackageSlice[]} packageModels Product slice set.
     * @returns {number} Lowest packageModel number from set.
     */
    const getLowestNumber = (packageModels: PackageSlice[] = []): number => Math.min( ...packageModels?.map(packageModel => packageModel.number) ?? [bluePrint]);


    /**
     * Get the highest packageModel number from set.
     *
     * @param {PackageSlice[]} packageModels Product slice set.
     * @returns {number} Highest packageModel number from set.
     */
    const getHighestNumber = (packageModels: PackageSlice[] = []): number => Math.max( ...packageModels?.map(packageModel => packageModel.number) ?? [bluePrint]);


    /**
     * Method for retrieving a list of packageModel
     */
    let prevHighestNumber: number = 0
    const run = async (): Promise<number[]> => {

        let packageModels = await fetchProductNumbers();
        let lowestOccupiedNumber = getLowestNumber(packageModels);
        let highestOccupiedNumber = getHighestNumber(packageModels);

        // No more results from back end, so get the highest available number and increment by 1
        if (packageModels?.length === 0 && prevHighestNumber !== undefined) {
            return [prevHighestNumber+1];
        }

        // overwrite previous highest number with current one.
        prevHighestNumber = highestOccupiedNumber;

        // Eliminate occupied number from complete set.
        // What remains are available numbers.
        let series = range(lowestOccupiedNumber, highestOccupiedNumber);
        for (let i = 0; i < packageModels?.length; i++) {
            let number = packageModels[i].number;
            // Remove number from availability list if already in use.
            if ( series.includes( number )) {
                series = series.filter(item => item != number);
            }
        }

        if (series.length < 1) {
            series.push(highestOccupiedNumber+1)
        }
        return series;
    }


    const [availableNumber, setAvailableNumber] = useState(0);
    useEffect(() => {
        run().then(numbers => {
            if (numbers.length > 0) {
                setAvailableNumber(numbers[0])
            }
        })
    }, []);


    return availableNumber;
}

export default useAvailablePackageNumber;