import React, { FC, useEffect, useState } from 'react';

import Input from '../../../../deprecated/components/Input/Input';
import { Option } from '../../../../deprecated/components/Input/InputTypes';
import MultiSelect from '../../../../deprecated/components/Input/MultiSelect';
import Select from '../../../../deprecated/components/Input/Select';
import TextArea from '../../../../lib/samfe/components/Form/Generic/TextArea';
import useLoadingScreen from '../../../../lib/samfe/components/LoadingScreen/useLoadingScreen';

import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import LegacyFormModal from '../../../../lib/samfe/components/Modal/LegacyFormModal';
import useToaster from '../../../../lib/samfe/components/Toaster/useToaster';
import useAxios from '../../../../lib/samfe/modules/Http/useAxios';
import { ListResponse } from '../../../../lib/samfe/modules/Http/useHttp';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import { AttributeType } from '../../../attribute/AttributeTypes';


/**
 * Attribute option partial
 */
type ParsedAttributeOption = {
    id: number,
    attribute_id: number,
    name: string
}

/**
 * Attribute product option partial
 */
type ParsedAttributeProductOption = {
    id: number,
    name: string
}

/**
 * Form field item.
 */
type ParsedAttributeProductItem = {
    attribute_id: number,
    attribute_name: string,
    attribute_order: number,
    attribute_type: AttributeType,
    attribute_description: string|null
    product_type_name: string,
    attribute_value: string|null,
    attribute_options: ParsedAttributeOption[]|null,
    attribute_product_options: ParsedAttributeProductOption[]|null,
    order: number
};

/**
 * PUT request item.
 */
type ParsedAttributeProductPutItem = {
    attribute_id: number;
    product_id: number;
    attribute_option_ids?: number[];
    attribute_value?: string;
    order: number;
};


/**
 * Custom response body for form fields.
 */
type Res = ListResponse<ParsedAttributeProductItem>

/**
 * Custom payload for saving changed attribute products.
 */
type UpdatePayload = {
    items: ParsedAttributeProductPutItem[]
}

/**
 * @todo no data in form
 * @param {number | undefined} parentId
 * @param {boolean} open
 * @param {(value: (((prevState: boolean) => boolean) | boolean)) => void} setOpen
 * @param {((id: (number | undefined)) => void) | undefined} onSuccess
 * @returns {JSX.Element}
 * @constructor
 */
const AttributeProductForm: FC<ExtendFormModalProps<ParsedAttributeProductItem[]>> = ({ parentId, open, setOpen, onSuccess }): JSX.Element => {

    const product_id = parentId!;
    const { setLoadingScreenProps } = useLoadingScreen();
    const { setToasterProps } = useToaster();
    const { get, put } = useAxios();


    /** @var {ParsedAttributeProductItem[]} items API response items */
    const [ items, setItems ] = useState<ParsedAttributeProductItem[]>([]);
    /** @var {ParsedAttributeProductItem[]} putItems attribute products that should be updated */
    const [ putItems, setPutItems ] = useState<ParsedAttributeProductPutItem[]>([]);
    const [ shouldSave, setShouldSave ] = useState(false);


    /**
     * Retrieve form values.
     */
    useEffect(() => {
        get<Res>(`products/${ product_id }/parsed/attributes/form-values`)
            .then(res => setItems(res.data.items));
    }, []);


    /**
     * Process value change for PUT request payload.
     *
     * @param putItem
     * @returns {void}
     */
    const handleChange = (putItem: ParsedAttributeProductPutItem): void => {
        const index = putItems.findIndex(item => item.attribute_id === putItem.attribute_id);
        if (index<0) {
            setPutItems([ ...putItems, putItem ]);
            return;
        }

        const updatedPutItems = [ ...putItems ];
        updatedPutItems[index] = putItem;
        setPutItems(updatedPutItems);
    };


    /**
     * Form field for text attribute.
     *
     * @param {ParsedAttributeProductItem} item
     * @param formType number or text type
     * @returns {JSX.Element}
     */
    const getInputItem = (item: ParsedAttributeProductItem, formType: 'number'|'text'): JSX.Element => {
        const currentValue = (putItems.find(putItem => putItem.attribute_id === item.attribute_id) ?? item)?.attribute_value ?? '';
        return <Input
            type={ formType }
            value={ currentValue }
            name={ item.attribute_name }
            onChange={ (_, newVal) => {
                if (newVal === '') {
                    return;
                }
                handleChange({
                    attribute_id: item.attribute_id,
                    product_id: product_id,
                    attribute_value: newVal,
                    order: item.order
                });
            } }
            required={ true }
            invalid={ currentValue === '' }
            max={512}
        />;
    };


    /**
     * Form field for text attribute.
     *
     * @param {ParsedAttributeProductItem} item
     * @returns {JSX.Element}
     */
    const getTextAreaItem = (item: ParsedAttributeProductItem): JSX.Element => {
        const currentValue = (putItems.find(putItem => putItem.attribute_id === item.attribute_id) ?? item)?.attribute_value ?? '';
        const isEmpty = (currentValue ?? '').length === 0;
        return <>
            <div>
                <TextArea
                    label={''}
                    value={ currentValue }
                    name={ item.attribute_name }
                    onChange={ (newVal) => {
                        if (newVal === '') {
                            return;
                        }
                        handleChange({
                            attribute_id: item.attribute_id,
                            product_id: product_id,
                            attribute_value: `${newVal}`,
                            order: item.order
                        });
                    } }
                    maxLength={512}
                    invalid={ isEmpty ?{
                        isInvalid: true,
                        message: isEmpty ? 'Veld is verplicht!' : undefined
                    } : undefined }
                />
            </div>

            <small className={classNames('relative block text-right text-graphite', isEmpty ? '-top-6' : '-top-1')}>{(currentValue ?? '').length} v/d 512 characters</small>
        </>;
    };


    /**
     * Form field for select attribute.
     *
     * @param {ParsedAttributeProductItem} item
     * @returns {JSX.Element}
     */
    const getSelectItem = (item: ParsedAttributeProductItem): JSX.Element => {

        let name = (item.attribute_product_options ?? []).length>0 ?item.attribute_product_options![0].name :'';
        let value = (item.attribute_product_options ?? []).length>0 ?item.attribute_product_options![0].id :undefined;

        const putItem = putItems.find(putItem => putItem.attribute_id === item.attribute_id);
        if (putItem) {
            const optionId = (putItem.attribute_option_ids ?? [])[0] ?? undefined;
            if (optionId) {
                const attributeOption = item.attribute_options?.find(option => option.id === optionId);
                if (attributeOption) {
                    name = attributeOption.name;
                    value = attributeOption.id;
                }
            }
        }

        return <Select
            value={ name }
            name={ item.attribute_name }
            required={ true }
            invalid={ name === '' }
            overflow={ true }
            options={ (item.attribute_options ?? []).sort((a, b) => {
                return a.name.localeCompare(b.name);
            }).map(ao => ({
                displayName: ao.name,
                value: ao.id,
                selected: ao.id === value
            }) as Option) }
            onChange={ (k, newVal) => {
                handleChange({
                    attribute_id: item.attribute_id,
                    product_id: product_id,
                    order: item.order,
                    attribute_option_ids: [ parseInt(newVal) ]
                });
            } }
        />;
    };


    /**
     * Form field for multi select attribute.
     *
     * @param {ParsedAttributeProductItem} item
     * @returns {JSX.Element}
     */
    const getMultiSelectItem = (item: ParsedAttributeProductItem): JSX.Element => {
        let attributeProductOptionIds = (item.attribute_product_options ?? []).map(apo => apo.id).sort();
        const putItem = putItems.find(putItem => putItem.attribute_id === item.attribute_id);
        if (putItem) {
            const optionIds = (putItem.attribute_option_ids ?? []);
            if (optionIds.length>0) {
                attributeProductOptionIds = optionIds;
            }
        }

        return <MultiSelect
            value={ attributeProductOptionIds }
            overflow={ true }
            name={ item.attribute_name }
            required={ true }
            invalid={ attributeProductOptionIds.length === 0 }
            options={ (item.attribute_options ?? []).sort((a, b) => {
                return a.name.localeCompare(b.name);
            }).map(ao => ({
                displayName: ao.name,
                value: ao.id,
                selected: attributeProductOptionIds.includes(ao.id)
            }) as Option) }

            onChange={ (_, newVals) => {

                const newValsSorted = newVals.sort();
                const hasAllOriginalItems = newValsSorted.every(arr2Item => attributeProductOptionIds.includes(arr2Item as number));
                const belongsToAllOriginalItems = attributeProductOptionIds.every(arr2Item => newValsSorted.includes(arr2Item))

                if (!hasAllOriginalItems || !belongsToAllOriginalItems) {
                    handleChange({
                        attribute_id: item.attribute_id,
                        product_id: product_id,
                        order: item.order,
                        attribute_option_ids: newVals.map(v => v as number)
                    });
                }
            } }
        />;
    };


    /**
     * Form field controller for correct attribute form field.
     *
     * @param {ParsedAttributeProductItem} item
     * @returns {JSX.Element}
     */
    const getFormItem = (item: ParsedAttributeProductItem): JSX.Element => {
        let input = <span className={ 'text-fire' }>Error</span>;

        switch (item.attribute_type) {
            case 'text':
            case 'number':
                input = getInputItem(item, item.attribute_type);
                break;
            case 'textarea':
                input = getTextAreaItem(item);
                break;
            case 'select':
                input = getSelectItem(item);
                break;
            case 'multiselect':
                input = getMultiSelectItem(item);
                break;
        }

        return <div key={ item.attribute_id } className={ 'grid grid-cols-1 lg:grid-cols-12 my-2' }>
            <div className="col-span-4 flex items-center">
                <p>
                    { item.attribute_name }<br/>
                    <small>{ item.attribute_description }</small>
                </p>

            </div>
            <div className="col-span-8">{ input }</div>
        </div>;
    };


    /**
     * Submit changed items with a PUT request.
     *
     * @description false is failed.
     * @returns {Promise<boolean>}
     */
    const handleSave = async(): Promise<boolean> => put(`products/${ product_id }/parsed/attributes`, { items: putItems } as UpdatePayload)
        .then(res => [ 200, 201 ].includes(res.status))
        .catch(() => false);


    /**
     * Check if PUT request is succeeded.
     *
     * @param {boolean} isSaved
     * @returns {void}
     */
    const handleSuccess = (isSaved: boolean): void => {
        setToasterProps({
            show: true,
            type: isSaved ?'success' :'error',
            title: isSaved ?'Opgeslagen' :'Opslaan mislukt'
        });

        if (isSaved) {
            onSuccess?.(undefined);
            setOpen(false);
            return;
        }
        setShouldSave(false);
    };


    /**
     * Handle call from LegacyFormModal save button.
     * LegacyFormModal resets its state by itself.
     * ignore if shouldSave is false.
     */
    useEffect(() => {
        if (!shouldSave) {
            return;
        }
        if (putItems.length<1) {
            handleSuccess(true);
            return;
        }

        setLoadingScreenProps({ show: true });
        handleSave()
            .then(handleSuccess)
            .finally(() => setLoadingScreenProps({ show: false }));

    }, [ shouldSave ]);


    return <LegacyFormModal
        open={ open }
        setOpen={ setOpen }
        resource={ 'Product eigenschappen' }
        setShouldSave={ setShouldSave }
        isCreate={ false }
        className={ '!w-full md:!w-[80vw] !max-w-[64rem]' }
    >
        <>
            <div className="hidden lg:grid grid-cols-12 fixed bg-white shadow w-full z-50 left-0 px-6 top-[4.5rem] py-4 border text-graphite font-semibold text-md">
                <div className="col-span-4">Eigenschap</div>
                <div className="col-span-8">Waarde</div>
            </div>

            <div className={ 'lg:mt-[3.7rem] overflow-y-auto h-[calc(100vh-20rem)] -mx-6 px-6 lg:pt-4 pb-4' }>
                { items.map(getFormItem) }
            </div>
        </>
    </LegacyFormModal>;
};

export default AttributeProductForm;