import { useMemo } from 'react';
import { useTableContext } from '../providers/Table.provider';
import { TableCallbackType } from '../types/TableCallback';
import useTableDeArchive from './useTableDearchive';


const canPerformAction = <T extends object>(
    tableAction?: TableCallbackType<T>
) => {
    return !(!tableAction || tableAction.hide == true);
};

const useTableCallbacks = <T extends object, R extends string>() => {

    const { callbacks } = useTableContext<T, R>();
    const {hasArchivedResources} = useTableDeArchive<T, keyof T, R, T[keyof T]>()


    /**
     *
     */
    const callbackConfigs = useMemo(() => {

        if (hasArchivedResources) {
            return []
        }
        if (!callbacks || callbacks.length == 0) {
            return [];
        }

        return callbacks.filter(canPerformAction) as TableCallbackType<T>[];
    }, [ callbacks, hasArchivedResources ]);


    const hasTableCallbacks = useMemo(() => {
        return callbackConfigs.length>0;
    }, [ callbackConfigs.length ]);

    return {
        callbackConfigs,
        hasTableCallbacks
    };
};

export default useTableCallbacks;
