import React from 'react';
import { MaterialIconName } from 'ts-material-icon-name-list';
import Button, {ButtonProps} from "./Button";
import Icon from "../Icon/Icon";

export type ActionButtonProps = ButtonProps & {
    text: JSX.Element|string,
    icon: JSX.Element|MaterialIconName|''
}

const ActionButton: React.FC<ActionButtonProps> = ({
    style,
    small,
    text,
    icon,
    iconClassName,
    ...props
 }):JSX.Element => <Button
    {...props}
    style={style}
    small={small}
    text={text}
    icon={typeof icon === 'string' && icon != '' ? <Icon materialIconName={icon} className={iconClassName} /> : icon}
    textColor={'white'}
    bgColor={{
        regular: 'aqua',
        hover: 'aqua-hover',
        active: 'aqua-active'
    }}
/>
export default ActionButton;
