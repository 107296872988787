import React, { FC, useState } from 'react';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import ShowPage from '../../templates/pages/ShowPage';
import CompoundForm from './CompoundForm';
import { CompoundModel } from './CompoundTypes';
import CompoundElementTable from './pivot/CompoundElementTable';
import useCompound from './useCompound';


const CompoundShow: FC = (): JSX.Element => {

    const [ currentCompound, setCurrentCompound ] = useState<CompoundModel>();

    return (
        <ShowPage
            title={ currentCompound?.name }
            breadcrumb={ currentCompound?.name }
            setCurrentModel={ setCurrentCompound }
            FormModal={ CompoundForm }
            httpHook={ useCompound }

            tabs={ [
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <ExampleGraph/>
                    </>
                },
                {
                    name: 'Grondstoffen',
                    id: 'elements',
                    element: <>
                        {currentCompound?.id && <CompoundElementTable parentId={ currentCompound?.id }/>}
                    </>
                }
            ] }
        />
    );
};
export default CompoundShow;