import React, { FC, useMemo } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { parsePackageDisplayNumber } from '../../package/PackageFunctions';
import { PackagePartPivotModel } from '../../package/PackageTypes';
import { PackagePartPivotRelationsBluePrint } from '../../package/pivot/packagePartPivot/PackagePartPivotTypes';
import usePartPackage from './usePartPackage';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const PackagePartPackageTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = usePartPackage(parentId);


    const rows = useMemo((): TableColumns<PackagePartPivotModel, PackagePartPivotRelationsBluePrint>[] => [
        {
            header: {
                children: 'Nummer'
            },
            column: (item) => ({
                children: parsePackageDisplayNumber(item.package?.number)
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Naam'
            },
            column: (item) => ({
                children: item.package?.name
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'packages' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/packages/${ item.package_id }`
            } }
            http={ {
                hook: httpHook,
                with: ['package'],
                searchCols: ['package.name', 'package.number'],
                sortCol: 'package_id',
                sortDir: 'ASC'
            } }
        />
    </>;
};

export default PackagePartPackageTable;