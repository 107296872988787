import React, { ReactNode, useEffect, useState } from 'react';
import { classNames } from '../../../modules/Parse/String';
import ErrorMessage from '../Caption/ErrorMessage';
import Label from '../Caption/Label';
import { requiredFieldClassname } from '../Support/FieldSupport';
import { InvalidField } from './GenericFieldTypes';


type Props = Omit<JSX.IntrinsicElements['input'], 'name'|'type'|'defaultValue'|'value'|'onChange'>&{
    label: string;
    name: string;
    type: React.HTMLInputTypeAttribute;
    value?: string|number
    onChange?: (value: number|string|boolean) => void,
    description?: ReactNode,
    invalid?: InvalidField
}

const Input: React.FC<Props> = ({
    label,
    name,
    required,
    className,
    value,
    disabled,
    onChange,
    description,
    invalid,
    ...props
}): JSX.Element => {

    const [ internalValue, setInternalValue ] = useState(value);
    useEffect(() => {
        if (value === internalValue) {
            return;
        }
        setInternalValue(value)
    }, [value]);


    const handleChange = (value: string) => {
        let castedValue: string|number = value;
        if (props.type === 'number' && Number.isFinite(Number(value)) && value !== '') {
            castedValue = Number.isInteger(value) ?parseInt(value) :parseFloat(value);
        }
        onChange?.(castedValue);
        setInternalValue(castedValue)
    };


    return <>
        <Label hidden={ props.hidden } invalid={ invalid?.isInvalid } htmlFor={ name }>{ label }{ required && '*' }</Label>
        { description && <span className="text-gray-500 font-normal text-sm">{ description }</span> }
        <div className="mt-2">
            <input
                { ...props }
                hidden={ props.hidden }
                required={ required }
                value={ internalValue }
                onInput={ (e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.currentTarget.value) }
                id={ name }
                disabled={ disabled }
                className={ classNames(props.hidden ?'hidden' :'block',
                    'w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
                    invalid?.isInvalid ?'ring-red-700' :'ring-gray-300',
                    disabled && 'hover:cursor-not-allowed opacity-60',
                    required && requiredFieldClassname,
                    className
                ) }
            />
            <ErrorMessage message={ invalid?.isInvalid ? invalid?.message : undefined }/>
        </div>
    </>;
};
export default Input;
