import { SoftDeleteModel } from '../../../lib/samfe/types/ModelTypes';
import { ChargeModel } from '../../charge/ChargeTypes';
import { FileModel } from '../../document/FileTypes';
import { WithCount } from '../../generic/ModelTypeExtensions';
import { ProductRiskModel } from '../../product/pivot/productRisk/ProductRiskTypes';


export type AssessmentStatus = 'not_applicable'|'to_test'|'test_requested'|'approved'|'not_approved'

/**
 * List of all available assessment statuses.
 *
 * @returns {AssessmentStatus[]}
 */
export const GetAssessmentStatuses: AssessmentStatus[] = [
    'not_applicable',
    'to_test',
    'test_requested',
    'approved',
    'not_approved'
];


/**
 * Get display name of assessment status.
 *
 * @param {AssessmentStatus} status
 * @returns {string}
 */
export const GetAssessmentStatusName = (status?: AssessmentStatus): string => {
    switch (status) {
        case 'not_applicable'   :
            return 'Risico onderbouwd';
        case 'to_test'          :
            return 'Moet geanalyseerd worden';
        case 'test_requested'   :
            return 'Analyse aangevraagd';
        case 'approved'         :
            return 'Geborgd';
        case 'not_approved'     :
            return 'Risico niet geborgd';
        default                 :
            return 'Oeps, er ging iets mis';
    }
};


export type AssessmentRelationsBluePrint = 'charge'
    |'charge.article'
    |'charge.article.product'
    |'charge.purchaseRow.purchase'
    |'productRisk'
    |'productRisk.risk'
    |'assessmentElements'
    |'files'
    |'frequencyIndex'
    |'history'


export type AssessmentFields = {
    charge_id: number,
    product_risk_id: number,
    comments: string,
    should_be_assessed: boolean|1|0,
    assessment_status: AssessmentStatus,
    frequency: number,
    requested_at: string
    approved_at: string
    blocked: boolean
}


export type AssessmentDto = Partial<AssessmentFields>


export type AssessmentRelations = {
    productRisk: ProductRiskModel
    frequencyIndex: number
    charge: ChargeModel
}


export type AssessmentModel = SoftDeleteModel
    &Partial<AssessmentRelations>
    &WithCount<Partial<AssessmentRelations>>
    &AssessmentDto

export type AssessmentBookItem = {
    file_id: number,
    assessment_ids: number[]
}

type AssessmentBulkResponse<T> = {
    items: T[],
    count: number
}

export type AssessmentBookPayload = {
    items: AssessmentBookItem[]
}

type AssessmentBookResponseItem = { [k: number]: boolean }
export type AssessmentBookResponse = AssessmentBulkResponse<AssessmentBookResponseItem>

export type AssessmentFileIdentifier = {
    uuid: string,
    file: File,
    assessmentIds: number[]
}


// Request assessment types
export type AssessmentRequestPayload = {
    assessment_ids: number[]
}

export type AssessmentRequestResponse = AssessmentBulkResponse<number>


export type AssessmentHistoryAssessmentFragment = {
    id: number,
    status: AssessmentStatus,
    requested_at: string|null,
    approved_at: string|null,
    blocked: boolean|null,
    index: number,
    sequence: number
}

export type AssessmentHistoryChargeFragment = {
    id: number,
    batchcode: string,
    received_date: string,
    expiration_date: string
}

export type AssessmentHistoryArticleFragment = {
    id: number,
    number: string
}

export type AssessmentHistoryProducerFragment = {
    id: number,
    name: string,
    purchase_id: number
}

export type AssessmentHistoryRepackFragment = {
    id: number
}

export type AssessmentHistoryItem = {
    assessment: AssessmentHistoryAssessmentFragment,
    charge: AssessmentHistoryChargeFragment,
    blocked: boolean,
    article: AssessmentHistoryArticleFragment,
    producer: AssessmentHistoryProducerFragment|null
    repack: AssessmentHistoryRepackFragment|null
}

export type AssessmentHistory = {
    risk_id: number,
    risk_name: string,
    product_risk_id: number,
    frequency: number,
    current: AssessmentHistoryItem,
    parent: AssessmentHistoryItem|null
    previous: AssessmentHistoryItem|null,
    fallback: AssessmentHistoryItem|null,
    files: FileModel[],
    fallback_files: FileModel[]
}

export type GroupedAssessmentHistory = {
    id: number, // CHARGE ID!
    batchcode: string,
    received_date: string,
    expiration_date: string,
    article_id: number,
    article_number: string,
    product_id: number,
    product_number: string,
    product_name: string,
    producer_name: string|null,
    purchase_id: number|null,
    repack_id: number|null,
    assessmentHistory: AssessmentHistory[]
}