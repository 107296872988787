
import {Account} from "./useCurrentUser";
import {Base64Decode, Base64Encode} from "../../lib/samfe/modules/Parse/String";
import useCookie from "../../lib/samfe/modules/Cookie/useCookie";
import {GetAvatarUrl} from "../../lib/samfe/modules/Parse/Url";


export type Profile = Account & {
    avatarUrl: string
}

const useProfile = () => {

    const cookie = useCookie('profile')

    /**
     *
     */
    const profile = (): Profile|undefined => {
        return Base64Decode(cookie.data);
    }


    const setProfile = ({name, email}: Account): void => {
        const profile: Profile = {name, email, avatarUrl: GetAvatarUrl(email, name)}
        cookie.setData(Base64Encode(profile));
    }

    return {
        profile: profile(),
        setProfile,
        destroyProfile: () => cookie.destroy()
    }
}
export default useProfile;