import useCookie from "./useCookie";

const useToken = () => {
    const cookie = useCookie('auth_token')

    const getData = () => cookie.data

    return {
        token: getData(),
        setToken: cookie.setData,
        destroyToken: () => cookie.destroy(),
        hasToken: cookie.hasData
    }
}
export default useToken;