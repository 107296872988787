import React from "react";
import {classNames} from "../../../lib/samfe/modules/Parse/String";

/**
 * @deprecated
 */
type ErrorMessageProps = {
    message?: string
}
/**
 *
 * @param props
 * @constructor
 * @deprecated
 */
const ErrorMessage: React.FC<ErrorMessageProps> = (props: ErrorMessageProps): JSX.Element => {

    return (
        <span className={classNames(props.message !== undefined && props.message !== '' ? 'ml-2': '', 'text-sm text-red-600 h-auto')}>
            {props.message}
        </span>
    )
}
export default ErrorMessage;