import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import useAssessmentHistory from '../http/useAssessmentHistory';
import BaseAssessmentTable from './BaseAssessmentTable';
import { useAssessmentFilters } from './hooks/useAssessmentTableFilters';


type Props = Required<ExtendTableProps>

const AssessmentTable: FC<Props> = () => {
    const filters = useAssessmentFilters();
    const httpHook = useAssessmentHistory()
    return <BaseAssessmentTable httpHook={ httpHook } filters={ filters } withRequestedAt={ true }/>
};

export default AssessmentTable;
