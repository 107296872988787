import {MD5} from "crypto-js";


type FilterIdsProps = {
    idKey?: string,
    ids?: number[],
    exclude?: boolean
}

/**
 * Get string of ids that should be included or excluded with api call.
 *
 * @example {idKey = 'id', ids = [1,2,3], exclude = false} => "id=1,id=2,id=3"
 * @example {idKey = 'pivot_id', ids = [1,2,3], exclude = true} => "pivot_id!=1,pivot_id!=2,pivot_id!=3"
 *
 * @param {string} idKey
 * @param {number[]} ids
 * @param {boolean} exclude
 */
export const filterIds = ({idKey = 'id', ids = [], exclude = false}: FilterIdsProps): string|undefined => ids.length > 0
    ? ids.map(id => `${idKey}${exclude ? '!=' : '='}${id}`).join(',')
    : undefined;

/**
 * Parse string to readable url safe string.
 *
 * @param {string} str
 * @returns {string} Url safe parsed string.
 */
export const UrlSafe = (str: string): string => encodeURIComponent(str.replace(/\s+/g, '-').toLowerCase())


/**
 * Get Gravatar Icon with a UI avatar fallback (open collective).
 *
 * @param {string} email
 * @param {string} name
 */
export const GetAvatarUrl = (email: string, name: string): string => {
    const defaultUrl = `https://ui-avatars.com/api/${name.replace(' ', '+')}/100/0F2943/ffffff`;
    return `https://www.gravatar.com/avatar/${MD5(email).toString()}?d=${defaultUrl}`
};

