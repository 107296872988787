

export type Quote = {
    quote: string,
    quotee: string
}

const list1: Quote[] = [
    {
        "quote": "Waiting is just nature's way of reminding us to practice patience.",
        "quotee": "Walt Timewaiter"
    },
    {
        "quote": "In the realm of waiting, time is the great teacher.",
        "quotee": "Professor T. Delay"
    },
    {
        "quote": "Waiting is like a well-brewed tea; the longer you steep, the better the flavor.",
        "quotee": "Earl Grey Waitington"
    },
    {
        "quote": "Remember, the art of waiting is mastering the dance between patience and distraction.",
        "quotee": "Fredrick Footshuffler"
    },
    {
        "quote": "Waiting is an opportunity to savor the suspense and appreciate life's unfolding story.",
        "quotee": "Olivia Longwatch"
    },
    {
        "quote": "Patience is a virtue, but it's also a remarkable way to reduce stress in the waiting game.",
        "quotee": "Dr. Serenity Serene"
    },
    {
        "quote": "While you wait, remember that good things come to those who resist the urge to check their watch.",
        "quotee": "Tiffany Watchman"
    },
    {
        "quote": "The key to waiting gracefully is to embrace each passing moment like an old friend.",
        "quotee": "Arthur Patienceworth"
    },
    {
        "quote": "Waiting is like a dress rehearsal for the beautiful performance that awaits you.",
        "quotee": "Lily Stagestruck"
    },
    {
        "quote": "Time spent waiting is never wasted when you fill it with laughter and good company.",
        "quotee": "Chuck Jokewaiter"
    },
    {
        "quote": "Waiting is the universe's way of giving you more time to appreciate life's smallest joys.",
        "quotee": "Joy Delighter"
    },
    {
        "quote": "The secret to waiting without losing your mind? Distract your impatience with curious exploration.",
        "quotee": "Curious George Waitworthy"
    },
    {
        "quote": "Waiting is a lot like baking a cake; you need to let it rise for the perfect outcome.",
        "quotee": "Betty Bakewaiter"
    },
    {
        "quote": "While you wait, remember that good things come to those who pause and find peace in the moment.",
        "quotee": "Harrison Stillheart"
    },
    {
        "quote": "Waiting is the art of finding joy in the spaces between life's busy moments.",
        "quotee": "Mona Mindfulwait"
    },
    {
        "quote": "Time spent waiting is an investment that yields appreciation for life's sweet surprises.",
        "quotee": "Benjamin Patiencemaker"
    },
    {
        "quote": "Waiting is a gentle nudge from destiny, urging you to pause and recalibrate.",
        "quotee": "Destiny Whisperer"
    },
    {
        "quote": "While waiting, embrace the uncertainty and discover the hidden treasures of patience.",
        "quotee": "Indiana Waitson"
    },
    {
        "quote": "Waiting is like a blank canvas; it's up to you to fill it with colors of contentment.",
        "quotee": "Pablo Picassozeal"
    },
    {
        "quote": "The art of waiting is a masterclass in resilience and finding peace within the pause.",
        "quotee": "Leonardo Patiencevinci"
    },
    {
        "quote": "Waiting is an invitation to slow down, breathe, and enjoy life's unfolding symphony.",
        "quotee": "Melody Harmonysong"
    },
    {
        "quote": "While you wait, remember that the universe has impeccable timing; your moment will come.",
        "quotee": "Stella Stardust"
    },
    {
        "quote": "Waiting is like planting a seed; trust that it will bloom into something beautiful in due time.",
        "quotee": "Daisy Gardengrace"
    },
    {
        "quote": "Patience is the gentle breeze that carries you through the moments of waiting.",
        "quotee": "Zephyr Tranquilwind"
    },
    {
        "quote": "While you wait, remember that the best views come to those who climb the mountain of patience.",
        "quotee": "Alpinist Everwaiting"
    },
    {
        "quote": "Waiting is a window of opportunity to observe life's details that would otherwise pass you by.",
        "quotee": "Iris Wonderwatch"
    }
];
const list2: Quote[] = [
    {
        "quote": "Waiting is like a puzzle; the pieces may be scattered, but they eventually come together.",
        "quotee": "Emma Enigmapiece"
    },
    {
        "quote": "Patience is the art of gracefully embracing life's intermissions.",
        "quotee": "Grace Pauseworthy"
    },
    {
        "quote": "While you wait, remember that every tick of the clock brings you closer to your destination.",
        "quotee": "Timothy Ticktocker"
    },
    {
        "quote": "Waiting is like brewing coffee; the longer you wait, the stronger and richer the outcome.",
        "quotee": "Barista Aroma"
    },
    {
        "quote": "In the realm of waiting, time is a gentle teacher and a trusted confidant.",
        "quotee": "Mister Sage Ticktock"
    },
    {
        "quote": "While waiting, find solace in the rhythm of your breath and the symphony of your thoughts.",
        "quotee": "Harmony Serenewait"
    },
    {
        "quote": "Waiting is an art form; the canvas is time, and the brush is patience.",
        "quotee": "Vincent van Delay"
    },
    {
        "quote": "Remember, waiting is a delightful pause in the grand theater of life.",
        "quotee": "Curtain Timekeeper"
    },
    {
        "quote": "Waiting is the gentle whisper of anticipation, guiding you towards the wonders yet to come.",
        "quotee": "Whispering Willow"
    },
    {
        "quote": "Patience is a tranquil oasis in the desert of waiting.",
        "quotee": "Sahara Serene"
    },
    {
        "quote": "While you wait, let gratitude fill the void, and watch blessings bloom before your eyes.",
        "quotee": "Gracie Gratefulwait"
    },
    {
        "quote": "Waiting is like watching a sunset; the beauty lies in appreciating each passing moment.",
        "quotee": "Amber Sundown"
    },
    {
        "quote": "Patience is the lantern that illuminates the darkest corners of waiting.",
        "quotee": "Lucas Luminary"
    },
    {
        "quote": "While waiting, remember that life's greatest adventures often begin with a patient step.",
        "quotee": "Adventurer Stillheart"
    },
    {
        "quote": "Waiting is the gentle breeze that whispers stories of anticipation and hope.",
        "quotee": "Breezy Daydreamer"
    },
    {
        "quote": "Patience is the anchor that keeps your spirit grounded amidst the stormy sea of waiting.",
        "quotee": "Captain Steadfast"
    },
    {
        "quote": "While you wait, embrace the tranquility and let serenity be your faithful companion.",
        "quotee": "Tranquilita Calmness"
    },
    {
        "quote": "Waiting is like weaving a tapestry; every thread of patience adds beauty to the final masterpiece.",
        "quotee": "Weaver Patienceheart"
    },
    {
        "quote": "Patience is the secret ingredient that turns waiting into a delightful recipe for life.",
        "quotee": "Chef Patiencemaster"
    },
    {
        "quote": "While waiting, remember that even the grandest symphony begins with a single, patient note.",
        "quotee": "Maestro Harmonysong"
    },
    {
        "quote": "Waiting is like gazing at the stars; the longer you look, the more constellations you discover.",
        "quotee": "Stella Stargazer"
    },
    {
        "quote": "Patience is the compass that guides you through the labyrinth of waiting.",
        "quotee": "Navigator Stillheart"
    },
    {
        "quote": "While you wait, let your imagination take flight and soar to places yet to be explored.",
        "quotee": "Dreamer Wanderlust"
    },
    {
        "quote": "Waiting is a symphony of anticipation, where each note brings you closer to the crescendo.",
        "quotee": "Melody Melodious"
    },
    {
        "quote": "Patience is the gentle whisper that assures you that every waiting has its divine purpose.",
        "quotee": "Angel Serenewait"
    },
    {
        "quote": "While waiting, remember that the universe has an impeccable sense of timing; your moment awaits.",
        "quotee": "Cosmic Timekeeper"
    }
];
const list3: Quote[] = [
    {
        "quote": "Waiting is the canvas where dreams come alive in vibrant hues of anticipation.",
        "quotee": "Painter Patiencebrush"
    },
    {
        "quote": "Patience is the gentle breeze that carries you through the meandering river of waiting.",
        "quotee": "River Whisperer"
    },
    {
        "quote": "While you wait, remember that the sweetest fruits are worth the patience of their ripening.",
        "quotee": "Harper Fruitfulwait"
    },
    {
        "quote": "Waiting is like a beautifully crafted novel; each page turned brings you closer to the climax.",
        "quotee": "Storyteller Pageflip"
    },
    {
        "quote": "Patience is the celestial clockwork that orchestrates the perfect timing of the universe.",
        "quotee": "Celestia Timekeeper"
    },
    {
        "quote": "While waiting, embrace the stillness and find serenity in the gentle rhythm of the moment.",
        "quotee": "Zen Seeker"
    },
    {
        "quote": "Waiting is the art of gracefully dancing to the music that plays in the spaces between.",
        "quotee": "Melissa Melodancer"
    },
    {
        "quote": "Patience is the sunbeam that illuminates the path while waiting for the dawn of fulfillment.",
        "quotee": "Sunny Raysofhope"
    },
    {
        "quote": "While you wait, let your heart be a sanctuary of peace amidst the chaos of anticipation.",
        "quotee": "Tranquil Heartwatcher"
    },
    {
        "quote": "Waiting is like sowing seeds; trust in the nurturing hands of time for a bountiful harvest.",
        "quotee": "Gardener Patiencegreen"
    },
    {
        "quote": "Patience is the gentle rain that nurtures the seeds of hope while waiting for growth.",
        "quotee": "Raindrop Serenity"
    },
    {
        "quote": "While waiting, remember that life's most beautiful melodies are composed in the silence between.",
        "quotee": "Composer Harmonysoul"
    },
    {
        "quote": "Waiting is the art of finding beauty in the pause and discovering the magic within.",
        "quotee": "Enchantress Stillheart"
    },
    {
        "quote": "Patience is the compass that guides you through the foggy passages of waiting.",
        "quotee": "Navigator Tranquilmind"
    },
    {
        "quote": "While you wait, let gratitude be the lantern that brightens the darkness of anticipation.",
        "quotee": "Grateful Glow"
    },
    {
        "quote": "Waiting is like a delicate dance; with each step, you become more in tune with the rhythm of life.",
        "quotee": "Graceful Footfalls"
    },
    {
        "quote": "Patience is the lighthouse that leads you safely through the stormy seas of waiting.",
        "quotee": "Lighthouse Keeper"
    },
    {
        "quote": "While waiting, remember that the journey is as important as the destination it unveils.",
        "quotee": "Wanderlust Wayfarer"
    },
    {
        "quote": "Waiting is the blank canvas where resilience paints the masterpiece of endurance.",
        "quotee": "Artistic Fortitude"
    },
    {
        "quote": "Patience is the gentle breeze that carries you through the delicate dance of waiting.",
        "quotee": "Zephyr Tranquility"
    },
    {
        "quote": "While you wait, let your spirit soar high on the wings of hope and unwavering faith.",
        "quotee": "Skyward Dreamer"
    },
    {
        "quote": "Waiting is the secret ingredient that adds depth and flavor to the recipe of life.",
        "quotee": "Culinary Patience"
    },
    {
        "quote": "Patience is the key that unlocks the door to contentment while waiting for what's to come.",
        "quotee": "Keykeeper Serenity"
    },
    {
        "quote": "While waiting, remember that the sweetest symphonies are composed by the hands of patience.",
        "quotee": "Symphony Conductor"
    },
    {
        "quote": "Waiting is like a beautifully woven tapestry; each thread adds intricacy to the final masterpiece.",
        "quotee": "Weaver of Time"
    },
    {
        "quote": "Patience is the gentle rain that nourishes the garden of waiting, blooming with dreams.",
        "quotee": "Garden Guardian"
    }
];
const list4: Quote[] = [
    {
        "quote": "Waiting is the fertile ground where possibilities take root and blossom into reality.",
        "quotee": "Blossom Bloomfield"
    },
    {
        "quote": "Patience is the compass that keeps you steady on the path while waiting for the destination.",
        "quotee": "Navigator Serenestep"
    },
    {
        "quote": "While you wait, remember that every sunset is a promise of a new sunrise on the horizon.",
        "quotee": "Dawn Dreamcatcher"
    },
    {
        "quote": "Waiting is like a gentle rain that washes away worries and brings forth a sense of renewal.",
        "quotee": "Raindrop Serenewash"
    },
    {
        "quote": "Patience is the sturdy anchor that keeps you grounded during the storms of waiting.",
        "quotee": "Anchor Guardian"
    },
    {
        "quote": "While waiting, find joy in the dance of anticipation, for every step brings you closer to fulfillment.",
        "quotee": "Joyful Rhythm"
    },
    {
        "quote": "Waiting is the canvas where resilience paints its masterpiece of unwavering determination.",
        "quotee": "Artist of Endurance"
    },
    {
        "quote": "Patience is the gentle melody that soothes the soul in the silence of waiting.",
        "quotee": "Melodic Serenity"
    },
    {
        "quote": "While you wait, remember that even the mightiest rivers start with a single, patient drop.",
        "quotee": "River's Journey"
    },
    {
        "quote": "Waiting is like a gentle breeze that whispers tales of hope and whispers of what's to come.",
        "quotee": "Whispering Zephyr"
    },
    {
        "quote": "Patience is the virtuous shield that protects the heart during the battlefield of waiting.",
        "quotee": "Valiant Defender"
    },
    {
        "quote": "While waiting, let gratitude be the beacon that illuminates the path of anticipation.",
        "quotee": "Grateful Guide"
    },
    {
        "quote": "Waiting is the fertile soil where dreams take root and grow into magnificent realities.",
        "quotee": "Dream Harvester"
    },
    {
        "quote": "Patience is the calming lullaby that soothes the restlessness of waiting.",
        "quotee": "Lullaby Weaver"
    },
    {
        "quote": "While you wait, remember that the stars shine brightest in the darkest of nights.",
        "quotee": "Stargazer's Serenade"
    },
    {
        "quote": "Waiting is like a gentle current that carries you towards the destination of your dreams.",
        "quotee": "Current of Destiny"
    },
    {
        "quote": "Patience is the gentle touch that mends the frayed edges of the waiting heart.",
        "quotee": "Heart Mender"
    },
    {
        "quote": "While waiting, find solace in the knowledge that every ending heralds a new beginning.",
        "quotee": "Eternal Renewal"
    },
    {
        "quote": "Waiting is the tender embrace that wraps you in anticipation and whispers of brighter tomorrows.",
        "quotee": "Embrace of Hope"
    },
    {
        "quote": "Patience is the compass that leads you to the treasures hidden within the realm of waiting.",
        "quotee": "Seeker of Treasures"
    },
    {
        "quote": "While you wait, remember that the journey itself is an adventure filled with valuable lessons.",
        "quotee": "Adventurous Spirit"
    },
    {
        "quote": "Waiting is like a gentle tide that carries you towards the shores of your dreams.",
        "quotee": "Tide of Destiny"
    },
    {
        "quote": "Patience is the radiant sun that bathes the waiting soul in warmth and perseverance.",
        "quotee": "Sunbeam of Endurance"
    },
    {
        "quote": "While waiting, let the stillness be your companion and guide on the path to self-discovery.",
        "quotee": "Stillness Seeker"
    },
    {
        "quote": "Waiting is the tapestry where resilience and hope weave together to create a masterpiece.",
        "quotee": "Master Weaver of Resilience"
    },
    {
        "quote": "Patience is the gentle whisper that reminds you to trust the process while waiting for your dreams.",
        "quotee": "Whisper of Trust"
    }
];


const getRandomArrayItem = <T,>(list: T[]): T => list[Math.floor(Math.random() * list.length)]

export const getRandomQuote = (): Quote => {
    const list = getRandomArrayItem<Quote[]>([list1, list2, list3, list4]);
    return getRandomArrayItem<Quote>(list);
}
