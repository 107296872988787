import { useEffect, useState } from 'react';


function useAsyncInit(init: () => Promise<void>, shouldInit: boolean = true): boolean {
    const [ initialized, setInitialized ] = useState(false);

    const runInit = () => {
        if (initialized) {
            return;
        }
        init().then(() => {
            setInitialized(true);
        });
    };

    useEffect(() => {
        if (shouldInit) {
            runInit();
        }
    }, [ shouldInit ]);

    return initialized;
}


export default useAsyncInit;
