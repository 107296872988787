import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import {CancelButton, SaveButton} from "../Button";
import Modal, {ModalProps, ModalState} from "./Modal";
import {ExtendFormProps} from "../Form";
import WarningModal from './WarningModal';


type Props<Dto> = Omit<ModalProps, 'title'> & {
    resource: string,
    isCreate?: boolean,
    className?: string,
    setShouldSave?: Dispatch<SetStateAction<boolean>>
}


export type ExtendFormModalProps<Dto> = ModalState & ExtendFormProps<Dto>

const LegacyFormModal = <Dto extends object,>({ open, setOpen, resource, children, setShouldSave, className, isCreate = true }: Props<Dto>): JSX.Element => {

    const onSaveClick = async () => {
        if (setShouldSave) {
            setShouldSave(true)
        }
    };

    const [ openWarningModal, setOpenWarningModal ] = useState(false);
    const handleEscape = useCallback((event: KeyboardEvent) => {
        const { key } = event;
        if(key === 'Escape'){
            setOpenWarningModal(true);
        }
    }, []);


    useEffect(() => {
        window.addEventListener("keydown", handleEscape);
        return () => {
            window.removeEventListener("keydown", handleEscape);
        };
    }, []);

    const footerEl = () => <>
        {openWarningModal && <WarningModal
            open={openWarningModal}
            setOpen={setOpenWarningModal}
            title={'Wijzigingen annuleren?'}
            text={''} confirmCallback={() => {
            setOpen(false)
        }}
        />}
        <div className="mt-5 sm:mt-4 flex flex-row-reverse float-right">
            <SaveButton onClick={onSaveClick} className={'sm:ml-3'} />
            <CancelButton type={"button"} onClick={() => setOpen(false)} style={"tertiary"} className={'mt-3 sm:mt-0'} />
        </div>
    </>

    return <Modal open={open} setOpen={setOpen} title={`${resource} ${isCreate ? 'toevoegen' : 'aanpassen'}`} footer={footerEl()} className={className}>
        {children}
    </Modal>
}
export default LegacyFormModal;

