import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import Stats, { Stat } from '../../lib/samfe/components/Stats';
import { datetimeToLocale } from '../../lib/samfe/modules/Parse/Date';
import ShowPage from '../../templates/pages/ShowPage';
import ChargeAssessmentTable from '../assessment/tables/ChargeAssessmentTable';
import { AssessmentStatus } from '../assessment/types/AssessmentTypes';
import PdfFile from '../document/PdfFile';
import ProfitLossForm from '../profitLoss/ProfitLossForm';
import ProfitLossTable from '../profitLoss/ProfitLossTable';
import ChargeForm from './ChargeForm';
import { batchCodeLabel, expirationDateLabel } from './ChargeFunctions';
import { ChargeModel, ChargeStatusDisplayName } from './ChargeTypes';
import ChargeCustomerTable from './pivot/ChargeCustomer/ChargeCustomerTable';
import ChargeLocationCreateForm from './pivot/ChargeLocations/ChargeLocationCreateForm';
import ChargeLocationTable from './pivot/ChargeLocations/ChargeLocationTable';
import ChargeSaleRowTable from './pivot/ChargeSaleRow/ChargeSaleRowTable';
import useCharge from './useCharge';


const ChargeShow: FC = (): JSX.Element => {

    const [ currentCharge, setCurrentCharge ] = useState<ChargeModel|undefined>(undefined);


    const batchCode = useMemo(() => {
        return currentCharge?.batchcode ?? 'Onbekend'
    }, [ currentCharge?.batchcode ]);


    const title = useMemo(() => {
        return <>
            <Link className={ 'underline underline-offset-4 decoration-aqua' } to={ `/products/${ currentCharge?.article?.product_id }` }>
                { currentCharge?.product?.name }
            </Link>
            &nbsp;→&nbsp;
            <Link className={ 'underline underline-offset-4 decoration-aqua' } to={ `/articles/${ currentCharge?.article_id }` }>
                { currentCharge?.article?.number }
            </Link>
            &nbsp;→&nbsp;
            { batchCodeLabel(currentCharge) }
        </>
    }, [ currentCharge?.product?.name, currentCharge?.article_id, currentCharge?.article?.number, currentCharge ]);


    const assessmentsWithAction = useMemo(() => {
        return (currentCharge?.assessments ?? []).filter(ass => ([
                'to_test',
                'test_requested',
                'not_approved'
            ] as AssessmentStatus[]).includes(ass.assessment_status ?? 'not_applicable')
        );
    }, [ currentCharge?.assessments ]);


    const chargeOriginStat: Stat = useMemo(() => {
        const chargeOrigin: Stat = {
            name: 'Onbekende bron',
            value: '-'
        };

        if (currentCharge?.parent) {
            chargeOrigin.name = 'Bron charge';
            chargeOrigin.value = <Link className={ 'underline' } to={ `/charges/${ currentCharge?.parent_id }` }>
                { batchCodeLabel(currentCharge?.parent) }
            </Link>;

        } else if (currentCharge?.producer) {
            chargeOrigin.name = 'Producent';
            chargeOrigin.value = <Link className={ 'underline' } to={ `/producers/${ currentCharge?.producer.id }` }>
                { currentCharge?.producer?.name }
            </Link>;
        }
        return chargeOrigin;
    }, [ currentCharge?.parent, currentCharge?.producer ]);


    const overviewTabContent = useMemo(() => {
        return <>
            <Stats stats={ [
                chargeOriginStat,
                {
                    name: 'Versie',
                    value: currentCharge?.product?.version
                },
                {
                    name: 'Artikel',
                    value: <Link className={ 'underline' } to={ `/articles/${ currentCharge?.article_id }` }>
                        { currentCharge?.article?.number }
                    </Link>
                },
                {
                    name: 'Rekvoorraad',
                    value: currentCharge?.stock?.rack
                },
                {
                    name: 'Status',
                    value: ChargeStatusDisplayName(currentCharge?.status)
                }
            ] }/>
            <Stats stats={ [
                {
                    name: 'Order',
                    value: <Link className={ 'underline' } to={ `/purchases/${ currentCharge?.purchaseRow?.purchase_id }` }>
                        { currentCharge?.purchaseRow?.purchase_id }
                    </Link>
                },
                {
                    name: 'Boekingsnummer',
                    value: currentCharge?.packaging_slip
                },
                {
                    name: 'Op voorraad sinds',
                    value: datetimeToLocale(currentCharge?.received_date)
                },
                {
                    name: 'THT',
                    value: expirationDateLabel(currentCharge?.expiration_date)
                }
            ] }/>
            <ExampleGraph/>
        </>
    }, [ chargeOriginStat, currentCharge?.product?.version, currentCharge?.article_id, currentCharge?.article?.number, currentCharge?.stock?.rack, currentCharge?.status, currentCharge?.purchaseRow?.purchase_id, currentCharge?.packaging_slip, currentCharge?.received_date, currentCharge?.expiration_date ]);


    return (
        <ShowPage
            hideArchive={ true }
            FormModal={ ChargeForm }
            title={ title }
            breadcrumb={ batchCode }
            setCurrentModel={ setCurrentCharge }
            httpHook={ useCharge }
            relations={ [
                'producer',
                'article',
                'product',
                'stock',
                'files',
                'parent',
                'purchaseRow.purchase',
                'assessments.productRisk.risk'
            ] }

            formActions={ [
                {
                    name: 'Voorraad muteren',
                    tabId: 'profit-loss',
                    icon: 'add',
                    form: ProfitLossForm
                },
                {
                    name: 'Locaties',
                    tabId: 'locations',
                    icon: 'add',
                    form: ChargeLocationCreateForm
                }
            ] }

            upload={ {
                modelName: 'ChargeModel',
                files: currentCharge?.files,
                extraColName: 'Risico',
                fileTypes: [
                    'Factuur',
                    'Grondstof certificaat',
                    'Intakeformulier',
                    'Uitvul formulier',
                    'Pakbon',
                    'Referentie afbeelding',
                    'Overig'
                ]
            } }

            tabs={ !currentCharge ?[] :[
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: overviewTabContent
                },
                {
                    name: 'Locaties',
                    id: 'locations',
                    element: <>
                        {currentCharge.id != undefined && <ChargeLocationTable parentId={ currentCharge.id }/>}
                    </>
                },
                {
                    name: 'Risico borgingen',
                    id: 'risk-assessments',
                    hasError: assessmentsWithAction.length > 0,
                    element: <>
                        { currentCharge.id && <ChargeAssessmentTable
                            parentId={ currentCharge.id }
                            assessmentsWithAction={ assessmentsWithAction }
                        /> }
                    </>
                },
                {
                    name: 'Mutaties',
                    id: 'profit-loss',
                    element: <>
                        { currentCharge.id && <ProfitLossTable parentId={ currentCharge.id }/> }
                    </>
                },
                {
                    name: 'Klanten',
                    id: 'customers',
                    element: <>
                        {currentCharge.id && <ChargeCustomerTable parentId={ currentCharge.id }/>}
                    </>
                },
                {
                    name: 'Verkoop',
                    id: 'sales',
                    element: <>
                        {currentCharge.id && <ChargeSaleRowTable parentId={ currentCharge.id }/>}
                    </>
                },
                {
                    name: 'COA',
                    id: 'coa',
                    element: <PdfFile
                        id={ currentCharge.id }
                        fileType={ 'coa' }
                        customFileName={ `'coa-${currentCharge?.product?.number}-${batchCode}` }
                    />
                }
            ] }
        />
    );
};
export default ChargeShow;