import { useTableContext } from '../providers/Table.provider';


const useTableScrollToTop = () => {

    const ctx = useTableContext();

    return () => {
        if (ctx.table.containerRef.current?.scrollTop) {
            ctx.table.containerRef.current.scrollTop = 0;
        }
    };
};

export default useTableScrollToTop;
