import { MinusIcon } from '@heroicons/react/24/outline';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import TextArea from '../../../lib/samfe/components/Form/Generic/TextArea';
import { parseExpirationSqlDateTime } from '../../../lib/samfe/modules/Parse/Date';
import { thousandSeparator } from '../../../lib/samfe/modules/Parse/Number';
import { classNames } from '../../../lib/samfe/modules/Parse/String';
import { batchCodeLabel, expirationDateLabel } from '../../charge/ChargeFunctions';
import {useLocation} from '../../location/useLocation';
import { ProductModel } from '../../product/ProductTypes';
import { getProductTypeUnit } from '../../productType/ProductTypeFunctions';
import { ProductTypeModel } from '../../productType/ProductTypeTypes';
import { RepackingModel, RepackingStatus } from '../RepackingTypes';
import useRepackingPivotless from '../useRepackingPivotless';


type RepackBookDisplayItem = {
    type: 'Bron'|'Target',
    batchCode?: string|JSX.Element,
    articleNumber: string,
    amount: number,
    expirationDate: string|JSX.Element,
    isBulk: boolean
}


type Props = {
    className?: string,
    repackOrder: RepackingModel,
    currentValues: {
        wasteAmount: number,
        parentAmount: number,
        targetAmount: number,
        locationId?: number,
        currentBatchCode: string,
        expirationDate: string,
        repackTimeInMinutes: number
    }
}

const RepackBookSummary: FC<Props> = ({
    repackOrder,
    currentValues,
    className
}): JSX.Element => {


    const location = useLocation();
    const repackHttp = useRepackingPivotless();

    const [ product, setProduct ] = useState<ProductModel>();
    const [ productType, setProductType ] = useState<ProductTypeModel>();
    const [ repackStatus, setRepackStatus ] = useState<RepackingStatus|undefined>(repackOrder.status);

    const [ locationName, setLocationName ] = useState<string>();

    const {
        wasteAmount,
        parentAmount,
        targetAmount,
        locationId,
        currentBatchCode,
        expirationDate
    } = useMemo(() => currentValues, [ currentValues ]);

    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    const {
        parentArticle,
        targetArticle,
        parentCharge
    } = useMemo(() => {
        setProduct(repackOrder.parentArticle?.product);
        setProductType(repackOrder.parentArticle?.product?.productType);
        if (repackStatus !== repackOrder.status) {
            setRepackStatus(repackOrder.status);
        }

        return repackOrder;
    }, [ repackOrder ]);


    useEffect(() => {
        location.getItem(locationId).then(loc => setLocationName(loc?.name));
    }, [ locationId ]);


    const getParentDisplayData = (): RepackBookDisplayItem => ({
        type: 'Bron',
        batchCode: batchCodeLabel(parentCharge),
        amount: parentAmount,
        articleNumber: parentArticle?.number ?? 'Onbekend',
        expirationDate: expirationDateLabel(parentCharge?.expiration_date),
        isBulk: parentArticle?.is_bulk === true
    });

    const getTargetDisplayData = (): RepackBookDisplayItem => ({
        type: 'Target',
        batchCode: <>
            { currentBatchCode.length>0
              ?currentBatchCode
              :<span className={ classNames('text-fire font-medium') }>Onbekend</span>
            }
            {locationName  && ` (${ locationName })`}
        </>,
        amount: targetAmount,
        articleNumber: targetArticle?.number ?? 'Onbekend',
        expirationDate: <>{ expirationDate.length>0
          ?expirationDateLabel(expirationDate)
          :<span className={ classNames('text-fire font-medium') }>Onbekend</span>
        }</>,
        isBulk: targetArticle?.is_bulk === true
    });


    const summarySkeleton = <Skeleton width={ 100 } inline={ true }/>;

    const getH2 = (children: ReactNode): JSX.Element => <h2 className={ 'text-aqua text-[1.05rem] font-semibold mb-1' }>{ children }</h2>;
    const getH3 = (children: ReactNode): JSX.Element => <h3 className={ 'text-aqua text-[0.9rem] font-semibold mb-1' }>{ children }</h3>;
    const getH4 = (children: ReactNode): JSX.Element => <h4 className={ 'text-aqua text-[0.85rem] font-medium mb-0.5' }>{ children }</h4>;
    const getH5 = (children: ReactNode): JSX.Element => <h4 className={ 'text-aqua text-[0.85rem] font-normal mb-0.5' }>{ children }</h4>;

    const getP = (children: ReactNode): JSX.Element => <p className={ 'text-graphite text-sm leading-6' }>{ children ?? summarySkeleton }</p>;

    const getUnit = (amount: number, isBulk: boolean) => isBulk
                                                         ?getProductTypeUnit(productType, amount !== 1)
                                                         :(amount === 1 ?'artikel' :'artikelen');


    const parentAmountAsBulk = () => parentAmount * (parentArticle?.amount ?? 0);
    const wasteAmountAsBulk = () => wasteAmount * (parentArticle?.amount ?? 0);


    const getDisplayItem = (displayData: RepackBookDisplayItem): JSX.Element => {

        const unit = getUnit(displayData.amount, displayData.isBulk);

        return <div className={ 'rounded-md p-4 shadow shadow-aqua/20' }>
            { getH3(displayData.articleNumber) }
            { getP(displayData.batchCode) }
            { getP(displayData.expirationDate) }
            { getP(`${ thousandSeparator(displayData.amount) } ${ unit }`) }
        </div>;
    };


    return <div className={ classNames('h-[calc(100%-3rem)] mt-4 mb-6 p-6 shadow-aqua/40 shadow-md rounded-lg text-aqua bg-white all-children:text-left', className) }>

        { getH2(product?.name) }

        <div className="grid grid-cols-7 gap-1 my-6">
            <div className={ 'col-span-3' }>{ getDisplayItem(getParentDisplayData()) }</div>
            <div className={ 'col-span-1 relative' }>
                <span className={ 'text-5xl text-aqua absolute top-[2rem] left-0 right-0 !text-center' }>→</span>
            </div>
            <div className={ 'col-span-3' }>{ getDisplayItem(getTargetDisplayData()) }</div>
        </div>

        { getH2('Samenvatting') }

        <div className={ 'grid grid-cols-12 gap-x-2 pr-8 mt-3' }>
            <div className="col-span-5">{ getH4('Uitvultijd') }</div>
            <div className={ 'col-span-3 all-children:!text-right' }>{ getP(currentValues.repackTimeInMinutes) }</div>
            <div className={ 'col-span-4' }>{ getP('minuten') }</div>
        </div>

        { parentArticle?.is_bulk === true
          ?<ul>

              <li className={ classNames('py-1.5 all-children:text-graphite') }>
                  <div className={ 'grid grid-cols-12 gap-x-2 pr-8' }>
                      <div className="col-span-5">{ getH4('Uitgevuld van bron') }</div>
                      <div className={ 'col-span-3 all-children:!text-right' }>{ getP(thousandSeparator(parentAmountAsBulk())) }</div>
                      <div className={ 'col-span-4' }>{ getP(getUnit(parentAmountAsBulk(), true)) }</div>
                  </div>
              </li>
              <li className={ classNames('all-children:text-graphite') }>
                  <div className={ 'grid grid-cols-12 gap-x-2 pr-8' }>
                      <div className="col-span-5 ">{ getH4('Waarvan rest') }</div>
                      <div className={ 'col-span-3 all-children:!text-right' }>{ getP(thousandSeparator(wasteAmountAsBulk())) }</div>
                      <div className={ 'col-span-4' }>{ getP(getUnit(wasteAmountAsBulk(), true)) }</div>
                  </div>
              </li>
              <li className={ '!text-right w-full relative h-6 -mt-3 -mb-1' }>
                  <MinusIcon className={ '!text-graphite w-5 right-0 absolute' }/>
              </li>
              <li className={ classNames('py-1.5 all-children:text-graphite border-t-graphite border-t border-opacity-20 ') }>
                  <div className={ 'grid grid-cols-12 gap-x-2 pr-8' }>
                      <div className="col-span-5">{ getH3('Nieuwe artikelen') }</div>
                      <div className={ 'col-span-3 all-children:!text-right' }>{ getH3(thousandSeparator(targetAmount)) }</div>
                      <div className={ 'col-span-4' }>{ getH3(getUnit(targetAmount, false)) }</div>
                  </div>
              </li>
          </ul>

          :<ul>

              <li className={ classNames('pt-1.5 all-children:text-graphite') }>
                  <div className={ 'grid grid-cols-12 gap-x-2 pr-8' }>
                      <div className="col-span-5">{ getH4('Omgepakt van bron') }</div>
                      <div className={ 'col-span-3 all-children:!text-right' }>{ getP(thousandSeparator(parentAmount)) }</div>
                      <div className={ 'col-span-4' }>{ getP(getUnit(parentAmount, false)) }</div>
                  </div>
              </li>
              <li className={ classNames('all-children:text-graphite') }>
                  <div className={ 'grid grid-cols-12 gap-x-2 pr-8' }>
                      <div className="col-span-5">{ getH5('Waarvan rest') }</div>
                      <div className={ 'col-span-3 all-children:!text-right' }>{ getP(wasteAmount) }</div>
                      <div className={ 'col-span-4' }>{ getP(getUnit(wasteAmount, false)) }</div>
                  </div>
              </li>
              <li className={ '!text-right w-full relative h-6 -mt-3 -mb-1' }>
                  <MinusIcon className={ '!text-graphite w-5 right-0 absolute' }/>
              </li>
              <li className={ classNames('py-1.5 all-children:text-graphite border-t-graphite border-t border-opacity-20 ') }>
                  <div className={ 'grid grid-cols-12 gap-x-2 pr-8' }>
                      <div className="col-span-5">{ getH3('Nieuwe artikelen') }</div>
                      <div className={ 'col-span-3 all-children:!text-right' }>{ getH3(thousandSeparator(targetAmount)) }</div>
                      <div className={ 'col-span-4' }>{ getH3(getUnit(targetAmount, false)) }</div>
                  </div>
              </li>
          </ul> }

        <div className={ 'mt-4 mb-6' }>
            { getH3('Opmerkingen') }
            <TextArea label={ '' } rows={ 3 } maxLength={ 256 } disabled={ repackStatus == 'booked' } name={ 'comments' } value={ repackOrder.comments } onChange={ async(value) => {
                await repackHttp.update(repackOrder.id, {
                    comments: `${ value }`
                });
            } }/>
        </div>

    </div>;
};
export default RepackBookSummary;