import { useMemo } from 'react';
import { AssessmentHistory } from '../types/AssessmentTypes';


export const assessmentIsRequired = (item: AssessmentHistory) => {
    if (item.parent !== null) {
        return false;
    }
    return item.current.assessment.index == 1;
}

const useAssessmentIsRequired = (item: AssessmentHistory) => {
    return useMemo(() => assessmentIsRequired(item), [ item ]);
};

export default useAssessmentIsRequired;
