import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';


const useRedirectToLogout = () => {

    const navigate = useNavigate();
    const reactRouterLocation = useLocation();
    const [ _, setQueryParams ] = useSearchParams();

    return () => {
        const newQueryParams: URLSearchParams = new URLSearchParams();
        newQueryParams.set('redirect_after_authentication', `${ reactRouterLocation.pathname }${ reactRouterLocation.hash }`);
        setQueryParams(newQueryParams);
        navigate({
            pathname: '/logout',
            search: newQueryParams.toString()
        });
    };
};

export default useRedirectToLogout;
