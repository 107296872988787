import React from 'react';
import useId from '../../../lib/samfe/modules/Router/useId';
import useCustomer from '../../../resources/customer/useCustomer';
import ShowPage from '../../../templates/pages/ShowPage';
import SaleTableTestComponent from './SaleTable.test.component';
import TableTestComponent from './Table.test.component';


const TableTestShow = () => {

    const id = useId()

    return (
        <>
            <ShowPage
                title={ 'Test table index' }
                httpHook={ useCustomer }
                tabs={ [
                    {
                        id: 'sales',
                        name: 'Sales',
                        element: <SaleTableTestComponent customerId={id} />
                    },
                    {
                        id: 'test-data',
                        name: 'Test data',
                        element: <TableTestComponent />
                    }
                ] }
            />

            {/*<ButtonStyleGuide />*/ }

        </>
    );
};

export default TableTestShow;
