import { useMemo } from 'react';
import { dispatchHttpEvent } from '../../events/Http.event';
import { CustomButtonAction } from '../../templates/pages/ShowPage';
import { ElementModel } from './ElementTypes';
import useElement from './useElement';


const useCustomElementActions = (element?: ElementModel): CustomButtonAction[] => {
    const elementHook = useElement();
    return useMemo((): CustomButtonAction[] => {
        if (!element?.id) {
            return [];
        }
        const customActions: CustomButtonAction[] = [];
        if (element?.is_reviewed === true) {
            customActions.push({
                title: 'Markeer voor review',
                onClick: () => {
                    elementHook.update(element.id, {
                        is_reviewed: false
                    }).then(dispatchHttpEvent);
                }
            });
        } else {
            customActions.push({
                title: 'Markeren als beoordeeld',
                onClick: () => {
                    elementHook.update(element.id, {
                        is_reviewed: true
                    }).then(dispatchHttpEvent);
                }
            });
        }

        return customActions;
    }, [ element?.id, element?.is_reviewed, elementHook ]);
};

export default useCustomElementActions;
