import useHttp from '../../../../../lib/samfe/modules/Http/useHttp';
import { AssessmentRequestPayload, AssessmentRequestResponse } from '../../../types/AssessmentTypes';


const useRequestAssessment = () => {

    const requestAssessments = useHttp<AssessmentRequestResponse, AssessmentRequestPayload, ''>({
        endpoint: 'assessments/request'
    });

    return async(assessmentIds: number[]) => {
        return await requestAssessments.create({ assessment_ids: assessmentIds }) as unknown as Promise<AssessmentRequestResponse>;
    };
};

export default useRequestAssessment;
