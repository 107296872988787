import useHttp from '../../../../../lib/samfe/modules/Http/useHttp';
import { AssessmentRequestPayload, AssessmentRequestResponse } from '../../../types/AssessmentTypes';


const useCancelAssessment = () => {

    const cancelAssessmentRequests = useHttp<AssessmentRequestResponse, AssessmentRequestPayload, ''>({
        endpoint: 'assessments/cancel-request'
    });

    return async(assessmentIds: number[]) => {
        return await cancelAssessmentRequests.create({ assessment_ids: assessmentIds }) as unknown as Promise<AssessmentRequestResponse>;
    };
};

export default useCancelAssessment;
