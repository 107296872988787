import useHttp from "../../../lib/samfe/modules/Http/useHttp";
import {MinimumModel} from "../../../lib/samfe/types/ModelTypes";

export type SaleFileModel = {
    id?: number,
    open?: string,
    batchcode?: string,
    articleNumber?: string,
    articleId?: number,
    productName?: string,
    productNumber?: string,
    productVersion?: number
    name?: string,
    productId?: number,
    customerId?: number,
    download?: string,
} & MinimumModel

export type SaleFileDto = Partial<SaleFileModel>
export type SaleFileRelationsBluePrint = ('');

export const useCoaSaleFile = (id: number) => useHttp<SaleFileModel, SaleFileDto, SaleFileRelationsBluePrint>({
    endpoint: `sales/${id}/coas`
});
export const useProductSaleFile = (id: number) => useHttp<SaleFileModel, SaleFileDto, SaleFileRelationsBluePrint>({
    endpoint: `sales/${id}/productdossiers`
});