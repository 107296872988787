import { FormikErrors, FormikTouched } from 'formik';
import React, { ComponentType, useEffect, useMemo, useState } from 'react';
import { classNames } from '../../../modules/Parse/String';
import { GridCol, GridColSpan, GridGap } from '../../../vendor/Tailwind/Tailwind';
import Form, { ExtendFormProps, FormProps } from '../../Form';
import useFormikRef from '../../Form/Effects/useFormikRef';
import Modal, { ModalProps, ModalState } from '../Modal';
import { useFormModalContextCtx } from './FormModalContextProvider';
import FormModalFooter from './FormModalFooter';


type FormGrid = {
    cols: GridCol
    form: GridColSpan,
    htmlRight?: GridColSpan|'hidden',
    gap?: GridGap
}


type Props<Dto> = Omit<ModalProps, 'children'|'title'>&Omit<FormProps<Dto>, 'formRef'>&{
    id: number|undefined,
    resource: string,
    htmlBeforeForm?: JSX.Element
    htmlAfterForm?: JSX.Element,
    htmlRightOfForm?: JSX.Element,
    operationName?: string,
    className?: string,
    grid?: FormGrid,
    canSave?: boolean,
    hideCreateAnotherOne?: boolean
}

export type ExtendFormModalProps<Dto> = ModalState&ExtendFormProps<Dto>

export type UseFormComponent<Dto> = ComponentType<ExtendFormModalProps<Dto>>;


const FormModal = <Dto extends object, >({
    id,
    formikConfig,
    formFields,
    open,
    setOpen,
    resource,
    parentId,
    htmlBeforeForm,
    htmlAfterForm,
    htmlRightOfForm,
    operationName,
    onClose,
    className,
    grid,
    withSaveButton = true,
    canSave = true,
    hideCreateAnotherOne = false
}: Props<Dto>): JSX.Element => {

    const formRef = useFormikRef<Dto>();
    const { formModalContext: { omitCreateAnotherOne } } = useFormModalContextCtx();
    const [ createAnotherOneWasUsed, setCreateAnotherOneWasUsed ] = useState(false);
    const [ wasSaved, setWasSaved ] = useState(false);

    const grids: FormGrid = useMemo(() => {
        return {
            cols: 'grid-cols-12',
            form: htmlRightOfForm ?'col-span-4' :'col-span-12',
            htmlRight: htmlRightOfForm ?'col-span-8' :'hidden',
            gap: 'gap-8',
            ...grid
        };
    }, [ htmlRightOfForm, grid?.cols, grid?.form, grid?.htmlRight, grid?.gap ]);

    const [ internalOpen, setInternalOpen ] = useState(open);
    //noinspection DuplicatedCode
    useEffect(() => {
        if (open) {
            setInternalOpen(true);
        }
    }, [ open ]);

    useEffect(() => {
        if (!internalOpen) {
            setTimeout(() => {
                setOpen(false);
            }, 350);
        }
    }, [ internalOpen ]);

    const onCancelClick = () => {
        if (createAnotherOneWasUsed && wasSaved) {
            formikConfig.onSuccess?.(formikConfig.id);
        }
        onClose?.();
        setInternalOpen(false);
    };


    const [ showCreateAnotherOne, setShowCreateAnotherOne ] = useState(hideCreateAnotherOne ?false :id === undefined);
    const [ createAnotherOne, setCreateAnotherOne ] = useState(false);
    useEffect(() => {
        setShowCreateAnotherOne(hideCreateAnotherOne ?false :id === undefined);
    }, [ id ]);


    const [ showForm, setShowForm ] = useState(true);
    useEffect(() => {
        setShowForm(true);
    }, [ showForm ]);

    const onSaveClick = () => {

        if (!canSave) {
            return;
        }
        if (!formRef.current) {
            return;
        }

        type FieldsCast = {
            [k: string]: {
                spec: {
                    label: string
                    optional: boolean,
                }
            }
        }
        const fields = formikConfig.validationSchema.fields as unknown as FieldsCast;
        const values = formRef.current.values;


        let errors: FormikErrors<Dto> = {};
        Object.keys(values).forEach(name => {
            const field = fields[name];
            const label = field.spec.label;
            const value = formRef.current!.getFieldProps(name).value;
            formRef.current!.validateField(name);
            if (!field.spec.optional && `${ value }`.replace(' ', '') === '') {
                const errMsg = `${ label } is een verplicht veld`;
                // @ts-ignore
                errors[name] = errMsg;
                formRef.current!.setFieldError(label, errMsg);
            }
        });


        // @feature white space detection
        const hasErrors = Object.keys(errors).length>0;
        if (hasErrors) {
            formRef.current.setErrors(errors);
        }

        formRef.current.validateForm(formRef.current.values).then(dtoErrors => {
            errors = { ...errors, ...dtoErrors };
        });

        if (formRef.current.isValid && !hasErrors) {
            formRef.current.handleSubmit();
            return;
        }

        const fieldKeys = Object.keys(formikConfig.validationSchema.fields);
        formRef.current.setTouched(fieldKeys.reduce((initialTouched, k) => ({
            ...initialTouched,
            [k]: true
        }), {} as FormikTouched<Dto>));
    };

    const handleCreateAnotherOne = () => {
        formRef.current?.resetForm();
        Promise.resolve(formikConfig.initializer()).then(() => {
            setShowForm(false);
            setCreateAnotherOneWasUsed(true);
        });

    };

    useEffect(() => {
        if (formikConfig.isSaved) {
            setWasSaved(true);
            if (createAnotherOne && !omitCreateAnotherOne) {
                handleCreateAnotherOne();
                return;
            }
            formikConfig.onSuccess?.(formikConfig.id);
            onCancelClick();
        }
    }, [ formikConfig.isSaved ]);

    return <Modal
        open={ internalOpen }
        setOpen={ onCancelClick }
        title={ `${ resource } ${ operationName ?? (formikConfig.id && !createAnotherOneWasUsed ?'aanpassen' :'toevoegen') }` }
        onClose={ onClose }
        className={ classNames(htmlRightOfForm && 'sm:w-[80vw] sm:max-w-[72rem]', className) }
        footer={ <FormModalFooter
            save={ {
                show: withSaveButton,
                allow: canSave,
                onClick: onSaveClick
            } }
            onCancelClick={ onCancelClick }
            createAnotherOne={ {
                show: showCreateAnotherOne,
                omit: omitCreateAnotherOne,
                state: [ createAnotherOne, setCreateAnotherOne ]
            } }
        /> }
    >
        { htmlBeforeForm }

        <div className={ classNames('grid', grids.cols, grids.gap) }>
            <div className={ grids.form }>
                { showForm && <Form parentId={ parentId } formRef={ formRef } formikConfig={ formikConfig } formFields={ formFields }/> }
            </div>
            <div className={ grids.htmlRight }>{ htmlRightOfForm }</div>
        </div>
        { htmlAfterForm }
    </Modal>;
};
export default FormModal;

