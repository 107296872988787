import React, { FC } from 'react';
import { API_URL } from '../../config/config';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import PrinterJobForm from '../printerJob/PrinterJobForm';
import usePrinterJob from '../printerJob/usePrinterJob';
import PrinterForm from './PrinterForm';
import { PrinterFileDto, PrinterFileModel, PrinterFileRelationsBluePrint, PrinterModel } from './PrinterTypes';
import usePrinter from './usePrinter';
import usePrinterFile from './usePrinterFile';


const PrinterFileForm: FC<ExtendFormModalProps<PrinterFileDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    const printer = usePrinter();

    /**
     *
     */
    const shape = (initialPrinter?: PrinterModel): Shape<PrinterFileDto> => ({
        printerName: Yup.string()
            .label('Printer')
            .controlType('selectSearch')
            .required()
            .selectSearchConfig({
                useHook: usePrinter,
                expectsInitialModel: initialPrinter !== undefined,
                initialModel: initialPrinter,
                searchOptions: {
                    valueCol: 'name',
                    searchCols: [ 'display_name' ],
                    displayName: (model) => `${ model.display_name }`,
                    limit: 'all',
                    filter: 'archived=0',
                    FormModal: PrinterForm
                }
            }),
        jobName: Yup.string()
            .label('Taak')
            .required()
            .controlType('selectSearch')
            .selectSearchConfig({
                useHook: usePrinterJob,
                expectsInitialModel: (initialPrinter?.printJobs ?? []).length>0,
                initialModel: (initialPrinter?.printJobs ?? []).length>0 ?initialPrinter?.printJobs![0] :undefined,
                searchOptions: {
                    valueCol: 'name',
                    searchCols: [ 'name' ],
                    displayName: (model) => `${ model.name }`,
                    limit: 'all',
                    filter: 'archived=0',
                    FormModal: PrinterJobForm
                }
            }),

        details: Yup.string().hidden(true)
    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<PrinterFileDto>(shape());

    const initializer = async() => {
        const printersRes = await printer.getList({
            limit: 1,
            with: [ 'printJobs' ]
        });

        const initialPrinter = printersRes.length>0 ?printersRes[0] :undefined;
        setShape(shape(initialPrinter));
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<PrinterFileModel, PrinterFileDto, PrinterFileRelationsBluePrint>({
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: usePrinterFile,
        onSuccess: onSuccess,
        morphPayload: (_, dto) => {
            const newValues: PrinterFileDto = {
                printerName: dto.printerName,
                details: `${ API_URL }/api/v1/file/${ id }`,
                jobName: dto.jobName
            };
            return newValues;
        }
    });


    return <FormModal
        id={ id }
        resource={ 'Bestand printen' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default PrinterFileForm;
