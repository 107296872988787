import React from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import { optionIsSelected } from '../../../../lib/samfe/components/Form/Support/FieldSupport';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { getMetricUnits } from '../../../../lib/samfe/modules/Parse/Metric';
import ExcipientForm from '../../../excipient/ExcipientForm';
import useExcipient from '../../../excipient/useExcipient';
import { ElementDto, ElementModel, ElementRelationsBluePrint } from '../../ElementTypes';
import useElement from '../../useElement';


const ElementExcipientForm: React.FC<ExtendFormModalProps<ElementDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    const element = useElement();

    const shape = (initialElement?: ElementModel): Shape<ElementDto> => ({

        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text'),

        type: Yup.string()
            .controlType('input')
            .inputType('hidden')
            .defaultValue('hulpstof'),

        compound_id: Yup.number()
            .controlType('input')
            .inputType('hidden'),

        // @fixme PUT
        excipient_id: Yup.number()
            .label('Functie')
            .required()
            .controlType('selectSearch')
            .selectSearchConfig({
                initialModel: initialElement?.excipient,
                expectsInitialModel: id !== undefined,
                useHook: useExcipient,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    displayName: model => `${ model.name }`,
                    limit: 'all',
                    filter: 'archived=0',
                    FormModal: ExcipientForm
                }
            }),

        category_id: Yup.number()
            .controlType('input')
            .inputType('hidden'),


        intake: Yup.number()
            .label('Referentie inname')
            .hidden(true)
            .controlType('input')
            .defaultValue(initialElement?.intake?? 0)
            .inputType('number'),


        unit: Yup.string()
            .required()
            .label('RI eenheid')
            .controlType('select')
            .multiSelect(false)
            .options(getMetricUnits.map((unit, i) => ({
                displayName: unit ?? '',
                value: unit,
                selected: optionIsSelected(initialElement?.unit ?? 'mg', unit, i)
            }))),

        comments: Yup.string()
            .label('Notities')
            .controlType('textArea'),

        is_reviewed: Yup.boolean()
            .label('Hulpstof risico\'s gecontrolleerd')
            .controlType('checkbox')

    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<ElementDto>(shape());

    const initializer = async() => {
        const initialElement = await element.getItem(id, { with: [ 'excipient' ] });
        setShape(shape(initialElement));
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<ElementModel, ElementDto, ElementRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useElement,
        onSuccess: onSuccess
    });


    return <FormModal
        id={ id }
        resource={ 'Hulpstof' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default ElementExcipientForm;
