/**
 *  Check if an option is selected from an existing model.
 *
 *  Value check first, if not present index check if index is zero.
 * @param constant
 * @param currentValue
 * @param index
 */
export const optionIsSelected = (currentValue: string|number|boolean|undefined, constant: any, index: number): boolean => currentValue
    ? (currentValue === constant)
    :(index === 0);


export const isEmpty = (value: string|number|boolean|null|undefined): boolean => (
    value === undefined
    || value === null
    || (typeof value === 'string' && value.replace(' ', '') === '')
);

export type SupportedPayloadType = string|number|boolean|null
export type SupportedPayloadValue = "string" | "number" | "boolean" | "null"
export const getDefaultEmptyValue = (type: SupportedPayloadValue): string|null => type === 'string' ? '' : null

export const requiredFieldClassname: string = 'required-field'