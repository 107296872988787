// @fixme might break
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React, {ForwardedRef, Fragment, useEffect, useState} from "react";
import { Listbox, Transition} from "@headlessui/react";
import {defaultOption, FieldProps, Option} from "./InputTypes";
import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
import {classNames} from "../../../lib/samfe/modules/Parse/String";



type SelectSearchProps = FieldProps & {
    options: Option[],
    overflow?: boolean,
    maxDropdownWidth?: string
    maxDropdownHeight?: string

}


/**
 * Select box with search capabilities.
 *
 * @param props
 * @constructor
 * @deprecated
 */
const Select = React.forwardRef<HTMLInputElement, SelectSearchProps>((props: SelectSearchProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {

    const initSelectedOption: Option = {
        displayName: '',
        value: '',
        disabled: true
    };

    /**
     * Get selected option if present, else empty option is default
     */
    const getSelectedOption = (): Option => {
        let selectedOption = initSelectedOption;
        props.options.forEach((option) => {
            if (option.selected || props.value === option.value) {
                selectedOption = option;
            }
        });
        return selectedOption;
    }


    const [selectedOption, setSelectedOption] = useState(initSelectedOption);
    const [value, setValue] = useState(props.value ?? '');
    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');





    /**
     * Handle value changes.
     * @param optionValue
     */
    const onChange = (optionValue: string|number) => {

        const option = props.options.find((option) => option.value === optionValue) || initSelectedOption
        setSelectedOption(option);

        const value = option.value.toString();
        let isValid = true;
        if (props.required === true) {
            isValid = value.replace(' ', '') !== '';
        }

        // Fire change event for form on hidden input value change
        if (props.id) {
            const hiddenInput = document.getElementById(props.id) as HTMLInputElement|undefined;
            if (hiddenInput !== undefined) {
                if (hiddenInput.value !== value) {
                    hiddenInput.dispatchEvent(new Event('input', { bubbles: true }));
                }
            }
        }

        setValue(option.value.toString());
        setHasError( !isValid);
        setErrorMsg(!isValid ?'Verplicht veld!':'');

        if (props.onChange !== undefined) {
            props.onChange(props.name, value);
        }
    }

    /**
     * Check if given value from parent changes.
     *
     * Only applies when current value === ''
     * Feedback to parent with new value to keep in sync.
     */
    useEffect((): void => {
        let newVal = (value ?? '').toString()
        if (newVal.toString() === '') {
            newVal = (props.value ?? '').toString();
            setValue(newVal);
        }

        // @todo is this necessary?
        // if (props.onChange !== undefined) {
        //     props.onChange(props.name, newVal)
        // }

    }, [props.value]);


    /**
     * Show/hide error.
     *
     * @param isError
     */
    const toggleError = (isError: boolean): void => {
        setHasError(isError)
        setErrorMsg( isError ?'Veld is verplicht!' : '');
    }



    /**
     * Wrapper for conditional error message.
     */
    const errorMessage = (): JSX.Element => (
        hasError
            ? <ErrorMessage message={errorMsg} />
            : <></>
    );


    /**
     * Initialize component
     */
    useEffect((): void => {
        setSelectedOption(getSelectedOption());
    }, []);


    /**
     * Watch option change given from parent.
     */
    useEffect((): void => {
        setValue(props.value ?? '') // @fixme might cause side effects
        setSelectedOption(getSelectedOption());

        // @todo is this necessary?
        // if (props.onChange !== undefined) {
        //     props.onChange(props.name, (value ?? '').toString());
        // }
    }, [JSON.stringify(props.options)]);



    /**
     * Listen to error feedback from parent.
     */
    useEffect((): void => {
        if (props.invalid === true && (value === undefined || value === '')) {
            toggleError(true);
        }
    }, [props.invalid]);


    /**
     * Notify parent on value change
     */
    useEffect(() => {
        // @todo is this necessary?
        // if (props.onChange !== undefined) {
        //     props.onChange(props.name, (value ?? '').toString());
        // }
    }, [value]);







    // NEW

    /**
     * Button that shows select field with current selected value.
     */
    const listBoxButton = (): JSX.Element => (
        <Listbox.Button className={`py-1 bg-white relative w-full border ${props.disabled === true ? 'hover:cursor-not-allowed text-gray-400' : 'text-graphite'} ${hasError ? 'border-red-600' : 'border-gray-300'} h-[2.1rem] rounded-md shadow-sm pl-2 pr-10 text-left text-md cursor-default focus:outline-none focus:ring-1 focus:ring-aqua focus:border-aqua sm:text-md`}>
            <span className="block truncate">{selectedOption.displayName}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <span className="material-icons">unfold_more</span>
            </span>
        </Listbox.Button>
    );


    /**
     * Content of option item
     * @param {Option} option
     * @param {boolean} active
     */
    const optionContent = (option: Option, active: boolean): JSX.Element  => (
        <>
            <span className={
                `${selectedOption.displayName === option.displayName ? 'font-semibold' : 'font-normal'} 
                 ${active ? 'text-white' : (selectedOption.displayName === option.displayName ?'text-aqua':'')} 
                 block truncate`
            }>
                {option.displayName}
            </span>
            {selectedOption.displayName === option.displayName ? (
                <span className={`absolute inset-y-0 right-0 flex items-center text-center mr-2`} >
                    <span className="material-icons text-md text-white p-1 bg-aqua rounded-full ">done</span>
                </span>
            ) : null}
        </>
    );


    /**
     * Option item of select list
     * @param {Option} option
     * @param {number} index
     */
    const listBoxOption = (option: Option, index: number) => {

        const defaultClasses = 'select-none relative py-2 pl-3 pr-9';
        const cursorClass = selectedOption.displayName === option.displayName ? 'cursor-default' : 'cursor-pointer';

        return(
            <Listbox.Option
                key={index}
                value={option.value}
                onClick={() => setSelectedOption(option)}
                className={({active}) => {
                    const activeClasses = active ? `text-white bg-aqua ${cursorClass}` : 'text-gray-900';
                    return `${defaultClasses} ${activeClasses}`;
                }}
            >
                {({active}) => optionContent(option, active)}
            </Listbox.Option>
        );
    }


    /**
     * Wrapper for select options.
     */
    const listBoxOptions = (): JSX.Element => (
        <div>
        <Listbox.Options
            className={classNames(
                `${props.name}-list ${props.overflow === true? 'absolute': 'relative'} z-10 mt-1 w-full bg-white shadow-lg max-h-72 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`,
                    `${props.name}-list`,
                    props.maxDropdownWidth ?? '',
                    props.maxDropdownHeight ?? ''
            )}
        >
            {listBoxOption({...defaultOption, selected: selectedOption.value === ''}, 0)}
            {
                props.options.map((option, index) =>
                    listBoxOption(option, index+1)
                )
            }
        </Listbox.Options>
        </div>
    )




    /**
     * Headless UI wrapper for select field with cross browser styling.
     */
    const listBox = (): JSX.Element => (
        <Listbox disabled={props.disabled === true} value={selectedOption.value ? selectedOption.value : defaultOption.displayName} onChange={onChange}>
            {({ open }) => (
                <>
                    <Label disabled={props.disabled === true} text={props.label} />
                    <div className="relative">
                        { listBoxButton() }

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            {listBoxOptions()}
                        </Transition>
                        {errorMessage()}
                    </div>
                </>
            )}
        </Listbox>
    );


    /**
     * Hidden input for holding form data.
     */
    const input = () => (
        <input type={'hidden'} name={props.name} id={props.id} value={value ?? ''} ref={ref} aria-required={props.required} />
    );


    return(
        <div className={'py-1 text-left'}>
            {/* Form data will be stored in hidden input*/}
            {input()}

            {/* Select wrapper for cross browser styling */}
            {listBox()}
        </div>
    );
});

export default Select;