import {Dispatch, SetStateAction, useState} from "react";
import {ExtendModel} from "./useHttp";
import {MinimumModel} from "../../types/ModelTypes";

export type Pagination<Model extends MinimumModel> = {
    currentPage: number,
    totalItems: number,
    perPage: number,
    sortCol?: keyof Model
    sortDir: 'ASC'|'DESC',
    sortCount?: string,
    search?: string,
    filter?: string
}

export type PaginatedResponse<T> = {
    items: T[],
    page: 0,
    limit: number|'all',
    order: 'ASC'|'DESC',
    orderColumn: string,
    total: number
}

export type UsePagination<Model> = {pagination: Pagination<Model>, setPagination: Dispatch<SetStateAction<Pagination<Model>>>};

const usePagination = <Model extends ExtendModel,>(initial: Pagination<Model>|undefined = {
    currentPage: 0,
    totalItems: 0,
    perPage: 25,
    sortCol: 'id',
    sortDir: 'ASC'
}): UsePagination<Model> => {
     const [pagination, setPagination] = useState<Pagination<Model>>(initial);
     return {
         pagination,
         setPagination
     }
}

export default usePagination;