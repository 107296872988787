import { useMemo } from 'react';
import { AssessmentHistory } from '../types/AssessmentTypes';


export const assessmentRequested = (item: AssessmentHistory) => {
    return item.current.assessment.status == 'test_requested'
}

const useAssessmentIsRequested = (item: AssessmentHistory) => {
    return useMemo(() => assessmentRequested(item), [ item ]);
};

export default useAssessmentIsRequested;
