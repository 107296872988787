import { useMemo } from 'react';
import { AssessmentHistory } from '../types/AssessmentTypes';


const useAssessmentIndex = (item: AssessmentHistory) => {
    return useMemo(() => {
        return item.current.assessment.index;
    }, [ item.current.assessment.index ])
};

export default useAssessmentIndex;
