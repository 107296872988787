import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import Disclosure from '../../../../lib/samfe/components/Disclosure/Disclosure';
import Checkbox from '../../../../lib/samfe/components/Form/Generic/Checkbox';
import { DeleteIcon } from '../../../../lib/samfe/components/Icon';
import Modal from '../../../../lib/samfe/components/Modal';
import FormModalFooter from '../../../../lib/samfe/components/Modal/FormModal/FormModalFooter';
import AssessmentBookUpload from './components/AssessmentBookUpload';
import FileUploadPill from './components/FileUploadPill';
import useBookAssessment from './http/useBookAssessment';
import useChargeAssessment from '../../http/useChargeAssessment';
import { AssessmentFileIdentifier, GroupedAssessmentHistory } from '../../types/AssessmentTypes';


type CurrentOpenItem = {
    assessmentId: number,
    open: boolean
}

type Props = {
    chargeId: number,
    openState: [ boolean, Dispatch<SetStateAction<boolean>> ],
    onSave?: () => (void|Promise<void>)
}
/**
 * Charge with batchcode=12809013 / id=15984 used for testing.
 */
const AssessmentBookForm: FC<Props> = ({
    chargeId,
    openState,
    onSave
}) => {

    const chargeAssessments = useChargeAssessment(chargeId);
    const bookAssessment = useBookAssessment();


    const [ groupedAssessmentHistory, setGroupedAssessmentHistory ] = useState<GroupedAssessmentHistory>();


    useEffect(() => {
        chargeAssessments.getItemWithStatusFilter([ 'test_requested' ]).then(setGroupedAssessmentHistory);
    }, [ chargeId ]);

    const assessmentItems = useMemo(() => {
        return groupedAssessmentHistory?.assessmentHistory ?? [];
    }, [ groupedAssessmentHistory?.assessmentHistory ]);


    const [ open, setOpen ] = useMemo(() => openState, [ openState ]);
    const [ droppedFiles, setDroppedFiles ] = useState<AssessmentFileIdentifier[]>([]);
    const [ filesWithAssessments, setFilesWithAssessments ] = useState<AssessmentFileIdentifier[]>([]);
    const [ openDisclosureId, setOpenDisclosureId ] = useState<number>();

    /**
     * @param {string} uuid
     * @param {number} assessmentId
     * @param {boolean} active
     */
    const handleChange = (uuid: string, assessmentId: number, active: boolean) => {

        const fileIndex = filesWithAssessments.findIndex(fwa => fwa.uuid == uuid);
        if (fileIndex<0) {
            if (active) {
                const file = droppedFiles.find(df => df.uuid == uuid)?.file;
                if (!file) {
                    console.error(`Could not find file with uuid ${ uuid }`);
                    return;
                }
                setFilesWithAssessments([
                    ...filesWithAssessments, {
                        uuid, file, assessmentIds: [ assessmentId ]
                    }
                ]);
            }
            // No file present and not active, no action needed
            return;
        }

        const newFilesWithAssessments = [ ...filesWithAssessments ];
        // File exist and active
        if (active) {
            // Add id to list
            if (!newFilesWithAssessments[fileIndex].assessmentIds.includes(assessmentId)) {
                newFilesWithAssessments[fileIndex].assessmentIds = [
                    ...newFilesWithAssessments[fileIndex].assessmentIds,
                    assessmentId
                ];
            }
        } else {
            // Remove id from list
            newFilesWithAssessments[fileIndex].assessmentIds = newFilesWithAssessments[fileIndex]
                .assessmentIds
                .filter(aid => aid != assessmentId);
        }
        setFilesWithAssessments(newFilesWithAssessments);
    };

    const countFilesOfAssessment = useCallback((aId?: number): number => {
        if (!aId) {
            return 0;
        }

        return filesWithAssessments
            .filter(fwa => fwa.assessmentIds.includes(aId))
            .length;
    }, [ filesWithAssessments ]);


    useEffect(() => {

        const disclosureStates: CurrentOpenItem[] = assessmentItems.map(ass => {
            const assessmentId = ass.current.assessment.id;
            return {
                assessmentId: assessmentId,
                open: openDisclosureId == assessmentId
            } as CurrentOpenItem;
        });
        if (!openDisclosureId && disclosureStates.length>0) {
            disclosureStates[0].open = true;
        }
    }, [ assessmentItems, openDisclosureId ]);

    const handleRemoveFile = (uuid: string) => {
        setDroppedFiles([ ...droppedFiles.filter(df => df.uuid !== uuid) ]);
        setFilesWithAssessments([ ...filesWithAssessments.filter(fwa => fwa.uuid !== uuid) ]);
    };

    const handleClearState = () => {
        setDroppedFiles([]);
        setFilesWithAssessments([]);
        setOpenDisclosureId(undefined);
        setOpen(false);
    };

    /**
     *
     * @returns {Promise<void>}
     */
    const handleSave = async(): Promise<void> => {
        await bookAssessment(chargeId, filesWithAssessments)
            .then(onSave)
            .finally(handleClearState);
    };

    /**
     *
     */
    const handleCancel = () => {
        handleClearState();
    };


    return (
        <Modal
            title={ `Analyses inboeken voor charge ${ groupedAssessmentHistory?.batchcode }` }
            open={ open }
            setOpen={ setOpen }
            className={ 'sm:!w-[64rem] sm:!max-w-none sm:mx-8' }
            footer={ <FormModalFooter
                save={ { onClick: handleSave } }
                onCancelClick={ handleCancel }
            /> }
        >

            <div>
                <AssessmentBookUpload droppedFileState={ [ droppedFiles, setDroppedFiles ] }/>

                <h2 className={ 'text-lg leading-9 text-aqua font-medium' }>Analyse uploads voor de volgende risico&apos;s</h2>

                { assessmentItems.map(item => {
                    const assessmentId = item.current.assessment.id;
                    const fileCount = countFilesOfAssessment(assessmentId);

                    return (
                        <div key={ assessmentId }>
                            <Disclosure
                                title={ item.risk_name }
                                onClick={ () => setOpenDisclosureId(assessmentId) }
                                className={ '!mx-0 all-children:!-mx-0' }
                                titleClassName={ '!-m x-8' }
                                contentClassName={ '!-m x-8' }
                                defaultOpen={ droppedFiles.length>0 }
                                variation={ 'descriptive' }
                                toggle={ {
                                    openendState: { title: <FileUploadPill fileCount={ fileCount }/> },
                                    closedState: { title: <FileUploadPill fileCount={ fileCount }/> }
                                } }
                            >
                                { droppedFiles.length == 0 && <p className={ 'relative -left-2 text-graphite font-light' }>Geen bestanden ge-upload</p> }
                                <ul>
                                    { droppedFiles.map(df => <li key={ df.uuid }>
                                        <Checkbox
                                            checked={ filesWithAssessments.find(fwa => fwa.uuid == df.uuid && fwa.assessmentIds.includes(assessmentId)) !== undefined }
                                            name={ `${ assessmentId }.${ df.uuid }` }
                                            label={ <span className={ 'pl-2' }>{ df.file.name }</span> }
                                            onChange={ (active) => handleChange(df.uuid, assessmentId, active) }
                                        />
                                    </li>) }
                                </ul>
                            </Disclosure>
                        </div>
                    );
                }) }

                <hr className={ 'mt-8 mb-4' }/>
                <div className={ 'text-xs' }>
                <h2 className={ 'text- leading-7 text-aqua font-medium' }>Alle geüploade bestanden</h2>
                    { droppedFiles.length == 0 && <p className={ 'relative mt-1 text-graphite font-light text-xs' }>Geen bestanden ge-upload</p> }

                    <ul>
                        { droppedFiles.map(df => <li key={ df.uuid } className={ 'gap-y-1.5 flex items-center content-center' }>
                            <button onClick={ () => handleRemoveFile(df.uuid) } className={ 'w-7 inline-flex items-center content-center' }>
                                <DeleteIcon/>
                            </button>
                            <div className={ 'inline-flex' }>
                                { df.file.name }
                            </div>

                        </li>) }
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

export default AssessmentBookForm;
