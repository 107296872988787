import React, {FC} from "react";
import TopButton from '../../lib/samfe/components/Button/TopButton';
import IndexPage from "../../templates/pages/IndexPage";
import PrinterJobForm from "./PrinterJobForm";
import PrinterJobTable from "./PrinterJobTable";
import {useNavigate} from "react-router-dom";



const PrinterJobIndex: FC = (): JSX.Element => {
    const navigate = useNavigate();
    return <IndexPage
        title={"Printer taken"}
        Table={PrinterJobTable}
        FormModal={PrinterJobForm}
        actionButton={<TopButton
            style={'secondary'}
            icon={''}
            text={'Printers'}
            onClick={() => navigate('/settings/printers')}
        />}
    />
};

export default PrinterJobIndex;