import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MaterialIconName } from 'ts-material-icon-name-list';
import { dispatchHttpEvent, useHttpEvent } from '../../events/Http.event';
import { ActionButton } from '../../lib/samfe/components/Button';
import ActionMenu, { ActionItem } from '../../lib/samfe/components/Menu/ActionMenu';
import ArchiveModal from '../../lib/samfe/components/Modal/ArchiveModal';
import { UseFormComponent } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import Tabs, { Tab } from '../../lib/samfe/components/Tab';
import { RequestParams, UseHttp } from '../../lib/samfe/modules/Http/useHttp';
import useId from '../../lib/samfe/modules/Router/useId';
import { MinimumModel, SoftDeleteModel } from '../../lib/samfe/types/ModelTypes';
import { PageProvider } from '../../providers/PageProvider';
import Upload, { ExtendUploadProps } from '../../resources/document/Upload';
import BasePage, { ExtendBasePageProps } from './BasePage';


/**
 *
 */
export type FormAction = {
    name: string,
    tabId?: string,
    icon?: MaterialIconName,
    form: UseFormComponent<any>
}

/**
 *
 */
type CustomAction = {
    name: string,
    icon?: string,
    callback: (id: number) => void
}

/**
 *
 */
type FormState = {
    formAction: FormAction;
    open: boolean;
};


export type CustomButtonAction = {
    title: string,
    hidden?: boolean
    onClick: () => void|Promise<void>
}

/**
 *
 */
type Props<Model extends MinimumModel, Dto extends object, RelationsBlueprint extends string> = ExtendBasePageProps&{
    httpHook: () => UseHttp<Model, Dto, RelationsBlueprint>,
    tabs: Tab[],
    setCurrentModel?: Dispatch<SetStateAction<Model|undefined>>
    breadcrumb?: string,
    FormModal?: UseFormComponent<any>,
    relations?: RelationsBlueprint[]
    counts?: RelationsBlueprint[]
    formActions?: FormAction[],
    customActions?: CustomAction[]
    upload?: ExtendUploadProps,
    resourceIdentifier?: keyof Model,
    customButtonActions?: CustomButtonAction[]
    uploadsExtraColName?: string
    uploadsExtraColRef?: string
    hideArchive?: boolean,
    titleWarningMessage?: ReactNode
}


const ShowPage = <Model extends MinimumModel, Dto extends object, RelationsBlueprint extends string>({
    breadcrumb,
    FormModal,
    tabs,
    httpHook,
    setCurrentModel,
    relations,
    counts,
    upload,
    resourceIdentifier,
    customButtonActions,
    uploadsExtraColName,
    uploadsExtraColRef,
    hideArchive = false,
    titleWarningMessage,
    ...props
}: Props<Model, Dto, RelationsBlueprint>): JSX.Element => {

    const id = useId();
    const http = httpHook();
    const navigate = useNavigate();
    const location = useLocation();
    const httpDispatched = useHttpEvent();

    const [ model, setModel ] = useState<Model|undefined>(undefined);
    const modelIsArchived = useMemo(() => {
        if (!model || !('archived' in model)) {
            return false;
        }
        return [ 1, true, 'true' ].includes((model as SoftDeleteModel)?.archived ?? false);
    }, [ model ]);

    const title = useMemo(() => {
        return props.title ?? '';
    }, [ props.title ]);

    const formActions = useMemo(() => {
        return props.formActions ?? [];
    }, [ props.formActions ]);

    const customActions = useMemo(() => {
        return props.customActions ?? [];
    }, [ props.customActions ]);


    const internalFormActions = useMemo(() => {
        return formActions.map(formAction => ({
            formAction,
            open: false
        }));
    }, [ formActions ]);


    const [ lastArchivedStatus, setLastArchivedStatus ] = useState<boolean>(modelIsArchived);
    /** State manager for variable amount of form actions. */
    const [ formStates, setFormStates ] = useState<FormState[]>(modelIsArchived ?[] :internalFormActions);
    const [ prevFormActions, setPrevFormActions ] = useState(formActions);
    /** Patch for form action changes */
    const [ internalFormStateUpdate, setInternalFormStateUpdate ] = useState(false);
    const [ showEditModal, setShowEditModal ] = useState(false);
    const [ openArchiveModal, setOpenArchiveModal ] = useState(false);


    // resource related


    /**
     *
     */
    const basePath: string = useMemo(() => {
        const trimmed = location.pathname.endsWith('/') ?location.pathname.substring(0, location.pathname.length - 1) :location.pathname;
        return trimmed.replace(`${ id }`, '');
    }, [ location.pathname, id ]);

    /**
     *
     */
    const itemName: ReactNode = useMemo(() => {
        const fallback = title ?? '';
        if (!resourceIdentifier) {
            return fallback;
        }
        return `${ model?.[resourceIdentifier] ?? fallback }`;
    }, [ resourceIdentifier, model, title ]);


    // HTTP related

    /**
     * Get resource.
     *
     * @returns {void}
     */
    const resolve = useCallback(() => {
        http.getItem(id, { with: relations ?? [], withCount: counts ?? [] } as RequestParams<Dto, RelationsBlueprint>).then(model => {
            if (!model) {
                navigate(basePath, { replace: true });
            }
            setCurrentModel?.(model);
            setModel(model);
        });
    }, [ http, id, relations, counts, navigate, basePath, setCurrentModel ]);


    /**
     * Initial api call
     */
    useEffect((): void => {
        resolve();
    }, [ id ]);

    /**
     * Re init resource through api call.
     */
    useEffect((): void => {
        if (!httpDispatched) {
            return;
        }
        resolve();
    }, [ httpDispatched ]);


    useEffect(() => {
        if (modelIsArchived) {
            setFormStates([]);
        }

        if (modelIsArchived !== lastArchivedStatus) {
            setLastArchivedStatus(modelIsArchived);
            setFormStates(internalFormActions);
            dispatchHttpEvent();
        }
    }, [ model ]);

    const deArchive = () => {
        http.update(id, { archived: false } as Dto).then((model) => {
            if (model) {
                setCurrentModel?.(model);
                setModel(model);
            }
        });
    };

    /**
     * Call action after resource is updated.
     */
    const handleUpdated = (): void => {
        resolve();
        setShowEditModal(false);
    };

    /**
     * Call action after resource is archived.
     *
     * @returns {void}
     */
    const handleArchived = (): void => {
        const currentPath = location.pathname;
        const parentPath = currentPath.split('/').slice(0, -1).join('/');
        navigate(parentPath);
    };

    /**
     * Modal opener for given form actions.
     *
     * @param {number} index
     * @returns {void}
     */
    const handleOpenModal = (index: number): void => {
        const updatedModalStates = [ ...formStates ];
        updatedModalStates[index].open = true;
        setFormStates(updatedModalStates);
    };

    /**
     * Modal closer for given form actions.
     *
     * @param {number} index
     * @returns {void}
     */
    const handleCloseModal = (index: number): void => {
        const updatedModalStates = [ ...formStates ];
        updatedModalStates[index].open = false;
        setFormStates(updatedModalStates);
    };


    useEffect(() => {
        if (modelIsArchived) {
            return;
        }
        if (internalFormStateUpdate) {
            setInternalFormStateUpdate(false);
            return;
        }

        const getIdentifier = (actions: FormAction[]) => actions.map(action =>
            `${ action.tabId },${ action.name },${ action.icon }`).join(';');

        const prevIdentifier = getIdentifier(prevFormActions);
        const newIdentifier = getIdentifier(formActions);
        if (prevIdentifier == newIdentifier) {
            setInternalFormStateUpdate(true);
            return;
        }
        setPrevFormActions(formActions);
        setFormStates(internalFormActions);
    }, [ formActions ]);


    const rowActions: ActionItem[] = useMemo(() => {
        const actions: ActionItem[] = [];
        if (FormModal !== undefined) {
            actions.push({
                title: 'Aanpassen',
                iconName: 'edit',
                callBack: () => setShowEditModal(true)
            } as ActionItem);
        }

        actions.push(...customActions.map(action => ({
            title: action.name,
            iconName: action.icon ?? 'edit',
            callBack: () => action.callback(id)
        }) as ActionItem));

        actions.push(...formStates
            .filter(formState => formState.formAction.tabId === undefined)
            .map((formState, i) => ({
                title: formState.formAction.name,
                iconName: formState.formAction.icon ?? 'edit',
                callBack: () => handleOpenModal(i)
            }) as ActionItem)
        );

        if (!hideArchive) {
            actions.push({
                title: 'Archiveren',
                iconName: 'delete',
                callBack: () => setOpenArchiveModal(true),
                text: {
                    default: 'text-fire',
                    hover: 'text-white'
                },
                background: {
                    hover: 'bg-fire-hover'
                }
            });
        }

        if (actions.length === 0) {
            actions.push({
                title: 'Geen acties beschikbaar',
                iconName: '',
                callBack: () => undefined
            });
        }

        return actions;
    }, [ FormModal, customActions, id, formStates, hideArchive ]);


    const [ initiallyDispatched, setInitiallyDispatched ] = useState(false);
    /**
     * Relation tabs construction.
     */
    const tabList: Tab[] = useMemo(() => {

        if (!model) {
            return [];
        }
        if (!initiallyDispatched) {
            setInitiallyDispatched(true)
            return []
        }
        let currentTabs = [ ...tabs ];

        if (initiallyDispatched) {
            if (httpDispatched) {
                currentTabs = [ ...tabs ].map(tab => ({ ...tab, element: <></> }));
            }
        } else {
            currentTabs = tabList
        }

        if (!modelIsArchived) {
            currentTabs.forEach((tab, index) => {
                const formActionIndex = formActions.findIndex(action => action.tabId === tab.id);
                if (formActionIndex> -1) {
                    currentTabs[index].action = {
                        icon: formActions[formActionIndex].icon ?? 'edit',
                        callback: () => handleOpenModal(formActionIndex)
                    };
                }
            });
        }

        if (upload) {
            currentTabs.push({
                name: 'Uploads',
                id: 'uploads',
                element: <Upload
                    modelName={ upload.modelName }
                    fileTypes={ upload.fileTypes }
                    readonly={ modelIsArchived }
                    modelId={ id }
                    files={ upload.files }
                    extraColName={ uploadsExtraColName }
                    extraColRef={ uploadsExtraColRef }
                    onSuccess={ resolve }
                />
            });
        }
        return currentTabs;
    }, [ httpDispatched, tabs, formActions, upload, id, resolve, model, initiallyDispatched ]);


    const actionSection = useMemo(() => {
        if (modelIsArchived) {
            return [
                <ActionButton
                    className={ '!max-w-auto !w-auto px-4 !py-1 !h-auto ml-2' }
                    key={ 'recover' }
                    text={ 'De-archiveren' }
                    icon={ '' }
                    onClick={ deArchive }
                />
            ];
        }
        return [
            <div key={ 0 }>
                { customButtonActions?.filter(customAction => customAction.hidden !== true).map(action => <ActionButton
                    className={ '!max-w-auto !w-auto px-4 !py-1 !h-auto ml-2' }
                    key={ action.title }
                    text={ action.title }
                    icon={ '' }
                    disabled={ modelIsArchived }
                    onClick={ action.onClick }
                />) }


            </div>,
            <div key={ 1 } className={ 'bg-gray-200 h-10 px-2 pt-0.5 max-h-8 rounded' }>
                <ActionMenu id={ id } disabled={ modelIsArchived } rowActions={ rowActions }/>
            </div>
        ];
    }, [ modelIsArchived, customButtonActions, id, rowActions ]);


    return (
        <PageProvider type={ 'show' }>
            <BasePage
                title={ <>
                    { (!modelIsArchived && !titleWarningMessage) && title }
                    { modelIsArchived || titleWarningMessage && <div className={ 'flex items-center  text-[0.85rem] all-children:decoration-carrot all-children:underline-carrot' }>
                        <div className={ 'bg-carrot text-white pl-3 pr-2.5 py-[0.15rem] rounded-l-md' }>
                            { modelIsArchived ? 'Gearchiveerd' : titleWarningMessage }
                        </div>
                        <div className={ 'bg-white border border-carrot text-carrot pl-2.5 pr-3 py-[calc(0.15rem-1px)] rounded-r-md' }>{ title }</div>
                    </div> }
                </> }
                breadcrumb={ breadcrumb }
                actionSection={ actionSection }
            >
                <>
                    { <Tabs tabs={ tabList }/> }

                    { FormModal && showEditModal && <FormModal
                        id={ id }
                        open={ showEditModal }
                        setOpen={ setShowEditModal }
                        onSuccess={ handleUpdated }
                    /> }

                    { openArchiveModal && <ArchiveModal
                        open={ openArchiveModal }
                        setOpen={ setOpenArchiveModal }
                        useHttp={ http }
                        id={ id }
                        resourceName={ itemName }
                        itemName={ itemName }
                        onSuccess={ handleArchived }
                    /> }

                    {/* Manage given forms */ }
                    { formStates.map((formState, i) => <div key={ i }>
                        { formState.open && <formState.formAction.form
                            open={ formState.open }
                            setOpen={ () => handleCloseModal(i) }
                            parentId={ id }
                            onSuccess={ () => {
                                dispatchHttpEvent();
                                handleCloseModal(i);
                            } }
                        /> }
                    </div>) }
                </>
            </BasePage>
        </PageProvider>
    );
};
export default ShowPage;