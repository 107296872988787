import { Menu, Transition } from '@headlessui/react';
import React, { FC, Fragment, ReactNode } from 'react';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import { TableActionProps, TableActionType } from '../../types/TableAction';


const MenuButton: FC = () => (
    <div className={'w-full flex justify-end'}>
        <Menu.Button
            className="-mr-2.5 text-gray-900 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-sky">
            <span className="sr-only">Open options</span>
            <span className={ 'select-none material-icons mt-1 whitespace-nowrap text-xl font-bold' }>
                more_vert
            </span>
        </Menu.Button>
    </div>
);


type MenuItemProps<T extends object> = TableActionProps<T>&{
    position: 'first'|'middle'|'last'
}
const MenuItem = <T extends object>({
    disabled = false,
    onClick,
    children,
    variation = 'primary',
    position,
    item
}: MenuItemProps<T>) => {
    return (
        <Menu.Item>
            <button
                onClick={ async() => {
                    if (!disabled) {
                        await onClick(item);
                    }
                } }
                disabled={ disabled }
                className={ classNames('group flex items-center px-4 py-2.5 text-sm w-full',
                    disabled && 'opacity-50 !bg-gray-100 hover:cursor-not-allowed',
                    variation == 'primary' && 'text-gray-700 hover:text-gray-900 active:text-gray-950 hover:bg-gray-50 active:bg-gray-100',
                    variation == 'destructive' && 'text-red-700 hover:text-red-900 active:text-red-950 hover:bg-red-50 active:bg-red-100',
                    position == 'first' && 'rounded-tl-md',
                    position == 'last' && 'rounded-bl-md'
                ) }
            >
                { children }
            </button>
        </Menu.Item>
    );
};


const ItemsWrapper: FC<{ children: ReactNode }> = ({ children }) => (
    <Transition
        as={ Fragment }
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
    >
        <Menu.Items
            className="z-20 origin-top-right absolute right-[3.25rem] -top-3.5 w-64 rounded-l shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            { children }
        </Menu.Items>
    </Transition>
);


type Props<T extends object> = {
    items: TableActionType<T>[]
}

const TableActionMenuComponent = <T extends object>({ items }: Props<T>) => {
    return (
        <div className={ 'sticky' }>
            <Menu as="div" className="absolute text-left contents z-0">
                <MenuButton/>
                <ItemsWrapper>
                    { items.map(({ children, ...item }, i) => <MenuItem
                        key={ i }
                        { ...item }
                        position={ i == 0 ?'first' :i == items.length - 1 ?'last' :'middle' }
                    >
                        { children }
                    </MenuItem>) }
                </ItemsWrapper>
            </Menu>
        </div>
    );
};

export default TableActionMenuComponent;
