import React from "react";
import useHttp from "../../../../../lib/samfe/modules/Http/useHttp";
import {
    CompositionProductDto,
    CompositionProductModel,
    CompositionProductRelationsBluePrint
} from "../CompositionProductTypes";


const useCompositionExcipient = (productId?: number) => useHttp<CompositionProductModel, CompositionProductDto, CompositionProductRelationsBluePrint>({
    endpoint: `products/${productId}/composition-excipients`
});
export default useCompositionExcipient;
