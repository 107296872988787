import React, { forwardRef } from 'react';
import { classNames } from '../../../lib/samfe/modules/Parse/String';


type Props = JSX.IntrinsicElements['tbody']


const getClassName = (props: Props) => props.className;
const TbodyComponent = forwardRef<HTMLTableSectionElement, Props>((props, ref) => {

    return (
        <tbody ref={ ref } { ...props } className={ classNames(
            'text-graphite font-light',
            'even-children:bg-aqua even-children:bg-opacity-[3%] hover:even-children:bg-aqua/5',
            'hover:odd-children:bg-aqua hover:odd-children:bg-opacity-[7%]',
            getClassName(props))
        }/>
    );
});

TbodyComponent.displayName = 'TbodyComponent';
export default TbodyComponent;
