import useHttp from '../../../lib/samfe/modules/Http/useHttp';
import { AssessmentStatus, GroupedAssessmentHistory } from '../types/AssessmentTypes';


const useChargeAssessment = (chargeId: number) => {

    const chargeAssessmentHistoryHook = useHttp<GroupedAssessmentHistory, {}, ''>({
        endpoint: 'charge-assessments'
    });

    const getItem = async (): Promise<GroupedAssessmentHistory|undefined> => {
        return await chargeAssessmentHistoryHook.getItem(chargeId);
    }

    const getItemWithStatusFilter = async (statuses : AssessmentStatus[] = []): Promise<GroupedAssessmentHistory|undefined> => {
        let filter: string|undefined = undefined;
        if (statuses.length>0) {
            filter = statuses.map(status => `assessment_status=${ status }`).join(',')
        }
        return await chargeAssessmentHistoryHook.getItem(chargeId, { filter });
    }

    const getList = async () => {
        return await getItem().then(r => {
            const res: GroupedAssessmentHistory[] = [];
            if (r) {
                res.push(r)
            }
            return res
        })
    }

    return {
        ...chargeAssessmentHistoryHook,
        getItem,
        getList,
        getItemWithStatusFilter
    };
};

export default useChargeAssessment;
