import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../../../lib/samfe/modules/Parse/String';
import { TableColumnType } from '../types/TableColumn';


export type TdPosition = 'first'|'middle'|'last';

type TdProps = JSX.IntrinsicElements['td'];
type Props = Omit<TdProps, 'onClick'>&{
    type: TableColumnType|'action'|'sort',
    linkTo?: string,
    onClick?: () => void|Promise<void>
    position: TdPosition
}
const TdComponent: FC<Props> = ({
    children,
    className,
    type,
    linkTo,
    onClick,
    position,
    ...props
}) => {

    const hasLink = useMemo(() => {
        if (type == 'action' || type == 'sort') {
            return false;
        }
        return linkTo != undefined;
    }, [ type, linkTo ]);

    const hasOnClick = useMemo(() => {
        if (type == 'action' || type == 'sort') {
            return false;
        }
        return onClick != undefined;
    }, [ type, onClick ]);


    const hasAction = useMemo(() => {
        return hasLink || hasOnClick;
    }, [ hasLink, hasOnClick ]);

    const tdRef = useRef<HTMLTableCellElement>(null);

    /**
     * Scroll horizontal i.o. with mouse on hover to see all items in tab nav.
     * @todo move to 1 level up so that all last rows are observed io observer per row
     */
    useEffect(() => {
        if (type != 'action' || !tdRef.current?.parentElement) {
            return;
        }

        const tracker = document.createElement('td');
        tracker.style.width = '0px';
        tracker.style.height = '0px';
        tdRef.current.parentElement.appendChild(tracker);
        const observer = new IntersectionObserver(
            ([ e ]) => {
                if (e.isIntersecting) {
                    tdRef.current?.classList.remove('border-l');
                    tdRef.current?.classList.remove('bg-white');
                } else {
                    tdRef.current?.classList.add('border-l');
                    tdRef.current?.classList.add('bg-white');
                }
            },
            { threshold: [ 0.1 ], root: tdRef.current.parentElement?.parentElement?.parentElement?.parentElement }
        );
        observer.observe(tdRef.current.nextElementSibling!);
    }, []);


    return (
        <td ref={ tdRef } { ...props } className={ classNames('whitespace-nowrap',
            position == 'first' && 'border-t border-gray-200',

            !hasAction && classNames(
                'py-3.5',
                position == 'first' && 'pr-4 pl-4 sm:pl-10 lg:pl-12',
                position == 'middle' && 'px-4',
                position == 'last' && 'pl-4 pr-4 sm:pr-10 lg:pr-12'
            ),
            ([ 'numeric', 'percentage', 'currency', 'action' ] as TableColumnType[]).includes(type as TableColumnType) && 'text-right',
            ([ 'date' ] as TableColumnType[]).includes(type as TableColumnType) && 'text-center',
            type == 'action' && 'sticky right-0',
            className
        ) }>

            { !hasAction && <>{ children }</> }

            { hasLink && <Link to={ linkTo! } className={ classNames('block py-3.5',
                position == 'first' && 'pr-4 pl-4 sm:pl-10 lg:pl-12',
                position == 'middle' && 'px-4',
                position == 'last' && 'pl-4 pr-4 sm:pr-10 lg:pr-12'
            ) }>
                { children }
            </Link> }

            { hasOnClick && <button
                onClick={ onClick }
                style={ { textAlign: 'inherit' } }
                className={ classNames('block w-full py-3.5',
                    position == 'first' && 'pr-4 pl-4 sm:pl-10 lg:pl-12',
                    position == 'middle' && 'px-4',
                    position == 'last' && 'pl-4 pr-4 sm:pr-10 lg:pr-12'
                ) }>
                { children }
            </button> }

        </td>
    );
};

export default TdComponent;
