import React, {useEffect, useState} from "react";
import useFormikField from "../../Effects/useFormikField";
import {useFormikContext} from "formik";
import {MinimumModel} from "../../../../types/ModelTypes";

type Props<Model extends MinimumModel> = {
    omitFormik?: boolean,
    name: string,
    valueCol: keyof Model
}

const useFormikSelectSearch = <Model extends MinimumModel>({name, valueCol, omitFormik = false}: Props<Model>) => {

    const [model, setFormikModel] = useState<Model|undefined>(undefined);

    const formikField = !omitFormik ?useFormikField(name) :undefined;
    const formikCtx = !omitFormik ?useFormikContext() :undefined;


    /**
     * Set value on change.
     */
    useEffect(() => {
        if (!model) {
            return;
        }
        formikCtx?.setFieldValue(name, model[valueCol])
    }, [model]);


    return {
        ...formikField,
        setFormikModel,
        formikCtx
    }
}
export default useFormikSelectSearch;