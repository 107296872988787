import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import useToaster from '../../lib/samfe/components/Toaster/useToaster';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import { classNames } from '../../lib/samfe/modules/Parse/String';
import { GroupContactDto, GroupModel, GroupRelationsBluePrint } from './GroupTypes';
import { useGroupContact } from './useGroup';


const LogoSource: FC<{ logoSrc?: string }> = ({ logoSrc }) => {
    return <>
        { logoSrc
          ? <img className={ 'max-w-64 h-8' } src={ logoSrc } alt={ 'Logo preview' }/>
          : <div className={'h-8'}></div>
        }
    </>
};


const LogoDragAndDrop: FC<{
    id?: number,
    setLogo: Dispatch<SetStateAction<File|undefined>>,
}> = ({ id, setLogo }) => {

    const { setToasterProps } = useToaster();

    const { getRootProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles: File[]) => {
            if (acceptedFiles.length >=0 ) {
                setLogo(acceptedFiles[0]);
                const file = acceptedFiles[0];
                setLogo(file);
                return;
            }
            setToasterProps({
                type: 'error',
                title: 'Bestandtype niet ondersteund',
                message: 'Ondersteund: jp(e)g, png',
                show: true
            });
        },
        accept: {
            'image/jpeg': [ '.jpg', '.jpeg', '.png' ]
        }
    });

    return (
        <div { ...getRootProps() } className={ classNames('mt-8 mb-8 text-center border-[0.25rem] border-dashed p-6 rounded-md',
            isDragActive ?'border-sky bg-sky bg-opacity-10 text-sky' :'border-graphite border-opacity-10'
        ) }>
            <span className={ 'block mx-auto hover:cursor-pointer text-5xl text-sky material-icons' }>
                { isDragActive ?'arrow_drop_down_circle' :'cloud_upload' }
            </span>
            <span className={ 'block mx-auto text-center w-full font-medium text-lg' }>
                { isDragActive
                  ?'Drop it like its hot 🔥'
                  :<>
                      <span className={ 'text-sky hover:cursor-pointer' }>{ id ?'Vervang' :'Upload' } logo</span>&nbsp;
                      <span className={ 'text-graphite' }>of sleep het in dit vak</span>
                  </>
                }
            </span>
            <small className={ 'text-graphite-hover' }>PNG of JPEG tot 2MB</small>
        </div>
    );
};


const GroupForm: FC<ExtendFormModalProps<GroupContactDto>> = ({
    id,
    open,
    setOpen,
    onSuccess
}): JSX.Element => {

    const { setToasterProps } = useToaster();

    const [ logo, setLogo ] = useState<File>();

    const base64ToFile = (str:string): File => {
        const pos = str.indexOf(';base64,');
        const type = str.substring(5, pos);
        const imageContent = window.atob(str.substring(pos + 8));

        // create an ArrayBuffer and a view (as unsigned 8-bit)
        const buffer = new ArrayBuffer(imageContent.length);
        const view = new Uint8Array(buffer);

        // fill the view, using the decoded base64
        for(let n = 0; n < imageContent.length; n++) {
            view[n] = imageContent.charCodeAt(n);
        }

        // convert ArrayBuffer to Blob
        return new Blob([ buffer ], { type: type }) as File;
    }


    // set source if file exist on create/update
    const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const logoSrc = useAsyncMemo(async () => {
        if (!logo) {
            return undefined
        }
        return fileToBase64(logo).catch(() => {
            setToasterProps({
                type: 'error',
                title: 'Fout met het verwerken van het bestand',
                message: 'Ondersteund: jp(e)g, png',
                show: true
            });
            return undefined
        });

    }, [logo], undefined);


    /**
     *
     */
    const shape = (): Shape<GroupContactDto> => ({
        group_name: Yup.string()
            .required()
            .label('Groep naam')
            .controlType('input')
            .inputType('text'),
        group_key: Yup.string()
            .required()
            .label('Korte naam')
            .controlType('input')
            .inputType('text'),
        address: Yup.string()
            .required()
            .label('Adres')
            .controlType('input')
            .inputType('text'),
        postalcode: Yup.string()
            .required()
            .label('Postcode')
            .controlType('input')
            .inputType('text'),
        city: Yup.string()
            .required()
            .label('Stad')
            .controlType('input')
            .inputType('text'),
        contact_name: Yup.string()
            .required()
            .label('Contact persoon')
            .controlType('input')
            .inputType('text'),
        email: Yup.string()
            .required()
            .label('Email')
            .controlType('input')
            .inputType('text'),
        phone: Yup.string()
            .required()
            .label('Telefoonnummer')
            .controlType('input')
            .inputType('text'),
        logo: Yup.string().hidden(true).required()
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<GroupContactDto>(shape());

    const initializer = async() => {
        setShape(shape());
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<GroupModel, GroupContactDto, GroupRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useGroupContact,
        onSuccess: onSuccess,
        morphPayload: (payload) => {
            if (logoSrc !== null) {
                payload.logo = logoSrc;
            }
            return payload;
        }
    });


    useEffect(() => {
        if (formikConfig.initialValues?.logo) {
            setLogo(base64ToFile(formikConfig.initialValues?.logo as string));
        }
    }, [formikConfig.initialValues]);


    return <FormModal
        id={ id }
        resource={ 'Groepen' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        htmlBeforeForm={<LogoSource logoSrc={ logoSrc }/>}
        htmlAfterForm={ <LogoDragAndDrop id={ id } setLogo={ setLogo } /> }
    />;
};

export default GroupForm;
