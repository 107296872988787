import React, {createContext, useState, useContext, Dispatch, FC, PropsWithChildren, SetStateAction} from 'react';
import {LoadingScreenProps} from "./index";



type LoadingScreenContextType = {
    loadingScreenProps: LoadingScreenProps;
    setLoadingScreenProps: Dispatch<SetStateAction<LoadingScreenProps>>;
};

const LoadingScreenContext = createContext<LoadingScreenContextType | undefined>(undefined);



export const LoadingScreenProvider: FC<PropsWithChildren<any>> = ({ children }): JSX.Element => {
    const [loadingScreenProps, setLoadingScreenProps] = useState<LoadingScreenProps>({
        show: false,
        isFullscreen: false,
        onCancel: () => {},
    });

    return (
        <LoadingScreenContext.Provider value={{ loadingScreenProps, setLoadingScreenProps }}>
            {children}
        </LoadingScreenContext.Provider>
    );
};


export const useLoadingScreen = (): LoadingScreenContextType => {
    const context = useContext(LoadingScreenContext);
    if (!context) {
        throw new Error('useLoadingScreen must be used within a LoadingScreenProvider');
    }
    return context;
};

export default useLoadingScreen;
