import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useLoadingScreen from '../../lib/samfe/components/LoadingScreen/useLoadingScreen';
import useToaster from '../../lib/samfe/components/Toaster/useToaster';
import useToken from '../../lib/samfe/modules/Cookie/useToken';
import useAxios from '../../lib/samfe/modules/Http/useAxios';
import useCurrentUser from './useCurrentUser';
import useIsLoggedIn from './useIsLoggedIn';
import useRedirectToLogout from './useRedirectToLogout';
import useCookie from "../../lib/samfe/modules/Cookie/useCookie";


export type Group = {
    name: string,
    theme: string,
    logo?: string|undefined,
}

type User = {
    id: number,
    name: string,
    email: string,
    group: Group,
}

export type LoginData = {
    user: User,
    token: string
};

type LogoutData = {
    success: boolean
}

type Credentials = {
    email: string,
    password: string
};


type CancelPayload = {
    token: string
}

export type ResetPayload = {
    token: string,
    password1: string
    password2: string
}


const useAuth = () => {

    const redirectToLogout = useRedirectToLogout();
    const axios = useAxios();
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    const authCookie = useToken();
    const groupCookie = useCookie('group');
    const { setToasterProps } = useToaster();
    const { setLoadingScreenProps } = useLoadingScreen();
    const getIsLoggedIn = useIsLoggedIn();
    const reactRouterLocation = useLocation();
    const [ queryParams ] = useSearchParams();

    /**
     *
     * @param show
     */
    const renderLoadingScreen = (show: boolean) => setLoadingScreenProps({ show });


    /**
     *
     */
    const errorToaster = () => setToasterProps({
        show: true,
        type: 'error',
        title: 'Inloggen mislukt.'
    });


    /**
     *
     * @param {Credentials} credentials
     */
    const login = (credentials: Credentials) => {
        renderLoadingScreen(true);
        axios.post<Credentials, LoginData>('users/login', credentials).then(res => {
            if (res.status !== 200) {
                errorToaster();
                return;
            }
            const { token, user } = res.data;
            currentUser.setUser({ ...user, token });
            authCookie.setToken(res.data.token);

            const groupOrNull = res?.data?.user?.group;
            if (groupOrNull != undefined) { // Must be smaller than 4KiB (optimize SVG!)
                groupOrNull.logo = ''; // Don't save logo
                groupCookie.setData(JSON.stringify(groupOrNull));
            } else {
                groupCookie.destroy();
            }

            if (queryParams.has('redirect_after_authentication')) {
                navigate(queryParams.get('redirect_after_authentication')!)
                return;
            }
            navigate('/dashboard')
        })
            .catch(() => errorToaster())
            .finally(() => renderLoadingScreen(false));
    };


    /**
     *
     */
    const logout = (): void => {
        const excludedRoutes = [ 'login', 'reset-password', 'cancel-password-reset', 'forgot-password' ];
        if (excludedRoutes.includes(reactRouterLocation.pathname.replace('/', ''))) {
            return;
        }
        groupCookie.destroy();

        axios.get<LogoutData, ''>('users/logout').finally(() => {
            currentUser.destroyUser();
            const params = new URLSearchParams(reactRouterLocation.search);
            navigate({
                pathname: '/login',
                search: params.toString(),
            });
        });
    };


    /**
     *
     */
    const isLoggedIn = async(): Promise<boolean> => await getIsLoggedIn();

    /**
     *
     * @param isGuarded
     */
    const redirectIfUnAuthenticated = (isGuarded: boolean = true) => {
        if (!isGuarded) {
            return;
        }
        isLoggedIn().then(isLoggedIn => !isLoggedIn && redirectToLogout());
    };

    /**
     *
     * @param email
     */
    const forgotPassword = async(email: string): Promise<boolean> => await axios.post('users/reset', { email })
        .then(() => true)
        .catch(() => false);


    /**
     *
     * @param payload
     */
    const resetPassword = async(payload: ResetPayload): Promise<boolean> => await axios.post('users/password', payload)
        .then(() => true)
        .catch(() => false);


    const invalidateResetPasswordToken = async(token: string): Promise<boolean> => await axios
        .post<CancelPayload>('users/invalidate', { token: token })
        .then(() => true)
        .catch(() => false);


    return {
        login,
        logout,
        isLoggedIn,
        redirectIfUnAuthenticated,
        forgotPassword,
        resetPassword,
        invalidateResetPasswordToken
    };
};

export default useAuth;