import React, {FC} from "react";
import IndexPage from "../../templates/pages/IndexPage";
import ProductTypeForm from "./ProductTypeForm";
import ProductTypeTable from "./ProductTypeTable";


const ProductTypeIndex: FC = (): JSX.Element => <IndexPage
    title={"Producttypes"}
    Table={ProductTypeTable}
    FormModal={ProductTypeForm}
/>
export default ProductTypeIndex;