import { useMemo } from 'react';
import { classNames } from '../../../lib/samfe/modules/Parse/String';
import { getColStyle, getDataType } from '../functions/Table.functions';
import { TableColumns } from '../types/TableRow';


const useTableColumns = <T extends object, R extends string>(rows: TableColumns<T, R>[]) => {
    return useMemo((): TableColumns<T, R>[] => {
        return rows.map(item => {
            const dataType = getDataType(item.type);
            const colStyle = getColStyle(dataType);

            return {
                ...item,
                type: dataType,
                header: {
                    ...item.header,
                    style: {
                        ...colStyle,
                        ...item.style,
                        ...item.header.style
                    },
                    className: classNames(item.className, item.header.className)
                }
            };
        });
    }, [ rows ]);
};

export default useTableColumns;
