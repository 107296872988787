import { useMemo } from 'react';
import { AssessmentHistory } from '../types/AssessmentTypes';


export const assessmentIsNotApplicable = (item: AssessmentHistory) => {
    if (item.parent !== null) {
        return true;
    }
    return item.current.assessment.index == 0 || item.frequency == 0;
}

const useAssessmentIsNotApplicable = (item: AssessmentHistory) => {
    return useMemo(() => assessmentIsNotApplicable(item), [ item ]);
};

export default useAssessmentIsNotApplicable;
