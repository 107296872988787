import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import { SelectOption } from '../../lib/samfe/components/Form/Effects/useSelect';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import { compareSqlDates, jsDateToSqlDate, parseExpirationSqlDateTime, sortByDate, sqlDateTimeToDate } from '../../lib/samfe/modules/Parse/Date';
import { classNames } from '../../lib/samfe/modules/Parse/String';
import { getFullArticleName } from '../article/ArticleFunctions';
import { ArticleModel } from '../article/ArticleTypes';
import useArticle from '../article/useArticle';
import { batchCodeLabel } from '../charge/ChargeFunctions';
import { ChargeModel } from '../charge/ChargeTypes';
import useCharge from '../charge/useCharge';
import { formatProductNumber } from '../product/ProductFunctions';
import { ProductModel } from '../product/ProductTypes';
import useProduct from '../product/useProduct';
import { getProductTypeUnit } from '../productType/ProductTypeFunctions';
import { ProductTypeModel } from '../productType/ProductTypeTypes';
import RepackingReceipt from './RepackingReceipt';
import { repackCompanies, RepackCompany, RepackingDto, RepackingModel, RepackingRelationsBluePrint, RepackingStatus, RepackStatusTranslation } from './RepackingTypes';
import useRepackingPivotless from './useRepackingPivotless';


type RepackShape = Omit<RepackingDto, 'target_charge_id'>&{
    product_id: number,
    show_expiration_date: boolean
}

/**
 *
 * @param id
 * @param open
 * @param setOpen
 * @param onSuccess
 * @constructor
 */
/** */
const RepackingForm: FC<ExtendFormModalProps<RepackingDto>> = ({
    id,
    open,
    setOpen,
    onSuccess,
    onClose
}): JSX.Element => {

    const repacking = useRepackingPivotless();
    const article = useArticle();
    const charge = useCharge();

    /**
     * States for processing order
     */
    const [ initialRepackOrder, setInitialRepackOrder ] = useState<RepackingModel>();
    const [ currentProduct, setCurrentProduct ] = useState<ProductModel>();
    const [ currentParentArticle, setCurrentParentArticle ] = useState<ArticleModel>();
    const [ currentParentCharge, setCurrentParentCharge ] = useState<ChargeModel>();
    const [ currentTargetArticle, setCurrentTargetArticle ] = useState<ArticleModel>();


    /**
     * Amount stored in db, based on parent amount.
     *
     * @example parent=bulk,        target=package(60), target amount=10    => repackable amount = (10*60)/1 = 600 units.
     * @example parent=package(30), target=package(60), target amount=10    => repackable amount = (10*60)/30 = 20 units.
     * @example parent=package(60), target=package(60), target amount=10    => repackable amount = (10*60)/60 = 10 units.
     * @example parent=package(60), target=package(30), target amount=10    => repackable amount = (10*30)/60 = 5 units.
     * @example parent=package(60), target=bulk,        target amount=1000  => repackable amount = (1000*1)/60 = 16.6667 => 17 units.
     */
    const [ repackAmount, setRepackAmount ] = useState(0);


    /**
     * Amount displayed for target.
     *
     * @example parent=bulk,        target=package(60), target amount=10
     * @example parent=package(30), target=package(60), target amount=10
     * @example parent=package(60), target=package(60), target amount=10
     * @example parent=package(60), target=package(30), target amount=10
     * @example parent=package(60), target=bulk,        target amount=1000
     */
    const [ targetAmount, setTargetAmount ] = useState(0);

    /** Can be selected by end user or is used if no stock is available. */
    const [ isBackOrder, setIsBackOrder ] = useState(true);

    /** Articles available to use from selected product. */
    const [ availableArticles, setAvailableArticles ] = useState<ArticleModel[]>([]);

    /** Charges available from selected parent article. */
    const [ availableCharges, setAvailableCharges ] = useState<ChargeModel[]>([]);


    /** Used for displaying the correct units. */
    const [ currentProductType, setCurrentProductType ] = useState<ProductTypeModel>();

    /** Date of fulfilling repack order */
    const [ repackDate, setRepackDate ] = useState(jsDateToSqlDate());
    const [ expirationDate, setExpirationDate ] = useState<string>();
    const [ showExpirationDate, setShowExpirationDate ] = useState(false);

    /** Batch code of target charge. */
    const [ batchCode, setBatchCode ] = useState('');

    /** Comments of end user */
    const [ comments, setComments ] = useState('');
    const [ status, setStatus ] = useState<RepackingStatus>();
    const [ repackedBy, setRepackedBy ] = useState<RepackCompany>();


    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    /**
     * total amount that is usable for repacking.
     *
     * @example all examples use usable stock from parent in pills.
     * @example bulk => 10_000 * 1 = 10_000 pills available for repacking.
     * @example package(60) => 1_000 * 60 = 60_000 pills available for repacking.
     *
     * @type {number}
     */
    const usableAmountPerUnit: number = useMemo(() => {
        if (isBackOrder) {
            return Infinity;
        }
        const usableStock = currentParentCharge?.stock?.usable_stock ?? 0;
        const parentContents = currentParentArticle?.amount ?? 0;
        return usableStock * parentContents;
    }, [ currentParentCharge, isBackOrder ]);

    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    /**
     * total claimed number of units (pills, tablets etc.).
     *
     * @example target=package(60), target amount=10 => 10*60 = 600 pills.
     * @type {number}
     */
    const claimedAmountPerUnit: number = useMemo(() => {
        const parentContents = currentParentArticle?.amount ?? 0;
        const isInitialCharge = initialRepackOrder?.parent_charge_id === currentParentCharge?.id;
        const claimedAmount = isInitialCharge ?initialRepackOrder?.amount ?? 0 :0;
        return claimedAmount * parentContents;
    }, [ currentParentCharge ]);

    /**
     * Total available for current repack order.
     *
     * When a repack order already exists and is edited, it already has a claim on the parent stock.
     * The amount of the record + usable in parent = available.
     * Value is in pills.
     *
     * @type {number}
     */
    const availableAmountPerUnit: number = useMemo(() => {
        return usableAmountPerUnit + claimedAmountPerUnit;
    }, [ usableAmountPerUnit, claimedAmountPerUnit ]);

    /**
     * Max amount the target can use.
     *
     * @type {number}
     */
    const availableTargetAmount: number = useMemo(() => {
        return Math.floor(availableAmountPerUnit / (currentTargetArticle?.amount ?? 1));
    }, [ availableAmountPerUnit, currentTargetArticle?.amount ]);

    /**
     * Amount requested for target article per amount.
     *
     * @type {number}
     */
    const requestedAmountPerUnit: number = useMemo(() => {
        return targetAmount * (currentTargetArticle?.amount ?? 1);
    }, [ targetAmount, currentTargetArticle?.amount ]);


    const initializeStock = () => {
        const claimedAmount = initialRepackOrder?.amount ?? 0;
        const parentContents = initialRepackOrder?.parentArticle?.amount ?? 1;
        const totalAmountPerUnit = claimedAmount * parentContents;
        const targetContents = initialRepackOrder?.targetArticle?.amount ?? 1;
        setRepackAmount(initialRepackOrder?.amount ?? 0);
        setTargetAmount(Math.floor(totalAmountPerUnit / targetContents));
    };


    const [ initialized, setInitialized ] = useState(false);
    useEffect(() => {
        if (!initialRepackOrder) {
            if (id === undefined) {
                setInitialized(true);
            }
            return;
        }

        // Initialize values
        setCurrentProduct(initialRepackOrder.parentArticle?.product);
        setCurrentParentArticle(initialRepackOrder.parentArticle);
        setCurrentParentCharge(initialRepackOrder.parentCharge);
        setCurrentTargetArticle(initialRepackOrder.targetArticle);

        setIsBackOrder(initialRepackOrder.parent_charge_id === undefined);

        // Process Stock
        initializeStock();

        // Set field values
        setCurrentProductType(initialRepackOrder.parentArticle?.product?.productType);
        setRepackDate(initialRepackOrder.repack_date ?? sqlDateTimeToDate());
        setExpirationDate(initialRepackOrder.expiration_date ?? initialRepackOrder.parentCharge?.expiration_date);
        setShowExpirationDate(!(!initialRepackOrder.expiration_date))
        setBatchCode(initialRepackOrder.batchcode ?? '');
        setComments(initialRepackOrder.comments ?? '');
        setRepackedBy(initialRepackOrder.repacked_by ?? 'Triple Pharma');

        switch (initialRepackOrder.status) {
            case 'backorder': {
                if (!initialRepackOrder.parentCharge) {
                    break;
                }
                setStatus('pending');
                break;
            }
            default: {
                if (!initialRepackOrder.parentCharge) {
                    setStatus('backorder');
                    break;
                }
                setStatus(initialRepackOrder.status);
            }
        }

        setInitialized(true);
    }, [ initialRepackOrder ]);


    /**
     * Listen if requested amount is changed and set repack amount.
     */
    useEffect(() => {
        setRepackAmount(Math.ceil(requestedAmountPerUnit / (currentParentArticle?.amount ?? 1)));
    }, [ requestedAmountPerUnit ]);


    /**
     *
     * @returns {Promise<ArticleModel[]>}
     */
    const getAvailableArticles = async(): Promise<ArticleModel[]> => {
        if (currentProduct === undefined) {
            return [];
        }

        return await article.getList({
            filter: `active=1,product_id=${ currentProduct?.id }`,
            with: [ 'package.parts' ],
            limit: 'all'
        }).then(articles => articles
            .sort((a, b) => `${ a.number }`.localeCompare(`${ b.number }`))
        );
    };

    /**
     *
     * @returns {Promise<ChargeModel[]>}
     * */
    const getAvailableCharges = async(): Promise<ChargeModel[]> => {
        const article = currentParentArticle ?? initialRepackOrder?.parentArticle;
        if (!article) {
            return [];
        }

        return await charge.getList({
            filter: `article_id=${ article.id },archived=0,amount>0`,
            with: [ 'stock', 'chargeLocations.location.group' ],
            limit: 'all'
        }).then(charges => charges
            .sort((a, b) => sortByDate(a.expiration_date, b.expiration_date))
        );
    };


    /**
     *
     * @param {number} articleId
     */
    const handleParentArticleChange = (articleId?: number): void => {
        if (!initialized) {
            return;
        }

        const newArticle = availableArticles.find(article => article.id === articleId);
        setCurrentParentArticle(newArticle);
    };

    /**
     *
     * @param {number} chargeId
     */
    const handleParentChargeChange = (chargeId?: number): void => {
        if (!initialized) {
            return;
        }

        const isBackOrder = chargeId === -1;
        const currentCharge = !isBackOrder ?availableCharges.find(charge => charge.id === chargeId) :undefined;
        if (currentCharge?.expiration_date && showExpirationDate) {
            setExpirationDate(compareSqlDates(expirationDate, currentCharge.expiration_date, 'highest'))
        }

        setCurrentParentCharge(currentCharge);
        setIsBackOrder(isBackOrder);
        setStatus(isBackOrder ?'backorder' :(id && currentCharge?.id === initialRepackOrder?.parent_charge_id ?initialRepackOrder?.status :'pending'));
    };


    /**
     * @param {number} articleId
     */
    const handleTargetArticleChange = (articleId?: number): void => {
        if (!initialized || !articleId) {
            return;
        }
        const newArticle = availableArticles.find(article => article.id === articleId);

        if (newArticle?.id === currentTargetArticle?.id) {
            return;
        }
        setCurrentTargetArticle(newArticle);

    };

    /**
     * @param {number} amount
     */
    const handleTargetAmountChange = (amount?: number): void => {
        if (!initialized) {
            return;
        }
        setTargetAmount(amount ?? 0);
    };

    /**
     * @param {string} batchCode
     */
    const handleBatchCodeChange = (batchCode?: string): void => {
        if (!initialized) {
            return;
        }
        setBatchCode(batchCode ?? '');
    };

    /**
     * @param {string} repackDate
     */
    const handleRepackDateChange = (repackDate?: string): void => {
        if (!initialized) {
            return;
        }
        setRepackDate(repackDate ?? '');
    };

    /**
     * @param {boolean} showExpirationDate
     */
    const handleShowExpirationDateChange = (showExpirationDate?: boolean): void => {
        if (!initialized) {
            return;
        }

        const showDate = showExpirationDate == true;
        if (showDate) {
            setExpirationDate(expirationDate ?? currentParentCharge?.expiration_date)
        }
        setShowExpirationDate(showDate);
    };

    /**
     * @param {string} expirationDate
     */
    const handleExpirationDateChange = (expirationDate?: string): void => {
        if (!initialized) {
            return;
        }
        setExpirationDate(showExpirationDate ? expirationDate : undefined);
    };

    /**
     * @param {RepackCompany} repackedBy
     */
    const handleRepackedBy = (repackedBy?: string): void => {
        if (!initialized || !repackedBy) {
            return;
        }
        setRepackedBy(repackedBy as RepackCompany);
    };

    /**
     * @param {string} status
     */
    const handleStatusChange = (status?: string): void => {
        if (!initialized || !status) {
            return;
        }
        const castedStatus = status as RepackingStatus;
        setStatus(castedStatus);
    };

    /**
     * @param {string} comments
     */
    const handleCommentsChange = (comments?: string): void => {
        if (!initialized) {
            return;
        }
        setComments(comments ?? '');
    };

    /**
     *
     * @param {number} amount
     * @returns {string}
     */
    const getUnit = (amount: number): string => getProductTypeUnit(currentProductType, amount != 1);

    /**
     *
     * @param {ChargeModel} charge
     * @returns {string}
     */
    const getChargeOptionDisplayName = (charge: ChargeModel): JSX.Element => {
        const expDate = parseExpirationSqlDateTime(charge.expiration_date);
        const batchcode = batchCodeLabel(charge);
        const usable = charge?.stock?.usable_stock ?? 0;

        const unit = currentParentArticle?.is_bulk
                     ?getUnit(usable)
                     :'verpakkingen';

        return <>{ batchcode } [{ expDate }] ({ usable } { unit })</>;
    };


    /**
     *
     * @returns {SelectOption[]}
     */
    const getAvailableArticleOptions = (articleModel?: ArticleModel): SelectOption[] => {
        return (availableArticles.length>0
                ?availableArticles.map((article, i) => ({
                    displayName: getFullArticleName(article),
                    value: article.id,
                    selected: articleModel !== undefined ?articleModel?.id === article.id :i === 0
                }) as SelectOption)
                :[
                    {
                        displayName: 'Geen resultaten beschikbaar',
                        value: undefined,
                        selected: true,
                        disabled: true
                    }
                ]
        );
    };


    /**
     *
     */
    const shape = (): Shape<RepackShape> => ({

        product_id: Yup.number()
            .label('Product')
            .required()
            .controlType('selectSearch')
            .selectSearchConfig({
                initialModel: currentProduct,
                expectsInitialModel: id !== undefined,
                useHook: useProduct,
                onChange: setCurrentProduct,
                searchOptions: {
                    searchCols: [ 'name', 'number' ],
                    limit: 'all',
                    filter: 'archived=0',
                    valueCol: 'id',
                    relations: [ 'productType' ],
                    displayName: model => `${ formatProductNumber(model.number) } - ${ model.name }`
                }
            }),

        parent_article_id: Yup.number()
            .label(`Bron Artikel`)
            .required()
            .controlType('select')
            .handleValueChange(handleParentArticleChange)
            .options(getAvailableArticleOptions(currentParentArticle)),

        parent_charge_id: Yup.number()
            .label('Bron Charge')
            .controlType('select')
            .handleValueChange(handleParentChargeChange)
            .options([
                    ...availableCharges.map((charge, i) => ({
                        displayName: getChargeOptionDisplayName(charge),
                        value: charge.id,
                        selected: currentParentCharge !== undefined ?currentParentCharge?.id === charge.id :(isBackOrder ?false :i === 0)
                    }) as SelectOption),
                    {
                        displayName: 'Backorder',
                        value: -1,
                        selected: isBackOrder
                    }
                ]
            ),

        target_article_id: Yup.number()
            .label('Nieuw Artikel')
            .required()
            .controlType('select')
            .handleValueChange(handleTargetArticleChange)
            .options(getAvailableArticleOptions(currentTargetArticle)),

        amount: Yup.number()
            .label('Verwacht aantal nieuwe artikelen')
            .required()
            .inputType('number')
            .controlType('input')
            .description(usableAmountPerUnit<Infinity ?`max ${ availableTargetAmount } verpakkingen` :'')
            .defaultValue(targetAmount)
            .handleValueChange(handleTargetAmountChange)
            .min(1)
            .max(availableTargetAmount),

        batchcode: Yup.string()
            .label('Nieuwe batchcode')
            .inputType('text')
            .controlType('input')
            .defaultValue(batchCode)
            .handleValueChange(handleBatchCodeChange),

        show_expiration_date: Yup.boolean()
            .label('Target THT invullen')
            .defaultValue(showExpirationDate)
            .handleValueChange(handleShowExpirationDateChange)
            .controlType('checkbox'),

        expiration_date: Yup.string()
            .label('Gewenste THT op verpakking')
            .inputType('date')
            .hidden(!showExpirationDate)
            .defaultValue(expirationDate ?? '')
            .handleValueChange(handleExpirationDateChange)
            .controlType('input'),

        repack_date: Yup.string()
            .label('Gewenste datum dat uitvulopdracht klaar is')
            .required()
            .inputType('date')
            .controlType('input')
            .defaultValue(sqlDateTimeToDate(initialRepackOrder?.repack_date))
            .handleValueChange(handleRepackDateChange),

        repacked_by: Yup.string()
            .label('Uitgevuld door')
            .controlType('select')
            .handleValueChange(handleRepackedBy)
            .options(repackCompanies.map((company, i) => ({
                displayName: company,
                value: company,
                selected: repackedBy !== undefined ?repackedBy === company :i === 0
            }))),

        status: Yup.string()
            .label('Status')
            .hidden(([ 'backorder', 'booked' ] as RepackingStatus[]).includes(status ?? 'backorder') || isBackOrder)
            .inputType('text')
            .controlType('select')
            .handleValueChange(handleStatusChange)
            .options(([ 'pending', 'processing', 'processed' ] as RepackingStatus[]).map((statusItem, i) => ({
                displayName: RepackStatusTranslation(statusItem),
                value: statusItem,
                selected: status !== undefined ?status === statusItem :i === 0
            }))),

        comments: Yup.string()
            .controlType('textArea')
            .handleValueChange(handleCommentsChange)
            .defaultValue(comments)
            .max(256)
            .label('Notitie')
    });


    /**
     * Init shape
     */
    const { validationSchema, setShape } = useSchema<RepackShape>(shape());


    const [ initialRender, setInitialRender ] = useState(id !== undefined);


    useEffect(() => {
        if (!initialized || !currentProduct) {
            return;
        }
        getAvailableArticles().then(setAvailableArticles).finally(() => {

            if (id !== undefined && initialRender) {
                setInitialRender(false);
                return;
            }

            // Initialize values
            setTargetAmount(0);
            setCurrentParentArticle(undefined);
            setCurrentParentCharge(undefined);
            setIsBackOrder(true);
            setCurrentTargetArticle(undefined);

        });
    }, [ currentProduct ]);


    useEffect(() => {
        if (!initialized) {
            return;
        }
        getAvailableCharges().then(setAvailableCharges);
        if (currentParentCharge?.article_id !== currentParentArticle?.id) {
            setCurrentParentCharge(undefined);
            setIsBackOrder(true);
        }
    }, [ currentParentArticle ]);

    useEffect(() => {
        if (!initialized) {
            return;
        }
        if (!currentParentCharge) {
            setIsBackOrder(true);
        }
    }, [ currentParentCharge ]);


    useEffect(() => {
        if (!initialized || (initialRender && targetAmount === 0 && id !== undefined)) {
            return;
        }
        setShape(shape());
    }, [
        availableArticles,
        availableCharges,
        availableAmountPerUnit,
        currentParentCharge,
        currentTargetArticle,
        targetAmount,
        showExpirationDate,
        expirationDate,
        status,
        initialized,
        repackedBy
    ]);


    const initializer = async() => {
        await repacking.getItem(id, {
            with: [
                'parentArticle.product.productType',
                'parentArticle.package.parts',
                'parentCharge.stock',
                'parentCharge.chargeLocations.location.group',
                'targetArticle.package.parts'
            ]
        }).then(setInitialRepackOrder);
    };


    /**
     * Init form config
     */
    const { formikConfig, formFields } = useForm<RepackingModel, RepackShape, RepackingRelationsBluePrint>({
        id,
        validationSchema,
        useHttpHook: useRepackingPivotless,
        initializer,
        initialized: useAsyncInit(initializer, open),
        skipInitialFetch: true,
        onSuccess: onSuccess,
        relations: [
            'parentArticle.package.parts',
            'parentArticle.product.productType',
            'parentCharge.stock',
            'targetArticle.package.parts'
        ],
        morphPayload: (_, dto) => {

            const newDto = { ...dto } as { [k: string]: any };

            delete newDto['product_id'];
            delete newDto['show_expiration_date'];

            if (dto.parent_charge_id === -1) {
                newDto['parent_charge_id'] = null;
            }

            if (id === undefined) {
                newDto['status'] = 'pending';
                if (!showExpirationDate) {
                    delete newDto['expiration_date'];
                }
            } else {
                newDto['expiration_date'] = showExpirationDate ? expirationDate : null;
            }

            if (currentParentCharge === undefined) {
                newDto['status'] = 'backorder';
            }

            if (batchCode === undefined || (batchCode ?? '').replaceAll(' ', '') === '') {
                newDto['batchcode'] = null;
            }

            if (status === 'backorder' && currentParentCharge !== undefined) {
                newDto['status'] = 'pending';
            }

            newDto['amount'] = repackAmount;
            return newDto as RepackShape;
        }
    });


    return <FormModal
        className={ classNames('sm:!w-[72rem] sm:!max-w-none', !initialized && initialRender && '!opacity-0') }
        id={ id }
        resource={ 'Uitvul opdracht' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        onClose={ onClose }
        htmlRightOfForm={ <RepackingReceipt repackOrder={ {
            ...initialRepackOrder,
            parent_article_id: currentParentArticle?.id,
            parent_charge_id: currentParentCharge?.id,
            target_article_id: currentTargetArticle?.id,

            batchcode: batchCode,
            amount: repackAmount,
            expiration_date: showExpirationDate ?expirationDate : undefined,
            repack_date: repackDate,
            comments: comments,

            parentArticle: { ...currentParentArticle, product: currentProduct },
            parentCharge: currentParentCharge,
            targetArticle: currentTargetArticle,
            repacked_by: repackedBy
        } }/> }
    />;
};
export default RepackingForm;