import React, { useCallback, useMemo, useState } from 'react';
import Table from '../../../components/table';
import { TableFilterType } from '../../../components/table/types/TableFilter';
import { TableColumns } from '../../../components/table/types/TableRow';
import { dispatchHttpEvent } from '../../../events/Http.event';
import ArchiveModal from '../../../lib/samfe/components/Modal/ArchiveModal';
import useAsyncMemo from '../../../lib/samfe/hooks/useAsyncMemo';
import { UseHttp, UseHttpHook } from '../../../lib/samfe/modules/Http/useHttp';
import { MinimumModel, SoftDeleteModel } from '../../../lib/samfe/types/ModelTypes';


type ResourceModel = MinimumModel&{ name?: string };
type PivotModel = SoftDeleteModel&{ element_id?: number };

type Props<
    // Resource related
    RModel extends ResourceModel,
    RDto extends object,
    RRelationsBlueprint extends string,
    // Pivot related
    PModel extends PivotModel,
    PDto extends object,
    PRelationBlueprint extends string
> = {
    parentId: number,
    resourceName: string
    useResourceHook: UseHttpHook<RModel, RDto, RRelationsBlueprint>,
    foreignKey: keyof PModel
    usePivotHook: UseHttpHook<PModel, PDto, PRelationBlueprint>,
}

const BaseElementRiskResourceTable = <
    RModel extends ResourceModel, RDto extends object, RRelationsBlueprint extends string,
    PModel extends PivotModel, PDto extends object, PRelationBlueprint extends string
>({
    parentId,
    resourceName,
    useResourceHook,
    foreignKey,
    usePivotHook
}: Props<
    RModel, RDto, RRelationsBlueprint,
    PModel, PDto, PRelationBlueprint
>): JSX.Element => {

    const resourceHook = useResourceHook();
    const pivotHook = usePivotHook(parentId);

    const [ currentResource, setCurrentResource ] = useState<RModel>();
    const [ openArchiveModal, setOpenArchiveModal ] = useState(false);


    const pivots: PModel[] = useAsyncMemo(async() => {
        return await pivotHook.getList({
            limit: 'all'
        } as unknown as Partial<PModel>);
    }, [], []);


    const findPivot = useCallback((item: RModel): PModel|undefined => {
        return pivots.find(piv => (piv[foreignKey] as unknown as number) == item.id && piv.archived != true);
    }, [ pivots, foreignKey ]);


    const withPivot = useCallback((item: RModel, shouldExist: boolean): boolean => {
        const foundItem = findPivot(item);
        if (shouldExist) {
            return foundItem != undefined;
        }
        return foundItem == undefined;
    }, [ findPivot ]);


    const currentPivot: PModel|undefined = useMemo(() => {
        if (!currentResource) {
            setOpenArchiveModal(false);
            return undefined;
        }
        const foundPivot = findPivot(currentResource);
        if (!foundPivot) {
            setCurrentResource(undefined);
            setOpenArchiveModal(false);
        }
        return foundPivot;
    }, [ currentResource, findPivot ]);


    const rows = useMemo((): TableColumns<RModel, RRelationsBlueprint>[] => [
        {
            header: {
                children: 'Naam',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        }
    ], []);


    const filters: TableFilterType<RModel>[] = useMemo(() => {
        if (pivots.length === 0) {
            return [];
        }

        const hasMoreThenOne = pivots.length>1;
        const getValue = (isActive: boolean) => {
            const [ first, ...rest ] = pivots;
            let separator = '';
            if (hasMoreThenOne) {
                separator = isActive ?'|' :',';
            }
            const operator = isActive ?'=' :'!=';
            const idChain = rest.map(er => `id${ operator }${ er[foreignKey] }`).join(separator);
            return `${ first[foreignKey] }${ separator }${ idChain }`;
        };

        return [
            {
                displayName: 'actief',
                column: 'id',
                operator: '=',
                value: getValue(true)
            },
            {
                displayName: 'inactief',
                column: 'id',
                operator: '!=',
                value: getValue(false)
            }
        ] as TableFilterType<RModel>[];
    }, [ pivots, foreignKey ]);


    return <>
        <Table
            id={ resourceName.replaceAll(' ', '-').toLowerCase() }
            rows={ rows }
            http={ {
                hook: resourceHook as UseHttp<RModel, {}, RRelationsBlueprint>,
                searchCols: [ 'name' ],
                sortCol: 'name',
                sortDir: 'ASC',
                filters: filters,
                filterConfig: {
                    hideArchived: true
                },
                disableDeArchiving: true
            } }
            callbacks={ [
                {
                    title: 'Activeren',
                    hideForRow: (item) => withPivot(item, true),
                    onClick: async (item) => pivotHook
                        .create({ element_id: parentId, [foreignKey]: item.id } as PDto)
                        .then(dispatchHttpEvent)
                },
                {
                    title: 'Deactiveren',
                    variation: 'destructive',
                    hideForRow: (item) => withPivot(item, false),
                    onClick: (item) => {
                        setCurrentResource(item);
                        setOpenArchiveModal(true);
                    }
                }
            ] }
            styling={ {
                variation: 'buttons'
            } }
        />

        { openArchiveModal && currentResource && currentPivot && <ArchiveModal
            open={ openArchiveModal }
            setOpen={ setOpenArchiveModal }
            useHttp={ pivotHook }
            resourceName={ resourceName }
            itemName={ currentResource.name }
            id={ currentPivot.id }
            onSuccess={ () => {
                setCurrentResource(undefined);
                dispatchHttpEvent();
            } }
            onCancel={ () => setCurrentResource(undefined) }
        /> }
    </>;
};

export default BaseElementRiskResourceTable;
