import { GroupModel } from '../group/GroupTypes';
import { ChargeLocationModel } from './ChargeLocationTypes';
import { LocationModel } from './LocationTypes';


export const getLocationGroupName = (group?: GroupModel, format: 'name'|'key'|'full' = 'full') => {
    if (!group) {
        return '';
    }
    const name = group?.name ?? '';
    if (format == 'name') {
        return name;
    }

    let key = '';
    if (group?.key && (group?.key ?? '').replaceAll(' ', '') != '') {
        key = group.key;
    }
    if (format == 'key') {
        return key;
    }
    return `${ name } - ${ key }`;
};


export const getLocationName = (location?: LocationModel) => {
    if (!location) {
        return 'Niet beschikbaar';
    }
    return `${ getLocationGroupName(location.group, 'key')} ${ location?.name ?? '' }`;
};


export const getLocationLevel = (chargeLocation?: ChargeLocationModel): string => {
    if (!chargeLocation || !chargeLocation.level) {
        return '-';
    }
    if ((`${ chargeLocation.level }` ?? '').replaceAll(' ', '') == '') {
        return '-';
    }
    return `${ chargeLocation.level }`;
};


export const getFullLocation = (cl?: ChargeLocationModel) => {
    return `${getLocationName(cl?.location)} ${getLocationLevel(cl)}`
}
