import React, { forwardRef } from 'react';

type ComponentType = HTMLTableCellElement
type ComponentProps = JSX.IntrinsicElements['th']

const ThComponent = forwardRef<ComponentType, ComponentProps>(
    (props, ref) => <th ref={ ref } { ...props } />
);

ThComponent.displayName = Object.keys({ ThComponent })[0];
export default ThComponent;
