

// useElementRiskReview

import useHttp from "../../lib/samfe/modules/Http/useHttp";
import {ElementRiskReviewDto, ElementRiskReviewModel, ElementRiskReviewRelationsBluePrint} from "./ElementRiskReviewTypes";


const useElementRiskReview = () => useHttp<ElementRiskReviewModel, ElementRiskReviewDto, ElementRiskReviewRelationsBluePrint>({
    endpoint: 'element-risk-reviews'
});
export default useElementRiskReview;
