import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { ChargeCustomerModel, ChargeCustomerRelationsBluePrint } from './ChargeCustomerTypes';
import useChargeCustomer from './useChargeCustomer';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ChargeCustomerTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useChargeCustomer(parentId);

    const rows = useMemo((): TableColumns<ChargeCustomerModel, ChargeCustomerRelationsBluePrint>[] => [
        {
            header: {
                children: 'Klant',
                sortCol: 'company'
            },
            column: (item) => ({
                children: item.company
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aantal artikelen',
                sortCol: 'quantity'
            },
            column: (item) => ({
                children: item.quantity
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Totale kosten',
                sortCol: 'total_cost'
            },
            column: (item) => ({
                children: item.total_cost
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Totaal besteed',
                sortCol: 'total_spent'
            },
            column: (item) => ({
                children: item.total_spent
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Laatste aankoop op',
                sortCol: 'last_ordered_at'
            },
            column: (item) => ({
                children: item.last_ordered_at
            }),
            type: 'date'
        }
    ], []);

    return <>
        <Table
            id={ 'customers' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/customers/${ item.customer_id }`
            } }
            http={ {
                hook: httpHook,
                sortCol: 'company',
                sortDir: 'ASC',
            } }
        />
    </>;
};

export default ChargeCustomerTable;