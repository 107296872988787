
/**
 * Type of key in given model.
 */
type Identifier = string|number;


/**
 * Used for searching values in parsed Model.
 */
type SearchableItem = {[key:string]: Identifier};


/**
 * Manage CRUD of resource by keeping track of
 * initial, available, current, new, updated and deleted items.
 */
export default class CrudManager<Model> {

    /**
     * Identifier of model.
     * @private
     */
    private readonly identifier: string;

    /**
     * Initial given items.
     * @private
     */
    private initialItems: Model[];

    /**
     * Complete list of all items.
     * @private
     */
    private allItems: Model[];

    /**
     * Available items that can be chosen.
     * @private
     */
    private availableItems: Model[] = [];

    /**
     * All items that are present except deleted.
     * Used for displaying current list in DOM.
     * @private
     */
    private currentItems: Model[] = [];

    /**
     * List of all deleted items, contains only items that were initially present.
     * @private
     */
    private deletedItems: Model[] = [];

    /**
     * List of updated items that were initially present.
     * @private
     */
    private updatedItems: Model[] = [];

    /**
     * List of new items that were not present initially.
     * @private
     */
    private newItems: Model[] = [];


    /**
     * Restore all lists to their initial values.
     * @returns {void}
     */
    public refresh = (): void => {

        // Get all available items
        this.availableItems = this.allItems.filter((item) => !this.hasItem(item, this.initialItems) );

        // set initial as current
        this.currentItems = this.initialItems;

        // empty deleted, updated and new items
        this.deletedItems   = [];
        this.updatedItems   = [];
        this.newItems       = [];
    }


    /**
     * Construct manager.
     *
     * @param initialItems
     * @param allItems
     * @param {Identifier} identifier
     * @returns {void}
     */
    constructor(identifier: string, initialItems: Model[] = [], allItems: Model[] = []) {
        this.identifier = identifier;
        this.initialItems = initialItems;
        this.allItems = allItems;
        this.refresh();
    }


    /**
     * Set initial list and complete list.
     *
     * @param initialItems
     * @param allItems
     * @returns {void}
     */
    public init = (initialItems: Model[], allItems: Model[]): void => {
        this.initialItems = initialItems;
        this.allItems = allItems;
        this.refresh();
    }

    /**
     * Log current state of CrudManager.
     *
     * For debug purposes only.
     *
     * @returns {void}
     */
    public log = (): void => console.info(`Crud manager output`, {
        new: this.getNewItems(),
        deleted: this.getDeletedItems(),
        initial: this.getInitialItems(),
        all: this.getAllItems(),

    })


    /**
     * Getter for initial items.
     */
    public getInitialItems = (): Model[] => this.initialItems;


    /**
     * Getter for initial item.
     * 
     * @param {Identifier} identifier
     */
    public getInitialItem = (identifier: Identifier): Model|undefined => (
        this.findItem(identifier, this.initialItems)
    );


    /**
     * Getter for current items.
     */
    public getCurrentItems = (): Model[] => this.currentItems;

    /**
     * Setter for current items.
     * 
     * @param items
     * @returns {void}
     */
    private setCurrentItems = (items: Model[]): void => {
        this.currentItems = items;
    }

    /**
     * Getter for current item.
     * 
     * @param {Identifier} identifier
     */
    public getCurrentItem = (identifier: Identifier): Model|undefined => (
        this.findItem(identifier, this.currentItems)
    );

    /**
     * Setter for current item.
     * 
     * @param item
     */
    private setCurrentItem = (item: Model): void => {
        this.setCurrentItems(this.addToList(item, this.currentItems));
    }

    /**
     * Remove current item.
     * 
     * @param item
     * @returns {void}
     */
    private removeCurrentItem = (item: Model): void => {
        this.setCurrentItems(this.removeFromList(item, this.currentItems));
    }


    /**
     * Getter for all items.
     */
    public getAllItems = (): Model[] => this.allItems;


    /**
     * Getter for item in all items.
     * 
     * @param {Identifier} identifier
     */
    public getItem = (identifier: Identifier): Model|undefined => (
        this.findItem(identifier, this.allItems)
    );


    /**
     * Getter for available items.
     */
    public getAvailableItems = (): Model[] => this.availableItems;

    /**
     * Setter for available items.
     *
     * @param items
     * @returns {void}
     */
    private setAvailableItems = (items: Model[]): void => {
        this.availableItems = items;
    }

    /**
     * Getter for available item.
     *
     * @param {Identifier} identifier
     */
    public getAvailableItem = (identifier: Identifier): Model|undefined => (
        this.findItem(identifier, this.availableItems)
    );

    /**
     * Getter for available item.
     *
     * @param item
     * @returns {void}
     */
    private setAvailableItem = (item: Model): void => {
        this.setAvailableItems(this.addToList(item, this.availableItems));
    }

    /**
     * Remove available item.
     *
     * @param item
     * @returns {void}
     */
    private removeAvailableItem = (item: Model): void => {
        this.setAvailableItems(this.removeFromList(item, this.availableItems));
    }


    /**
     * Getter for deleted items.
     */
    public getDeletedItems = (): Model[] => this.deletedItems;

    /**
     * Setter for deleted items.
     *
     * @param items
     * @returns {void}
     */
    private setDeletedItems = (items: Model[]): void => {
        this.deletedItems = items;
    }
    
    /**
     * Getter for deleted item.
     *
     * @param {Identifier} identifier
     */
    public getDeletedItem = (identifier: Identifier): Model|undefined => (
        this.findItem(identifier, this.deletedItems)
    );

    /**
     * Setter for deleted item.
     *
     * @param item
     * @returns {void}
     */
    private setDeletedItem = (item: Model): void => {
        this.setDeletedItems(this.addToList(item, this.deletedItems));
    }

    /**
     * Remove deleted item from list.
     *
     * @param item
     * @returns {void}
     */
    private removeDeletedItem = (item: Model): void => {
        this.setDeletedItems(this.removeFromList(item, this.deletedItems));
    }
    

    /**
     * Getter for updated items.
     */
    public getUpdatedItems = (): Model[] => this.updatedItems;

    /**
     * Setter for updated items.
     *
     * @param items
     */
    private setUpdatedItems = (items: Model[]): void => {
        this.updatedItems = items;
    }

    /**
     * Getter for updated item.
     *
     * @param {Identifier} identifier
     */
    public getUpdatedItem = (identifier: Identifier): Model|undefined => (
        this.findItem(identifier, this.updatedItems)
    );

    /**
     * Remove updated item from list.
     *
     * @param item
     * @returns {void}
     */
    private removeUpdatedItem = (item: Model): void => {
        this.setUpdatedItems(this.removeFromList(item, this.updatedItems));
    }


    /**
     * Getter for new items.
     */
    public getNewItems = (): Model[] => this.newItems;

    /**
     * Setter for new items.
     *
     * @param items
     * @returns {void}
     */
    private setNewItems = (items: Model[]): void => {
        this.newItems = items;
    }

    /**
     * Getter for new item.
     *
     * @param {Identifier} identifier
     */
    public getNewItem = (identifier: Identifier): Model|undefined => (
        this.findItem(identifier, this.newItems)
    );

    /**
     * Remove new item from list.
     *
     * @param item
     * @returns {void}
     */
    private removeNewItem = (item: Model): void => {
        this.setNewItems(this.removeFromList(item, this.newItems));
    }


    /**
     * Update item in current list.
     *
     * @param item
     * @returns {void}
     */
    public updateItem = (item: Model): void => {

        // Check if item in delete list, if so remove from list.
        this.removeDeletedItem(item);

        // Remove model from available list.
        this.removeAvailableItem(item);

        // Get index of current item to assign it to updated item after replace.
        const index = this.getIndex(item, this.currentItems);
        this.removeCurrentItem(item);
        this.setCurrentItem(item);
        if (index !== undefined) {
            this.reOrder(this.getIdentifier(item)!, index);
        }

        // If in initial list, add/update in "update list".
        if (this.hasItem(item, this.initialItems)) {
            this.setUpdatedItems(this.hasItem(item, this.updatedItems)
                ? this.updateInList(item, this.updatedItems)
                : this.addToList(item, this.updatedItems)
            );
            return;
        }

        // add/update in "insert list".
        this.setNewItems(this.hasItem(item, this.newItems)
            ? this.updateInList(item, this.newItems)
            : this.addToList(item, this.newItems)
        );
    }

    
    /**
     * Add item to current list.
     *
     * @param {Identifier} identifier
     * @returns {void}
     */
    public addItem = (identifier: Identifier|undefined = undefined): void => {
        const item = identifier ? this.getAvailableItem(identifier) : this.availableItems[0] ?? undefined;
        if (item === undefined) {
            return;
        }
        this.updateItem(item);
    }


    /**
     * Replace item in current list, used for select fields.
     * If initial item is replaced with a new item, initial item will be moved to delete list.
     *
     * @param prevIdentifier
     * @param newIdentifier
     * @returns {void}
     */
    public replaceItem = (prevIdentifier: Identifier, newIdentifier: Identifier): void => {

        const prevItem = this.getCurrentItem(prevIdentifier);
        if (prevItem === undefined) {
            return;
        }

        const newItem = this.getAvailableItem(newIdentifier);
        if (newItem === undefined) {
            return;
        }

        // If prev item in update list
        if (this.hasItem(prevItem, this.updatedItems)) {
            this.removeUpdatedItem(prevItem);
            // Move to delete list if prev item is initial item.
            if (this.hasItem(prevItem, this.initialItems) && !this.hasItem(prevItem, this.deletedItems)) {
                this.setDeletedItem(prevItem);
            }
        }

        // in current list
        // new item should be placed on index of old item
        const index = this.getIndex(prevItem, this.currentItems);
        this.removeCurrentItem(prevItem)
        this.setCurrentItem(newItem);
        this.reOrder(newIdentifier, index ?? this.currentItems.length-1);
    }


    /**
     * Remove item from current list.
     * If item is initial, it will be moved to delete list.
     *
     * @param {Identifier} identifier
     * @returns {void}
     */
    public deleteItem = (identifier: Identifier): void => {

        // Get deleted item.
        const item = this.getCurrentItem(identifier);
        if (item === undefined) {
            return;
        }

        // Add to available list
        this.setAvailableItem(item);

        // Remove from add list
        this.removeNewItem(item)

        // Remove from current list.
        this.removeCurrentItem(item);

        // Remove from delete list if not initial item
        if (!this.hasItem(item, this.initialItems)) {
            this.removeDeletedItem(item);
            return;
        }

        // Add to delete list if not present.
        if (!this.hasItem(item, this.deletedItems)) {
            this.setDeletedItem(item);
        }
    }


    /**
     * Reset item to its initial value.
     * It will keep its index due to UX issues with "jumping in list".
     *
     * @param {Identifier} identifier
     * @returns {void}
     */
    public resetItem = (identifier: Identifier): void => {

        // Get current item, if not present check delete list
        const item = this.getCurrentItem(identifier) ?? this.getDeletedItem(identifier);
        if (item === undefined) {
            return;
        }

        // Get index before resetting item to persist its position.
        const index = this.getIndex(item, this.currentItems);

        // Remove items occurrence in all lists.
        this.removeCurrentItem(item);
        this.removeDeletedItem(item);
        this.removeNewItem(item);
        this.removeUpdatedItem(item);
        this.removeAvailableItem(item);

        // Revert to initial value if applicable.
        const initialItem = this.getInitialItem(identifier);
        if (initialItem !== undefined) {
            this.setCurrentItem(initialItem);
            this.reOrder(identifier, index ?? this.currentItems.length -1)
        }
    }


    /**
     * Reorder item in list.
     *
     * @see https://stackoverflow.com/a/2440723
     *
     * @param {Identifier} identifier
     * @param {number} index
     * @returns {void}
     */
    public reOrder = (identifier: Identifier, index: number): void => {

        // Get deleted item.
        const item = this.getCurrentItem(identifier);
        if (item === undefined) {
            return;
        }

        // Get old index
        const oldIndex = this.getIndex(item, this.currentItems);
        if (oldIndex === undefined) {
            return;
        }

        // Remove old index and insert on new index.
        const newList = [...this.currentItems];
        newList.splice(index, 0, newList.splice(oldIndex, 1)[0]);

        // Update current items.
        this.setCurrentItems(newList);
    }


    /**
     * Check if item is changed compared with its initial values.
     *
     * @param item
     * @param {string[]} properties Model properties that should be tested.
     * @returns {boolean}
     */
    public isChanged = (item: Model, ...properties: string[]): boolean => {

        const identifier = this.getIdentifier(item);
        if (identifier === undefined) {
            return false;
        }

        const initialItem = this.getInitialItem(identifier);
        if (initialItem === undefined) {
            return false;
        }

        // Parse items to compare the values of the given properties.
        const testableItem = item as unknown as SearchableItem;
        const testableInitialItem = initialItem as unknown as SearchableItem;

        let isChanged = false;
        properties.forEach(property => {
            if (testableItem[property] !== testableInitialItem[property]) {
                isChanged = true
            }
        })
        return isChanged;
    }
    

    /**
     * Get identifier value from item.
     *
     * @param item
     * @returns {Identifier|undefined}
     */
    private getIdentifier = (item: Model): Identifier|undefined => (item as unknown as SearchableItem)[this.identifier]


    /**
     * Get index of item in given list.
     *
     * @param item
     * @param list
     * @returns {number|undefined}
     */
    private getIndex = (item: Model, list: Model[]): number|undefined => {
        const identifier = this.getIdentifier(item);
        const index = list.findIndex(model => (model as unknown as SearchableItem)[this.identifier] === identifier);
        return index > -1 ? index : undefined;
    }


    /**
     * Find item in given list.
     *
     * @param {Identifier} identifier
     * @param list
     */
    private findItem = (identifier: Identifier, list: Model[]): Model|undefined => (
        list.find(item => (item as {[key:string]: any})[this.identifier] === identifier)
    );


    /**
     * Check if given list has item.
     *
     * @param item
     * @param list
     * @returns {boolean}
     */
    private hasItem = (item: Model, list: Model[]): boolean => list.find(lItem => {
        const lId = (lItem as { [key: string]: any })[this.identifier]
        const iId = (item as { [key: string]: any })[this.identifier];
        return lId === iId;
    }) !== undefined;


    /**
     * Add item to given list and return new list.
     *
     * @param item
     * @param list
     */
    private addToList = (item: Model, list: Model[]): Model[] => {
        const newList = [...list];

        const identifier = this.getIdentifier(item);
        if (identifier === undefined) {
            return newList;
        }

        if (!this.hasItem(item, newList)) {
            newList.push(item)
        }
        return newList;
    }


    /**
     * Remove item from given list and return new list.
     *
     * @param item
     * @param list
     */
    private removeFromList = (item: Model, list: Model[]): Model[] => {
        const newList = [...list];
        const identifier = this.getIdentifier(item);
        if (identifier === undefined) {
            return list;
        }

        const itemIsPresent = this.hasItem(item, newList);
        const index = this.getIndex(item, list);

        if (itemIsPresent && index !== undefined) {
            newList.splice(index, 1);
        }
        return newList;
    }


    /**
     * Update item in given list and return new list.
     *
     * @param item
     * @param list
     */
    private updateInList = (item: Model, list: Model[]): Model[] => {
        const newList = [...list];
        const identifier = this.getIdentifier(item);
        if (identifier === undefined) {
            return list;
        }

        const itemIsPresent = this.hasItem(item, newList) !== undefined;
        const index = this.getIndex(item, list);

        if (itemIsPresent && index !== undefined) {
            newList[index] = item;
        }
        return newList;
    }

}