import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import BaseElementTable from '../../element/BaseElementTable';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>
const CategoryElementTable: FC<Props> = ({ parentId }): JSX.Element => {
    return <BaseElementTable
        forType={ 'reference' }
        extraFilter={{
            column: 'category_id',
            operator: '=',
            value: parentId
        }}
    />;
};

export default CategoryElementTable;