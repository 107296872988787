import React, { FC } from 'react';
import { AssessmentHistory } from '../../types/AssessmentTypes';
import AssessmentRiskItem, { WithAssessmentTimeStamps } from './AssessmentRiskItem';


type Props = WithAssessmentTimeStamps &{
    items: AssessmentHistory[],
}
const AssessmentRiskItemsColumn: FC<Props> = ({
    items,
    withRequestedAt = false,
    witApprovedAt = false
}) => <ul className={ 'grid grid-cols-1 gap-y-2' }>
    { (items).map(item => <li key={ item.current.assessment.id }>
        <AssessmentRiskItem item={ item } withRequestedAt={withRequestedAt} witApprovedAt={witApprovedAt}/>
    </li>) }
</ul>;

export default AssessmentRiskItemsColumn;