import { useMemo } from 'react';
import { AssessmentHistory } from '../types/AssessmentTypes';


export const assessmentIsOptional = (item: AssessmentHistory) => {
    if (item.current.assessment.status == 'to_test' || item.current.assessment.index <= 1 || item.frequency == 0) {
        return false;
    }

    if (item.previous?.assessment.status == 'approved') {
        return true;
    }
    return item.current.assessment.index > 1;
}

const useAssessmentIsOptional = (item: AssessmentHistory) => {
    return useMemo(() => assessmentIsOptional(item), [ item ]);
};

export default useAssessmentIsOptional;
