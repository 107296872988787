import React, { FC } from 'react';
import { classNames } from '../../../lib/samfe/modules/Parse/String';


type Props = JSX.IntrinsicElements['thead']

const TheadComponent: FC<Props> = ({
    children,
    className,
    ...props
}) => {
    return (
        <thead{ ...props } className={ classNames('sticky z-[1] text-graphite', className) }>
        { children }
        </thead>
    );
};

export default TheadComponent;
