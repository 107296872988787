import React, {FC, useState} from "react";
import GaLogo from "../../assets/images/great-applications-logo.png";
import ResearchPng from "../../assets/images/research.png";
import {ActionButton} from "../../lib/samfe/components/Button";
import {useNavigate} from "react-router-dom";
import useToaster from "../../lib/samfe/components/Toaster/useToaster";
import useLoadingScreen from "../../lib/samfe/components/LoadingScreen/useLoadingScreen";
import useAuth from "../../resources/user/useAuth";


const ForgotPassword: FC = (): JSX.Element => {

    const navigate = useNavigate()

    const {setToasterProps} = useToaster();
    const {setLoadingScreenProps} = useLoadingScreen();
    const {forgotPassword} = useAuth();
    const [email, setEmail] = useState('');
    const [mailSent, setMailSent] = useState(false)


    const submit = () => {

        setLoadingScreenProps({show: true})

        forgotPassword(email)
            .then((isValid) => {
                setToasterProps({
                    show: true,
                    type: isValid ? 'info' : "error",
                    title: isValid ?'Mail verzonden naar het bekende e-mailadres': 'Email niet bekend'
                })
                setMailSent(isValid);
            })
            .finally(() => setLoadingScreenProps({show: false}))
    }

    return <>

        <form onSubmit={(e) => e.preventDefault()} >

            <div className="flex min-h-full flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                className="w-56"
                                src={GaLogo}
                                alt={'Great Applications'}
                            />
                            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Wachtwoord vergeten
                            </h2>
                        </div>

                        <div className="mt-10">
                            <div className="space-y-6">
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        E-mailadres
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            disabled={mailSent}
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aqua sm:text-sm sm:leading-6"
                                            onChange={ e => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>


                                <div>
                                    <button
                                        type="submit"
                                        onClick={(submit)}
                                        disabled={mailSent}
                                        className="flex w-full justify-center rounded-md bg-aqua px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-aqua-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aqua"
                                    >
                                        Nieuw wachtwoord aanvragen
                                    </button>

                                </div>
                                <ActionButton
                                    className={'!w-auto'}
                                    style={'tertiary'}
                                    text={'Terug naar inloggen'}
                                    icon={''}
                                    onClick={() => {
                                        navigate('/login')
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden justify-center w-0 flex-1 lg:flex lg:h-screen">
                    <img
                        className="w-full object-contain"
                        src={ResearchPng}
                        alt="auth"
                    />
                </div>
            </div>
        </form>
    </>
}

export default ForgotPassword;