import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useFileHandler from '../../../resources/document/useFileHandler';
import SaleForm from '../../../resources/sale/SaleForm';
import SaleSentModal from '../../../resources/sale/SaleSentModal';
import { SaleModel, SaleRelationsBluePrint, SaleStatuses, SaleStatusTranslation } from '../../../resources/sale/SaleTypes';
import useSale from '../../../resources/sale/useSale';
import Table from '../index';
import { ExtendTableProps } from '../types/Table';
import { TableColumns } from '../types/TableRow';


type Props = ExtendTableProps & {
    customerId?: number
}


const SaleTableTestComponent: FC<Props> = ({ customerId }) => {

    const httpHook = useSale();
    const { getDocument } = useFileHandler();
    const navigate = useNavigate();

    //
    //const emptySalesLimit = 20;
    //const [ emptySales, setEmptySales ] = useState<SaleModel[]>([]);
    //const [ emptySalesCount, setEmptySalesCount ] = useState(0);
    //
    //useEffect(() => {
    //    httpHook.getList({
    //        limit: emptySalesLimit,
    //        select: [ 'id' ],
    //        order: 'DESC',
    //        doesntHave: [ 'saleRows' ],
    //        filter: 'status!=sent'
    //    }).then(setEmptySales);
    //}, []);
    //
    //useEffect(() => {
    //    setEmptySalesCount(httpHook.pagination.pagination.totalItems);
    //}, [ httpHook.pagination.pagination.totalItems ]);


    const rows = useMemo((): TableColumns<SaleModel, SaleRelationsBluePrint>[] => [
        {
            header: {
                children: 'Pakbonnummer',
                sortCol: 'id'
            },
            column: (item) => ({
                children: item.id,
                linkTo: (item) => `${item.id}`
            }),
            type: 'id',
            style: {
                width: '2rem'
            }
        },
        {
            header: {
                children: 'Klant'
            },
            column: (item) => ({
                children: `${ item.customer?.company } (${ item.customer?.number })`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Besteldatum',
                sortCol: 'ordered_at'
            },
            column: (item) => ({
                children: item.ordered_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Verzenddatum',
                sortCol: 'shipped_at'
            },
            column: (item) => ({
                children: item.shipped_at,
                onClick: () => alert('yeet'),
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Aantal orderregels'
            },
            column: (item) => ({
                children: item.saleRows?.[0]?.status,
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Inkoopprijs'
            },
            column: (item) => ({
                children: item.financialData?.purchase_price
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Verkoopprijs'
            },
            column: (item) => ({
                children: item.financialData?.total
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Winstmarge'
            },
            column: (item) => ({
                children: item.financialData?.profit_margin_percentage
            }),
            type: 'percentage'
        },
        {
            header: {
                children: 'Status'
            },
            column: (item) => ({
                children: SaleStatusTranslation(item.status)
            }),
            type: 'text'
        }
    ], []);

    return (
        <>

            {/*{ emptySales.length>0 && <Disclosure*/ }
            {/*    state={ 'warning' }*/ }
            {/*    title={ `Openstaande orders (laatste ${ emptySalesLimit>emptySalesCount ?emptySalesCount :emptySalesLimit } v/d ${ emptySalesCount } open orders)` }*/ }
            {/*>*/ }
            {/*    { emptySales.map(sale => <Alert*/ }
            {/*        key={ sale.id }*/ }
            {/*        type={ 'warning' }*/ }
            {/*        message={ <>*/ }
            {/*            Order { sale.id } heeft nog geen orderregels,&nbsp;*/ }
            {/*            <Link className={ 'underline' } to={ `/sales/${ sale.id }` }>bekijken</Link>.*/ }
            {/*        </> }*/ }
            {/*    />) }*/ }
            {/*</Disclosure> }*/ }


            <Table
                id={ 'sales-example' }
                rows={ rows }
                http={ {
                    hook: httpHook,
                    searchCols: [ 'id', 'customer.company', 'wcImport.wc_order_id' ],
                    with: [ 'financialData', 'customer', 'wcImport' ],
                    sortCol: 'id',
                    sortDir: 'ASC',
                    withCount: [ 'saleRows' ],
                    filter: customerId != undefined ? {
                        column: 'customer_id',
                        operator: '=',
                        value: customerId
                    }: undefined,
                    filters: SaleStatuses.map(status => (
                        {
                            column: 'status',
                            displayName: SaleStatusTranslation(status),
                            value: status,
                        }
                    )),
                    filterConfig: {
                        hideArchived: false
                    },
                    disableDeArchiving: false
                } }
                styling={ {
                    variation: 'menu'
                } }
                forms={ {
                    createPivot: {
                        Form: SaleForm,
                        onSuccess: (id) => {
                            navigate(`/sales/${id}`)
                        }
                    },
                    edit: {
                        Form: SaleForm,
                        id: (sale) => sale.id,
                        disableForRow: (sale) => sale.status == 'sent'
                    },
                    archive: {
                        id: (t) => t.id,
                        itemName: (t) => `Verkooporder ${ t.id }`,
                        resourceName: () => 'Verkooporder'
                    },
                    actions: [
                        {
                            title: 'Verzenden',
                            id: (t) => t.id,
                            Form: SaleSentModal,
                            disableForRow: (t) => t.status == 'sent'
                        }
                    ]
                } }
                callbacks={[
                    {
                        title: 'Pakbon downloaden',
                        onClick: (item) => {
                            getDocument(item.id, 'packslip');
                        },

                    }
                ]}
            />
        </>
    );
};

export default SaleTableTestComponent;