import useHttp from "../../lib/samfe/modules/Http/useHttp";
import { MinimumModel } from '../../lib/samfe/types/ModelTypes';
import {LocationDto, LocationModel, LocationRelationsBluePrint} from "./LocationTypes";
import {ChargeLocationDto, ChargeLocationModel, ChargeLocationRelationsBluePrint} from "./ChargeLocationTypes";


export const useLocation = () => useHttp<LocationModel, LocationDto, LocationRelationsBluePrint>({
    endpoint: 'locations'
});

export const useChargeLocation = () => useHttp<ChargeLocationModel, ChargeLocationDto, ChargeLocationRelationsBluePrint>({
    endpoint: 'charge-locations'
});


export type ChargeLocationBulkItem = {
    location_id: number,
    level: number|null
}

export type ChargeLocationPivotModel = MinimumModel & {
    locations: ChargeLocationBulkItem[]
}
export const useChargeLocationsPivotBulk = (chargeId?: number) => useHttp<ChargeLocationPivotModel, Partial<ChargeLocationPivotModel>, ''>({
    endpoint: `charges/${chargeId}/locations`
});


export type ChargeLocationPivotMoveModel = MinimumModel & {
    current_charge_location_id: number,
    new_location_id: number,
    new_level: number|null
}

export const useChargeLocationPivotMove = (chargeId?: number) => useHttp<ChargeLocationPivotMoveModel, Partial<ChargeLocationPivotMoveModel>, ''>({
    endpoint: `charges/${chargeId}/move-location`
});
