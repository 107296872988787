import React, { ComponentType, FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExtendTableProps, TableNotification } from '../../components/table/types/Table';
import { dispatchHttpEvent } from '../../events/Http.event';
import { CreateButton } from '../../lib/samfe/components/Button';
import { UseFormComponent } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import { PageProvider } from '../../providers/PageProvider';
import BasePage, { ExtendBasePageProps } from './BasePage';

type TableType = ComponentType<Partial<ExtendTableProps>>|ComponentType<Required<ExtendTableProps>>
type Props = ExtendBasePageProps&{
    Table: TableType
    parentId?: number,
    notification?: TableNotification
    FormModal?: UseFormComponent<any>
    actionButton?: JSX.Element,
    preventRedirect?: boolean
}

const IndexPage: FC<Props> = ({
    title,
    Table,
    parentId,
    notification,
    FormModal,
    actionButton,
    preventRedirect = false
}): JSX.Element => {

    const [ showCreateModal, setShowCreateModal ] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    const handleCreated = (id?: number) => {
        if (!preventRedirect) {
            navigate(`${ location.pathname }/${ id ?? '' }`);
            return;
        }
        dispatchHttpEvent();
    };

    return (
        <PageProvider type={ 'index' }>
            <BasePage
                isIndex={ true }
                title={ title }
                breadcrumb={ typeof title === 'string' ?title :undefined }
                actionSection={ [
                    FormModal && <CreateButton key={ 0 } className={ '!w-16 text-sm !h-8 !p-0' } onClick={ () => setShowCreateModal(true) }/>,
                    actionButton
                ] }
            >
                {/* @ts-ignore Developer should be responsible for providing resources associated with the table */}
                <Table parentId={parentId} notification={notification}/>
                { FormModal && showCreateModal ?<FormModal open={ showCreateModal } setOpen={ setShowCreateModal } onSuccess={ handleCreated }/> :undefined }
            </BasePage>
        </PageProvider>
    );
};
export default IndexPage;