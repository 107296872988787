// @fixme might break
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React, {ChangeEvent, ForwardedRef, useEffect, useState} from "react";
import Label from "./Label";
import ErrorMessage from "./ErrorMessage";
import {FieldProps} from "./InputTypes";
import {classNames} from "../../../lib/samfe/modules/Parse/String";


/**
 *
 * @deprecated
 */
export type InputProps = FieldProps & {
    type: "hidden"|"text"|"number"|"email"|"password"|"date"|"tel"
    step?: number,
    min?: number,
    max?: number
    className?: string
    placeholder?: string,
    noWrap?: boolean
}
/**
 * @deprecated
 */
export type InputRef = HTMLInputElement & InputProps

/**
 * @deprecated
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<InputProps> & React.RefAttributes<InputRef>>}
 */
const Input = React.forwardRef<InputRef, InputProps>((props: InputProps, ref: ForwardedRef<InputRef>): JSX.Element => {

    const [value, setValue] = useState(props.value !== undefined ? props.value : '');
    const [hasError, setHasError] = useState(props.invalid === true);
    const [errorMsg, setErrorMsg] = useState('');

    const defaultClasses = 'shadow-sm focus:ring-sky focus:border-aqua block w-full px-2 py-1 sm:text-md border rounded-md';
    const validationClasses = hasError ? 'border-red-600' :'border-gray-300';


    /**
     *
     * @param isError
     */
    const toggleError = (isError: boolean): void => {
        setHasError(isError)
        setErrorMsg( isError ?'Veld is verplicht!' : '');
    }


    /**
     *
     * @param value
     */
    const validate = (value: string): void => toggleError(props.required === true && value === '')


    /**
     *
     * @param e
     */
    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        validate(value);
        setValue(value);

        if (props.onChange !== undefined) {
            props.onChange(props.name, value);
        }
    }


    /**
     * @todo check if commented out code breaks in different component usages.
     */
    useEffect((): void => {
        // let newVal = (value ?? '').toString()
        // if (newVal !== value) {
            setValue((props.value ?? '').toString());
        // }
        //
        // if (props.onChange !== undefined) {
        //     props.onChange(props.name, newVal);
        // }

    }, [props.value]);


    /**
     *
     */
    useEffect((): void => {
        if (props.invalid === true) {
            if (props.type === 'number' && value === 0) {
                toggleError(false);
                return;
            }
            toggleError(true);
            return;
        }
        toggleError(false);
    }, [props.invalid]);


    /**
     *
     */
    return (
        <div className={classNames(`${props.noWrap !== true ? 'py-1 mt-1' : ''}text-left ${props.disabled === true ? 'opacity-50 text-gray-400 hover:cursor-not-allowed' : ''}`,
                props.wrapperClassName
            )}>
            { props.label && <Label text={ props.label } refId={ props.id } disabled={ props.disabled }/> }
            <input
                className={classNames(
                    defaultClasses,
                    validationClasses,
                    props.disabled === true ? 'hover:cursor-not-allowed' : '',
                    props.className ?? ''
                )}
                step={props.step ?? ''}
                type={props.type}
                name={props.name}
                id={props.id}
                value={value}
                aria-required={props.required}
                disabled={props.disabled}
                onChange={onChange}
                aria-invalid={"false"}
                ref={ref}
                min={props.min}
                max={props.max}
                placeholder={props.placeholder}
                // aria-valuemin={props.min}
                // aria-valuemax={props.max}
            />
            <ErrorMessage message={errorMsg} />
        </div>
    );
})

export default Input;