import { useMemo } from 'react';
import { useTableContext } from '../providers/Table.provider';
import { TableArchiveFormProps, TableFormActionProps, TableFormProps } from '../types/TableForm';
import useTableDeArchive from './useTableDearchive';


const canPerformAction = <T extends object, K extends keyof T, V = T[K]>(
    tableFormAction?: TableArchiveFormProps<T, K, V>|TableFormProps<T, K, V>,
    idRequired: boolean = true
) => {
    if (!tableFormAction || tableFormAction?.hide == true) {
        return false;
    }

    if (idRequired && !tableFormAction.id) {
        return false
    }

    if ('Form' in tableFormAction) {
        return tableFormAction.Form != undefined;
    }
    return true;
};


const useTableForms = <T extends object, K extends keyof T, R extends string, V = T[K]>() => {


    const { forms, http } = useTableContext<T, R>();
    const {hasArchivedResources} = useTableDeArchive<T, K, R, V>()

    /**
     *
     */
    const editFormConfig = useMemo(() => {
        if (hasArchivedResources) {
            return undefined
        }
        if (!forms?.edit || !canPerformAction<T, K, V>(forms.edit as TableFormProps<T, K, V>)) {
            return undefined;
        }

        return {
            title: 'Aanpassen',
            variation: 'primary',
            ...forms?.edit
        } as TableFormActionProps<T, K, V>;
    }, [ forms?.edit, hasArchivedResources ]);


    /**
     *
     */
    const archiveFormConfig = useMemo(() => {
        if (hasArchivedResources || http.filterConfig?.hideArchived == true) {
            return undefined
        }
        if (!forms?.archive || !canPerformAction<T, K, V>(forms.archive as TableArchiveFormProps<T, K, V>)) {
            return undefined;
        }
        return forms?.archive as TableArchiveFormProps<T, K, V>;
    }, [ forms?.archive, hasArchivedResources, http.filterConfig?.hideArchived ]);


    /**
     *
     */
    const actionFormConfigs = useMemo(() => {
        if (hasArchivedResources) {
            return []
        }
        if (!forms?.actions || forms.actions.length == 0) {
            return [];
        }

        return forms.actions.filter(action => canPerformAction(action, false)) as TableFormActionProps<T, K, V>[];
    }, [ forms?.actions, hasArchivedResources ]);


    const hasTableForms = useMemo(() => {
        if (hasArchivedResources) {
            return false
        }
        return editFormConfig != undefined || archiveFormConfig != undefined || actionFormConfigs.length>0;
    }, [ editFormConfig, archiveFormConfig, actionFormConfigs.length, hasArchivedResources ]);


    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    /**
     * Table form configs excluding archive.
     */
    const formConfigs = useMemo(() => {
        const actions: TableFormActionProps<T, K, V>[] = [];
        if (editFormConfig) {
            actions.push(editFormConfig);
        }
        return [ ...actions, ...actionFormConfigs ];
    }, [ hasTableForms ]);


    return {
        hasTableForms,
        formConfigs,
        archiveFormConfig
    };
};

export default useTableForms;
