import { useEffect, useMemo, useState } from 'react';
import { SoftDeleteModel } from '../../../lib/samfe/types/ModelTypes';
import { useTableContext } from '../providers/Table.provider';
import { DefaultTableFilter, TableFilterConfig, TableFilterType } from '../types/TableFilter';
import useTablePagination from './useTablePagination';


export const getTableFilterValue = <T, >(filter: TableFilterType<T>|DefaultTableFilter<T>): string => {
    return `${ filter.column as string }${ filter.operator ?? '=' }${ filter.value }`;
};


const useTableFilter = <T extends SoftDeleteModel, R extends string>() => {


    const ctx = useTableContext<T, R>();
    const [ pagination, setPagination ] = useTablePagination<T, R>();
    const [ currentFilter, setCurrentFilter ] = useState<TableFilterType<T>>();


    const filterConfig: TableFilterConfig = useMemo(() => ({
        hideAllFilters: false,
        hideArchived: false,
        hideShowAll: false,
        ...ctx.http.filterConfig
    }), [ ctx.http.filterConfig ]);


    const filters: TableFilterType<T>[] = useMemo(() => ctx.http.filters ?? [], [ ctx.http.filters ]);
    const defaultFilter: DefaultTableFilter<T>|undefined = useMemo(() => ctx.http.filter, [ ctx.http.filter ]);


    const defaultFilterValue: string|undefined = useMemo(() => (
        defaultFilter !== undefined ?getTableFilterValue(defaultFilter) :undefined
    ), [ defaultFilter ]);


    const currentFilterValue: string|undefined = useMemo(() => (
        currentFilter !== undefined ?getTableFilterValue(currentFilter) :undefined
    ), [ currentFilter ]);


    const fullFilterValue: string|undefined = useMemo(() => {
        if(!defaultFilterValue && !currentFilterValue) {
            return undefined
        }

        if (!defaultFilterValue) {
            return currentFilterValue;
        }

        if (!currentFilterValue) {
            return defaultFilterValue
        }

        return `${ defaultFilterValue },${ currentFilterValue }`
    }, [ defaultFilterValue, currentFilterValue ]);


    const showAllFilter: TableFilterType<T> = useMemo(() => ({
        displayName: 'Alles weergeven',
        column: 'archived',
        operator: '=',
        value: false
    }), []);

    const archivedFilter: TableFilterType<T> = useMemo(() => ({
        displayName: 'Gearchiveerd',
        column: 'archived',
        operator: '=',
        value: true
    }), []);


    const allFilters = useMemo(() => {
        const allFilters: TableFilterType<T>[] = [];
        if (filterConfig.hideAllFilters) {
            return allFilters;
        }

        if (!filterConfig.hideShowAll) {
            allFilters.push(showAllFilter);
        }
        allFilters.push(...filters);

        if (!filterConfig.hideArchived) {
            allFilters.push(archivedFilter);
        }
        return allFilters;
    }, [
        filterConfig.hideShowAll,
        filterConfig.hideArchived,
        filters,
        showAllFilter,
        archivedFilter
    ]);

    const allFilterValues: string[] = useMemo(() => {
        return allFilters.map(getTableFilterValue);
    }, [ allFilters ]);

    const [ isInitialized, setIsInitialized ] = useState(false);
    useEffect(() => {
        if (!pagination?.filter || isInitialized) {
            return;
        }
        setIsInitialized(true)


        let filterVal = pagination.filter;
        if (defaultFilterValue != undefined) {
            const parts = filterVal.split(',', 2);
            if (parts.length>1) {
                filterVal = parts[1];
            }
        }
        const filterIndex = allFilterValues.findIndex(val => val == filterVal);
        if (filterIndex> -1) {
            setCurrentFilter(allFilters[filterIndex]);
            return;
        }
        if (allFilters.length < 1) {
            setCurrentFilter(undefined);
            return;
        }
        let defaultSelected = allFilters.find(item => item.default === true);
        if (!defaultSelected) {
            defaultSelected = allFilters[0];
        }

        setCurrentFilter(defaultSelected);
    }, [ pagination?.filter ]);


    const [ calledByUser, setCalledByUser ] = useState(false);
    const handleCurrentFilterChange = (filter: TableFilterType<T>) => {
        setCalledByUser(true)
        setCurrentFilter(filter)
    }

    useEffect(() => {
        if (!calledByUser) {
            return;
        }

        setPagination({
            ...pagination,
            filter: fullFilterValue,
            currentPage: 0
        });
        setCalledByUser(false)
    }, [fullFilterValue]);

    return {
        filters: allFilters,
        filterValues: allFilterValues,
        currentFilter,
        setCurrentFilter: handleCurrentFilterChange,
        currentFilterValue
    };

};

export default useTableFilter;
