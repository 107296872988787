import React, { useEffect, useState } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import { getWeekNumber, jsDateToSqlDate, sqlDateToJsDate } from '../../lib/samfe/modules/Parse/Date';
import ProducerForm from '../producer/ProducerForm';
import { ProducerModel } from '../producer/ProducerTypes';
import useProducer from '../producer/useProducer';
import { PurchaseDto, PurchaseModel, PurchaseRelationsBluePrint } from './PurchaseTypes';
import usePurchase from './usePurchase';


const PurchaseForm: React.FC<ExtendFormModalProps<PurchaseDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    const purchase = usePurchase();

    const [ currentProducer, setCurrentProducer ] = useState<ProducerModel>();
    const [ initialWeekExpected, setInitialWeekExpected ] = useState(getWeekNumber());
    const [ initialPurchase, setInitialPurchase ] = useState<PurchaseModel>();
    const [ currentComment, setCurrentComment ] = useState<string>();

    const shape = (
        initialPurchase: PurchaseModel|undefined,
        initialWeekExpected: number
    ): Shape<PurchaseDto> => ({
        producer_id: Yup.number()
            .label('Producent')
            .required()
            .hidden(id !== undefined)
            .controlType('selectSearch')
            .selectSearchConfig({
                expectsInitialModel: false,
                onChange: setCurrentProducer,
                useHook: useProducer,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    filter: 'archived=0', // @todo monkey patch
                    limit: 'all',
                    displayName: model => `${ model.name }`,
                    FormModal: ProducerForm
                }
            }),

        status: Yup.string()
            .label('Status')
            .hidden(true)
            .required()
            .controlType('input')
            .defaultValue(initialPurchase?.status ?? 'open'),

        week_expected: Yup.number()
            .inputType('number')
            .controlType('input')
            .label(`Week verwacht (we zitten nu in week ${getWeekNumber()})`)
            .defaultValue(initialWeekExpected)
            .min(1)
            .max(52)
            .required(),

        order_date: Yup.string()
            .required()
            .hidden(true)
            .controlType('input')
            .inputType('date')
            .defaultValue(initialPurchase?.order_date ?? jsDateToSqlDate())
            .label('Verwachte besteldatum'),

        comments: Yup.string()
            .controlType('textArea')
            .handleValueChange(setCurrentComment)
            .label('Notitie')
    });


    const { validationSchema, setShape } = useSchema<PurchaseDto>(shape(undefined, initialWeekExpected));


    // @todo if week ordered in year a and expected week in year b -> calc diff, current week now as fallback
    useEffect(() => {
        if (!currentProducer) {
            return;
        }

        let filter = `producer_id=${ currentProducer.id }`;
        if (id) {
            filter += `,purchase_id!=${ id }`;
        }

        purchase.getList({
            filter,
            limit: 1,
            order: 'DESC',
            orderBy: 'id'
        }).then(purchases => {
            const currentWeek = getWeekNumber();

            if (purchases.length === 0) {
                setInitialWeekExpected(currentWeek);
                return;
            }
            const prevPurchase = purchases[0];
            const prevWeekOrdered = getWeekNumber(sqlDateToJsDate(prevPurchase.order_date));
            const prevWeekExpectedParsed = parseInt(`${ prevPurchase?.week_expected }`);
            const prevWeekExpected = !isNaN(prevWeekExpectedParsed) ?prevWeekExpectedParsed :1;

            const deliveryWeeks = (prevWeekExpected - prevWeekOrdered);
            const weekOfDelivery = currentWeek + deliveryWeeks;
            const initialWeekExpected = weekOfDelivery<0 ?currentWeek :weekOfDelivery;

            setInitialWeekExpected(initialWeekExpected);
            setShape(shape(initialPurchase, initialWeekExpected));

        });
    }, [ currentProducer ]);

    
    const initializer = async() => {


        const initialModel = await purchase.getItem(id, { with: [ 'producer' ] });
        setInitialPurchase(initialModel);
        setCurrentComment(initialPurchase?.comments)
        setShape(shape(initialModel, initialWeekExpected));
    };

    const { formikConfig, formFields } = useForm<PurchaseModel, PurchaseDto, PurchaseRelationsBluePrint>({
        id: id,
        validationSchema: validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: usePurchase,
        onSuccess,
        morphPayload: (formikValues, dto) => {

            dto.week_expected = `${ formikValues.week_expected }`;

            if (id !== undefined && (!currentComment || `${currentComment ?? ''}`.replaceAll(' ', '') == '')) {
                //@ts-ignore
                dto.comments = null
            }

            return dto;
        }
    });

    return <FormModal
        id={ id }
        resource={ 'Inkooporder' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default PurchaseForm;