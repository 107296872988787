import React, { FC, ReactNode } from 'react';
import { classNames } from '../../../lib/samfe/modules/Parse/String';


type Props = {
    active?: boolean,
    onClick: () => void|Promise<void>,
    children: ReactNode
}

const PaginationPageButtonComponent: FC<Props> = ({
    active = false,
    onClick,
    children
}) => {
    return (
        <button
            onClick={ onClick }
            className={ classNames('rounded-md text-xs w-8 h-8',
                active
                ?'bg-aqua text-milk font-bold cursor-default'
                :'bg-milk text-graphite font-medium hover:shadow-md active:shadow-none hover:text-aqua'
            ) }
        >
            { children }
        </button>
    );
};

export default PaginationPageButtonComponent;
