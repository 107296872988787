import React, {PropsWithChildren} from "react";
import classNames from "classnames";


type Props = PropsWithChildren<any> & JSX.IntrinsicElements['label'] & {
    invalid?: boolean
}

const Label: React.FC<Props> = ({
    children,
    className,
    invalid,
    ...props
}): JSX.Element => {

    // @feature TPGA-1471
    // @feature TPGA-1472
    return <label {...props} className={classNames(props.hidden? 'hidden': 'block', "text-sm font-medium leading-6", invalid === true ? 'text-red-700 font-semibold':'text-gray-900 font-medium', className)}>
        {children}
    </label>
}
export default Label
