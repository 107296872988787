import { useState } from 'react';
import useHttp from "../../../../lib/samfe/modules/Http/useHttp";
import { AttributeType } from '../../../attribute/AttributeTypes';
import {AttributeProductDto, AttributeProductModel, AttributeProductRelationsBluePrint} from "./AttributeProductTypes";

export type ParsedAttributeProductItem = {
    id: number // placeholder, do not use!
    attribute_id: number,
    attribute_name: string,
    attribute_order: number,
    attribute_type: AttributeType,
    product_type_name: string,
    attribute_value: string|null,
    attribute_option_names: string[]|null,
    order: number
};

export const useParsedAttributeProductIndex = (id?: number) => useHttp<ParsedAttributeProductItem, Partial<ParsedAttributeProductItem>, ''>({
    endpoint: `products/${id}/parsed/attributes`
});


const useAttributeProduct = (initialProductId?: number) => {
    const [ productId, setProductId ] = useState(initialProductId);
    const http = useHttp<AttributeProductModel, AttributeProductDto, AttributeProductRelationsBluePrint>({
        endpoint: `products/${productId}/attributes`
    });

    return{
        ...http,
        productId,
        setProductId
    }

}
export default useAttributeProduct;
