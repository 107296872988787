import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { classNames } from '../../../lib/samfe/modules/Parse/String';
import ProductTable from '../../product/ProductTable';
import { AttributeType } from '../AttributeTypes';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const AttributeProductTable: FC<Props> = ({ parentId }): JSX.Element => {

    return <ProductTable
        withRelations={ [ 'attributeProducts', 'attributeProducts.option', 'attributeProducts.attribute' ] }
        hideTypeColumn={ true }
        hideEdit={ true }
        hideArchive={ true }
        filter={ {
            column: 'attributeProducts.attribute_id',
            operator: '=',
            value: parentId
        } }
        extraColumns={ [
            {
                header: {
                    children: 'Waarde'
                },
                column: (item) => {

                    const attributeType = item.attributeProducts?.[0]?.attribute?.type ?? 'text';

                    if (([ 'select', 'multiselect' ] as AttributeType[]).includes(attributeType)) {
                        return {
                            children: <ul className={ classNames(attributeType == 'multiselect' && 'list-disc list-inside') }>
                                { item.attributeProducts?.map(ap => <li key={ ap.id }>
                                    { ap.option?.name }
                                </li>) }
                            </ul>
                        };
                    }

                    return {
                        children: <ul>
                            { item.attributeProducts?.map(ap => <li key={ ap.id }>
                                { ap.attribute_value }
                            </li>) }
                        </ul>
                    };
                },
                type: 'element'
            }
        ] }
    />;
};

export default AttributeProductTable;