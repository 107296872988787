import React, { FC } from 'react';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import InfoModal from '../../lib/samfe/components/Modal/InfoModal';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import { SaleDto } from './SaleTypes';
import useSale from './useSale';


const SaleSentModal: FC<ExtendFormModalProps<SaleDto>> = ({ open, setOpen, onClose, id }): JSX.Element => {

    const saleHook = useSale();

    const sale = useAsyncMemo(async() => {
        return await saleHook.getItem(id, {
            with: [ 'customer' ]
        });
    }, [ id ]);

    return (
        <>
            { sale && <InfoModal
                open={ open }
                setOpen={ setOpen }
                title={ `Verkooporder ${ sale.id } verzenden?` }
                text={ <>
                    Verkooporder wordt gemarkeerd als verzonden voor klant: <br/>
                    <strong className={ 'pt-2 block' }>{ sale.customer?.company }</strong>
                </> }
                confirmCallback={ async() => {
                    saleHook.markAsSent(sale?.id!).then(() => onClose?.());
                } }
            /> }
        </>
    );
};

export default SaleSentModal;