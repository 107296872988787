import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { classNames } from '../../../../modules/Parse/String';
import Skeleton from '../../../Skeleton/Skeleton';
import ErrorMessage from '../../Caption/ErrorMessage';
import Label from '../../Caption/Label';
import useChecked from '../../Effects/useChecked';
import useFormikField from '../../Effects/useFormikField';
import { requiredFieldClassname } from '../../Support/FieldSupport';


type Props = Omit<JSX.IntrinsicElements['input'], 'name'|'type'|'value'|'defaultValue'>&{
    label: string,
    description?: string
    name: string;
    defaultValue?: boolean
    handleValueChange?: (value: string|boolean|number) => void,

}

const Checkbox: React.FC<Props> = ({
    label,
    name,
    description,
    required,
    disabled,
    defaultValue,
    handleValueChange,
    ...props
}): JSX.Element => {

    const isChecked = (b: boolean|undefined|null|1|0) => b == true || b == 1;

    const { field, meta, invalid } = useFormikField(name);
    const [ checked, setChecked ] = useChecked(name, isChecked(field.value));
    const formikCtx = useFormikContext();
    useEffect(() => {
        formikCtx?.setFieldValue(name, isChecked(checked));
    }, [ checked ]);


    return checked !== undefined ?<>
        <div className="mt-2">
            <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                    <Field
                        { ...props }
                        { ...field }
                        data-testid={ field.name }
                        required={ required }
                        value={ isChecked(field.value) }
                        onClick={ () => setChecked(!checked) }
                        onChange={ () => handleValueChange?.(!checked) }
                        checked={ isChecked(field.value) }
                        id={ name }
                        disabled={ disabled }
                        type="checkbox"
                        className={ classNames(
                            'h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600',
                            disabled && 'hover:cursor-not-allowed opacity-60',
                            required && requiredFieldClassname
                        ) }
                    />
                </div>

                <div className="ml-3 text-sm leading-6">
                    <Label invalid={ invalid } htmlFor={ name }>
                        { label }{ required && '*' }
                    </Label>
                    { description && <span className="text-gray-500 font-normal">
                        &nbsp;<span className="sr-only">{ label }</span>&nbsp;{ description }
                    </span> }
                </div>

            </div>

            <ErrorMessage meta={ meta }/>

        </div>
    </> :<Skeleton type={ 'checkbox' }/>;
};
export default Checkbox;
