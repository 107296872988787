import React from "react";
import {AttributeOptionDto, AttributeOptionModel, AttributeOptionRelationsBluePrint} from "./AttributeOptionTypes";
import useHttp from "../../../lib/samfe/modules/Http/useHttp";



const useAttributeOption = (attributeId?: number) => useHttp<AttributeOptionModel, AttributeOptionDto, AttributeOptionRelationsBluePrint>({
    endpoint: `attributes/${attributeId}/options`
});
export default useAttributeOption;
