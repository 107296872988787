import useAxios from '../../lib/samfe/modules/Http/useAxios';
import useHttp from "../../lib/samfe/modules/Http/useHttp";
import { CompanyInfoModel, GroupContactDto, GroupDto, GroupModel, GroupRelationsBluePrint } from './GroupTypes';

const useGroup = () => {

    const axios = useAxios();

    const groupHttp = useHttp<GroupModel, GroupDto, GroupRelationsBluePrint>({
        endpoint: 'groups'
    });

    const getCompanyInfoByUserGroup = async () => {
        return await axios.get<CompanyInfoModel>(`users/group`, {})
            .then(r => r.data)
            .catch(() => undefined)
    }

    return {
        ...groupHttp,
        getCompanyInfoByUserGroup
    }
}


export const useGroupContact = () => {

    const groupContactHttp = useHttp<GroupModel, GroupContactDto, GroupRelationsBluePrint>({
        endpoint: 'group-contacts'
    });

    return {
        ...groupContactHttp,
    }
}

export default useGroup;