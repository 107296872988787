export type MinimumModel = {
    id?: number,
    created_at?: string,
    updated_at?:string
}

export type SoftDeleteModel = MinimumModel & {
    archived?: boolean|1|0
}

export const modelNotArchived = (archived?: boolean|1|0) => ![true, 1, undefined].includes(archived)