import React, { FC, useState } from 'react';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import ShowPage from '../../templates/pages/ShowPage';
import StandardProducerTable from './pivot/StandardProducerTable';
import StandardForm from './StandardForm';
import { StandardModel } from './StandardTypes';
import useStandard from './useStandard';


const StandardShow: FC = (): JSX.Element => {

    const [ currentStandard, setCurrentStandard ] = useState<StandardModel|undefined>(undefined);

    return (
        <ShowPage
            title={ currentStandard?.name! }
            breadcrumb={ currentStandard?.name! }
            setCurrentModel={ setCurrentStandard }
            FormModal={ StandardForm }
            httpHook={ useStandard }
            tabs={ currentStandard ?[
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <ExampleGraph/>
                    </>
                },
                {
                    name: 'Producenten',
                    id: 'producers',
                    element: <>{ currentStandard.id  && <StandardProducerTable parentId={ currentStandard.id }/> }</>
                }
            ] :[] }
        />
    );
};
export default StandardShow;