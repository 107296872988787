import React, {FC, useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import useToaster from "../../lib/samfe/components/Toaster/useToaster";
import useLoadingScreen from "../../lib/samfe/components/LoadingScreen/useLoadingScreen";
import useAuth from "../../resources/user/useAuth";


const CancelToken: FC = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const {setToasterProps} = useToaster();
    const {setLoadingScreenProps} = useLoadingScreen();

    const {invalidateResetPasswordToken} = useAuth()

    useEffect(() => {
        const token = searchParams.get('token')
        if (!token) {
            navigate('/login')
            return;
        }
        setLoadingScreenProps({show: true})

        invalidateResetPasswordToken(token)
            .then((isReset) => {
                setToasterProps({
                    show: true,
                    type: isReset ?'success': 'error',
                    title: isReset ? 'Token succesvol geannuleerd' : 'Token invalideren mislukt'
                })
            })
            .finally(function (){
                setLoadingScreenProps({
                    show: false
                })
                navigate('/login')
            })

    }, []);

    return <></>

}

export default CancelToken;