import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import ExcipientForm from './ExcipientForm';
import { ExcipientModel, ExcipientRelationsBluePrint } from './ExcipientTypes';
import useExcipient from './useExcipient';


type Props = Required<ExtendTableProps>

const ExcipientTable: FC<Props> = () => {

    const httpHook = useExcipient();

    const rows = useMemo((): TableColumns<ExcipientModel, ExcipientRelationsBluePrint>[] => [
        {
            header: {
                children: 'Naam',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aantal gekoppelde grondstoffen',
                sortCount: 'elements'
            },
            column: (item) => ({
                children: item.elements_count
            }),
            type: 'numeric'
        }
    ], []);

    return (
        <Table
            id={ 'excipients' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `${ item.id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: [ 'name' ],
                sortCol: 'name',
                sortDir: 'ASC',
                withCount: [ 'elements' ]
            } }
            forms={ {
                edit: {
                    Form: ExcipientForm,
                    id: (record) => record.id
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => record.name,
                    resourceName: () => 'Hulpstof functies'
                }
            } }
        />
    );
};

export default ExcipientTable; 