import {PackageModel} from "../package/PackageTypes";
import {SoftDeleteModel} from "../../lib/samfe/types/ModelTypes";
import {WithCount} from "../generic/ModelTypeExtensions";
import { PackagePartPivotModel } from '../package/pivot/packagePartPivot/PackagePartPivotTypes';
import {TableFilterType} from "../../components/table/types/TableFilter";


export type PackagePartType =
    'Accessoire'
    |'Afsluiting'
    |'Bulkverpakking'
    |'Diverse'
    |'Klant'
    |'Verpakking'
    |'Label'

export const PackagePartTypes: PackagePartType[] = [
    'Accessoire', // Accessoires
    'Afsluiting', // Deksel
    'Bulkverpakking',
    'Diverse',
    'Klant',
    'Verpakking', // Pot
    'Label',
]


export type PackagePartFields = {
    name: string,
    short_name: string,
    type: PackagePartType,
    // order: number,
    // laravel_through_key: number
}

export type PackagePartDto = Partial<PackagePartFields>

export type PackagePartRelations = {
    packages?: PackageModel[],
    packageParts?: PackagePartPivotModel[],
}

export type PackagePartModel = SoftDeleteModel & Partial<PackagePartRelations> & WithCount<Partial<PackagePartRelations>> & PackagePartDto

export type PackagePartRelationsBluePrint = (
    'packages'
    |'packages.articles'
    |'packages.articles.product'
    |'packages.articles.package'
    |'packages.parts'
    |'packageParts'
)

export const PackagePartTypeFilter: TableFilterType<PackagePartModel>[] = PackagePartTypes.map((item) => ({
    displayName: item,
    default: false,
    column: 'type',
    value: item,
}))
