// @fixme might break
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */

import React, {ForwardedRef, Fragment, useEffect, useState} from "react";
import { Listbox, Transition} from "@headlessui/react";
import {defaultOption, FieldProps, Option} from "./InputTypes";
import ErrorMessage from "./ErrorMessage";


type MultiSelectValue = string|number;

type MultiSelectProps = Omit<FieldProps, 'value'|'onChange'> & {
    value: MultiSelectValue[],
    onChange?: (name: string, values: MultiSelectValue[]) => void
    options: Option[],
    overflow?: boolean
}



/**
 * Select box with search capabilities.
 *
 * @param props
 * @constructor
 * @deprecated
 */
const MultiSelect = React.forwardRef<HTMLInputElement, MultiSelectProps>((props: MultiSelectProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {


    const [options, setOptions] = useState([] as Option[]);
    const [values, setValues] = useState([] as MultiSelectValue[]);

    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');


    const [initialized, setInitialized] = useState(false);
    const init = () => {
        const propOptions = props.options;
        const propValues = props.value;
        setValues(propValues);
        setOptions(propOptions.map(opt => {
            const isValue = propValues.find((val) => val === opt.value) !== undefined;
            const newOpt = {...opt};
            if (isValue) {
                newOpt.selected = true
            }
            return newOpt;
        }))
        setInitialized(true);
    }


    /**
     * Initialize component
     */
    useEffect((): void => {
        init();
    }, []);


    /**
     * Watch option change given from parent.
     */
    useEffect((): void => {
        init();
    }, [JSON.stringify(props.options)]);


    /**
     * Initialize component
     */
    useEffect((): void => {
        if (!initialized) {
            return;
        }
        if (props.onChange) {
            props.onChange(props.name, values)
        }
    }, [values]);




    /**
     * Handle value changes.
     * @todo on (de)select
     * @param optionValue
     */
    const onChange = (optionValue: string) => {
        // flip option selected
        setOptions([...options.map(opt => {
            const newOpt = {...opt};
            if (opt.value === optionValue) {
                const isSelected = !newOpt.selected
                newOpt.selected = isSelected
                setValues(isSelected
                    ?[...values, opt.value] // Add
                    :[...values].filter(value => value !== opt.value) // Remove
                );
            }
            return newOpt;
        })])
    }

    /**
     * Check if given value from parent changes.
     *
     * Only applies when current value === ''
     * Feedback to parent with new value to keep in sync.
     */
    useEffect((): void => {
        init()
    }, [props.value]);


    /**
     * Show/hide error.
     * @fixme
     * @param isError
     */
    const toggleError = (isError: boolean): void => {
        setHasError(isError)
        setErrorMsg( isError ?'Veld is verplicht!' : '');
    }



    /**
     * Wrapper for conditional error message.
     */
    const errorMessage = (): JSX.Element => (
        hasError
            ? <ErrorMessage message={errorMsg} />
            : <></>
    );

    /**
     * Listen to error feedback from parent.
     */
    useEffect((): void => {
            toggleError(props.invalid === true);
    }, [props.invalid]);


    const getSelectedOptionNames = (): string[] => {
        const selectedNames:string[] = [];
        options.forEach(opt => {
            if (opt.selected === true) {
                selectedNames.push(opt.displayName)
            }
        })
        return selectedNames;
    }






    // NEW
    /**
     * Button that shows select field with current selected value.
     */
    const listBoxButton = (): JSX.Element => (
        <Listbox.Button className={`py-1 h-auto bg-white relative w-full border ${props.invalid ? 'border-red-600' : 'border-gray-300'} min-h-[2.1rem] rounded-md shadow-sm pl-2 pr-10 text-left text-md cursor-default focus:outline-none focus:ring-1 focus:ring-aqua focus:border-aqua sm:text-md`}>
            <span className="block">{[...getSelectedOptionNames()].map((opt, i) => <span key={i} className={'inline-block bg-milk bg-opacity-75 text-graphite rounded-md px-2 py-1 m-0.5'}>{opt}</span>)}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <span className="material-icons">unfold_more</span>
            </span>
        </Listbox.Button>
    );


    /**
     * Content of option item
     * @param {Option} option
     * @param {boolean} active
     */
    const optionContent = (option: Option, active: boolean): JSX.Element  => (
        <>
            <span className={
                `${option.selected ? 'font-semibold' : 'font-normal'} 
                 ${active ? 'text-white' : (option.selected ?'text-aqua':'')} 
                 block truncate`
            }>
                {option.displayName}
            </span>
            {option.selected ? (
                <span className={`absolute inset-y-0 right-0 flex items-center text-center mr-2`} >
                    <span className="material-icons text-md text-white p-1 bg-aqua rounded-full ">done</span>
                </span>
            ) : null}
        </>
    );


    /**
     * Option item of select list
     * @param {Option} option
     * @param {number} index
     */
    const listBoxOption = (option: Option, index: number) => {

        const defaultClasses = 'select-none relative py-2 pl-3 pr-9';
        const cursorClass = option.selected ? 'cursor-default' : 'cursor-pointer';

        return(
            <Listbox.Option
                key={index}
                value={option.value}
                // onClick={() => setSelectedOption([option])}
                className={({active}) => {
                    const activeClasses = active ? `text-white bg-aqua ${cursorClass}` : 'text-gray-900';
                    const selected = option.selected ? '!bg-milk':'';
                    return `${defaultClasses} ${activeClasses} ${selected}`;
                }}
            >
                {({active}) => optionContent(option, active)}
            </Listbox.Option>
        );
    }


    /**
     * Wrapper for select options.
     */
    const listBoxOptions = (): JSX.Element => (
        <Listbox.Options className={`${props.name}-list ${props.overflow === true? 'absolute': 'relative'} z-10 mt-1 w-full bg-white shadow-lg max-h-72 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`}>
            {listBoxOption(defaultOption, 0)}
            {
                options.map((option, index) =>
                    listBoxOption(option, index+1)
                )
            }
        </Listbox.Options>
    )




    /**
     * Headless UI wrapper for select field with cross browser styling.
     */
    const listBox = (): JSX.Element => (
        <Listbox value={[...getSelectedOptionNames()].join(', ')} onChange={onChange}>
            {({ open }) => (
                <>
                    <Listbox.Label className="block text-md font-medium text-gray-500 ml-2">
                        {props.label}
                    </Listbox.Label>

                    <div className="mt-1 relative">
                        { listBoxButton() }

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            {listBoxOptions()}
                        </Transition>
                        {errorMessage()}
                    </div>
                </>
            )}
        </Listbox>
    );


    /**
     * Hidden input for holding form data.
     */
    const input = () => (
        <input type={'hidden'} name={props.name} id={props.id} value={values.join(', ') ?? ''} ref={ref} aria-required={props.required} />
    );


    return(
        <div className={'py-1 text-left'}>
            {/* Form data will be stored in hidden input*/}
            {input()}

            {/* Select wrapper for cross browser styling */}
            {listBox()}
        </div>
    );
});

export default MultiSelect;