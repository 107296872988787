import {useCookies} from "react-cookie";
import {useEffect, useState} from "react";
import { APP_DOMAIN, COOKIE_PREFIX, PRODUCTION_MODE } from '../../../../config/config';



const useCookie = (cookieName: string) => {

    const currentCookie = `${COOKIE_PREFIX}_${cookieName}`;
    const [cookies, setCookie, removeCookie] = useCookies([currentCookie]);

    /**
     *
     */
    const data = (): string|undefined => cookies[currentCookie];

    /**
     *
     * @param data
     */
    const setData = (data?: string): void => setCookie(currentCookie, data, {
        path: '/',
        domain: APP_DOMAIN,
        secure: PRODUCTION_MODE,
        sameSite: 'strict'
    })

    /**
     *
     */
    const hasData = () : boolean => data !== undefined;



    /**
     *
     */
    const [prevCookieData, setPrevCookieData] = useState(data());
    useEffect(() => {
        if (prevCookieData === data()) {
            return;
        }
        setPrevCookieData(data());
    }, [data]);


    /**
     *
     */
    const destroy = () : void => {
        setData();
        setPrevCookieData(undefined);
        removeCookie(currentCookie, {
            maxAge: 0,
            expires: new Date(0)
        });
    };

    return {
        data: data(),
        setData,
        destroy,
        hasData
    }
}

export default useCookie;