import React from 'react';
import Button, {ButtonProps} from "./Button";
import {DeleteIcon} from "../Icon";

const DeleteButton: React.FC<ButtonProps> = ({
    style,
    small,
    text,
    ...props
}):JSX.Element => <Button
    {...props}
    style={style}
    small={small}
    icon={<DeleteIcon />}
    text={text ?? 'Verwijderen'}
    textColor={'white'}
    bgColor={{
        regular: 'fire',
        hover: 'fire-hover',
        active: 'fire-active'
    }}
/>
export default DeleteButton;
