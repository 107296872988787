import React from "react";
import {classNames} from "../../../lib/samfe/modules/Parse/String";

type Props = {
    text?: string|JSX.Element,
    refId?: string
    disabled?: boolean
}

/**
 *
 * @param props
 * @constructor
 * @deprecated
 */const Label: React.FC<Props> = (props: Props): JSX.Element => {

    return (
        <label htmlFor={props.refId} className={classNames(
            props.disabled === true ? 'hover:cursor-not-allowed' : '',
            'text-md font-medium text-graphite text-left block'
            )}
        >
            {props.text}
        </label>
    );

}
export default Label