import React from 'react';
import { ChargeLocationModel } from '../../../../resources/location/ChargeLocationTypes';
import { getFullLocation } from '../../../../resources/location/LocationFunctions';


export const parseChargeLocations = (chargeLocations: ChargeLocationModel[]): JSX.Element => {
    return <ul>
        { chargeLocations.filter(cl => cl.archived == false).map(cl => <li key={ cl.id }>
            { getFullLocation(cl) }
        </li>) }
    </ul>;
};