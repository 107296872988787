import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import ProductTable from '../../product/ProductTable';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>
const ProductTypeProductTable: FC<Props> = ({ parentId }): JSX.Element => {

    return <ProductTable hideTypeColumn={true} filter={ {
        column: 'product_type_id',
        operator: '=',
        value: parentId
    } }/>;
};

export default ProductTypeProductTable;