import React, { FC, useEffect, useState } from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { modelNotArchived } from '../../../../lib/samfe/types/ModelTypes';
import { getFullArticleName } from '../../../article/ArticleFunctions';
import { ArticleModel } from '../../../article/ArticleTypes';
import useArticle from '../../../article/useArticle';
import useCharge from '../../../charge/useCharge';
import usePurchase from '../../usePurchase';
import { PurchaseRowDto, PurchaseRowModel, PurchaseRowRelationsBluePrint } from './PurchaseRowTypes';
import usePurchaseRow from './usePurchaseRow';


const PurchaseRowForm: FC<ExtendFormModalProps<PurchaseRowDto>> = ({
    parentId,
    id,
    open,
    setOpen,
    onSuccess
}): JSX.Element => {


    const purchase = usePurchase();
    const purchaseRow = usePurchaseRow(parentId);
    const charge = useCharge();

    const [ initialPurchaseRow, setInitialPurchaseRow ] = useState<PurchaseRowModel>();
    const [ currentArticle, setCurrentArticle ] = useState<ArticleModel|undefined>();

    const [ availableArticleIds, setAvailableArticleIds ] = useState<number[]>([]);
    const [ predictedPrice, setPredictedPrice ] = useState<number>(1);

    const getAvailableArticleIds = async(): Promise<number[]> => {
        if (!parentId) {
            return [];
        }
        return await purchase.getItem(parentId, {
            with: [ 'producer.articleProducers.article.product', 'producer.articleProducers.article.package.parts' ]
        }).then(purchase => {
            return (purchase?.producer?.articleProducers ?? [])
                .filter(ap =>
                    modelNotArchived(ap.archived) &&
                    modelNotArchived(ap.article?.archived) &&
                    (ap.article?.active === true || ap.article?.active === 1)
                ).map(ap => ap.article!.id as number);
        });
    };


    const getPredictedPrice = async(): Promise<number> => {
        if (!currentArticle?.id) {
            return 1;
        }
        return await charge.getList({
            filter: `article_id=${ currentArticle.id }`,
            limit: 1,
            order: 'DESC',
            orderBy: 'created_at'
        }).then(charges => charges.length>0 ?charges[0].price_per_amount ?? 1 :1);
    };


    useEffect(() => {
        getAvailableArticleIds().then(setAvailableArticleIds);
    }, [ parentId ]);

    useEffect(() => {
        if (!currentArticle) {
            return;
        }
        getPredictedPrice().then(setPredictedPrice);
    }, [ currentArticle ]);


    const handleArticleChange = (article?: ArticleModel) => {

        if (currentArticle && !article) {
            return;
        }
        setCurrentArticle(article);
    };


    const shape = (): Shape<PurchaseRowDto> => ({

        purchase_id: Yup.number()
            .inputType('hidden')
            .controlType('input'),

        article_id: Yup.number()
            .label('Artikel')
            .required()
            .controlType('selectSearch')
            .selectSearchConfig({
                useHook: useArticle,
                expectsInitialModel: id != undefined,
                initialModel: currentArticle,
                onChange: handleArticleChange,
                searchOptions: {
                    relations: [ 'product', 'package.parts' ],
                    whereIn: {
                        key: 'id',
                        operator: '=',
                        values: availableArticleIds
                    },
                    searchCols: [ 'number' ],
                    relationSearch: [
                        {
                            relationCol: 'product.name'
                        }
                    ],
                    valueCol: 'id',
                    limit: 'all',
                    // @fixme monkey patch
                    filter: `active=1${ availableArticleIds.length == 0 ?',id=0' :'' }`,
                    displayName: getFullArticleName
                }
            }),

        charge_id: Yup.number()
            .label('Charge')
            .hidden(true)
            .inputType('hidden')
            .controlType('input'),

        quantity: Yup.number()
            .label('Aantal')
            .required()
            .inputType('number')
            .controlType('input'),

        price_per_amount: Yup.number()
            .label('Verwachte prijs per stuk')
            .required()
            .description('per 1000 voor bulk')
            .inputType('number')
            .min(0)
            .defaultValue(predictedPrice)
            .controlType('input'),

        status: Yup.string()
            .label('Status')
            .controlType('input')
            .inputType('text')
            .hidden(true)
            .defaultValue(initialPurchaseRow?.status ?? 'open'),

        comments: Yup.string()
            .controlType('textArea')
            .label('Notitie'),

        sent_at: Yup.string()
            .label('Verzenddatum')
            .controlType('input')
            .inputType('text')
            .hidden(true),

        received_at: Yup.string()
            .label('Ontvangstdatum')
            .controlType('input')
            .inputType('text')
            .hidden(true)
    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<PurchaseRowDto>(shape());


    useEffect(() => {
        setShape(shape());
    }, [
        predictedPrice,
        availableArticleIds
    ]);


    // @todo reset states on re init
    const initializer = async() => {
        purchaseRow.getItem(id, { with: [ 'article.product', 'article.package.parts' ] }).then(pr => {
                setInitialPurchaseRow(pr);
                setCurrentArticle(pr?.article);
            })
            .finally(() => setShape(shape()));
    };


    /**
     *
     */
    const { formikConfig, formFields } = useForm<PurchaseRowModel, PurchaseRowDto, PurchaseRowRelationsBluePrint>({
        id,
        validationSchema,
        useHttpHook: usePurchaseRow,
        parentId: parentId,
        onSuccess: onSuccess,
        initializer,
        initialized: useAsyncInit(initializer, open),
        morphPayload(values, dto) {
            if (!id) {
                dto.status = 'open';
            }
            dto.purchase_id = parentId;
            dto.price_per_amount = parseFloat(values.price_per_amount);
            return dto;
        }
    });


    return <FormModal
        id={ id }
        parentId={ parentId }
        resource={ 'Inkoopregel' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default PurchaseRowForm;
