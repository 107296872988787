import React, { ComponentType, useEffect } from 'react';
import ArchiveModal, { ArchiveModalProps } from '../../../../lib/samfe/components/Modal/ArchiveModal';
import DeArchiveModal from '../../../../lib/samfe/components/Modal/DeArchiveModal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';


export type CurrentTableFormProps<T extends object> = {
    Form: ComponentType<ExtendFormModalProps<Partial<T>>>,
    formProps: ExtendFormModalProps<Partial<T>>
}

const CurrentTableForm = <T extends object>({ Form, formProps }: CurrentTableFormProps<T>) => {
    return <Form{ ...formProps }/>;
};


type TableFormModalsProps<T extends object, R extends string> = {
    currentFormProps?: CurrentTableFormProps<T>
    archiveModalProps?: ArchiveModalProps<T, Partial<T>, R>
    deArchiveModalProps?: ArchiveModalProps<T, Partial<T>, R>
}

const TableForms = <T extends object, R extends string>({ currentFormProps, archiveModalProps, deArchiveModalProps }: TableFormModalsProps<T, R>) => {
    return <>
        { currentFormProps != undefined && <CurrentTableForm { ...currentFormProps }/> }
        { archiveModalProps != undefined && <ArchiveModal { ...archiveModalProps } /> }
        { deArchiveModalProps != undefined && <DeArchiveModal { ...deArchiveModalProps } /> }
    </>;
};
export default TableForms;
