import React, {FC, useState} from "react";
import ProfileForm from "./ProfileForm";
import {EditButton} from "../../lib/samfe/components/Button";
import useCurrentUser from "../../resources/user/useCurrentUser";
import BasePage from "../../templates/pages/BasePage";

const ProfileShow: FC = (): JSX.Element => {


    const [openProfileEditModal, setOpenProfileEditModal] = useState(false)
    const {user: {profile}} = useCurrentUser();


    return (
        <BasePage
            title={'Profiel instellingen'}
            breadcrumb={''} actionSection={[
                <EditButton key={0} onClick={() => {
                    setOpenProfileEditModal(true);
                }} />
            ]}
        >
            <>
                <div className="grid grid-cols-1 md:grid-cols-6 mx-1 text-left">
                    <div className="bg-white col-span-1"><h1 className="">Personal Information</h1></div>

                    <div className="col-span-1">
                        <img className="max-sm:h-32 max-sm:w-32 xl:h-32 xl:w-32 grid rounded relative bg-gray-50 " alt="profile"
                             src={profile?.avatarUrl}
                        ></img>
                    </div>

                    <div className="col-span-2 ">

                        <div className="w-1/3 text-blue-900 py-2 my-1 bg-blue-300 text-center rounded-xl border-black">{profile?.name}</div>
                        <div className="w-1/2 text-blue-900 py-2 my-1  bg-blue-300 text-center rounded-xl  underline  ">{profile?.email}</div>
                    </div>
                </div>

                {openProfileEditModal && <ProfileForm
                    open={openProfileEditModal}
                    setOpen={setOpenProfileEditModal}
                />}
            </>
        </BasePage>
    )
}
export default ProfileShow;