import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { ProductIngredientModel, ProductIngredientRelationsBluePrint } from './ProductIngredientTypes';
import useProductIngredient from './useProductIngredient';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ProductIngredientTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useProductIngredient(parentId);

    const rows = useMemo((): TableColumns<ProductIngredientModel, ProductIngredientRelationsBluePrint>[] => [
        {
            header: {
                children: 'Ingredient'
            },
            column: (item) => ({
                children: item.ingredient?.name
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'ingredients' }
            rows={ rows }
            rowSort={ {
                idColumn: 'id',
                orderColumn: 'order'
            } }
            http={ {
                hook: httpHook,
                with: [
                    'ingredient'
                ],
                sortCol: 'order',
                sortDir: 'ASC'
            } }
            forms={ {
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.ingredient?.name }`,
                    resourceName: () => 'Ingredient'
                }
            } }
        />
    </>;
};

export default ProductIngredientTable;