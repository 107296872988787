import React from "react";
import useHttp from "../../../../lib/samfe/modules/Http/useHttp";
import {
    ElementRiskRegulationDto,
    ElementRiskRegulationModel,
    ElementRiskRegulationRelationsBluePrint
} from "./ElementRiskRegulationTypes";


const useElementRiskRegulation = (id?: number) => useHttp<ElementRiskRegulationModel, ElementRiskRegulationDto, ElementRiskRegulationRelationsBluePrint>({
    endpoint: `elements/${id}/risk-regulations`
});
export default useElementRiskRegulation;
