import React, { FC } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import { AccountDto, AccountModel, AccountRelationsBluePrint } from './AccountTypes';
import useAccount from './useAccount';


const AccountForm: FC<ExtendFormModalProps<AccountDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {


    /**
     *
     */
    const shape = (): Shape<AccountDto> => ({

        name: Yup.string()
            .label('Naam')
            .required()
            .controlType('input')
            .inputType('text'),

        email: Yup.string()
            .label('E-mailadres')
            .required()
            .controlType('input')
            .inputType('email')
    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<AccountDto>(shape());

    const initializer = async() => {
        setShape(shape());
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<AccountModel, AccountDto, AccountRelationsBluePrint>({
        id,
        validationSchema,
        onSuccess,
        useHttpHook: useAccount,
        initializer,
        initialized: useAsyncInit(initializer, open)
    });


    return <FormModal
        id={ id }
        resource={ 'Account' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default AccountForm;
