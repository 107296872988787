import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import CategoryForm from './CategoryForm';
import { CategoryModel, CategoryRelationsBluePrint } from './CategoryTypes';
import useCategory from './useCategory';


type Props = Required<ExtendTableProps>

const CategoryTable: FC<Props> = () => {

    const httpHook = useCategory();

    const rows = useMemo((): TableColumns<CategoryModel, CategoryRelationsBluePrint>[] => [
        {
            header: {
                children: 'Naam',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aantal grondstoffen',
                sortCount: 'elements'
            },
            column: (item) => ({
                children: item.elements_count
            }),
            type: 'numeric'
        }
    ], []);

    return (
        <Table
            id={ 'element-categories' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `${ item.id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: [ 'name' ],
                sortCol: 'name',
                sortDir: 'ASC',
                withCount: ['elements']
            } }
            forms={ {
                edit: {
                    Form: CategoryForm,
                    id: (record) => record.id
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.name }`,
                    resourceName: () => 'Grondstof categorie'
                }
            } }
        />
    );
};

export default CategoryTable;