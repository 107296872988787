import React, {FC} from "react";
import IndexPage from "../../templates/pages/IndexPage";
import RiskTable from "./RiskTable";
import RiskForm from "./RiskForm";


const RiskIndex: FC = (): JSX.Element => <IndexPage
    title={"Risico's"}
    Table={RiskTable}
    FormModal={RiskForm}
/>
export default RiskIndex;