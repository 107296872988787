import { useEffect, useState } from 'react';
import useHttp from '../../../lib/samfe/modules/Http/useHttp';
import { SaleRowDto, SaleRowModel, SaleRowRelationsBluePrint } from './SaleRowTypes';


export const useSaleRowResource = () => useHttp<SaleRowModel, SaleRowDto, SaleRowRelationsBluePrint>({
    endpoint: `sale-rows`
});


const useSaleRow = (initialSaleId?: number) => {


    const [ saleId, setSaleId ] = useState(initialSaleId);
    const http = useHttp<SaleRowModel, SaleRowDto, SaleRowRelationsBluePrint>({
        endpoint: `sales/${ saleId }/rows`
    });

    const [ saleIdUpdated, setSaleIdUpdated ] = useState(false);
    const [ id, setId ] = useState<number>();
    const [ payload, setPayload ] = useState<SaleRowDto>();
    const [ onSuccess, setOnSuccess ] = useState<() => void>();
    const [ operation, setOperation ] = useState<'create'|'update'>();

    const [ saleIdSetByMethod, setSaleIdSetByMethod ] = useState(false);


    const setSaleIdByMethod = (saleId?: number) => {
        setSaleIdSetByMethod(true);
        setSaleId(saleId);
    };

    const setSaleIdByReference = (saleId?: number) => {
        setSaleIdSetByMethod(false);
        setSaleId(saleId);
    };

    const updateWithSaleId = (saleId?: number, id?: number, payload?: SaleRowDto, onSuccess?: () => void) => {
        setSaleIdByMethod(saleId);
        setId(id);
        setPayload(payload);
        setOnSuccess(onSuccess);
        setSaleIdUpdated(true);
        setOperation('update');
    };

    const createWithSaleId = (saleId?: number, payload?: SaleRowDto, onSuccess?: () => void) => {
        setSaleIdByMethod(saleId);
        setPayload(payload);
        setOnSuccess(onSuccess);
        setSaleIdUpdated(true);
        setOperation('create');
    };


    useEffect(() => {
        if (!saleIdSetByMethod || !operation || !saleId) {
            return;
        }

        if (operation === 'update') {
            http.update(id, payload as SaleRowDto)
                .then(() => onSuccess?.());
        }
        if (operation === 'create') {
            http.create(payload as SaleRowDto)
                .then(() => onSuccess?.());
        }

        setPayload(undefined);
        setId(undefined);
        setOperation(undefined);
        setSaleIdUpdated(false);
        setSaleIdByMethod(undefined);

    }, [ saleIdUpdated ]);


    return {
        ...http,
        saleId,
        setSaleId: setSaleIdByReference,
        updateWithSaleId,
        createWithSaleId
    };
};
export default useSaleRow;