import React, {createContext, useState, useContext, Dispatch, FC, PropsWithChildren, SetStateAction} from 'react';
import {ToasterProps} from "./index";



type ToasterContextType = {
    toasterProps: ToasterProps;
    setToasterProps: Dispatch<SetStateAction<ToasterProps>>;
};

const ToasterContext = createContext<ToasterContextType | undefined>(undefined);



export const ToasterProvider: FC<PropsWithChildren<any>> = ({ children }): JSX.Element => {
    const [toasterProps, setToasterProps] = useState<ToasterProps>({
        title: '',
        message: undefined,
        type: 'info',
        show: false,
        onClose: () => {},
    });

    return (
        <ToasterContext.Provider value={{ toasterProps, setToasterProps }}>
            {children}
        </ToasterContext.Provider>
    );
};


export const useToaster = (): ToasterContextType => {
    const context = useContext(ToasterContext);
    if (!context) {
        throw new Error('useToaster must be used within a ToasterProvider');
    }
    return context;
};

export default useToaster;
