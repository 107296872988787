import React, { useMemo } from 'react';
import ArchiveModal from '../../../lib/samfe/components/Modal/ArchiveModal';
import { classNames } from '../../../lib/samfe/modules/Parse/String';
import ActionTdComponent from '../components/stateful/ActionTd.component';
import SortTdComponent from '../components/stateful/SortTd.component';
import { getColStyle } from '../functions/Table.functions';
import { useTableContext } from '../providers/Table.provider';
import { TableActionProps } from '../types/TableAction';
import { TdComponentProps } from '../types/TableColumn';
import { TableArchiveFormInternalProps, TableFormActionProps } from '../types/TableForm';
import { TableColumns } from '../types/TableRow';
import useTableCallbacks from './useTableCallbacks';
import useTableDeArchive from './useTableDearchive';
import useTableForms from './useTableForms';


const useTableRows = <T extends object, K extends keyof T, R extends string, V = T[K]>(
    data: T[],
    colData: TableColumns<T, R>[],
    handleOpenForm: (currentModel: T, formConfig: TableFormActionProps<T, K, V>|TableArchiveFormInternalProps<T, K, V>) => void
) => {


    const { hasTableForms, formConfigs, archiveFormConfig } = useTableForms<T, K, R, V>();
    const { hasTableCallbacks, callbackConfigs } = useTableCallbacks<T, R>();
    const { hasArchivedResources, deArchiveConfig } = useTableDeArchive<T, K, R, V>();

    const { styling, rowSort } = useTableContext<T, R>();
    const actionStyleVariation = useMemo(() => {
        return styling?.variation ?? 'menu';
    }, [ styling?.variation ]);

    const hasDnd = useMemo(() => {
        return rowSort?.idColumn != undefined && rowSort?.orderColumn != undefined;
    }, [ rowSort?.idColumn, rowSort?.orderColumn ]);


    /**
     * @description Do not remove "data.length" from dependency array, React does not see the state update when postProcessData is used.
     */
    return useMemo(() => {
        const tableRows: TdComponentProps[][] = [];

        data.forEach((dataItem, dataIndex) => {

            const columns: TdComponentProps[] = colData.map(colItem => {

                const column = colItem.column(dataItem);
                if (column.linkTo != undefined && column.onClick != undefined) {
                    throw new Error('Column can only have "linkTo" or "onClick", not both!');
                }

                const dataType = colItem.type ?? 'text';
                const colStyle = getColStyle(dataType);

                return {
                    ...column,
                    type: colItem.type ?? 'text',
                    className: classNames(colItem.className, column.className),
                    linkTo: column.linkTo?.(dataItem),
                    onClick: column.onClick ?() => column.onClick?.(dataItem) :undefined,
                    style: {
                        ...colStyle,
                        ...colItem.style,
                        ...column.style
                    }
                };
            });

            const allRowActions: TableActionProps<T>[] = [];


            if (hasTableForms) {
                const rowFormActions: TableActionProps<T>[] = formConfigs
                    .filter(formConfig => {
                        if (!formConfig.hideForRow) {
                            return true;
                        }
                        return !formConfig.hideForRow(dataItem);
                    })
                    .map(formConfig => ({
                        disabled: formConfig.disabled ?? formConfig.disableForRow?.(dataItem) ?? false,
                        value: formConfig.id?.(dataItem),
                        onClick: () => handleOpenForm(dataItem, formConfig),
                        children: formConfig.title,
                        variation: formConfig.variation ?? 'primary',
                        onSuccess: formConfig.onSuccess,
                        item: dataItem
                    } as TableActionProps<T>));

                allRowActions.push(...rowFormActions);
            }

            if (hasTableCallbacks) {
                const rowActions: TableActionProps<T>[] = callbackConfigs
                    .filter(actionConfig => {
                        if (!actionConfig.hideForRow) {
                            return true;
                        }
                        return !actionConfig.hideForRow(dataItem);
                    })
                    .map(actionConfig => ({
                        disabled: actionConfig.disabled ?? actionConfig.disableForRow?.(dataItem) ?? false,
                        onClick: () => actionConfig.onClick(dataItem),
                        children: actionConfig.title,
                        variation: actionConfig.variation ?? 'primary',
                        item: dataItem
                    } as TableActionProps<T>));
                allRowActions.push(...rowActions);
            }


            if (hasTableForms && archiveFormConfig != undefined) {
                const parsedArchivedConfig: TableArchiveFormInternalProps<T, K, V> = {
                    ...archiveFormConfig,
                    Form: ArchiveModal,
                    isArchive: true
                };

                allRowActions.push({
                    disabled: archiveFormConfig.disabled ?? archiveFormConfig.disableForRow?.(dataItem) ?? false,
                    value: archiveFormConfig.id?.(dataItem),
                    onClick: () => handleOpenForm(dataItem, parsedArchivedConfig),
                    Form: ArchiveModal,
                    children: 'Archiveren',
                    variation: 'destructive',
                    item: dataItem
                } as TableActionProps<T>);

            }

            if (hasArchivedResources && deArchiveConfig !== undefined) {
                //deArchiveConfig
                const parsedArchivedConfig: TableArchiveFormInternalProps<T, K, V> = {
                    ...deArchiveConfig,
                    Form: ArchiveModal,
                    isArchive: true
                };

                allRowActions.push({
                    disabled: false,
                    value: deArchiveConfig.id?.(dataItem),
                    onClick: () => handleOpenForm(dataItem, parsedArchivedConfig),
                    children: 'De-archiveren',
                    variation: 'destructive',
                    item: dataItem
                });
            }

            if (allRowActions.length>0) {
                columns.push({
                    children: <ActionTdComponent items={ allRowActions } type={ actionStyleVariation }/>,
                    type: 'action'
                });
            }


            if (hasDnd) {
                const dndId = dataItem[rowSort!.idColumn] as unknown as number;
                let prevId: number|undefined = undefined;
                if (dataIndex > 0) {
                    prevId = data[dataIndex-1][rowSort!.idColumn] as unknown as number
                }
                let nextId: number|undefined = undefined;
                if (dataIndex < data.length-1) {
                    nextId = data[dataIndex+1][rowSort!.idColumn] as unknown as number
                }
                columns.unshift({
                    children: <SortTdComponent
                        id={ dndId }
                        prevId={prevId }
                        nextId={nextId}
                        index={dataIndex}
                    />,
                    type: 'sort'
                });
            }

            tableRows.push(columns);
        });
        return tableRows;
    }, [ data, colData, hasDnd, hasTableForms, formConfigs, actionStyleVariation, handleOpenForm, archiveFormConfig, hasTableCallbacks, callbackConfigs, hasArchivedResources, deArchiveConfig, rowSort ]);
};

export default useTableRows;
