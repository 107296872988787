import useAxios from '../../lib/samfe/modules/Http/useAxios';
import { SideBarFields} from './SideBarTypes';


const useSideBar = () =>{

    const {get} = useAxios({
        omitLoadingScreen: true
    })
    const getData = async () => await get<SideBarFields>('sidebar')
        .then(res => res.data)


    return {
        get: getData
    }

}
export default useSideBar;
