import useAxios from '../../../lib/samfe/modules/Http/useAxios';
import { WooCommerceImportEntity } from './WooCommerceImportTypes';


const useWooCommerceImport = () => {

    const { get } = useAxios();

    const getOrder = async(id: number) => await get<WooCommerceImportEntity>(`woocommerce/${ id }/import`)
        .then(res => res.data);


    return {
        getOrder
    };
};


export default useWooCommerceImport;