import React, { useEffect, useState } from 'react';
import {Field} from "formik"
import Label from "../Caption/Label";
import ErrorMessage from "../Caption/ErrorMessage";
import useFormikField from "../Effects/useFormikField";
import Skeleton from "../../Skeleton/Skeleton";
import useInput from "../Effects/useInput";
import {classNames} from "../../../modules/Parse/String";
import {requiredFieldClassname} from "../Support/FieldSupport";

type Props = Omit<JSX.IntrinsicElements['input'], 'name'|'type'|'defaultValue'> & {
    label: string;
    name: string;
    type: React.HTMLInputTypeAttribute;
    defaultValue?: string|number;
    handleValueChange?: (value: number|string|boolean) => void,
    description?: string
}

const Input: React.FC<Props> = ({
    label,
    name,
    required,
    className,
    defaultValue,
    disabled,
    handleValueChange,
    description,
    ...props
}): JSX.Element => {

    const {field, meta, invalid} = useFormikField(name)
    const [value, setValue] = useInput(name, props.type, field.value ?? defaultValue ?? '')

    const [ initialRun, setInitialRun ] = useState(true);
    const [ prevValue, setPrevValue ] = useState(value);
    useEffect(() => {
        if (value == defaultValue && value == prevValue && defaultValue == prevValue && !initialRun) {
            return;
        }

        if (initialRun) {
            setInitialRun(false)
        }

        setPrevValue(field.value)
        setValue(field.value)
    }, [field.value]);


    const handleValue = (value: string) => {
        let castedValue: string|number = value;
        if (props.type == 'number' && Number.isFinite(Number(value)) && value !== '' ) {
            castedValue = Number.isInteger(value) ? parseInt(value) : parseFloat(value);
        }
        handleValueChange?.(castedValue)
        setValue(castedValue)
    }

    return field.value !== undefined ? <>
        <Label hidden={props.hidden} invalid={invalid} htmlFor={name}>{label}{required && '*'}</Label>
        {description && <span className="text-gray-500 font-normal text-sm">{description}</span>}
        <div className="mt-2">
            <Field
                {...props}
                {...field}
                hidden={props.hidden}
                data-testid={field.name}
                required={required}
                value={value}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleValue(e.currentTarget.value)}
                id={name}
                as={'input'}
                disabled={disabled}
                className={classNames( props.hidden ? 'hidden' : 'block',
                    "w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6",
                    invalid? 'ring-red-700' : 'ring-gray-300',
                    disabled && 'hover:cursor-not-allowed opacity-60',
                    required && requiredFieldClassname,
                    className,
                )}
            />
            <ErrorMessage meta={meta} />
        </div>
    </>: <Skeleton type={"input"} />
}
export default Input;
