import React, {FC} from "react";
import TopButton from '../../lib/samfe/components/Button/TopButton';
import IndexPage from "../../templates/pages/IndexPage";
import PackagePartTable from "./PackagePartTable";
import PackagePartForm from "./PackagePartForm";
import {useNavigate} from "react-router-dom";

const PackagePartIndex: FC = (): JSX.Element => {
    const navigate = useNavigate();

    return(
        <IndexPage
            title={'Verpakkingsonderdelen'}
            Table={PackagePartTable}
            FormModal={PackagePartForm}
            actionButton={<TopButton
                style={'secondary'}
                icon={''}
                text={'Verpakkingen'}
                onClick={() => navigate('/packages')}
            />}
        />
    )
}
export default PackagePartIndex;