import { useMemo } from 'react';
import { FileModel } from '../../../document/FileTypes';
import { AssessmentHistory, AssessmentHistoryItem } from '../../types/AssessmentTypes';


type AssessmentFile = {
    id: number,
    name: string,
    url: string,
    charge_id: number,
    batchcode: string,
    assessment_id: number,
}
const useAssessmentFiles = (item: AssessmentHistory) => {

    const parseFileItems = (assessmentItem: AssessmentHistoryItem, files: FileModel[]): AssessmentFile[] => {
        const assessment = assessmentItem.assessment;
        const charge = assessmentItem.charge;
        return files
            .filter(file => file.model_id == assessment.id)
            .map(file => ({
                id: file.id,
                name: `${file.name}.${file.extension}`,
                url: file.url,
                charge_id: charge.id,
                batchcode: charge.batchcode,
                assessment_id: assessment.id
            }) as AssessmentFile);
    };

    return useMemo(() => {
        if (item.files.length>0) {
            if (item.current.assessment.status == 'approved' || item.current.assessment.approved_at != null) {
                return parseFileItems(item.current, item.files);
            }
            if (item.parent != null && (item.parent.assessment.status == 'approved' || item.parent.assessment.approved_at != null)) {
                return parseFileItems(item.parent , item.files);
            }
            if (item.previous) {
                return parseFileItems(item.previous, item.files);
            }
        }
        if (item.fallback_files.length>0 && item.fallback != null) {
            return parseFileItems(item.fallback, item.fallback_files);
        }
        return [];
    }, [ item.parent, item.current, item.files, item.fallback, item.previous, item.current.assessment.approved_at ]);
};

export default useAssessmentFiles;
