import React from "react";
import Input from "./Field/Input";
import Select from "./Field/Select";
import TextArea from "./Field/TextArea";
import Checkbox from "./Field/Checkbox/Checkbox";
import Radio from "./Field/Radio";
import Switch from "./Field/Switch";
import {SelectOption} from "./Effects/useSelect";
import {MinimumModel} from "../../types/ModelTypes";
import SelectSearch from "./Field/SelectSearch/SelectSearch";
import {SelectSearchConfig} from "./Field/SelectSearch/SelectSearchTypes";

export type FormControlType = 'input'|'textArea'|'select'|'selectSearch'|'radio'|'switch'|'checkbox';

export type FormControlProps<Model extends MinimumModel,  Dto extends Partial<Model>, RelationBlueprint extends string> = {
    controlType: FormControlType,
    label: string,
    name: string,
    description?: string,
    type?: React.HTMLInputTypeAttribute,
    min?: number,
    max?: number,
    steps?: number,
    options?: SelectOption[],
    multiSelect?: boolean,
    hidden?: boolean,
    defaultValue?: string|boolean|number,
    required?: boolean
    disabled?: boolean
    handleValueChange?: (value?: string|boolean|number) => void,
    selectSearchConfig?: SelectSearchConfig<Model, Dto , RelationBlueprint>
    autoFocus?: boolean,
    className?: string
}

const FormControl = <Model extends MinimumModel,  Dto extends Partial<Model>, RelationBlueprint extends string>({
    controlType,
    defaultValue,
    ...props
}: FormControlProps<Model, Dto, RelationBlueprint>): JSX.Element => {
    let el: JSX.Element;

    switch (controlType) {
        case "input"    :
            el = <Input {...props} type={props.type ?? 'text'}
                        defaultValue={defaultValue as string | number | undefined}/>; break;
        case "textArea" :
            el = <TextArea {...props} defaultValue={defaultValue as string | undefined}/>; break;
        case "select"   :
            el = <Select {...props} options={props.options ?? []}
                         defaultValue={defaultValue as string | number | undefined}/>; break;
        case "selectSearch":
            el = <SelectSearch {...props} name={props.name} label={props.label} config={props.selectSearchConfig!} />; break;
        case "switch"   :
            el = <Switch {...props} defaultValue={defaultValue as boolean | undefined}/>; break;
        case "radio"    :
            el = <Radio {...props} options={props.options ?? []} defaultValue={defaultValue as boolean | undefined}/>; break;
        case "checkbox" :
            el = <Checkbox {...props} defaultValue={defaultValue as boolean | undefined}/>; break;
        default         :
            el = <span className={'text-red-700 font-bold'}>Invalid control provided!</span>; break;

    }
    return <div className={'my-4'}>{el}</div>;
}
export default FormControl;
