import React, { FC, useMemo } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import Disclosure from '../../../lib/samfe/components/Disclosure/Disclosure';
import useChargeAssessment from '../http/useChargeAssessment';
import { AssessmentModel, GetAssessmentStatusName } from '../types/AssessmentTypes';
import BaseAssessmentTable from './BaseAssessmentTable';


const AssessmentNotification: FC<{ assessments: AssessmentModel[] }> = ({ assessments }) => {

    const openAssessments = useMemo(() => {
        return assessments.filter(ass => ass.assessment_status == 'to_test');
    }, [ assessments ]);


    const warningAssessments = useMemo(() => {
        return assessments.filter(ass => ass.assessment_status == 'not_approved');
    }, [ assessments ]);

    const requestedAssessments = useMemo(() => {
        return assessments.filter(ass => ass.assessment_status == 'test_requested');
    }, [ assessments ]);

    return <>

        { assessments.length>0 && <Disclosure title={ 'Risico analyses met open acties' } variation={ 'info' }>
            <ul className={'mb-8 list-disc list-inside'}>
                { openAssessments.map(ass => <li key={ ass.id }>
                    { GetAssessmentStatusName(ass.assessment_status) } op <strong>{ ass.productRisk?.risk?.name }</strong>
                </li>) }
                { warningAssessments.map(ass => <li key={ ass.id }>
                    { GetAssessmentStatusName(ass.assessment_status) } voor <strong>{ ass.productRisk?.risk?.name }</strong>
                </li>) }
                { requestedAssessments.map(ass => <li key={ ass.id }>
                    { GetAssessmentStatusName(ass.assessment_status) } voor <strong>{ ass.productRisk?.risk?.name }</strong>
                </li>) }
            </ul>
        </Disclosure> }

    </>;
};


type Props = Omit<Required<ExtendTableProps>, 'htmlBefore'|'notification'>&{
    assessmentsWithAction: AssessmentModel[]
}

const ChargeAssessmentTable: FC<Props> = ({ parentId, assessmentsWithAction }) => {

    const assessmentHistoryHook = useChargeAssessment(parentId);
    return <BaseAssessmentTable
        httpHook={ assessmentHistoryHook }
        hideProduct={ true }
        hideArticle={true}
        hideCharge={ true }
        withRequestedAt={ true }
        witApprovedAt={ true }
        htmlBefore={ <AssessmentNotification assessments={ assessmentsWithAction }/> }
    />;
};

export default ChargeAssessmentTable;
