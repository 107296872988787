import React, {CSSProperties, FC, Fragment, useEffect, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {getRandomQuote, Quote} from "./LoadingQuote";
import Button from "../Button/Button";
import {ExclamationTriangleIcon} from "@heroicons/react/20/solid";
import ReactSkeleton from "react-loading-skeleton";
import {classNames} from "../../modules/Parse/String";


export type LoadingScreenProps = {
    show: boolean,
    onCancel?: () => void,
    isFullscreen?: boolean
};


/**
 * Loading screen for between actions.
 */
const LoadingScreen: FC<LoadingScreenProps> = ({show, onCancel, isFullscreen = false }): JSX.Element => {

    /** Internal state. */
    const [currentShow, setCurrentShow] = useState<boolean>(show);

    /** Random quote while waiting. */
    const [quote, setQuote] = useState<Quote>(getRandomQuote());

    /** State for show/hide cancel button. */
    const [showCancel, setShowCancel] = useState<boolean>(false);

    /** State for show/hide cancel dialogue. */
    const [openCancelDialog, setOpenCancelDialog] = useState(false);

    /** Ref for outside modal click support. */
    const cancelButtonRef = useRef<HTMLButtonElement>(null);

    /** Make sure timeOut is persisted for state change. */
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const unsetTimeOut = (): void => {timeoutRef.current !== null && clearTimeout(timeoutRef.current)};


    /**
     * Toggle between show and hide loading screen.
     * Calls cancel button after 10 seconds for case of infinite loading.
     *
     */
    const toggle = (): void => {
        document.body.style.overflow = currentShow ? 'hidden' : 'unset';
        if (currentShow) {
            setQuote(getRandomQuote());
            timeoutRef.current = setTimeout(() => {setShowCancel(true)}, 10000);
            return;
        }
        unsetTimeOut();
        setShowCancel(false);
    };

    /** Init visibility toggle on mount */
    useEffect(() => {
        if (isFullscreen) {
            toggle();
        }
    }, []);

    /** Update internal state on show change */
    useEffect(() => {setCurrentShow(show)}, [show]);

    /** Toggle state on show change. */
    useEffect(() => {
        if (isFullscreen) {
            toggle();
        }
    }, [currentShow]);

    /** Clean up on unmount */
    useEffect(() => unsetTimeOut(), []);


    /**
     * Handle cancel action on dialogue confirmation.
     */
    const handleCancel = (): void => {
        if (onCancel) {
            onCancel();
        }
        setCurrentShow(false);
    };


    /**
     * Loading animation.
     */
    const loader: JSX.Element = <svg className={'mx-auto relative top-[25vh]'} xmlns="http://www.w3.org/2000/svg" width="256px" height="128px" viewBox="0 0 72 100" preserveAspectRatio="xMidYMid">
        <rect x="12" y="0" rx="2" width="12" height="12" fill="#ffffff" className={'shadow drop-shadow-md'}>
            <animate attributeName="y" repeatCount="indefinite" dur="1.666s" calcMode="spline" keyTimes="0;0.5;1" values="41;44;44" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.666s"></animate>
            <animate attributeName="width" repeatCount="indefinite" dur="1.666s" calcMode="spline" keyTimes="0;0.5;1" values="18;12;12" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.666s"></animate>
            <animate attributeName="height" repeatCount="indefinite" dur="1.666s" calcMode="spline" keyTimes="0;0.5;1" values="18;12;12" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.666s"></animate>
        </rect>
        <rect x="30" y="0" rx="2" width="12" height="12" fill="#ffffff" className={'shadow drop-shadow-md'}>
            <animate attributeName="y" repeatCount="indefinite" dur="1.666s" calcMode="spline" keyTimes="0;0.5;1" values="41.75;44;44" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.333s"></animate>
            <animate attributeName="width" repeatCount="indefinite" dur="1.666s" calcMode="spline" keyTimes="0;0.5;1" values="16.5;12;12" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.333s"></animate>
            <animate attributeName="height" repeatCount="indefinite" dur="1.666s" calcMode="spline" keyTimes="0;0.5;1" values="16.5;12;12" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.333s"></animate>
        </rect>
        <rect x="48" y="0" rx="2" width="12" height="12" fill="#ffffff" className={'shadow drop-shadow-md'}>
            <animate attributeName="y" repeatCount="indefinite" dur="1.666s" calcMode="spline" keyTimes="0;0.5;1" values="41.75;44;44" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
            <animate attributeName="width" repeatCount="indefinite" dur="1.666s" calcMode="spline" keyTimes="0;0.5;1" values="16.5;12;12" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
            <animate attributeName="height" repeatCount="indefinite" dur="1.666s" calcMode="spline" keyTimes="0;0.5;1" values="16.5;12;12" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
        </rect>
    </svg>;


    /**
     * Custom drop shadow to make white text readable on blurred translucent background.
     */
    const dropShadow: CSSProperties = {
        filter: 'drop-shadow(0 0px 0px rgba(0, 0, 0, 0.1)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.1))'
    };


    /**
     * Wrapper for random quote while waiting.
     */
    const quoteText: JSX.Element = <blockquote className="relative top-[25vh] text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
        <q className={'text-white'} style={dropShadow}>
            <cite>{quote.quote}</cite>
        </q>
        <p className={'mt-4 -ml-4 flex items-center justify-center text-base text-white'} style={dropShadow}>
            ― {quote.quotee}
        </p>
    </blockquote>;


    /**
     * Cancel button for calling cancel confirmation dialogue.
     */
    const cancelButton: JSX.Element = <Transition
        show={showCancel}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
    >
        <div className={'w-full text-center relative mt-12 top-[25vh]'}>
            <Button style={'secondary'} onClick={() => setOpenCancelDialog(true)} text={<span style={dropShadow}>Afbreken</span>} icon={<></>} textColor={'white'} bgColor={{active: 'white', regular: 'white', hover: 'white'}} />
        </div>
    </Transition>;


    /**
     * Dialog for confirming cancel action.
     */
    const cancelDialog: JSX.Element = <Transition.Root show={openCancelDialog} as={Fragment}>
        <Dialog as="div" className="relative z-[694201]" initialFocus={cancelButtonRef} onClose={setOpenCancelDialog}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                        Actie afbreken?
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Dit kan gevolgen hebben voor de huidige actie.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                    onClick={() => handleCancel()}
                                >
                                    Afbreken
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    onClick={() => setOpenCancelDialog(false)}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>;


    return <>
        { !isFullscreen && <div className={ classNames(!currentShow && 'hidden', 'fixed -top-2 z-[69420] w-full') }>
            <ReactSkeleton baseColor={ '#0C6A57' } highlightColor={ '#16DEAE' } borderRadius={ 0 } className={ 'my-0 py-0 h-2.5' }/>
        </div> }

        { isFullscreen && <>
            <Transition
                show={currentShow}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={'overflow-hidden fixed backdrop-blur-sm bg-gradient-to-tl from-blue-800/50 to-blue-800/5 top-0 bottom-0 left-0 right-0 z-[69420]'}>
                    {loader}
                    {quoteText}
                    {cancelButton}
                </div>
            </Transition>
            {currentShow && cancelDialog}
        </> }

    </>;
};
export default LoadingScreen;
