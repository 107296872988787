import Yup from "../Yup";
import {Dispatch, SetStateAction, useEffect, useState} from "react";


export type Shape<Dto> = Record<keyof Dto, Yup.Schema>|(() => Record<keyof Dto, Yup.Schema>);
export type Schema<Dto> = Yup.ObjectSchema<Partial<Dto>, Partial<Dto>, Partial<Dto>>;

function useSchema<Dto>(initialShape: Shape<Dto>): {validationSchema: Schema<Dto>, setShape: Dispatch<SetStateAction<Shape<Dto>>>} {

    const [shape, setShape] = useState<Shape<Dto>>(initialShape);

    const schemaFromShape = (shape: Shape<Dto>): Schema<Dto> => Yup.object<Dto>().shape( typeof shape === 'function'
        ? shape()
        : shape
    ) as unknown as Schema<Dto>

    const [validationSchema, setSchema] = useState<Schema<Dto>>(schemaFromShape(initialShape));

    useEffect(() => {
        setSchema(schemaFromShape(shape))
    }, [shape]);

    return {validationSchema, setShape};
}
export default useSchema;
