import React, { FC, useState } from 'react';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import ShowPage from '../../templates/pages/ShowPage';
import CategoryForm from './CategoryForm';
import { CategoryModel } from './CategoryTypes';
import CategoryElementTable from './pivot/CategoryElementTable';
import useCategory from './useCategory';


const CategoryShow: FC = (): JSX.Element => {

    const [ currentCategory, setCurrentCategory ] = useState<CategoryModel>();

    return (
        <ShowPage
            title={ currentCategory?.name }
            breadcrumb={ currentCategory?.name }
            setCurrentModel={ setCurrentCategory }
            FormModal={ CategoryForm }
            httpHook={ useCategory }
            tabs={ currentCategory ?[
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <ExampleGraph/>
                    </>
                },
                {
                    name: 'Grondstoffen',
                    id: 'elements',
                    element: <>
                        {currentCategory.id && <CategoryElementTable parentId={ currentCategory.id }/>}
                    </>
                }
            ] :[] }
        />
    );
};
export default CategoryShow;