import { PaperClipIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useMemo, useState } from 'react';
import { FileType } from '../../../../resources/document/DocumentTypes';
import { viewPdf } from '../../../../resources/document/pdfFunctions';
import useFileHandler from '../../../../resources/document/useFileHandler';
import { SaveButton } from '../Button';
import EmailInput from '../Form/Generic/EmailInput';
import { InvalidField } from '../Form/Generic/GenericFieldTypes';
import Input from '../Form/Generic/Input';
import TextArea from '../Form/Generic/TextArea';
import useToaster from '../Toaster/useToaster';
import Modal from './index';
import { ModalProps } from './Modal';


type Props = ModalProps&{
    file: {
        id: number,
        name: string,
        type: FileType
    }
    mail?: {
        defaultSubject?: string,
        defaultMessage?: string,
        defaultRecipients?: string[],
        defaultCcs?: string[]
    }
}
const MailFileModal: React.FC<Props> = ({
    open,
    setOpen,
    title,
    onClose,
    file,
    mail
}) => {

    // Modal related actions

    const [ internalOpen, setInternalOpen ] = useState(open);

    useEffect(() => {
        if (open) {
            setInternalOpen(true);
        }
    }, [ open ]);

    useEffect(() => {
        if (!internalOpen) {
            setTimeout(() => {
                setOpen(false);
            }, 350);
        }
    }, [ internalOpen ]);

    const handleClose = () => {
        setInternalOpen(false);
        onClose?.();
    };

    // Mail related actions

    const { setToasterProps } = useToaster();
    const { mailFile } = useFileHandler();

    const {
        defaultSubject = '',
        defaultMessage = '\n--\nVerzonden met GA',
        defaultRecipients = [],
        defaultCcs = []
    } = useMemo(() => ({ ...mail }), [ mail ]);


    const [ recipients, setRecipients ] = useState<string[]>(defaultRecipients);
    const [ recipientsTouched, setRecipientsTouched ] = useState(false);
    const [ subject, setSubject ] = useState(defaultSubject);
    const [ subjectTouched, setSubjectTouched ] = useState(false);
    const [ ccs, setCcs ] = useState<string[]>(defaultCcs);
    const [ message, setMessage ] = useState(defaultMessage);
    const [ messageTouched, setMessageTouched ] = useState(false);

    const recipientsError: InvalidField|undefined = useMemo(() => ({
        message: 'Veld is verplicht',
        isInvalid: recipientsTouched ?recipients.length === 0 :false
    }), [ recipientsTouched, recipients.length ]);

    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    const subjectError: InvalidField|undefined = useMemo(() => ({
        message: 'Veld is verplicht',
        isInvalid: subjectTouched ?subject.replaceAll(' ', '') === '' :false
    }), [ subject, subjectTouched ]);

    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    const messageError: InvalidField|undefined = useMemo(() => ({
        message: 'Veld is verplicht',
        isInvalid: messageTouched ?message.replaceAll(' ', '') === '' :false
    }), [ message, messageTouched ]);


    const handleRecipientsChanged = (emails: string[]) => {
        setRecipientsTouched(true);
        setRecipients(emails);
    };

    const handleSubjectChanged = (subject: string) => {
        setSubjectTouched(true);
        setSubject(subject);
    };

    const handleMessageChanged = (message: string) => {
        setMessageTouched(true);
        setMessage(message);
    };

    const submit = () => {
        let hasError = false;

        if (recipients.length === 0) {
            setRecipientsTouched(true);
            hasError = true;
        }

        if (subject.replaceAll(' ', '') == '') {
            setSubjectTouched(true);
            hasError = true;
        }

        if (message.replaceAll(' ', '') == '') {
            setMessageTouched(true);
            hasError = true;
        }

        if (hasError) {
            return;
        }

        mailFile(file.id, file.type, {
            email_addresses: recipients,
            ccs, subject, message,
            file_name: file.name
        }).then(() => {
            setToasterProps({
                title: 'Email verzonden',
                type: 'success',
                show: true
            });
            handleClose();
        });
    };


    return (
        <Modal
            open={ internalOpen }
            setOpen={ handleClose }
            onClose={ onClose }
            title={ title }
            className={ 'sm:!w-[80vw] sm:!max-w-[42rem]' }
            footer={ <>
                <button
                    className={ 'absolute left-6 bottom-10 flex gap-1 bg-milk/50 w-fit px-2.5 py-0.5 rounded-md text-xs text-aqua font-medium' }
                    onClick={ () => viewPdf(file.id, file.type) }
                >
                    <PaperClipIcon className={ 'w-3 mt-0.5' }/>
                    <span>{ file.name }.pdf</span>
                </button>

                <div className="mt-5 sm:mt-4 flex flex-row-reverse float-right">
                    <SaveButton className={ 'sm:ml-3' } onClick={ submit }>Verzenden</SaveButton>
                </div>
            </>
            }
        >
            <EmailInput
                label={ 'Email adressen *' }
                initialEmails={ recipients }
                onChange={ handleRecipientsChanged }
                invalid={ recipientsError }
            />

            <EmailInput
                label={ 'CC' }
                initialEmails={ ccs }
                onChange={ setCcs }
            />

            <Input
                label={ 'Onderwerp' }
                required={ true }
                name={ 'subject' }
                type={ 'text' }
                value={ subject }
                onChange={ (v) => handleSubjectChanged(`${ v }`) }
                invalid={ subjectError }
            />

            <div className="my-4">
                <TextArea
                    label={ 'Bericht' }
                    required={ true }
                    name={ 'message' }
                    value={ message }
                    onChange={ (v) => handleMessageChanged(`${ v }`) }
                    rows={ 8 }
                    invalid={ messageError }
                />
            </div>
        </Modal>
    );
};

export default MailFileModal;
