import useAxios from '../../lib/samfe/modules/Http/useAxios';
import useHttp from '../../lib/samfe/modules/Http/useHttp';
import { PurchaseDto, PurchaseModel, PurchaseRelationsBluePrint } from './PurchaseTypes';


const usePurchase = () => {
    const axios = useAxios();

    const purchaseHttp = useHttp<PurchaseModel, PurchaseDto, PurchaseRelationsBluePrint>({
        endpoint: 'purchases'
    });

    const markAsSent = async(purchaseId: number) => {
        await axios.post(`purchases/${ purchaseId }/sent`, {});
    };

    return {
        ...purchaseHttp,
        markAsSent
    }
};

export default usePurchase;