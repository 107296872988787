import React from 'react';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import { TableActionButton, TableDestructiveButton } from '../../../button';
import { TableActionProps } from '../../types/TableAction';


const TableActionButtonComponent = <T extends object>({
    disabled = false,
    onClick,
    children,
    variation = 'primary',
    item,
    className
}: TableActionProps<T>) => {

    return (
        <div>

            { variation == 'primary' && <TableActionButton
                {...item}
                className={classNames('w-full', className)}
                disabled={disabled}
                onClick={ async() => {
                    if (!disabled) {
                        await onClick(item);
                    }
                } }
            >{ children }</TableActionButton> }

            { variation == 'destructive' && <TableDestructiveButton
                {...item}
                disabled={disabled}
                className={classNames('w-full', className)}
                onClick={ async() => {
                if (!disabled) {
                    await onClick(item);
                }
            } }>{ children }</TableDestructiveButton> }
        </div>
    );
};

export default TableActionButtonComponent;
