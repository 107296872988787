import { isNaN } from 'formik';


/**
 *
 * @param value
 * @param precision
 */
export const round = (value: number|undefined, precision: number): number => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round((value ?? 0) * multiplier) / multiplier;
};


/**
 *
 * @param value
 */
export const thousandSeparator = (value?: number|string): string => {
    if (isNaN(value) || value === 0) {
        return '0';
    }

    const safeNum = parseFloat(`${ value }`);
    const base = Math.floor(safeNum);
    const decimals = safeNum - base;

    let val = `${ base }`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (decimals>0) {
        const decLen = `${ safeNum }`.length - `${ base }`.length;
        const decParsed = `${ safeNum }`.split('.')[1].substring(0, decLen - 1);
        val = val.concat(`,${ decParsed }`);
    }
    return val;
};


/**
 *
 * @param number
 * @param precision
 */
export const toPercentageString = (number: number|undefined, precision: number = 2): string =>
    `${ thousandSeparator(round(number ?? 0, precision)) }%`;


/**
 * Get percentage string of amount from total.
 *
 * @param amount
 * @param total
 */
export const amountToPercentage = (amount: number|undefined, total: number|undefined): string => {
    const safeTotal = total === undefined || total === 0 ?0 :total;
    return toPercentageString((100 / safeTotal) * (amount ?? 0));
};


export const valutaThousandSeperator = (value?: number|string): string => isNaN(value) || value === 0 ?'0' :`${ value }`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

/**
 * Parse amount to Euro string.
 *
 * @param amount
 * @constructor
 */
export const toEuroString = (amount?: number|string): string => valutaThousandSeperator(
    new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
    }).format(typeof amount === 'string'
              ?parseFloat(amount ?? '0.00')
              :round(amount, 2) ?? 0.00
    )
);

export const sortByNumber = (a?: number, b?: number, sort: 'ASC'|'DESC' = 'ASC'): number => {
    if (a == b) {
        return 0;
    }

    const order: 1|-1 = sort === 'ASC' ?1 :-1;
    const aIsSmaller = -1 * order;
    const bIsSmaller = 1 * order;
    if (!a) {
        return aIsSmaller;
    }

    if (!b) {
        return bIsSmaller
    }
    return a<b ?aIsSmaller :bIsSmaller;
};