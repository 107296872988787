import useAxios from '../../../../lib/samfe/modules/Http/useAxios';
import useHttp from '../../../../lib/samfe/modules/Http/useHttp';
import { ElementRiskDto, ElementRiskModel, ElementRiskPostPayload, ElementRiskRelationsBluePrint } from './ElementRiskTypes';


const useElementRisk = (elementId?: number) => {
    const http = useHttp<ElementRiskModel, ElementRiskDto, ElementRiskRelationsBluePrint>({
        endpoint: `elements/${ elementId }/risks`
    });


    const { post } = useAxios();

    const create = async(payload: ElementRiskPostPayload) => await post<ElementRiskPostPayload>(
        `elements/${ elementId }/risks`,
        payload
    );

    return {
        ...http,
        create
    };
};
export default useElementRisk;
