import { RequestParams, UseHttp } from '../../../lib/samfe/modules/Http/useHttp';
import usePagination from '../../../lib/samfe/modules/Http/usePagination';
import { MinimumModel } from '../../../lib/samfe/types/ModelTypes';
import useTableTestDataHook, { TestTableDto, TestTableFields, TestTableModel, TestTableRelationsBlueprint, TestTableSearchCols } from './useTableData.test.hook';


const useTableHttpTestHook = (): UseHttp<TestTableModel, TestTableDto, TestTableRelationsBlueprint> => {

    const { pagination, setPagination } = usePagination<TestTableModel>();
    const httpData = useTableTestDataHook();

    /**
     *
     * @param {number} id
     * @param {RequestParams<Partial<TestTableModel>, TestTableRelationsBlueprint>} config
     * @param {boolean} omitId
     * @returns {Promise<TestTableModel | undefined>}
     */
    const getItem = async(id?: number, config?: RequestParams<Partial<TestTableModel>, TestTableRelationsBlueprint>, omitId: boolean = false): Promise<TestTableModel|undefined> => {
        if (config) {
            console.warn('Http config not supported for useTestTableHttpHook::getItem');
        }

        if (omitId) {
            console.warn('omitId not supported for useTestTableHttpHook::getItem');
        }
        return await httpData.getItem(id);
    };

    /**
     *
     * @param {RequestParams<Partial<TestTableModel>, TestTableRelationsBlueprint>} config
     * @returns {Promise<TestTableModel[]>}
     */
    const getList = async(config?: RequestParams<Partial<TestTableModel>, TestTableRelationsBlueprint>): Promise<TestTableModel[]> => {

        const currentPage = config?.page ?? pagination.currentPage;
        const perPage = (config?.limit == 'all' ?-1 :config?.limit) ?? pagination.perPage;
        const sortDir = config?.order ?? pagination.sortDir;
        const sortCol = (config?.orderBy ?? pagination.sortCol) as keyof TestTableFields|keyof MinimumModel|undefined;
        const filter = config?.filter ?? pagination.filter;
        const search = config?.search ?? pagination.search;
        const searchCols = [...(config?.searchCols??[]), ...(config?.relationSearch??[])]
        const res = await httpData.getList(
            perPage,
            currentPage,
            sortDir,
            sortCol,
            filter,
            searchCols as TestTableSearchCols,
            search
        );

        console.log('test data causing changes')
        setPagination({
            ...pagination,
            totalItems: res.total
        });
        return res.items ?? [];
    };

    /**
     *
     * @param {TestTableDto} payload
     * @returns {Promise<{id: number} | undefined>}
     */
    const create = async(payload: TestTableDto): Promise<{ id: number }|undefined> => {
        return await httpData.create(payload);
    };

    /**
     *
     * @param {number | undefined} id
     * @param {TestTableDto} payload
     * @returns {Promise<TestTableModel | undefined>}
     */
    const update = async(id: number|undefined, payload: TestTableDto): Promise<TestTableModel|undefined> => {
        return await httpData.update(id, payload);
    };

    /**
     *
     * @param {number} id
     * @returns {Promise<{id: number} | undefined>}
     */
    const destroy = async(id: number): Promise<{ id: number }|undefined> => {
        return await httpData.destroy(id);
    };

    return {
        getList,
        getItem,
        create,
        update,
        destroy,
        pagination: { pagination, setPagination }
    };
};

export default useTableHttpTestHook;
