import React, { FC, useMemo, useState } from 'react';
import { BREADCRUMB_HEIGHT_NUM_IN_REM } from '../../../lib/samfe/components/BreadCrumb';
import { classNames } from '../../../lib/samfe/modules/Parse/String';
import { NAVBAR_HEIGHT_NUM_IN_REM } from '../../../templates/layout/Navbar';
import { SecondaryCreateButton } from '../../button';
import FilterSelectComponent from '../components/stateful/FilterSelect.component';
import SearchInputComponent from '../components/stateful/SearchInput.component';
import { useTableContext } from '../providers/Table.provider';


export const TABLE_FILTER_SECTION_HEIGHT_NUM_IN_REM = 3.5625;
export const TABLE_FILTER_SECTION_HEIGHT_STR_IN_REM = `${ TABLE_FILTER_SECTION_HEIGHT_NUM_IN_REM }rem`;


const FilterSection: FC = <T extends object, R extends string>() => {

    const { styling, forms } = useTableContext<T, R>();

    const [ showCreateModal, setShowCreateModal ] = useState(false);

    const scrollOffset = useMemo(() => {
        // @todo implement
        const pivotOffset = styling?.isPivot ?'0' :0;
        return `calc(${ NAVBAR_HEIGHT_NUM_IN_REM }rem + ${ BREADCRUMB_HEIGHT_NUM_IN_REM }rem + ${ pivotOffset }px - 1px)`;

    }, [ styling?.isPivot ]);

    const CreatePivotForm = useMemo(() => {
        if (forms?.createPivot?.hide === true) {
            return undefined;
        }
        return forms?.createPivot?.Form;
    }, [ forms?.createPivot?.Form, forms?.createPivot?.hide ]);


    return (
        <>
            <section
                className={ 'sticky z-[3] bg-white px-4 sm:px-10 lg:px-12  border-y border-y-gray-200 w-full' }
                style={ {
                    top: scrollOffset,
                    minHeight: `calc(${ TABLE_FILTER_SECTION_HEIGHT_STR_IN_REM })`
                } }
            >
                <div className={ 'grid md:flex gap-x-4 gap-y-0 md:flex-row md:justify-end w-full' }>
                    <div className={ 'grid grid-cols-4 md:grid-cols-1 md:inline-flex gap-x-4 md:gap-x-0 md:pt-1 md:items-center' }>

                        <div className={ classNames(
                            'md:inline-flex items-center gap-x-4 flex-row-reverse',
                            CreatePivotForm != undefined ? 'col-span-3': 'col-span-4 flex items-center content-center all-children:!m-0 pt-1.5'
                        ) }>
                            <div className={'flex-1'}>
                                <SearchInputComponent/>
                            </div>
                            <div className={'flex-1 pt-0.5'}>
                                <FilterSelectComponent/>
                            </div>
                        </div>

                        { CreatePivotForm != undefined && <div className={ 'pt-4 md:pt-0 col-span-1' }>
                            <SecondaryCreateButton onClick={ () => setShowCreateModal(true) }/>
                        </div> }

                    </div>
                </div>
            </section>

            {CreatePivotForm != undefined && <CreatePivotForm
                open={showCreateModal}
                setOpen={setShowCreateModal}
                parentId={forms?.createPivot?.parentId} onSuccess={(id) => {
                    forms?.createPivot?.onSuccess?.(id)
                }}
            />}
        </>
    );
};

export default FilterSection;
