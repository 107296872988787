import React, {useEffect, useState} from "react";
import {classNames} from "../../../lib/samfe/modules/Parse/String";
import { Switch as HeadlessUiSwitch } from '@headlessui/react'
import {FieldProps} from "./InputTypes";

type Props = FieldProps & {
    id: string
    active: boolean
}

/**
 *
 * @param props
 * @constructor
 * @deprecated
 */
const Switch: React.FC<Props> = (props): JSX.Element => {


    /**
     *
     */
    const [initialized, setInitialized] = useState(false);
    const [toggle, setToggle] = useState(props.active);
    const [mock, setMock] = useState(false);


    /**
     * Mock input event to trigger change in form.
     */
    const dispatchInputEvent = (id: string) => {
        (document.getElementById(id)! as HTMLInputElement)
            .dispatchEvent(new Event('input', { bubbles: true} ));
    }

    /**
     *
     */
    useEffect(() => {
        setInitialized(true)
    }, [props.active]);


    /**
     *
     */
    useEffect(() => {
        setInitialized(true)
    }, [props.value]);


    /**
     *
     */
    useEffect(() => {
        if (!initialized) {
            return;
        }
        if (props.onChange) {
            props.onChange(props.name, `${props.value}`)
        }
        if (toggle) {
            dispatchInputEvent(props.id);
            return;
        }
        setMock(true)
    }, [toggle]);


    /**
     *
     */
    useEffect(() => {
        if (!initialized) {
            return;
        }
        if (mock) {
           dispatchInputEvent(`mock-${props.id}`);
           setMock(false);
       }
    }, [mock]);


    return (
        <>
            {toggle ? <input type="hidden" name={props.name} id={props.id} value={props.value} />: <></>}
            {mock ? <input type="hidden" name={props.name} id={`mock-${props.id}`} value={props.value} />: <></>}

            <div className="py-3 text-left flex items-start">
                <div className="flex items-center h-5 w-full">
                    <div className="ml text-sm w-[calc(100%-2rem)] lg:hidden">
                        <label htmlFor="comments" className="font-medium text-graphite">
                            {props.label}
                        </label>
                    </div>
                    <HeadlessUiSwitch
                        checked={toggle}
                        onChange={setToggle}
                        className={classNames(
                            toggle ? 'bg-aqua' : 'bg-gray-200',
                            'relative inline-flex h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky focus:ring-offset-2'
                        )}
                    >
                        <span className="sr-only">Use setting</span>
                        <span
                            aria-hidden="true"
                            className={classNames(
                                toggle ? 'translate-x-6' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                        >

                        <span className={classNames(
                            "material-icons pt-0.5 text-white right-6 absolute transition duration-200 ease-in-out",
                            toggle ?"opacity-100" : 'opacity-0'
                        )}>done</span>

                        <span className={classNames(
                            "material-icons pt-0.5 text-fire left-6 absolute transition duration-200 ease-in-out",
                            !toggle ?"opacity-100" : 'opacity-0'
                        )}>close</span>

                        </span>
                    </HeadlessUiSwitch>
                    <div className="ml-3 text-sm hidden lg:block">
                        <label htmlFor="comments" className="font-medium text-graphite">
                            {props.label}
                        </label>
                    </div>
                </div>

            </div>
            {/*<ErrorMessage message={errorMsg} />*/}
        </>
    )
}
export default Switch;