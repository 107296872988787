import React from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import { optionIsSelected } from '../../../../lib/samfe/components/Form/Support/FieldSupport';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { getMetricUnits } from '../../../../lib/samfe/modules/Parse/Metric';
import CategoryForm from '../../../category/CategoryForm';
import useCategory from '../../../category/useCategory';
import CompoundForm from '../../../compound/CompoundForm';
import useCompound from '../../../compound/useCompound';
import { ElementDto, ElementModel, ElementRelationsBluePrint } from '../../ElementTypes';
import useElement from '../../useElement';


const ElementCompoundForm: React.FC<ExtendFormModalProps<ElementDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    const element = useElement();

    /**
     * @param initialElement
     */
    const shape = (
        initialElement: ElementModel|undefined = undefined
    ): Shape<ElementDto> => ({

        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text'),

        type: Yup.string()
            .controlType('input')
            .inputType('hidden')
            .defaultValue('verbinding'),

        compound_id: Yup.number()
            .label('Verbinding')
            .required()
            .controlType('selectSearch')
            .selectSearchConfig({
                initialModel: initialElement?.compound,
                expectsInitialModel: id !== undefined,
                useHook: useCompound,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    limit: 'all',
                    filter: 'archived=0',
                    displayName: model => `${ model.name }`,
                    FormModal: CompoundForm
                }
            }),


        excipient_id: Yup.number()
            .controlType('input')
            .inputType('hidden'),


        category_id: Yup.number()
            .label('Categorie')
            .required()
            .controlType('selectSearch')
            .selectSearchConfig({
                initialModel: initialElement?.category,
                expectsInitialModel: id !== undefined,
                useHook: useCategory,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    limit: 'all',
                    filter: 'archived=0',
                    displayName: model => `${ model.name }`,
                    FormModal: CategoryForm
                }
            }),


        intake: Yup.number()
            .required()
            .label('Referentie inname')
            .controlType('input')
            .inputType('number')
            .steps(0.01)
            .defaultValue(initialElement?.intake?? 0)
            .min(0),


        unit: Yup.string()
            .required()
            .label('RI eenheid')
            .controlType('select')
            .multiSelect(false)
            .options(getMetricUnits.map((unit, i) => ({
                displayName: unit ?? '',
                value: unit,
                selected: optionIsSelected(initialElement?.unit ?? 'mg', unit, i)
            }))),

        comments: Yup.string()
            .label('Notities')
            .controlType('textArea'),

        is_reviewed: Yup.boolean()
            .label('Grondstof risico\'s gecontrolleerd')
            .controlType('checkbox')
    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<ElementDto>(shape());

    const initializer = async() => {
        const elementRecord = await element.getItem(id, { with: [ 'compound', 'category' ] });
        setShape(shape(elementRecord));
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<ElementModel, ElementDto, ElementRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useElement,
        onSuccess: onSuccess
    });


    return <FormModal
        id={ id }
        resource={ 'Actieve stof' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default ElementCompoundForm;
