import useAxios from '../../../../lib/samfe/modules/Http/useAxios';
import useHttp from '../../../../lib/samfe/modules/Http/useHttp';
import { ElementRiskCodeDto, ElementRiskCodeModel, ElementRiskCodePostPayload, ElementRiskCodeRelationsBluePrint } from './ElementRiskCodeTypes';


const useElementRiskCode = (elementId?: number) => {

    const http = useHttp<ElementRiskCodeModel, ElementRiskCodeDto, ElementRiskCodeRelationsBluePrint>({
        endpoint: `elements/${ elementId }/risk-codes`
    });
    const { post } = useAxios();

    const create = async(payload: ElementRiskCodePostPayload) => await post<ElementRiskCodePostPayload>(
        `elements/${ elementId }/risk-codes`,
        payload
    ).then(res => res.data);

    return {
        ...http,
        create
    };
};
export default useElementRiskCode;
