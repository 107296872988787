import React, {FC} from "react";
import ProductForm from "./ProductForm";
import ProductTable from "./ProductTable";
import IndexPage from "../../templates/pages/IndexPage";



const ProductIndex: FC = (): JSX.Element => <IndexPage
    title={"Producten"}
    Table={ProductTable}
    FormModal={ProductForm}
/>
export default ProductIndex;