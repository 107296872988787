import React, { FC, ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Breadcrumbs from '../../lib/samfe/components/BreadCrumb';
import Skeleton from '../../lib/samfe/components/Skeleton/Skeleton';
import { ucFirst } from '../../lib/samfe/modules/Parse/String';
import useAuth from '../../resources/user/useAuth';


export const PAGE_HEADER_HEIGHT_NUM_IN_REM = 3.5;
export const PAGE_HEADER_HEIGHT_STR_IN_REM = `${ PAGE_HEADER_HEIGHT_NUM_IN_REM }rem`;

export type ExtendBasePageProps = {
    title?: ReactNode
    breadcrumb?: string,
    isIndex?: boolean,
    isGuarded?: boolean,
}

type Props = ExtendBasePageProps&{
    actionSection?: (JSX.Element|undefined)[],
    children: ReactNode
}


const BasePage: FC<Props> = ({ breadcrumb, title, children, isIndex, isGuarded = true, actionSection = [] }): JSX.Element => {

    const { redirectIfUnAuthenticated } = useAuth();
    useEffect(() => {
        redirectIfUnAuthenticated(isGuarded);
    }, []);

    return (
        <>
            <Helmet>
                <title>{ ucFirst(breadcrumb) } | Great Applications</title>
            </Helmet>
            <Breadcrumbs breadcrumb={ breadcrumb } isIndex={ isIndex }/>
            <div className="mx-4 sm:mx-10 lg:mx-12">

                <div style={ { height: PAGE_HEADER_HEIGHT_STR_IN_REM } } className="flex items-center justify-between gap-x-2">
                    <h1 className="text-lg font-medium text-aqua">
                        { title ?? <Skeleton type={ 'h1' }/> }
                    </h1>
                    <div className="flex gap-x-2">
                        { actionSection.map((action, i) => action && <div key={ i } className={ 'inline' }>{ action }</div>) }
                    </div>
                </div>

                <div className={ 'relative' }>
                    { children }
                </div>
            </div>
        </>
    );
};
export default BasePage;