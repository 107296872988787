import {useNavigate, useParams} from "react-router-dom";
import useToaster from "../../components/Toaster/useToaster";

const useId = (mandatory: boolean = true) => {

    const { id } = useParams();
    const {setToasterProps} = useToaster()
    const navigate = useNavigate()

    if (id === undefined || isNaN(+id!)) {
        if (mandatory){
            navigate('/dashboard')
            setToasterProps({
                title: 'Pagina niet gevonden',
                type: "error",
                show: true
            })
            throw new Error(`Invalid id "${id}" provided.`);
        }
    }
    return parseInt(id!);
}
export default useId;