import React, {FC} from "react";
import TopButton from '../../lib/samfe/components/Button/TopButton';
import IndexPage from "../../templates/pages/IndexPage";
import PrinterForm from "./PrinterForm";
import PrinterTable from "./PrinterTable";
import {useNavigate} from "react-router-dom";


const PrinterIndex: FC = (): JSX.Element => {
    const navigate = useNavigate();

    return <IndexPage
        title={"Printers"}
        Table={PrinterTable}
        FormModal={PrinterForm}
        actionButton={<TopButton
            style={'secondary'}
            icon={''}
            text={'Taken'}
            onClick={() => navigate('/settings/printer-jobs')}
        />}
    />
}
export default PrinterIndex;