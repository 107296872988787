import useProfile, {Profile} from "./useProfile";
import useCookie from "../../lib/samfe/modules/Cookie/useCookie";
import useToken from "../../lib/samfe/modules/Cookie/useToken";

export type Identity = {
    id: number,
    token: string
}

export type Account = {
    email: string,
    name: string,
}

export type SetUser = Identity & Account;

export type User = {
    id?: number,
    token?: string,
    profile?: Profile,
    roles?: string[]
}

type UseCurrentUser = {
    user: User,
    setUser: (user: SetUser) => void,
    destroyUser: () => void
}

/**
 *
 */
const useCurrentUser = (): UseCurrentUser => {

    const cookie = useCookie('user');
    const tokenHook = useToken();
    const profileHook = useProfile();


    /**
     *
     */
    const getId = (): number|undefined => {
        const id = parseInt(cookie.data ?? '');
        return !isNaN(id) ? id: undefined;
    }


    /**
     *
     */
    const getUser = (): User => ({
        id: getId(),
        token: tokenHook.token,
        profile: profileHook.profile,
        roles: [] // @todo
    });


    const destroyUser = () => {
        cookie.destroy();
        tokenHook.destroyToken();
        profileHook.destroyProfile()
    }


    /**
     *
     * @param name
     * @param email
     * @param token
     * @param id
     */
    const setUser = ({name, email, token, id}: SetUser): void => {
        tokenHook.setToken(token)
        profileHook.setProfile({name, email})
        cookie.setData(`${id}`);
    }

    return {
        user: getUser(),
        setUser,
        destroyUser
    }
}
export default useCurrentUser;