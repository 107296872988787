import {Buffer} from "buffer";


/**
 * Format string to small letters with Capital first.
 *
 * @param {string} str
 * @returns {string}
 */
export const ucFirst = (str?:string|number): string => {
    return (str && typeof str === 'string' && str[0].toUpperCase() + str.slice(1)) || str?.toString() || '';
};


/**
 * Add comma seperated classnames to components.
 *
 * @param {(string|false|undefined)[]} classNames
 * @returns {string}
 */
export const classNames = (...classNames: (string|undefined|false)[]): string => classNames.filter(Boolean).join(' ')


/**
 * Convert any type to base 64 encoded string.
 *
 * @template T
 * @param {T} data
 * @returns {string}
 */
export const Base64Encode = <T>(data: T): string => Buffer.from(JSON.stringify(data)).toString('base64');


/**
 * Convert any type to base 64 encoded string.
 *
 * @template T
 * @param {string|undefined} data
 * @returns {T|undefined}
 */
export const Base64Decode = <T>(data?: string): T|undefined => {
    if (!data) {
        return undefined;
    }

    try {
        return JSON.parse( Buffer.from(data, 'base64').toString() );
    }
    catch {
        return undefined;
    }
}


