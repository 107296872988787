import React, { FC } from 'react';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import useRiskRegulation from '../../../riskRegulation/useRiskRegulation';
import BaseElementRiskResourceTable from '../BaseElementRiskResourceTable';
import useElementRiskRegulation from './useElementRiskRegulation';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ElementRiskRegulationTable: FC<Props> = ({ parentId }): JSX.Element => (
    <BaseElementRiskResourceTable
        resourceName={ 'Risico regulatie' }
        usePivotHook={ useElementRiskRegulation }
        foreignKey={ 'risk_regulation_id' }
        useResourceHook={ useRiskRegulation }
        parentId={ parentId }
    />
);

export default ElementRiskRegulationTable;
