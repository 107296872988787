import React from 'react';
import { APP_NAME } from '../../config/config';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import useCurrentUser from '../../resources/user/useCurrentUser';
import BasePage from '../../templates/pages/BasePage';


const Dashboard = () => {

    const { user: { profile } } = useCurrentUser();
    return (
        <BasePage
            title={ 'Dashboard' }
            breadcrumb={ 'Dashboard' }
        >
            <h2 className={ 'text-lg font-medium mb-4' }>Hoi { profile?.name }, welkom bij { APP_NAME }!</h2>
            <ExampleGraph/>
        </BasePage>
    );

};

export default Dashboard;