import { MetricUnit } from '../../../../lib/samfe/modules/Parse/Metric';
import { AssessmentModel } from '../../../assessment/types/AssessmentTypes';
import {RiskModel} from '../../../risk/RiskTypes';
import { MinimumModel, SoftDeleteModel } from '../../../../lib/samfe/types/ModelTypes';
import {ucFirst} from '../../../../lib/samfe/modules/Parse/String';


export type Gradation = 'geen'|'laag'|'medium'|'hoog';

export const Gradations: Gradation[] = [
    'geen', 'laag', 'medium', 'hoog'
];

/**
 *
 * @param gradation
 */
export const NormalizeGradation = (gradation?: string|null): Gradation => {
    switch (gradation?.toLowerCase()) {
        case 'geen': return 'geen';
        case 'laag': return 'laag';
        case 'medium': return 'medium';
        case 'hoog': return 'hoog';
        default: return 'geen';
    }
}

/**
 *
 * @param gradation
 * @returns {Gradation} with Capital first.
 */
export const GetGradationName = (gradation?: string|null): string => ucFirst(NormalizeGradation(gradation))


/**
 * Get severity of gradation, higher number is higher severity.
 *
 * @param {Gradation} gradation
 * @returns {number} severity
 */
export const GetGradationSeverity = (gradation: Gradation): number => {
    const severity = Gradations.indexOf(gradation);
    return severity >= 0 ? severity : 0;
}


export type ProductRiskRelationsBluePrint = 'product'|'assessments'|'risk'

export type ProductRiskFields = {
    product_id?: number,
    risk_id?: number,
    gradation?: Gradation,
    frequency?: number,
    blocked_for_assessment?: boolean
    blocked_comment?: string
}

export type ProductRiskDto = Partial<ProductRiskFields>

export type ProductRiskRelations = {
    risk: RiskModel
    assessments: AssessmentModel[]
}

export type ProductRiskModel = SoftDeleteModel & Partial<ProductRiskRelations> & ProductRiskDto

export type ProductRiskRiskCodeItem = {
    element_risk_code_id: number,
    name: string
}

export type ProductRiskElementItem = {
    id: number,
    review_id: number,
    name: string,
    // @info should be defined in element type def
    type: 'verbinding'|'hulpstof',
    excipient_name: string|null,
    compound_name: string|null,
    composition_id: number,
    composition_amount: 15,
    composition_unit: MetricUnit,
    is_reviewed: boolean,
    risk_codes: ProductRiskRiskCodeItem[]
}

export type ProductRiskItem = MinimumModel & {
    id: number|null,
    product_id: number|null,
    risk_id: number,
    name: string,
    gradation: Gradation|null,
    frequency: number|null,
    blocked_for_assessment?: boolean,
    blocked_comment?: string,
    elements: ProductRiskElementItem[]
}