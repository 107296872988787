import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import ProductTable from '../../product/ProductTable';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const IngredientProductTable: FC<Props> = ({ parentId }): JSX.Element => {

    return <ProductTable
        withRelations={ [ 'ingredientProducts' ] }
        hideEdit={true}
        hideArchive={true}
        filter={ {
            column: 'ingredientProducts.ingredient_id',
            operator: '=',
            value: parentId
        } }
    />;
};

export default IngredientProductTable;