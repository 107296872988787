import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useFormikContext} from "formik";
import {sqlDateTimeToDate} from "../../../modules/Parse/Date";


const useInput = (
    name:string,
    type: JSX.IntrinsicElements['input']['type'],
    initialValue?: JSX.IntrinsicElements['input']['value']
): [typeof initialValue, Dispatch<SetStateAction<typeof initialValue>>] => {
    const [value, setValue] = useState<typeof initialValue>(typeof initialValue === 'string' ?initialValue : undefined);
    const formikCtx = useFormikContext();
    useEffect(() => {
        if (value === undefined) {
            return;
        }
        let castedValue = value;

        if (type === 'date') {
            castedValue = sqlDateTimeToDate(value as string);
        }

        formikCtx.setFieldValue(name, castedValue)
    }, [value]);
    return [value, setValue];
};
export default useInput;
