import { getPackageIdentifier } from '../package/PackageFunctions';
import { ArticleModel } from './ArticleTypes';


/**
 * Parse resource number(s)/id(s) to display number.
 * @example singular: 10 -> 0010
 * @example multi: 1,2,3 -> 0001.0002.0003
 *
 * @param {number[]|undefined[]} numbers
 * @returns {string}
 */
export const parseArticleDisplayNumber = (...numbers: (number|string|undefined)[]): string =>
    numbers.map(n => String(n ?? 0).padStart(4, '0')).join('.');

/**
 * Article should include relations: product, package.parts|package.packageParts.part
 * @param {ArticleModel} article
 * @param withProductName
 * @returns {string}
 */
export const getFullArticleName = (article?: ArticleModel, withProductName = true): string => {
    return `${article?.number ?? '[onbekend_artikel]'} ${withProductName && article?.product ? `${article?.product?.name} ` : ''} ${getPackageIdentifier(article?.package)}`
}