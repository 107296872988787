import React, { FC, useMemo } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { API_URL } from '../../../config/config';
import useFileHandler from '../../document/useFileHandler';
import { SaleFileModel, SaleFileRelationsBluePrint, useCoaSaleFile } from './SaleDocTypes';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const CoaTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useCoaSaleFile(parentId);
    const { downloadFileByUrl } = useFileHandler();

    const rows = useMemo((): TableColumns<SaleFileModel, SaleFileRelationsBluePrint>[] => [
        {
            header: {
                children: 'Artikelnummer',
                sortCol: 'articleNumber'
            },
            column: (item) => ({
                children: item.articleNumber
            }),
            type: 'text',
            style: {
                width: '16rem'
            }
        },
        {
            header: {
                children: 'Naam',
                sortCol: 'productName'
            },
            column: (item) => ({
                children: item.productName
            }),
            type: 'text',
            style: {
                width: '24rem'
            }
        },
        {
            header: {
                children: 'Batchcode',
                sortCol: 'batchcode'
            },
            column: (item) => ({
                children: item.batchcode
            }),
            type: 'text'
        }
    ], []);

    const getCustomerFileUrl = (item: SaleFileModel, action: 'open'|'download') => (
        `${ API_URL }${ item[action] }?customerId=${ item.customerId }`
    );

    return <>
        <Table
            id={ 'coas' }
            rows={ rows }
            http={ {
                hook: httpHook,
                sortCol: 'articleNumber',
                sortDir: 'ASC',
                showAll: true
            } }
            callbacks={ [
                {
                    title: 'Openen',
                    onClick: (item) => {
                        window.open(getCustomerFileUrl(item, 'open'), '_blank');
                    },
                    disableForRow: (item) => !item.customerId
                },
                {
                    title: 'Downloaden',
                    onClick: (item) => {
                        const name = item.name ?? `coa-${ item.productId }-${ item.batchcode }`; // Latter is a fallback name.
                        downloadFileByUrl(getCustomerFileUrl(item, 'download'), name, 'pdf');
                    },
                    disableForRow: (item) => !item.customerId
                }
            ] }
            styling={ {
                variation: 'buttons'
            } }
        />
    </>;
};

export default CoaTable;