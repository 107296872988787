import React from "react";
import useHttp from "../../../../lib/samfe/modules/Http/useHttp";
import {
    ElementRiskTypeDto,
    ElementRiskTypeModel,
    ElementRiskTypeRelationsBluePrint
} from "./ElementRiskTypeTypes";


const useElementRiskType = (id?: number) => useHttp<ElementRiskTypeModel, ElementRiskTypeDto, ElementRiskTypeRelationsBluePrint>({
    endpoint: `elements/${id}/risk-types`
});
export default useElementRiskType;
