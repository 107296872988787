import React, {FC, useEffect, useState} from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { HighestValueOfObjectArray } from '../../../../lib/samfe/modules/Parse/Object';
import { filterIds } from '../../../../lib/samfe/modules/Parse/Url';
import { modelNotArchived } from '../../../../lib/samfe/types/ModelTypes';
import PackagePartForm from '../../../packagePart/PackagePartForm';
import usePackagePart from '../../../packagePart/usePackagePart';
import usePackage from '../../usePackage';
import { PackagePartPivotDto, PackagePartPivotModel, PackagePartPivotRelationsBluePrint } from './PackagePartPivotTypes';
import usePackagePartPivot from './usePackagePartPivot';
import {SelectOption} from "../../../../lib/samfe/components/Form/Effects/useSelect";
import useAxios from "../../../../lib/samfe/modules/Http/useAxios";


const PackagePartPivotForm: FC<ExtendFormModalProps<PackagePartPivotDto>> = ({ parentId, id, open, setOpen, onSuccess }): JSX.Element => {

    const packagePart = usePackagePartPivot(parentId);
    const packageHook = usePackage();
    const [ initialPackagePart, setInitialPackagePart ] = useState<PackagePartPivotModel|undefined>(undefined);
    const axios = useAxios();

    const [highestOrder, setHighestOrder] = useState<number>(0)
    const [desiredType, setDesiredType] = useState<string|undefined>(undefined)
    const [usedIds, setUsedIds] = useState<number[]>([])
    const [partTypes, setPartTypes] = useState<string[]>(['Deksel'])

    const currentPackagePart = () => id ?<span className={ 'text-graphite font-medium' }>{ initialPackagePart?.part?.name }</span> :<></>;

    /**
     * Monkey patch for limit all.
     */
    const getFilter = () => (filterIds({ ids: usedIds, exclude: true }) ?? 'archived=0') +
        (desiredType !== undefined ? `,type=${desiredType}` : '');

    const shape = (initialPackagePart?: PackagePartPivotModel): Shape<PackagePartPivotDto> => ({
        id: Yup.number()
            .inputType('hidden')
            .controlType('input'),

        package_id: Yup.number()
            .inputType('hidden')
            .controlType('input'),

        type: Yup.string()
            .required()
            .label('Onderdeel type')
            .controlType('select')
            .options(partTypes.map((type, i) => ({
                displayName: type,
                value: type,
                selected: desiredType !== undefined ? type === desiredType : i === 0
            }) as SelectOption))
            .handleValueChange(value => setDesiredType(value)),

        part_id: Yup.number()
            .label('Verpakkingsonderdeel')
            .required()
            .hidden(id !== undefined)
            .controlType('selectSearch')
            .selectSearchConfig({
                expectsInitialModel: false,
                useHook: usePackagePart,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    limit: 'all',
                    displayName: model => `${ model.name }`,
                    filter: getFilter(),
                    FormModal: PackagePartForm
                }
            }),

        order: Yup.number()
            .hidden(true)
            .label('Volgorde')
            .controlType('input')
            .inputType('number')
            .defaultValue(initialPackagePart?.order ?? highestOrder ?? 0)
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<PackagePartPivotDto>(shape());

    // @todo reset states on re init
    const initializer = async() => {
        axios.get<{items: string[]}>('part-types').then(
            result => setPartTypes(result.data?.items ?? [])
        )

        packagePart.getItem(id, { with: [ 'part', 'package.parts' ] }).then(model => {
            setInitialPackagePart(model);
        });

        packageHook.getItem(parentId, { with: [ 'packageParts' ] })
            .then(pack => {
                const parts = pack?.packageParts ?? [];
                setHighestOrder( HighestValueOfObjectArray(parts, 'order') + 1 );
                setUsedIds( parts
                    .filter(part => modelNotArchived(part.archived))
                    .map(part => part.part_id!));
            });
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<PackagePartPivotModel, PackagePartPivotDto, PackagePartPivotRelationsBluePrint>({
        id,
        initializer,
        initialized: useAsyncInit(initializer, open),
        validationSchema,
        useHttpHook: usePackagePartPivot,
        parentId: parentId,
        onSuccess: onSuccess,
        morphPayload(_, dto) {
            dto.package_id = parentId;
            delete dto.type;
            return dto;
        }
    });

    useEffect(() => {
        setShape(shape(initialPackagePart));
    }, [partTypes, initialPackagePart, usedIds, desiredType]);


    return <FormModal
        id={ id }
        parentId={ parentId }
        resource={ 'Verpakkingsonderdeel' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        htmlBeforeForm={ currentPackagePart() }
    />;
};

export default PackagePartPivotForm;
