import React, { FC, useState } from 'react';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import useId from '../../lib/samfe/modules/Router/useId';
import ShowPage from '../../templates/pages/ShowPage';
import ExcipientForm from './ExcipientForm';
import { ExcipientModel } from './ExcipientTypes';
import ExcipientElementTable from './pivot/ExcipientElementTable';
import useExcipient from './useExcipient';


const ExcipientShow: FC = (): JSX.Element => {

    const [ currentExcipient, setCurrentExcipient ] = useState<ExcipientModel|undefined>(undefined);

    return (
        <ShowPage
            title={ currentExcipient?.name! }
            breadcrumb={ currentExcipient?.name! }
            setCurrentModel={ setCurrentExcipient }
            FormModal={ ExcipientForm }
            httpHook={ useExcipient }
            tabs={ currentExcipient ?[
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <ExampleGraph/>
                    </>
                },
                {
                    name: 'Hulpstoffen',
                    id: 'elements',
                    element: <>
                        {currentExcipient.id && <ExcipientElementTable parentId={ currentExcipient.id }/> }
                    </>
                }
            ] :[] }
        />
    );
};
export default ExcipientShow;