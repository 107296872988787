import { Combobox } from '@headlessui/react';
import { Field } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { RequestParams } from '../../../../modules/Http/useHttp';
import { classNames } from '../../../../modules/Parse/String';
import { MinimumModel } from '../../../../types/ModelTypes';
import { DeleteButton } from '../../../Button';
import FormModalContextProvider from '../../../Modal/FormModal/FormModalContextProvider';
import Label from '../../Caption/Label';
import { ErrorMessage } from '../../index';
import { requiredFieldClassname } from '../../Support/FieldSupport';
import { SelectSearchConfig } from './SelectSearchTypes';
import useFormikSelectSearch from './useFormikSelectSearch';


type Props<Model extends MinimumModel, Dto extends Partial<Model>, RelationBlueprint extends string> = {
    label: string;
    name: string;
    required?: boolean
    disabled?: boolean,
    config: SelectSearchConfig<Model, Dto, RelationBlueprint>,
    className?: string
    omitFormik?: boolean,
    omitLabel?: boolean,
    autoFocus?: boolean,
}


/**
 * Search for value through API.
 */
const SelectSearch = <
    Model extends MinimumModel,
    Dto extends Partial<Model>,
    RelationBlueprint extends string
>({ config, ...props }: Props<Model, Dto, RelationBlueprint>): JSX.Element => {

    /**
     * Init search config
     */
    const { initialModel, onChange, parentId, useHook, searchOptions, expectsInitialModel } = config;

    /**
     * Init search options
     */
    const { searchCols, filter, whereIn, valueCol, relations, displayName, relationSearch, limit, select } = searchOptions;

    /**
     * Init Formik support
     */
    const { field, meta, invalid, setFormikModel, formikCtx } = useFormikSelectSearch<Model>({
        name: props.name,
        valueCol,
        omitFormik: props.omitFormik
    });

    /**
     * Init api hook
     */
    const hook = useHook(parentId);
    const inputRef = useRef<HTMLInputElement>(null);
    const inputSize = useResizeDetector();

    /**
     * Init states
     */
    const [ initialModelOverwrite, setInitialModelOverwrite ] = useState(expectsInitialModel);
    const [ initialized, setInitialized ] = useState(!(!limit));
    const [ initialFetched, setInitialFetched ] = useState(false);

    const [ models, setModels ] = useState<Model[]>([]);
    const [ selectedModel, setSelectedModel ] = useState<Model|undefined>(models[0]);
    const [ query, setQuery ] = useState(initialModel ?displayName(initialModel) :undefined);
    const [ open, setOpen ] = useState(false);
    const [ hasSearched, setHasSearched ] = useState(false);
    const [ showCreateOptionForm, setShowCreateOptionForm ] = useState(false);

    /**
     * Listen for search trigger.
     */
    const [ search, setSearch ] = useState(false);
    const [ filterChanged, setFilterChanged ] = useState(false);
    const [ filtered, setFiltered ] = useState<Model[]>([]);
    const [ internalFormikChange, setInternalFormikChange ] = useState(false);
    const [ prevFilter, setPrevFilter ] = useState<string>();



    useEffect(() => {
        if (props.autoFocus === true) {
            inputRef.current?.focus();
        }
    }, []);

    /**
     * Bootstrap.
     */
    useEffect(() => {
        // getModels();
        // Hide dropdown on scroll
        // @fixme remove fixed position of dropdown (overflow problem with modal)
        const modalContainer = document.getElementsByClassName('modal-container');
        modalContainer.length>0 && modalContainer[0].addEventListener('scroll', () => {
            setOpen(false);
        }, { passive: true });
    }, []);

    //useEffect(() => {
    //    if (selectedModel || initialModel) {
    //        return;
    //    }
    //    if (models.length === 0) {
    //        return;
    //    }
    //    const firstModel = models[0];
    //    setFormikModel(initialModel);
    //    setSelectedModel(firstModel);
    //    onChange?.(firstModel);
    //}, [initialFetched]);


    /**
     * Handle initial model.
     */
    useEffect(() => {
        //noinspection NonBlockStatementBodyJS
        if (!initialModel || selectedModel) {
            return;
        }

        setInitialModelOverwrite(true);
        // @todo sort by display value
        let newSelected = initialModel;
        const modelAlreadyExistsIndex = models.findIndex(model => model[valueCol] === initialModel[valueCol]);
        if (modelAlreadyExistsIndex> -1) {
            newSelected = models[modelAlreadyExistsIndex];
        } else {
            setModels([ ...models, initialModel ]);
        }

        onChange?.(newSelected);
        setSelectedModel(newSelected);
        //setQuery(displayName(newSelected));
        setFormikModel(initialModel);
    }, [ initialModel ]);


    /**
     * Only initialize after first keystroke.
     */
    useEffect(() => {
        if (query !== undefined) {
            setInitialized(true);
        }
    }, [ query ]);


    /**
     * Search trigger
     */
    useEffect(() => {
        if (filterChanged && !search) {
            setSearch(true);
        }
    }, [ filterChanged, search ]);


    /**
     * Get filtered results based on query
     */
    useEffect(() => {
        setFiltered((query ?? '').replaceAll(' ', '') === ''
            ? models
            : models.filter(m => {
                let queryParsed = query ?? '';
                let numberQParsed = Number(queryParsed).toString();
                if (numberQParsed !== 'NaN') {
                    queryParsed = numberQParsed;
                }
                for (const searchCol of searchCols) {
                    if (`${ m[searchCol] }`.toLowerCase().includes((queryParsed).toLowerCase())) {
                        return true;
                    }
                }
                for (const item of (relationSearch??[])) {
                    let targetSearch = m as any;
                    item.relationCol.split('.').forEach(propKey => {
                        targetSearch = targetSearch[propKey as keyof MinimumModel]
                    })
                    if (`${ targetSearch }`.toLowerCase().includes((queryParsed).toLowerCase())) {
                        return true;
                    }
                }
                return false;
            })
        );
    }, [ query, models ]);


    /**
     * Handle filter change
     */
    useEffect(() => {
        //noinspection NonBlockStatementBodyJS

        if (!initialized || (expectsInitialModel && !initialModel) || filter === prevFilter) {
            return;
        }

        setPrevFilter(filter);
        if (initialModelOverwrite) {
            setInitialModelOverwrite(false);
            return;
        }

        setFilterChanged(true);
        setFormikModel(undefined);
        onChange?.(undefined);
        setQuery('');
        setSearch(true);
        setOpen(false);
    }, [ filter, whereIn ]);



    useEffect(() => {

        if (internalFormikChange) {
            setInternalFormikChange(false);
            return;
        }

        if (!selectedModel) {
            return;
        }
        const val = selectedModel[valueCol ?? 'id'];
        if (field?.value !== val && formikCtx && meta?.error !== undefined) {
            formikCtx.setFieldValue(props.name, val);
            meta.error = undefined;
            meta.touched = true;

            setInternalFormikChange(true);
        }
    }, [ field ]);



    /**
     *
     * @param {Model[]} models
     * @returns {Model[]}
     */
    const sortModels = (models: Model[]): Model[] => models.sort(
        (a, b) => `${ displayName(a) }`.localeCompare(`${ displayName(b) }`)
    );


    /**
     * Get models from REST call.
     *
     * @param query
     */
    const getModels = (query?: string) => {
        const config = {
            filter: filter,
            whereIn: whereIn,
            with: relations
        } as RequestParams<Partial<Model>, RelationBlueprint>;

        // select
        if (select !== undefined) {
            config.select = select as (keyof Model|`${ RelationBlueprint }.${ string }`)[];
        }

        // @todo multi col support, single for MVP
        if (query && query.replace(' ', '') !== '') {
            config.search = query;
            const cols = searchCols.map(sc => `${ sc }`);
            cols.push(...(relationSearch??[]).map(item => `${item.relationCol}`))
            config.searchCols = cols
        } else {
            if (limit !== undefined) {
                config.limit = limit;
            }
        }

        hook.getList(config).then(models => {
            setModels(sortModels(models));
        }).finally(() => {
            //noinspection NestedFunctionCallJS
            setHasSearched(true);
            setSearch(false);
            setInitialFetched(true);
        });
    };


    useEffect(() => {
        if (search) {
            if (initialFetched && limit === 'all' && !filterChanged) {
                return;
            }
            getModels(query);
            if (filterChanged) {
                setFilterChanged(false);
            }
        }
    }, [ search ]);




    /**
     * Handle value change
     * @param model
     */
    const handleChange = (model: Model) => {
        onChange?.(model);
        setSelectedModel(model);
        setFormikModel(model);
    };


    /**
     * Listen for query change, remove all if empty query.
     * @param e
     */
    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setQuery(value);
        setSearch(true);

        if (value === '') {
            setSelectedModel(undefined);
            onChange?.(undefined);
        }
    };


    /**
     *
     */
    const handleReset = (): void => {
        setQuery('');
        setSearch(true);
        setSelectedModel(undefined);
        onChange?.(undefined);
        if (inputRef?.current) {
            inputRef.current.value = '';
        }
    };


    /**
     * Search on enter press.
     * @param e
     */
    const handleKeyPress = (e: KeyboardEvent): void => {
        const stayOpen = !(e.key === 'Enter');
        setOpen(stayOpen);
    };


    return <>

        { !props.omitFormik && <Field
            { ...field }
            id={ props.name }
            as={ 'input' }
            className={ 'hidden' }
            data-testid={ field?.name }
            required={ props.required }
            disabled={ props.disabled }
            value={ field?.value ?? '' }
        /> }

        <Combobox
            value={ selectedModel }
            onChange={ handleChange }
            name={ props.name }
            nullable={ true }
            // as="div"
        >

            { !props.omitLabel && <Label
                invalid={ invalid }
                { ...!props.omitFormik && { htmlFor: props.name } }
            >{ props.label }{ props.required && '*' }</Label> }

            <div ref={inputSize.ref} className="relative">
                <Combobox.Input
                    ref={ inputRef }
                    className={ classNames('relative w-full border-0 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset hover:cursor-text ring-gray-300 focus:outline-none focus-visible:ring-inset focus:ring-1 focus:ring-blue-600 sm:text-sm sm:leading-6',
                        props.required && requiredFieldClassname,
                        props.disabled && '!hover:cursor-not-allowed opacity-60',
                        props.className
                    ) }
                    onFocus={ () => setOpen(true) }
                    onBlur={ () => setOpen(false) }
                    // @feature display value makes filtered hidden
                    displayValue={ (selectedModel: Model|undefined) => selectedModel ?displayName(selectedModel) :(query ?? '') }
                    onChange={ handleInput }
                    onKeyUp={ (e: any) => {
                        handleKeyPress(e as KeyboardEvent);
                    } }
                />
                { query && query.replaceAll(' ', '') !== '' && <DeleteButton
                    type={ 'reset' }
                    style={ 'tertiary' }
                    small={ true }
                    onClick={ handleReset }
                    className={ 'absolute right-0 top-1.5' }
                /> }
            </div>
            {/* @todo Select option when initial value is provided */ }
            { open && <>
                <Combobox.Options
                    style={{
                        width: `${inputSize.width}px`,
                        height: `calc(${filtered.length === 0 ? '2.3125rem': (filtered.length >= 10 ? `${10*2.3125}rem`: `${filtered.length*2.3125}rem`)} + 0.5rem)`
                    }}
                    className={ 'fixed z-[1] mt-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'}
                    static={ true }
                    onMouseDown={ (e: any) => {
                        e.preventDefault();
                    } }
                >
                    { filtered.map((item, i) => (
                        <Combobox.Option
                            onClick={ () => setOpen(false) }
                            key={ i }
                            as={ 'li' }
                            value={ item }
                            className={ ({ active, selected }) => classNames(
                                selected && 'bg-sky',
                                active && '!bg-sky-hover',
                                (active || selected) ?'text-white' :'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9 border-b border-b-white hover:cursor-pointer'
                            ) }
                        >
                            { ({ selected }) => <>
                                { displayName(item) }
                                { selected && '' /*@todo selected support*/ }
                            </> }
                        </Combobox.Option>
                    )) }

                    {/* Option for creating resource if no results and form present */ }
                    { (filtered?.length ?? 0) === 0 && searchOptions.FormModal !== undefined && <Combobox.Option
                        onClick={ () => setShowCreateOptionForm(true) }
                        as={ 'li' }
                        value={ undefined }
                        className={ ({ active, selected }) => classNames(
                            selected && 'bg-sky',
                            active && '!bg-sky-hover',
                            (active || selected) ?'text-white' :'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9 border-b border-b-white hover:cursor-pointer'
                        ) }
                    >
                        Nieuw item toevoegen
                    </Combobox.Option> }


                </Combobox.Options>
            </> }

        </Combobox>

        { showCreateOptionForm && searchOptions.FormModal && <>
            <FormModalContextProvider ctx={ {
                formModalContext: { omitCreateAnotherOne: true },
                setFormModalContext: () => {}
            } }>
                <searchOptions.FormModal
                    parentId={ searchOptions.parentId }
                    open={ showCreateOptionForm }
                    setOpen={ setShowCreateOptionForm }
                    onSuccess={ (id) => {
                        hook.getItem(id, {
                            with: searchOptions?.relations ?? []
                        } as RequestParams<Partial<Model>, RelationBlueprint>).then(model => {
                            if (!model) {
                                handleReset();
                            } else {

                                setSelectedModel(model);
                                if (limit === 'all') {
                                    const query = searchOptions.displayName(model);
                                    setQuery(query);
                                    setModels(sortModels([ ...models, model ]));
                                } else {
                                    setSearch(true);
                                }
                                setOpen(false);
                                handleChange(model)
                            }
                            setShowCreateOptionForm(false);
                        });
                    } }
                />
            </FormModalContextProvider>
        </> }


        { filtered.length === 0 && !selectedModel && hasSearched && <ErrorMessage message={ 'Geen resultaten beschikbaar.' }/> }
        { meta && <ErrorMessage meta={ meta }/> }
        {/* @todo formik error message support */ }
    </>;
};

export default SelectSearch;