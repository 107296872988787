import React from "react";
import useHttp from "../../lib/samfe/modules/Http/useHttp";
import {
    CategoryDto,
    CategoryModel,
    CategoryRelationsBluePrint
} from "./CategoryTypes";


const useCategory = () => useHttp<CategoryModel, CategoryDto, CategoryRelationsBluePrint>({
    endpoint: 'element-categories'
});
export default useCategory;
