import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import {FormModal} from "../../lib/samfe/components/Modal";
import React, {useState} from "react";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import Yup from "../../lib/samfe/components/Form/Yup";
import {useForm} from "../../lib/samfe/components/Form";
import useAsyncInit from "../../lib/samfe/components/Form/Effects/useAsyncInit";
import {ProfileDto, ProfileModel, ProfileRelationsBlueprint} from "./ProfileTypes";
import useProfile from "./useProfile";
import useProfileCookie from "../../resources/user/useProfile";

const ProfileForm: React.FC<ExtendFormModalProps<ProfileDto>> = ({open, setOpen, onSuccess}): JSX.Element => {

    const [newName, setNewName] = useState('')
    const {profile, setProfile} = useProfileCookie();

    const updateUserName = () => {
        if (profile !== undefined) {
            setProfile({
                email: profile.email,
                name: newName
            })
        }
    }


    const shape = (name: string = ''): Shape<ProfileDto> => ({
        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text')
            .defaultValue(name)
            .handleValueChange((value) => {
                if (value != undefined) {
                    setNewName(value)
                }
            }),
    })



    const {validationSchema, setShape} = useSchema<ProfileDto>(shape());

    const initializer = async () => {
        const initialName = profile?.name;
        setShape(shape(initialName))
    };

    const {formikConfig, formFields} = useForm<ProfileModel, ProfileDto, ProfileRelationsBlueprint>({
        validationSchema: validationSchema,
        skipInitialFetch: true,
        staticFormMethod: 'PUT',
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useProfile,
        onSuccess: (id) => {
            updateUserName();
            onSuccess?.(id)
        },
    });

    return <FormModal
        // @info might break
        id={0}
        resource={'Profiel'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
}

export default ProfileForm;