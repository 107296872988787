import React, { FC, useState } from 'react';
import Stats from '../../lib/samfe/components/Stats';
import ShowPage from '../../templates/pages/ShowPage';
import PrinterJobForm from './PrinterJobForm';
import { PrinterJobModel } from './PrinterJobTypes';
import usePrinterJob from './usePrinterJob';


const PrinterJobShow: FC = (): JSX.Element => {

    const [ currentPrinterJob, setCurrentPrinterJob ] = useState<PrinterJobModel>();

    return (
        <ShowPage
            title={ currentPrinterJob?.name }
            breadcrumb={ currentPrinterJob?.name }
            setCurrentModel={ setCurrentPrinterJob }
            FormModal={ PrinterJobForm }
            httpHook={ usePrinterJob }
            relations={ [
                'printer'
            ] }

            tabs={ [
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <Stats stats={ [
                            {
                                name: 'Bedoeld voor',
                                value: currentPrinterJob?.printer?.display_name
                            }
                        ] }></Stats>
                    </>
                }
            ]}
        />
    );
};
export default PrinterJobShow;