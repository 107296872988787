import React, {FC} from "react";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import useAsyncInit from "../../lib/samfe/components/Form/Effects/useAsyncInit";
import Yup from "../../lib/samfe/components/Form/Yup";
import useRisk from "./useRisk";
import {RiskDto, RiskModel, RiskRelationsBluePrint} from "./RiskTypes";
import {useForm} from "../../lib/samfe/components/Form";
import {FormModal} from "../../lib/samfe/components/Modal";


const RiskForm: FC<ExtendFormModalProps<RiskDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    /**
     *
     */
    const shape = (): Shape<RiskDto> => ({
        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text'),

    });


    /**
     *
     */
    const {validationSchema, setShape} = useSchema<RiskDto>(shape());

    const initializer = async () => {
        setShape(shape())
    }

    /**
     *
     */
    const { formikConfig, formFields } = useForm<RiskModel, RiskDto, RiskRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useRisk,
        onSuccess: onSuccess
    });


    return <FormModal
        id={id}
        resource={'Risico'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
};

export default RiskForm;
