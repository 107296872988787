import {toPercentageString} from "./Number";

/**
 * Metric units for weight and volume.
 */
export type MetricUnit = 'mcg'|'mg'|'g'|'ml';

export const getMetricUnits: MetricUnit[] = ['mcg','mg','g', 'ml']

/**
 * Convert metric unit to its base (gram/liters)
 */
const metricUnitConversion = {
    mcg: 0.0001,
    mg: 0.001,
    // cg 0.01
    // dg 0.1
    g: 1,

    mcl: 0.0001,
    ml: 0.001,
    // cl 0.01
    // dl 0.1
    l: 1
}

type Weight = {
    amount: number,
    unit: MetricUnit
}

/**
 * Get reference intake of composition product item.
 *
 * @param composition
 * @param element
 * @returns {string} RI% || -
 */
export const getRi = (composition: Weight, element: Weight): string => {

    const compositionInGrams =  composition.amount  * metricUnitConversion[composition.unit];
    const elementInGrams =      element.amount      * metricUnitConversion[element.unit];

    const ri = (100/elementInGrams) * compositionInGrams;
    return isFinite(ri) ? toPercentageString(ri, 1) : (isNaN(ri) ? 'err': '-');
}