import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {batchCodeLabel} from "../../../charge/ChargeFunctions";
import {ChargeModel} from "../../../charge/ChargeTypes";


const ChargeBatchCodeColumn: FC<{
    charge: ChargeModel
}> = ({ charge }) => (
    <Link to={ `/charges/${ charge.id }?current-tab=risk-assessments` } className={ 'underline block' }>
        {batchCodeLabel(charge)}
    </Link>
);

export default ChargeBatchCodeColumn