import {ProductTypeModel} from "../productType/ProductTypeTypes";
import {ProductModel} from "../product/ProductTypes";
import {AttributeOptionModel} from "./pivot/AttributeOptionTypes";
import {AttributeProductModel} from "../product/pivot/attributeProduct/AttributeProductTypes";
import {SoftDeleteModel} from "../../lib/samfe/types/ModelTypes";
import {WithCount} from "../generic/ModelTypeExtensions";

export type AttributeType = 'select'|'multiselect'|'text'|'textarea'|'number';

export const attributeTypes: AttributeType[] = [ 'text', 'select', 'multiselect', 'textarea', 'number']

export type AttributeFields = {
    name?: string,
    description?: string,
    type?: AttributeType,
    order?: number,
    product_type_id?: number,
}

export type AttributeDto = Partial<AttributeFields>

export type AttributeRelations = {
    productType?: ProductTypeModel,
    products?: ProductModel[],
    options?: AttributeOptionModel[], // @fixme invalid type should be resource io pivot
    attributeOptions?: AttributeOptionModel[],
    attributeProducts?: AttributeProductModel[],
}

export type AttributeModel = SoftDeleteModel & Partial<AttributeRelations> & WithCount<Partial<AttributeRelations>> & AttributeDto

export type AttributeRelationsBluePrint = (
    'options'
    |'options.products'
    |'products'
    |'productType'
    |"attributeProducts"
    |"products.attributeProducts"
    |"products.attributeProducts.options"
    |'attributeOptions'
);