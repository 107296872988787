import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import VersionLogForm from './VersionLogForm';
import useProductVersionLog, { VersionLogModel, VersionLogRelationsBluePrint } from './VersionLogTypes';


type Props = ExtendTableProps & {
    productNumber: string
}

const VersionLogTable: FC<Props> = ({productNumber}): JSX.Element => {

    const httpHook = useProductVersionLog();


    const rows = useMemo((): TableColumns<VersionLogModel, VersionLogRelationsBluePrint>[] => [
        {
            header: {
                children: 'Versie'
            },
            column: (item) => ({
                children: item.product?.version
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Interne notite'
            },
            column: (item) => ({
                children: item.internal_comment
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Externe notitie'
            },
            column: (item) => ({
                children: item.external_comment
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aangemaakt op'
            },
            column: (item) => ({
                children: item.created_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Aangepast op'
            },
            column: (item) => ({
                children: item.updated_at
            }),
            type: 'date'
        }
    ], []);

    return <>
        <Table
            id={ 'version-log' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/products/${ item.product?.id }?current-tab=version-log`
            } }
            http={ {
                hook: httpHook,
                with: [ 'product' ],
                sortCol: 'id',
                sortDir: 'DESC',
                filter: {
                    column: 'product.number',
                    operator: '=',
                    value: productNumber
                },
                filterConfig: {
                    hideArchived: true
                },
                disableDeArchiving: true
            } }
            forms={ {
                edit: {
                    Form: VersionLogForm,
                    id: (record) => record.id,
                }
            } }
        />
    </>;
};
export default VersionLogTable;