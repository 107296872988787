import React, { FC, useMemo, useState } from 'react';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import Stats from '../../lib/samfe/components/Stats';
import useId from '../../lib/samfe/modules/Router/useId';
import ShowPage from '../../templates/pages/ShowPage';
import PackageForm from './PackageForm';
import { parsePackageDisplayNumber } from './PackageFunctions';
import { PackageModel } from './PackageTypes';
import PackageArticleTable from './pivot/PackageArticleTable';
import PackagePartPivotForm from './pivot/packagePartPivot/PackagePartPivotForm';
import PackagePackagePartTable from './pivot/packagePartPivot/packagePartPivotTable';
import usePackage from './usePackage';


const PackageShow: FC = (): JSX.Element => {

    const id = useId();
    const [ currentPackage, setCurrentPackage ] = useState<PackageModel>();

    const packageName = useMemo(() => {
        const packageName = currentPackage?.name;
        if (!packageName) {
            return 'Onbekende verpakking';
        }
        return packageName;
    }, [ currentPackage?.name ]);

    const title = useMemo(() => {
        let packageTitle = packageName;
        if (currentPackage?.number) {
            packageTitle = `[${ parsePackageDisplayNumber(currentPackage.number) }] ${ packageTitle }`
        }
        if (currentPackage?.short_name) {
            packageTitle = `${ packageTitle } (${ currentPackage.short_name })`;
        }
        return packageTitle;
    }, [ currentPackage?.short_name, packageName, currentPackage?.number ]);

    const partsCount = useMemo(() => {
        return (currentPackage?.packageParts ?? []).filter(pp => pp.archived == false).length;
    }, [ currentPackage?.packageParts ]);

    return (
        <ShowPage
            title={ title }
            breadcrumb={ packageName }
            setCurrentModel={ setCurrentPackage }
            FormModal={ PackageForm }
            httpHook={ usePackage }
            relations={ [ 'packageParts' ] }
            formActions={ [
                {
                    name: 'Onderdeel toevoegen',
                    icon: 'add',
                    tabId: 'parts',
                    form: PackagePartPivotForm
                }
            ] }

            tabs={ [
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <Stats stats={ [
                            {
                                name: 'Aantal onderdelen',
                                value: partsCount
                            },
                            {
                                name: 'Aantal charges',
                                value: 'Coming soon'
                            },
                            {
                                name: 'Voorraad',
                                value: 'Coming soon'
                            }
                        ] }/>
                        <ExampleGraph/>
                    </>
                },
                {
                    name: 'Onderdelen',
                    id: 'parts',
                    element: <PackagePackagePartTable parentId={ id }/>
                },
                {
                    name: 'Artikelen',
                    id: 'articles',
                    element: <PackageArticleTable parentId={ id }/>
                }
            ] }
        />
    );
};
export default PackageShow;