import React, { FC } from 'react';
import { classNames } from '../../modules/Parse/String';


type Props = {
    title: string,
    state: 'success'|'danger'|'warning'|'info'
    hideDot?: boolean
}


const Pill: FC<Props> = ({ title, state, hideDot = false}) => {
    return (
        <div className={ classNames('inline-flex items-center text-xs font-medium px-2.5 py-0.5 rounded-full',
            state == 'success' && 'bg-green-200 text-green-800',
            state == 'danger' && 'bg-red-100 text-red-800',
            state == 'warning' && 'bg-orange-100 text-orange-800',
            state == 'info' && 'bg-blue-200 text-blue-800'
        ) }>
            { !hideDot && <div className={ classNames('w-2 h-2 mr-1 rounded-full',
                state == 'success' && 'bg-green-600',
                state == 'danger' && 'bg-red-600',
                state == 'warning' && 'bg-orange-600',
                state == 'info' && 'bg-blue-600'
            ) }/> }
            <span>{ title }</span>
        </div>
    );
};

export default Pill;
