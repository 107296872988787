import React, { FC, useMemo } from 'react';
import IngredientForm from './IngredientForm';
import { IngredientModel, IngredientRelationsBluePrint } from './IngredientTypes';
import useIngredient from './useIngredient';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";


type Props = Required<ExtendTableProps>

const IngredientTable: FC<Props> = () => {

    const httpHook = useIngredient();

    const rows = useMemo((): TableColumns<IngredientModel, IngredientRelationsBluePrint>[] => [
        {
            header: {
                children: 'Naam',
                sortCol: 'name'
            },
            column: (item) => ({
                children:  item.name
            }),
            type: 'text'
        },{
            header: {
                children: 'Is allergeen',
                sortCol: 'is_allergen'
            },
            column: (item) => ({
                children: item.is_allergen ? 'Ja' : 'Nee'
            }),
            type: 'text'
        },{
            header: {
                children: 'Voorkomen in producten',
                sortCount: 'ingredientProducts'
            },
            column: (item) => ({
                children: item.ingredientProducts_count
            }),
            type: 'numeric'
        },
    ], []);

    return (
        <Table
            id={ 'ingredients' }
            rows={ rows }
            rowActions={{
                linkTo: (item) => `${item.id}`
            }}
            http={ {
                hook: httpHook,
                searchCols: ['name'],
                sortCol: 'name',
                sortDir: 'ASC',
                withCount: ['ingredientProducts'],
                filters: [
                    {
                        displayName: 'Allergenen',
                        column: 'is_allergen',
                        operator: '=',
                        value: 1
                    }
                ]
            } }
            forms={ {
                edit: {
                    Form: IngredientForm,
                    id: (record) => record.id,
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => record.name,
                    resourceName: () => 'Ingrediënt'
                },
            } }
        />
    );
};

export default IngredientTable;