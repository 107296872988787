import React, { FC, PropsWithChildren, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DesktopSidebar from './DesktopSidebar';
import MobileSidebar from './MobileSidebar';
import Navbar from './Navbar';


const excludedPaths = [
    '/login',
    '/forgot-password',
    '/reset-password',
    '/cancel-password-reset'
];

const ApplicationTemplate: FC<PropsWithChildren<any>> = ({ children }): JSX.Element => {
    const [ sidebarOpen, setSidebarOpen ] = useState(false);
    const showNav = !excludedPaths.includes(useLocation().pathname);

    return (
        <div className={ 'relative' }>
            { showNav && <MobileSidebar sidebarOpen={ sidebarOpen } setSidebarOpen={ setSidebarOpen }/> }
            { showNav && <DesktopSidebar/> }
            <div className="2xl:pl-72">
                { showNav && <Navbar sidebarOpen={ sidebarOpen } setSidebarOpen={ setSidebarOpen }/> }
                <main>{ children }</main>
            </div>
        </div>
    );
};
export default ApplicationTemplate;