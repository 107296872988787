import useAxios from '../../../../lib/samfe/modules/Http/useAxios';
import useHttp, { ListResponse, RequestParams } from '../../../../lib/samfe/modules/Http/useHttp';
import { ProductRiskItem } from './ProductRiskTypes';


const useProductRiskTable = (productId?: number) => {

    const http = useHttp<ProductRiskItem, {}, ''>({
        endpoint: `products/${ productId }/risk-table`
    });

    const axios = useAxios();

    const getListWithProductId = async(productId?: number, config?: RequestParams<Partial<ProductRiskItem>, ''>): Promise<ProductRiskItem[]> => {
        return await axios
            // @ts-ignore
            .get<ListResponse<ProductRiskItem>, ''>(`products/${ productId }/risk-table`, {
                page: http.pagination.pagination.currentPage,
                limit: http.pagination.pagination.perPage,
                ...config
            })
            .then(r => {
                http.pagination.setPagination({
                    ...http.pagination.pagination,
                    totalItems: r.data.total,
                    currentPage: r.data.page
                });
                return r.data.items ?? [];
            })
            .catch(() => []);
    };

    return {
        ...http,
        getListWithProductId
    };
};
export default useProductRiskTable;
