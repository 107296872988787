import React, { FC, useMemo, useState } from 'react';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import { Tab } from '../../lib/samfe/components/Tab';
import useId from '../../lib/samfe/modules/Router/useId';
import ShowPage from '../../templates/pages/ShowPage';
import AttributeForm from './AttributeForm';
import { AttributeModel } from './AttributeTypes';
import AttributeOptionForm from './pivot/AttributeOptionForm';
import AttributeOptionTable from './pivot/AttributeOptionTable';
import AttributeProductTable from './pivot/AttributeProductTable';
import useAttribute from './useAttribute';


const AttributeShow: FC = (): JSX.Element => {

    const id = useId();
    const [ currentAttribute, setCurrentAttribute ] = useState<AttributeModel>();


    const tabs = useMemo(() => {
        const tabs: Tab[] = [];
        if (!currentAttribute) {
            return tabs;
        }

        tabs.push({
            name: 'Overzicht',
            id: 'overview',
            element: <>
                <ExampleGraph/>
            </>
        });


        if (currentAttribute?.type != 'text' && currentAttribute?.type !== 'number') {
            tabs.push({
                name: 'Waarden',
                id: 'options',
                element: <AttributeOptionTable parentId={ id }/>
            });
        }

        tabs.push({
            name: 'Producten',
            id: 'products',
            element: <AttributeProductTable parentId={ id }/>
        });

        return tabs;
    }, [ currentAttribute, id ]);


    return (
        <ShowPage
            title={ `${ currentAttribute?.name } (${ currentAttribute?.type } voor ${ currentAttribute?.productType?.name })` }
            breadcrumb={ currentAttribute?.name }
            setCurrentModel={ setCurrentAttribute }
            FormModal={ AttributeForm }
            httpHook={ useAttribute }
            relations={ [ 'productType' ] }
            tabs={ tabs }
            formActions={ [
                {
                    name: 'Waarde toevoegen',
                    tabId: 'options',
                    icon: 'add',
                    form: AttributeOptionForm
                }
            ] }
        />
    );
};
export default AttributeShow;