import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { CancelButton, SaveButton } from '../../Button';
import Checkbox from '../../Form/Generic/Checkbox';

type SaveAction = {
    show: boolean,
    allow: boolean,
    onClick: () => void|Promise<void>
}

type CreateAnotherOneAction = {
    show: boolean,
    omit: boolean,
    state: [ boolean, Dispatch<SetStateAction<boolean>> ]
}

type Props = {
    save?: Partial<SaveAction>,
    onCancelClick: () => void|Promise<void>
    createAnotherOne?: Partial<CreateAnotherOneAction>
}

const FormModalFooter: FC<Props> = ({
    save,
    onCancelClick,
    createAnotherOne
}) => {

    const saveButton = useMemo((): SaveAction => ({
        show: true,
        allow: true,
        onClick: () => {
        },
        ...save
    }), [ save ]);

    const caoCheckbox = useMemo((): CreateAnotherOneAction => ({
        show: createAnotherOne?.show ?? false,
        omit: createAnotherOne?.omit ?? false,
        state: [
            createAnotherOne?.state?.[0] ?? false,
            createAnotherOne?.state?.[1] ?? ((() => false) as Dispatch<SetStateAction<boolean>>)
        ]
    }), [ createAnotherOne?.show, createAnotherOne?.omit, createAnotherOne?.state ]);

    const [ checked, setChecked ] = useMemo(() => caoCheckbox.state, [ caoCheckbox.state ]);


    return (
        <div className={ 'relative w-full' }>
            <div className="mt-5 sm:mt-4 flex flex-row-reverse float-right">
                { saveButton.show && <SaveButton disabled={ !saveButton.allow } onClick={ saveButton.onClick } className={ 'sm:ml-3' }/> }
                <CancelButton type={ 'button' } onClick={ onCancelClick } style={ 'tertiary' } className={ 'mt-3 sm:mt-0' }/>
            </div>
            { caoCheckbox.show && !caoCheckbox.omit && <div className={ 'absolute left-0 bottom-2' }>
                <Checkbox name={ '' } checked={ checked } onChange={ setChecked } label={ 'Hierna nog een item toevoegen' }/>
            </div> }
        </div>
    );
};

export default FormModalFooter;
