import moment from 'moment';
import React, { FC } from 'react';
import Pill from '../../lib/samfe/components/Pill/Pill';
import { TableFilterType } from '../../lib/samfe/components/Table/SmartTable/TableTypes';
import { jsDateToSqlDate, parseExpirationSqlDateTime } from '../../lib/samfe/modules/Parse/Date';
import { ChargeModel } from './ChargeTypes';


/**
 *
 * @type {string}
 */
const tenMonthsFromNow: string = moment()
    .startOf('month')
    .add(11, 'months')
    .format('YYYY-MM-DD');


/**
 *
 * @param {boolean} showAll
 * @return {TableFilterType<ChargeModel>[]}
 */
export const chargeTableFilters = (showAll: boolean = false): TableFilterType<ChargeModel>[] => [
    {
        displayName: 'Bruikbaar',
        column: 'amount',
        operator: '>',
        value: `0,expiration_date>=${ jsDateToSqlDate() }`,
        default: !showAll
    },
    {
        displayName: 'THT < 10 maanden',
        column: 'expiration_date',
        operator: '<',
        value: `${ tenMonthsFromNow },expiration_date>=${ jsDateToSqlDate() },amount>0`
    },
    {
        displayName: 'THT < 0 maanden',
        column: 'expiration_date',
        operator: '<',
        value: `${ jsDateToSqlDate() },amount>0`
    },
    {
        displayName: 'Geblokkeerd',
        column: 'status',
        operator: '=',
        value: 'blocked'
    },
    {
        displayName: 'Uitverkocht',
        column: 'amount',
        operator: '<=',
        value: 0
    }
];


/** */
type HighlightItemProps = {
    content?: string,
    isPill?: boolean
}


/**
 *
 */
const WarningItem: FC<HighlightItemProps> = ({ content = 'Onbekend', isPill = false}): JSX.Element => {
    if (isPill) {
        return <Pill hideDot={true} state={'warning'} title= { content } />
    }
    return <span className={ 'text-carrot font-medium' }>{ content }</span>;
};


/**
 *
 */
const DangerItem: FC<HighlightItemProps> = ({ content = 'Onbekend', isPill = false }): JSX.Element => {
    if (isPill) {
        return <Pill hideDot={true} state={'danger'} title= { content } />
    }
    return <span className={ 'text-fire font-medium' }>{ content }</span>;
}


/**
 *
 * @param {string} expirationDate
 * @param {boolean} isPill
 * @return {JSX.Element | string}
 */
export const expirationDateLabel = (expirationDate?: string, isPill:boolean = false): JSX.Element|string => {
    if (!expirationDate) {
        return <WarningItem isPill={isPill} content={ 'Onbekend' }/>;
    }

    const expJsDate = new Date(expirationDate);
    if (isNaN(expJsDate.getTime())) {
        return <DangerItem isPill={isPill} content={ expirationDate }/>;
    }

    const target = new Date();
    target.setMonth(target.getMonth() + 9);

    const formattedDate = parseExpirationSqlDateTime(expirationDate);
    const expMoment = moment(expJsDate).startOf('month');
    const endMoment = moment(target).endOf('month');
    return expMoment>endMoment ?formattedDate :<WarningItem isPill={isPill} content={ formattedDate }/>;
};


/**
 *
 * @param {ChargeModel} charge
 * @param {boolean} isPill
 * @return {JSX.Element | string}
 */
export const batchCodeLabel = (charge?: ChargeModel, isPill:boolean = false): JSX.Element|string => {

    if (!charge?.status) {
        return <DangerItem isPill={isPill} content={ charge?.batchcode }/>;
    }

    if (!charge?.batchcode) {
        return <DangerItem isPill={isPill} content={ charge?.batchcode }/>;
    }

    const isBlocked = charge.status.toLowerCase() == 'blocked';
    const blockedMarker = isBlocked ?'(GEBLOKKEERD)' :'';

    const isFavourite = charge.favourite == true;
    const favouriteMarker = isFavourite ?'⭐️' :'';

    const batchCode = `${ charge.batchcode }${ isBlocked ?' ' :'' }${ blockedMarker ?? '' }${ isFavourite ?' ' :'' }${ favouriteMarker ?? '' }`;
    return isBlocked ?<WarningItem isPill={isPill} content={ batchCode }/> :batchCode;
};
