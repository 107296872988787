import React from "react";
import useHttp from "../../lib/samfe/modules/Http/useHttp";
import {
    IngredientDto,
    IngredientModel,
    IngredientRelationsBluePrint
} from "./IngredientTypes";


const useIngredient = () => useHttp<IngredientModel, IngredientDto, IngredientRelationsBluePrint>({
    endpoint: 'ingredients'
});
export default useIngredient;
