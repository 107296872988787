import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useHttpEvent } from '../../events/Http.event';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import Skeleton from '../../lib/samfe/components/Skeleton/Skeleton';
import Stats from '../../lib/samfe/components/Stats';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import useHttp from '../../lib/samfe/modules/Http/useHttp';
import useId from '../../lib/samfe/modules/Router/useId';
import ShowPage from '../../templates/pages/ShowPage';
import useAssessment from '../assessment/http/useAssessment';
import ProductAssessmentTable from '../assessment/tables/ProductAssessmentTable';
import useAttribute from '../attribute/useAttribute';
import useProductRiskReview from '../productRiskReview/useProductRiskReview';
import AttributeProductForm from './pivot/attributeProduct/AttributeProductForm';
import AttributeProductTable from './pivot/attributeProduct/AttributeProductTable';
import useAttributeProduct from './pivot/attributeProduct/useAttributeProduct';
import ProductCompoundForm from './pivot/compositionProduct/productCompound/ProductCompoundForm';
import ProductCompoundTable from './pivot/compositionProduct/productCompound/ProductCompoundTable';
import ProductExcipientForm from './pivot/compositionProduct/productExcipient/ProductExcipientForm';
import ProductExcipientTable from './pivot/compositionProduct/productExcipient/ProductExcipientTable';
import ProductDossier from './pivot/pdf/ProductDossier';
import ProductArticleTable from './pivot/ProductArticleTable';
import ProductChargeTable from './pivot/ProductChargeTable';
import ProductIngredientForm from './pivot/productIngredient/ProductIngredientForm';
import ProductIngredientTable from './pivot/productIngredient/ProductIngredientTable';
import ProductRiskTable from './pivot/productRisk/ProductRiskTable';
import useProductRisk from './pivot/productRisk/useProductRisk';
import VersionLogForm from './pivot/versionLog/VersionLogForm';
import VersionLogTable from './pivot/versionLog/VersionLogTable';
import ProductForm from './ProductForm';
import { formatProductNumber } from './ProductFunctions';
import { ProductModel } from './ProductTypes';
import useProduct from './useProduct';


const categoryFallbackMessage = 'Niet beschikbaar';

const ProductShow: FC = (): JSX.Element => {

    const id = useId();
    const productHook = useProduct()
    const attribute = useAttribute();
    const attributeProduct = useAttributeProduct(id);
    const productRiskReview = useProductRiskReview();
    const productRiskHook = useProductRisk(id);
    const assessmentHook = useAssessment();
    const apWarningHttp = useHttp({ endpoint: `products/${ id }/parsed/attributes/show-warning` });
    const httpDispatched = useHttpEvent();
    const [ currentProduct, setCurrentProduct ] = useState<ProductModel|undefined>(undefined);

    const currentVersion = useMemo(() => {
        return currentProduct?.version ?? 0
    }, [ currentProduct?.version ]);

    const title: ReactNode = useMemo(() => {
        if (!currentProduct) {
            return <Skeleton type={ 'stat.body' }/>;
        }
        return `${ currentProduct?.name ?? '' } (${ formatProductNumber(currentProduct?.number) }) v${ currentVersion }`;
    }, [ currentProduct, currentVersion ]);


    const hasRiskWarning = useAsyncMemo(async() => {
        if (!currentProduct) {
            return false;
        }
        return await productRiskReview.getItem(id).then((productRiskReview) => {
            if (!productRiskReview) {
                return false;
            }
            const missingProducts = (productRiskReview.missing_product_risk_count ?? 0)>0;
            const unreviewedElementRiskReviews = (productRiskReview.unreviewed_element_risk_count ?? 0)>0;
            return missingProducts || unreviewedElementRiskReviews;
        });
    }, [ currentProduct, httpDispatched ], false);


    const hasAttributeWarning = useAsyncMemo(async() => {
        if (!currentProduct) {
            return false;
        }

        return await apWarningHttp.getItem(undefined, {}, true).then(res => {
            return (res as { show_warning: boolean }).show_warning;
        });
    }, [ currentProduct, httpDispatched ], false);


    const category: ReactNode = useAsyncMemo(async() => {
        if (!currentProduct) {
            return <Skeleton type={ 'stat.body' }/>;
        }
        if (typeof category == 'string' && category != categoryFallbackMessage) {
            return category;
        }
        return await attribute.getList({
            filter: 'name=Categorie',
            select: [ 'id' ],
            limit: 1
        }).then((attributes) => {
            if (attributes.length<1) {
                return categoryFallbackMessage;
            }
            return attributeProduct.getList({
                filter: `attribute_id=${ attributes[0].id }`,
                with: [ 'option' ],
                select: [ 'attribute_option_id', 'option.id', 'option.name' ],
                limit: 1
            }).then(categories => {
                return categories[0].option?.name ?? categoryFallbackMessage;
            });
        });
    }, [ currentProduct ], categoryFallbackMessage);


    const productRiskIds = useAsyncMemo(async() => {
        return await productRiskHook.getList({
            select: [ 'id' ],
            filter: `product_id=${ id },archived=false`,
            limit: 'all'
        }).then(res => res
            .filter(item => item.id != undefined)
            .map(item => item.id!)
        );
    }, [id], []);

    const hasAssessmentWarning = useAsyncMemo(async() => {
        const nOpenAssessments = await assessmentHook.getList({
            select: [ 'id' ],
            filter: 'assessment_status=to_test|assessment_status=test_requested|assessment_status=not_approved',
            limit: 1,
            whereIn: {
                key: 'product_risk_id',
                operator: '=',
                values: productRiskIds
            }
        }).then(r => r.length);

        return nOpenAssessments>0;
    }, [ productRiskIds ], false);


    const isOlderVersion = useAsyncMemo(async () => {
        if (!currentProduct?.version) {
            return false;
        }
        return await productHook.getList({
            filter: `number=${currentProduct?.number},archived=false`,
            select: ['version']
        }).then(items => {
            return items.find(item => currentVersion < (item.version??0)) != undefined
        })
    }, [currentProduct?.version], false)


    const titleWarningMessage = useMemo(() => {
        if (!isOlderVersion) {
            return undefined
        }
        return 'Oude versie'
    }, [ isOlderVersion ]);



    return (
        <>
            <ShowPage
                title={ title }
                titleWarningMessage={titleWarningMessage}
                resourceIdentifier={ 'name' }
                breadcrumb={ currentProduct?.name }
                setCurrentModel={ setCurrentProduct }
                FormModal={ ProductForm }
                httpHook={ useProduct }
                uploadsExtraColName={ 'Batchcode' }
                uploadsExtraColRef={ 'charges' }
                relations={ [
                    'productType',
                    'files'
                ] }

                formActions={ [
                    {
                        name: 'Eigenschappen',
                        tabId: 'attributes',
                        form: AttributeProductForm
                    },
                    {
                        name: 'Actieve stoffen',
                        tabId: 'compounds',
                        icon: 'add',
                        form: ProductCompoundForm
                    },
                    {
                        name: 'Hulpstoffen',
                        tabId: 'excipients',
                        icon: 'add',
                        form: ProductExcipientForm
                    },
                    {
                        name: 'Ingrediënten',
                        tabId: 'ingredients',
                        icon: 'add',
                        form: ProductIngredientForm
                    },
                    {
                        name: 'Versiebeheer',
                        tabId: 'version-log',
                        icon: 'add',
                        form: VersionLogForm
                    }
                ] }

                upload={ {
                    modelName: 'ProductModel',
                    files: currentProduct?.files,
                    fileTypes: [ 'Analyse certificaat', 'Referentie afbeelding', 'Certificaat' ],
                    extraColName: 'Batchcode',
                    extraColRef: 'charges'
                } }

                tabs={ currentProduct ?[
                    {
                        name: 'Overzicht',
                        id: 'overview',
                        element: <>
                            <Stats stats={ [
                                {
                                    name: 'Type',
                                    value: currentProduct?.productType?.name ?? 'Niet beschikbaar'
                                },
                                {
                                    name: 'Categorie',
                                    value: category
                                },
                                {
                                    name: 'Versie',
                                    value: currentProduct?.version
                                }
                            ] }/>
                            <ExampleGraph/>
                        </>
                    },
                    {
                        name: 'Eigenschappen',
                        id: 'attributes',
                        hasError: hasAttributeWarning,
                        element: <AttributeProductTable parentId={ id } notification={ hasAttributeWarning ?{
                            title: 'Ontbrekende eigenschappen',
                            variation: 'warning'
                        } :undefined }/>
                    },
                    {
                        name: 'Actieve stoffen',
                        id: 'compounds',
                        element: <ProductCompoundTable parentId={ id }/>
                    },
                    {
                        name: 'Hulpstoffen',
                        id: 'excipients',
                        element: <ProductExcipientTable parentId={ id }/>
                    },
                    {
                        name: 'Ingrediënten',
                        id: 'ingredients',
                        element: <ProductIngredientTable parentId={ id }/>
                    },
                    {
                        name: 'Risico\'s',
                        id: 'risks',
                        hasError: hasRiskWarning,
                        element:
                            <ProductRiskTable
                                parentId={ id }
                                notification={ !hasAttributeWarning ?undefined :{
                                    title: 'Onbehandelde risico\'s',
                                    variation: 'warning'
                                } }
                            />
                    },
                    {
                        name: 'Artikelen',
                        id: 'articles',
                        element: <ProductArticleTable parentId={ id }/>
                    },
                    {
                        name: 'Charges',
                        id: 'charges',
                        element: <ProductChargeTable parentId={ id }/>
                    },
                    {
                        name: 'Risico borgingen',
                        id: 'charge-assessments',
                        hasError: hasAssessmentWarning,
                        element: currentProduct.number ?<ProductAssessmentTable productNumber={ currentProduct.number }/> :<></>
                    },
                    {
                        name: 'Versiebeheer',
                        id: 'version-log',
                        hasError: isOlderVersion,
                        element: <>
                            { currentProduct.number && <VersionLogTable productNumber={ currentProduct.number }/> }
                        </>
                    },
                    {
                        name: 'Product Dossier',
                        id: 'product-dossier',
                        element: <ProductDossier initialModel={ currentProduct }/>

                    }

                ] :[] }
            />
        </>
    );
};
export default ProductShow;