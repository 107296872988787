import React, { FC, useMemo } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import AttributeOptionForm from './AttributeOptionForm';
import { AttributeOptionModel, AttributeOptionRelationsBluePrint } from './AttributeOptionTypes';
import useAttributeOption from './useAttributeOption';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const AttributeOptionTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useAttributeOption(parentId);


    const rows = useMemo((): TableColumns<AttributeOptionModel, AttributeOptionRelationsBluePrint>[] => [
        {
            header: {
                children: 'Naam',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'attribute-options' }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: [ 'name' ],
                sortCol: 'name',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: AttributeOptionForm,
                    parentId: (record) => record.attribute_id,
                    id: (record) => record.id
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => record.name,
                    resourceName: () => 'Eigenschap waarde'
                },
                actions: []
            } }
            callbacks={ [] }
        />
    </>;
};
export default AttributeOptionTable;