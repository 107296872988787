import React, { FC } from 'react';
import PaginationGroupComponent from '../components/stateful/PaginationGroup.component';
import PerPageSelectComponent from '../components/stateful/PerPageSelect.component';


const PaginationSection: FC = () => {


    return (
        <section className={ 'py-6 bg-white z-[2] px-4 sm:px-10 lg:px-12 border-t border-t-gray-200 sticky bottom-0 md:flex justify-between items-center md:w-full' }>
            <PerPageSelectComponent/>
            <div className={'pt-7'}>
                <PaginationGroupComponent />
            </div>
        </section>
    );
};

export default PaginationSection;
