import React, { FC } from 'react';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import ProductTable from '../../../product/ProductTable';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ElementProductTable: FC<Props> = ({ parentId }): JSX.Element => {

    return <ProductTable withRelations={['compositionProducts']} filter={{
        column: 'compositionProducts.element_id',
        operator: '=',
        value: parentId
    }} />
};

export default ElementProductTable;