import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import useAsyncMemo from '../../../../lib/samfe/hooks/useAsyncMemo';
import PdfFile from '../../../document/PdfFile';
import { ProductModel } from '../../ProductTypes';
import useProduct from '../../useProduct';


type VersionSelectProps = Omit<JSX.IntrinsicElements['select'], 'className'|'name'|'value'|'children'>&{
    productId?: number,
    versionedProducts: ProductModel[]
}

const VersionSelect: FC<VersionSelectProps> = ({ productId, versionedProducts, ...props }) => <>
    { productId && <select
        { ...props }
        name={ 'Product Version Selector' }
        value={ productId }
        className={ 'pl-3 pr-10 py-2text-base border-gray-200 focus:outline-none focus:ring-aqua focus:border-aqua sm:text-sm rounded-md' }
    >
        { versionedProducts.map((product, index) => (
            <option key={ index } value={ product.id }>
                v{ product.version }
            </option>
        )) }
    </select> }
</>;


type ProductDossierProps = {
    initialModel: ProductModel
}

const ProductDossier: React.FC<ProductDossierProps> = ({ initialModel }) => {

    const productHook = useProduct();
    const [ currentProduct, setCurrentProduct ] = useState<ProductModel>(initialModel);

    const versionedProducts: ProductModel[] = useAsyncMemo(async() => {
        if (!initialModel.number) {
            return [];
        }
        return await productHook.getList({
            filter: `number=${ initialModel.number }`,
            limit: 'all',
            orderBy: 'version',
            order: 'DESC'
        });
    }, [ initialModel.number ], []);

    const customFileName = useMemo(() => {
        return `productdossier-${ currentProduct.number }-v${ currentProduct.version }`;
    }, [ currentProduct.number, currentProduct.version ]);

    const handleVersionChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const versionedProductId = parseInt(event.target.value);
        const versionedProduct = versionedProducts.find(version => version.id === versionedProductId);
        if (versionedProduct) {
            setCurrentProduct(versionedProduct);
        }
    };


    return (
        <PdfFile
            id={ currentProduct.id }
            fileType={ 'productdossier' }
            customFileName={ customFileName }
            customJsxActions={ [
                <VersionSelect
                    key={ 0 }
                    onChange={ handleVersionChange }
                    productId={ currentProduct.id }
                    versionedProducts={ versionedProducts }
                />
            ] }
        />
    );
};

export default ProductDossier;