import React, { FC, useMemo, useState } from 'react';
import ShowPage from '../../templates/pages/ShowPage';
import LocationChargesTable from './LocationChargeTable';
import LocationForm from './LocationForm';
import { getLocationName } from './LocationFunctions';
import { LocationModel } from './LocationTypes';
import { useLocation } from './useLocation';


const LocationShow: FC = (): JSX.Element => {

    const [ currentLocation, setCurrentLocation ] = useState<LocationModel|undefined>(undefined);

    const locationName = useMemo(() => getLocationName(currentLocation), [ currentLocation ]);

    return (
        <ShowPage
            title={ locationName }
            breadcrumb={ locationName }
            setCurrentModel={ setCurrentLocation }
            FormModal={ LocationForm }
            httpHook={ useLocation }
            relations={ [ 'group' ] }
            tabs={ [
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        { currentLocation?.id && <LocationChargesTable parentId={ currentLocation.id }/> }
                        {/*<ExampleGraph/>*/ }
                    </>
                }
            ]}
        />
    );
};
export default LocationShow;
