import React, {FC, useEffect, useState} from "react";
import GaLogo from "../../assets/images/great-applications-logo.png";
import ResearchPng from "../../assets/images/research.png";
import {ActionButton} from "../../lib/samfe/components/Button";
import {useNavigate, useSearchParams} from "react-router-dom";
import Alert from "../../lib/samfe/components/Notifications/Alert";
import useToaster from "../../lib/samfe/components/Toaster/useToaster";
import useLoadingScreen from "../../lib/samfe/components/LoadingScreen/useLoadingScreen";
import useAuth, {ResetPayload} from "../../resources/user/useAuth";


const ResetPassword: FC = (): JSX.Element => {


    const navigate = useNavigate()
    const {resetPassword} = useAuth()

    const [searchParams] = useSearchParams();
    const {setToasterProps} = useToaster();
    const {setLoadingScreenProps} = useLoadingScreen();
    const [resetPasswordState, setResetPasswordState] = useState<ResetPayload>({
        token: searchParams.get('token') ?? '',
        password1: '',
        password2: ''
    })

    useEffect(() => {
        if (resetPasswordState.token === '') {
            navigate('/login');
        }
    }, []);


    const isValid = (): boolean => (resetPasswordState.password1 === resetPasswordState.password2) && resetPasswordState.password1 != '';

    const submit = () => {
       if (!isValid()) {
            return;
        }

        setLoadingScreenProps({show: true});

        resetPassword(resetPasswordState)
            .then(isValid => {
                setToasterProps({
                    show: true,
                    type: isValid ? 'success' : "error",
                    title: isValid ?'Wachtwoord reset gelukt': 'Wachtwoord reset mislukt'
                });

                if (isValid) {
                    navigate('/login');
                }
            })
            .finally(() => setLoadingScreenProps({show: false}) )
    }
    
    return <>

        <form onSubmit={e => e.preventDefault()} >

            <div className="flex min-h-full flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                className="w-auto"
                                src={GaLogo}
                                alt="Great Applications"
                            />
                            <h2 className="my-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Wachtwoord resetten
                            </h2>
                            {!isValid() && resetPasswordState.password1 !== '' && <Alert type={"warning"} message={'Wachtwoorden komen niet overeen'} />}
                        </div>

                        <div className="mt-10">
                            <div className="space-y-6">
                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                        Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="password1"
                                            onChange={(e) => {
                                                setResetPasswordState({...resetPasswordState, password1: e.target.value})

                                            }}
                                            name="password1"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aqua sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                        Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="password2"
                                            name="password2"
                                            onChange={(e) => {
                                                setResetPasswordState({...resetPasswordState, password2: e.target.value})
                                            }}
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aqua sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>


                                <div>
                                    <button
                                        type="submit"
                                        onClick={submit}
                                        className="flex w-full justify-center rounded-md bg-aqua px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-aqua-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aqua"
                                    >
                                        Wachtwoord veranderen
                                    </button>

                                </div>
                                <ActionButton
                                    className={'!w-auto'}
                                    style={'tertiary'}
                                    text={'Terug naar inloggen'}
                                    icon={''}
                                    onClick={() => {
                                        navigate('/login')
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden justify-center w-0 flex-1 lg:flex lg:h-screen">
                    <img
                        className="w-full object-contain"
                        src={ResearchPng}
                        alt="auth"
                    />
                </div>
            </div>
        </form>
    </>
}

export default ResetPassword;