import React, {FC, PropsWithChildren, useEffect} from "react";
import {LoadingScreenProvider, useLoadingScreen} from "./components/LoadingScreen/useLoadingScreen";
import Toaster from "./components/Toaster";
import useToaster, {ToasterProvider} from "./components/Toaster/useToaster";
import {AxiosProvider} from "./modules/Http/useAxios";
import LoadingScreen from "./components/LoadingScreen";
import useCookie from "./modules/Cookie/useCookie";
import {Group} from "../../resources/user/useAuth";



const SamfeRoot: FC<PropsWithChildren<any>> = ({children}): JSX.Element => {

    const { toasterProps, setToasterProps } = useToaster();
    const { loadingScreenProps, setLoadingScreenProps } = useLoadingScreen();

    const groupCookie = useCookie('group')

    const handleToasterClose = () => {
        setToasterProps({ title: '', message: '', type: 'info', show: false, onClose: () => {} });
    };

    const handleLoadingScreenClose = () => {
        setLoadingScreenProps({ show: false, onCancel: () => {} });
    };

    useEffect(() => {
        if (groupCookie?.data == undefined) {
            document.querySelector("html")?.removeAttribute("data-theme");
        } else {
            const group = groupCookie.data as unknown as Group
            document.querySelector("html")?.setAttribute("data-theme", group.theme);
        }
    }, [groupCookie?.data]);

    return <>
        {children}
        <LoadingScreen show={loadingScreenProps.show} onCancel={handleLoadingScreenClose} />
        <Toaster
            title={toasterProps.title}
            message={toasterProps.message}
            type={toasterProps.type}
            show={toasterProps.show}
            onClose={handleToasterClose}
        />
    </>
}

type Props = {
    children: PropsWithChildren<any>,
    isTest?: boolean
}
const SamfeProvider: FC<Props> = ({ children, isTest }): JSX.Element => (
    <LoadingScreenProvider>
        <ToasterProvider>
            <AxiosProvider isTest={isTest}>
                <SamfeRoot>
                    {children}
                </SamfeRoot>
            </AxiosProvider>
        </ToasterProvider>
    </LoadingScreenProvider>
);

export default SamfeProvider;
