import React from "react";
import 'react-loading-skeleton/dist/skeleton.css'
import {FormControlType} from "../Form/FormControl";
import ReactSkeleton from "react-loading-skeleton";

type Props = {
    type: FormControlType|'h1'|'breadcrumb'|'stat.body'
}

const Skeleton: React.FC<Props> = ({
   type
}): JSX.Element => {

    const label = <ReactSkeleton className={'!w-36 h-4 mb-2'} containerTestId={'skeleton'} />

    const select = <div className={'my-2'}>
        {label}
        <ReactSkeleton className={'w-full h-12'} containerTestId={'skeleton'} />
    </div>

    const input = <div className={'my-2'}>
        {label}
        <ReactSkeleton className={'w-full h-10'} containerTestId={'skeleton'} />
    </div>

    const h1 = <div className={'w-48'}>
        <ReactSkeleton className={'w-full h-6'} containerTestId={'skeleton'} />
    </div>


    const statBody = <div className={'w-24'}>
        <ReactSkeleton className={'w-full h-6'} containerTestId={'skeleton'} />
    </div>

    const breadcrumb = <div className={'w-32'}>
        <ReactSkeleton className={'w-full h-6'} containerTestId={'skeleton'} />
    </div>

    const textArea = <div className={'my-2'}>
        {label}
        <ReactSkeleton className={'w-full h-[6.75rem]'} containerTestId={'skeleton'} />
    </div>

    switch (type) {
        case 'radio':
        case 'select': return select;
        case 'selectSearch': return select;
        case 'input': return input;
        case 'h1': return h1;
        case 'breadcrumb': return breadcrumb;
        case 'textArea': return textArea;
        case 'stat.body': return statBody;
        default: return <>{type} not implemented</>
    }
}

export default Skeleton;
