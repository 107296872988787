import useAxios from '../../../../lib/samfe/modules/Http/useAxios';
import useHttp from '../../../../lib/samfe/modules/Http/useHttp';
import { RiskRiskCodeDto, RiskRiskCodeModel, RiskRiskCodePostPayload, RiskRiskCodeRelationsBluePrint } from './RiskRiskCodeTypes';


const useRiskRiskCode = (riskId?: number) => {

    const { post } = useAxios();
    const http = useHttp<RiskRiskCodeModel, RiskRiskCodeDto, RiskRiskCodeRelationsBluePrint>({
        endpoint: `risks/${riskId}/codes`
    })


    const create = async (payload: RiskRiskCodePostPayload) => await post<RiskRiskCodePostPayload>(
        `risks/${riskId}/codes`,
        payload
    )

    return {
        ...http,
        create

    }

};
export default useRiskRiskCode;
