import React from "react";
import {useModal} from "./useModal";
import Modal from "./Modal";
import FormModal from "./FormModal/FormModal";


export default Modal
export {
    useModal,
    FormModal
};
