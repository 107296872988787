import React, { FC, useEffect, useState } from 'react';
import { ActionButton } from '../../../../lib/samfe/components/Button';
import Checkbox from '../../../../lib/samfe/components/Form/Generic/Checkbox';
import FormControl from '../../../../lib/samfe/components/Form/Generic/FormControl';
import { InvalidField } from '../../../../lib/samfe/components/Form/Generic/GenericFieldTypes';
import Input from '../../../../lib/samfe/components/Form/Generic/Input';
import Select from '../../../../lib/samfe/components/Form/Generic/Select/Select';
import TextArea from '../../../../lib/samfe/components/Form/Generic/TextArea';
import Jumbotron from '../../../../lib/samfe/components/Jumbotron/Jumbotron';
import Modal from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { ucFirst } from '../../../../lib/samfe/modules/Parse/String';
import useElementRiskReview from '../../../elementRiskReview/useElementRiskReview';
import { SaleDto } from '../../../sale/SaleTypes';
import { GetGradationName, Gradation, Gradations, NormalizeGradation, ProductRiskDto, ProductRiskItem } from './ProductRiskTypes';
import useProductRiskForm from './useProductRiskForm';


// parentId = product id
// id = risk id
const ProductRiskForm: FC<ExtendFormModalProps<SaleDto>> = ({ parentId, id, open, setOpen, onSuccess }): JSX.Element => {

    const productRisk = useProductRiskForm(parentId);
    const [ productRiskItem, setProductRiskItem ] = useState<ProductRiskItem>();
    const elementRiskReview = useElementRiskReview();

    const [ gradation, setGradation ] = useState<Gradation>();
    const [ gradationInvalid, setGradationInvalid ] = useState<InvalidField>();

    const [ frequency, setFrequency ] = useState<number>(0);
    const [ frequencyInvalid, setFrequencyInvalid ] = useState<InvalidField>();

    const [ blockedComment, setBlockedComment ] = useState<string>();

    useEffect(() => {
        productRisk.getItem(id).then(productRiskItem => {
            setProductRiskItem(productRiskItem);
            setGradation(productRiskItem?.gradation !== null ? productRiskItem?.gradation : undefined);
            setFrequency(productRiskItem?.frequency !== null ? (productRiskItem?.frequency??0) : 0);
            setBlockedComment(productRiskItem?.blocked_comment ?? '')
        });
    }, []);

    const validateGradation = (): boolean => {
        let gradationInvalid: InvalidField|undefined = undefined;
        if (['niet bepaald', undefined, null].includes(gradation)) {
            gradationInvalid = {
                isInvalid: true,
                message: 'Verplicht veld, maak een keuze.'
            };
        }
        setGradationInvalid(gradationInvalid);
        return gradationInvalid === undefined;
    }


    const validateFrequency = (): boolean => {
        let frequencyInvalid: InvalidField|undefined = undefined;
        if (frequency < 0) {
            frequencyInvalid = {
                isInvalid: true,
                message: 'Vul een getal groter dan 0 in.'
            };
        }
        setFrequencyInvalid(frequencyInvalid);

        return frequencyInvalid === undefined;
    }


    useEffect(() => {
        validateGradation();
    }, [ gradation ]);

    useEffect(() => {
        validateFrequency()
    }, [ frequency ]);


    const handleSuccess = (id: number) => {
        onSuccess?.(id);
        setOpen(false);
    }


    const handleSubmit = async() => {
        if (!validateFrequency() || !validateGradation()) {
            return;
        }

        if (!productRiskItem?.id) {
            await productRisk.create(parentId!, {
                product_id: parentId,
                risk_id: id,
                gradation,
                frequency
            }).then(res => handleSuccess(res.id))
            return;
        }

        const payload: ProductRiskDto = {
            gradation,
            frequency
        }
        if (productRiskItem.blocked_for_assessment == true) {
            payload.blocked_comment = blockedComment;
        }

        await productRisk.update(parentId!, productRiskItem.id, payload).then(() => handleSuccess(productRiskItem.id))
    };


    /**
     * Action buttons in footer of modal.
     *
     * @returns {JSX.Element}
     */
    const footerEl = (): JSX.Element => <div className={ 'relative w-full' }>
        <div className="mt-5 sm:mt-4 flex flex-row-reverse float-right">
            <ActionButton icon={ '' } text={ 'Opslaan' } onClick={ handleSubmit }/>
        </div>
    </div>;


    return <Modal
        open={ open }
        setOpen={ setOpen }
        title={ `Product risico beheren` }
        className={ 'sm:!w-[80vw] !max-w-[48rem]' }
        footer={ footerEl() }
        onClose={() => onSuccess?.(undefined)}
    >

        <h2 className={ 'leading-6 font-medium text-lg' }>{ ucFirst(productRiskItem?.name) }</h2>

        {/* associated elements towards risk*/ }
        <div className={'my-4'}>
            <Jumbotron
                title={ <span className={ 'block border bg-aqua/5 p-4 rounded-t' }>
                <strong className={ 'font-medium !text-base' }>Risico evaluaties</strong><br/>
                <small className={ 'font-normal text-sm' }>Vink de grondstof aan als het risico is geëvalueerd.</small>
            </span> }
                description={ <span className={ 'block border-l border-r border-b p-4 rounded-b -mt-3' }>
                { productRiskItem?.elements.map((element, index) => <div key={ element.id }>
                    <Checkbox
                        name={ `${ element.id }` }
                        checked={ element.is_reviewed }
                        invalid={ {
                            isInvalid: !element.is_reviewed
                        } }
                        onChange={ async isReviewed => {
                            await elementRiskReview.update(element.review_id, {
                                is_reviewed: isReviewed
                            }).then(() => {
                                const newProductRiskItem = productRiskItem;
                                newProductRiskItem.elements[index].is_reviewed = isReviewed;
                                setProductRiskItem(newProductRiskItem);
                            });
                        } }
                        label={ <div className={ 'text-left' }>
                            <p>{ element.name }</p>
                            <ul>
                                { element.risk_codes.map(riskCode => <li key={ riskCode.element_risk_code_id }>
                                    <small>{ riskCode.name }</small>
                                </li>) }
                            </ul>
                        </div> }
                    />
                </div>) }
            </span> }
            />
        </div>

        <FormControl>
            <Input
                label={ 'Frequentie' }
                invalid={ frequencyInvalid }
                name={ 'frequency' }
                type={ 'number' }
                min={0}
                step={1}
                required={ true }
                value={ frequency ?? 0 }
                description={ <>
                    Charge controle interval. Geen controle is 0.
                </> }
                onChange={ (frequency) => setFrequency(frequency as number) }
            />
        </FormControl>

        <FormControl>
            <Select
                label={ 'Gradatie' }
                name={ 'gradation' }
                required={ true }
                invalid={ gradationInvalid }
                className={ '!fixed w-[calc(100%-3rem)] sm:w-[calc(80vw-3rem)] max-w-[45rem]' }
                onChange={ (gradationOption) => setGradation(NormalizeGradation(gradationOption.value as Gradation)) }
                options={ Gradations.map((gradationOption, i) => ({
                    displayName: GetGradationName(gradationOption),
                    value: gradationOption,
                    selected: gradation !== undefined ?gradationOption === NormalizeGradation(gradation) :i === 0
                })) }
            />
        </FormControl>

        <FormControl>
            <TextArea
                label={ 'Blokkeer notitie' }
                disabled={productRiskItem?.blocked_for_assessment != true}
                required={ true }
                name={ 'message' }
                value={ blockedComment }
                onChange={ (v) => setBlockedComment(`${ v }`) }
                rows={ 4 }
                maxLength={191}
            />
        </FormControl>


    </Modal>;
};

export default ProductRiskForm;
