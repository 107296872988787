import { MinimumModel } from '../../lib/samfe/types/ModelTypes';


type WcImportStatus = 'open'|'processed';

/**
 *
 * @param status
 */
export const GetWcImportStatusTranslation = (status?: WcImportStatus): string => {
    switch (status) {
        case 'open':
            return 'Nieuw';
        case 'processed':
            return 'Verwerkt';
        default: return 'Onbekend'
    }
};


export type WooCommerceAddress = {
    first_name: string,
    last_name: string,
    company?: string,
    address_1: string,
    address_2?: string,
    city: string,
    state?: string,
    postcode: string,
    country: string,
    email?: string,
    phone?: string
}

export type WooCommerceLineItem = {
    id: number,
    name: string,
    sku: string,
    quantity: number,
    total: number,
    subtotal: number
    product_id: number
}

export type WooCommerceMeta = {
    id: number,
    key: string,
    value: string
}

export type WooCommerceOrder = {
    id: number,
    total: number,
    discount_total?: number,
    discount_tax: number,
    customer_id?: number,
    customer_note?: string
    date_created: string
    billing: WooCommerceAddress,
    shipping: WooCommerceAddress,
    meta_data: WooCommerceMeta[],
    line_items: WooCommerceLineItem[],
    acf_klantnummer?: string
}

type WCOrderFields = {
    wc_order_id: number,
    json: string,
    status: WcImportStatus
}

export type WooCommerceRelationsBluePrint = '';
export type WooCommerceDto = Partial<WCOrderFields>
export type WooCommerceModel = MinimumModel&WooCommerceDto & {
    tableWcOrder:  Partial<WooCommerceOrder>
}