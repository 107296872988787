import {ChargeModel} from "../charge/ChargeTypes";
import {SoftDeleteModel} from "../../lib/samfe/types/ModelTypes";
import {WithCount} from "../generic/ModelTypeExtensions";

export type ProfitLossRelationsBluePrint = 'charge'

export type ProfitLossCause = 'Waste' | 'Delivery' | 'Miscounted' | 'Other'

export const ProfitLossCauses: ProfitLossCause[] = [
    'Waste',
    'Delivery',
    'Miscounted',
    'Other'
];

export const ProfitLossCauseDisplayName = (cause?: ProfitLossCause): string => {
    switch (cause) {
        case 'Waste'        : return 'Waste';
        case 'Delivery'     : return 'Kwijt door bezorging';
        case 'Miscounted'   : return 'Verkeerd geteld';
        case 'Other'        : return 'Anders';
        default             : return 'Onbekend';
    }
}


export type ProfitLossFields = {
    charge_id?: number,
    amount?: number,
    cause?: ProfitLossCause,
    comment?: string,
}

export type ProfitLossDto = Partial<ProfitLossFields>

export type ProfitLossRelations = {
    charge: ChargeModel
}

export type ProfitLossModel = SoftDeleteModel
    & Partial<ProfitLossRelations>
    & WithCount<Partial<ProfitLossRelations>>
    & ProfitLossDto
