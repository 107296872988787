/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */

import React, { CSSProperties } from 'react';
import { MaterialIconName } from 'ts-material-icon-name-list';
import {classNames} from "../../modules/Parse/String";

type Props = {
    materialIconName: MaterialIconName,
    className?: string,
    onClick?: () => void,
    style?: CSSProperties
}
const Icon: React.FC<Props> = ({materialIconName, className, onClick, style}): JSX.Element => {
    return <span style={style} onClick={onClick} className={classNames('material-icons text-xl mt-0.5', className)}>{materialIconName}</span>
}
export default Icon;
