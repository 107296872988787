import React, { FC } from 'react';
import { ExtendTableProps } from '../../components/table/types/Table';
import BaseArticleTable from './BaseArticleTable';


type Props = ExtendTableProps

const ArticleTable: FC<Props> = () => {
    return <BaseArticleTable/>;
};

export default ArticleTable;