import React, {useEffect, useState} from "react";
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';

import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import {PackageDto, PackageModel, PackageRelationsBluePrint} from "./PackageTypes";
import {FormModal} from "../../lib/samfe/components/Modal";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import Yup from "../../lib/samfe/components/Form/Yup";
import {useForm} from "../../lib/samfe/components/Form";
import usePackage from "./usePackage";
import useAvailablePackageNumber from "./useAvailablePackageNumber";

const PackageForm: React.FC<ExtendFormModalProps<PackageDto>> = ({id, open, setOpen, onSuccess}): JSX.Element => {

    const packageHttp = usePackage();
    const packageNumber = useAvailablePackageNumber();

    const [currentPackage, setCurrentPackage] = useState<PackageModel|undefined>(undefined);
    useEffect(() => {packageHttp.getItem(id).then(setCurrentPackage)}, []);


    const shape = (): Shape<PackageDto> => ({
        name: Yup.string()
            .required()
            .description('Geef een benaming voor de gehele verpakking')
            .label('Naam')
            .controlType('input')
            .inputType('text'),
        short_name: Yup.string()
            .label('Korte naam')
            .controlType('input')
            .max(50)
            .inputType('text'),

        number: Yup.number()
            .hidden(true)
            .label('Nummer')
            .controlType('input')
            .inputType('number'),
    })

    const {validationSchema} = useSchema<PackageDto>(shape());

    // @todo reset states on re init
    const initializer = async () => {}

    const {formikConfig, formFields} = useForm<PackageModel, PackageDto, PackageRelationsBluePrint>({
        id,
        validationSchema,
        onSuccess,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: usePackage,
        morphPayload: (_, dto) => {
            const newDto = {...dto};
            if (!currentPackage) {
                newDto.number = packageNumber;
            }
            return newDto
        },
    });

    return <FormModal
        id={id}
        resource={'Verpakking'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
}

export default PackageForm;