import { HomeIcon } from '@heroicons/react/20/solid';
import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Route, routes } from '../../../../Router';
import { NAVBAR_HEIGHT_STR_IN_REM } from '../../../../templates/layout/Navbar';
import { classNames } from '../../modules/Parse/String';
import useId from '../../modules/Router/useId';
import Skeleton from '../Skeleton/Skeleton';


export const BREADCRUMB_HEIGHT_NUM_IN_REM = 2.25;
export const BREADCRUMB_HEIGHT_STR_IN_REM = `${BREADCRUMB_HEIGHT_NUM_IN_REM}rem`;

type Props = {
    breadcrumb?: string
    isIndex?: boolean
}
const Breadcrumbs: FC<Props> = ({ breadcrumb = null, isIndex }): JSX.Element => {

    const location = useLocation();
    const id = useId(false);

    const [ currentRoutes, setCurrentRoutes ] = useState<Route[]>([]);
    const getCurrentRoutes = () => {
        return routes.filter((route) => {
            if (!location.pathname.startsWith(route.path)) {
                return false;
            }
            return !isIndex && location.pathname.includes(':id')
                   ?id !== undefined && !isNaN(id)
                   :true;
        }).reverse();
    };

    useEffect(() => {
        setCurrentRoutes(getCurrentRoutes());
    }, []);


    const showSkeleton = () => breadcrumb === null && isIndex !== true;

    const getListItem = (route: Route, key?: number, _?: Route[], isCurrent?: boolean) => {


        return <li key={ key } className="flex">
            { route.breadcrumb ?<div className="flex items-center max-w-[10rem] sm:max-w-[27.25rem] md:max-w-lg lg:max-w-md xl:max-w-2xl">
                <svg
                    className="w-4 h-9 flex-shrink-0 text-gray-300"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
                </svg>
                { showSkeleton()
                  ?<div className={ 'ml-2 -mt-1' }><Skeleton type={ 'breadcrumb' }/></div>
                  :<Link
                      to={ route.path }
                      className={ classNames(
                          'ml-4 text-sm w-full !max-w-[24rem] overflow-ellipsis overflow-hidden whitespace-nowrap',
                          isCurrent === true ?'text-aqua hover:text-aqua-hover font-medium' :'text-graphite hover:text-graphite-hover opacity-75',
                          'hover:underline underline-offset-4 decoration-[0.03125rem]'
                      ) }
                      aria-current={ isCurrent === true ?'page' :'false' }
                  >
                      { route.breadcrumb }
                  </Link>
                }
            </div> :<></> }
        </li>;
    };


    const getAncestorItems = () => {
        const items = Array.from(currentRoutes);
        if (isIndex) {
            items.pop();
        }
        return items.map(getListItem);
    };
    const getCurrentItem = () => {
        const currentRoute = currentRoutes[currentRoutes.length - 1];
        const withId = isNaN(id) ?'' :`/${ id }`;
        const lastPath = currentRoutes[currentRoutes.length - 1]?.path;
        return getListItem({
            ...currentRoute,
            breadcrumb: breadcrumb ?? undefined,
            path: `${ lastPath }${ withId }`
        }, currentRoutes.length - 1, [], true);
    };


    return (<>
        <nav style={{height: BREADCRUMB_HEIGHT_STR_IN_REM, top: NAVBAR_HEIGHT_STR_IN_REM}} className="breadcrumb-nav sticky z-10 border-b border-gray-200 bg-white left-0 2xl:left-72 right-0" aria-label="Breadcrumb">
            <ol className="flex w-full space-x-4 px-4 md:px-8 lg:px-12">
                <li className="flex">
                    <div className="flex items-center">
                        <Link to={ '/dashboard' } className="text-aqua hover:text-aqua-hover opacity-75">
                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                { getAncestorItems() }
                { showSkeleton()
                  ?<div className={ 'ml-2 mt-1.5' }><Skeleton type={ 'breadcrumb' }/></div>
                  :getCurrentItem()
                }
            </ol>
        </nav>
    </>);
};
export default Breadcrumbs;