import React, { FC, useState } from 'react';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import ShowPage from '../../templates/pages/ShowPage';
import ProductTypeProductTable from './pivot/ProductTypeProductTable';
import ProductTypeForm from './ProductTypeForm';
import { ProductTypeModel } from './ProductTypeTypes';
import useProductType from './useProductType';


const ProductTypeShow: FC = (): JSX.Element => {

    const [ currentProductType, setCurrentProductType ] = useState<ProductTypeModel|undefined>(undefined);

    return (
        <ShowPage
            title={ currentProductType?.name }
            breadcrumb={ currentProductType?.name }
            setCurrentModel={ setCurrentProductType }
            FormModal={ ProductTypeForm }
            httpHook={ useProductType }

            tabs={ currentProductType ?[
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <ExampleGraph/>
                    </>
                },
                {
                    name: 'Producten',
                    id: 'elements',
                    element: <>
                        {currentProductType.id && <ProductTypeProductTable parentId={ currentProductType.id }/>}
                    </>
                }
            ] :[] }
        />
    );
};
export default ProductTypeShow;