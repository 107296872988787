import React, { FC, useMemo } from 'react';
import Table from '../index';
import { ExtendTableProps } from '../types/Table';
import { TableColumns } from '../types/TableRow';
import { TestTableModel, TestTableRelationsBlueprint } from './useTableData.test.hook';
import useTableHttpTestHook from './useTableHttp.test.hook';


type Props = ExtendTableProps


const TableTestComponent: FC<Props> = () => {

    const httpHook = useTableHttpTestHook();

    const rows = useMemo((): TableColumns<TestTableModel, TestTableRelationsBlueprint>[] => [
        {
            header: {
                children: 'ID',
                sortCol: 'id'
            },
            column: (item) => ({
                children: item.id
            }),
            type: 'numeric',
            style: {
                width: '1rem'
            }
        },
        {
            header: {
                children: 'Product',
                sortCol: 'product'
            },
            column: (item) => ({
                children: item.product
            }),
            type: 'text'
        },
        {
            header: {
                children: 'THT',
                sortCol: 'expirationDate'
            },
            column: (item) => ({
                children: item.expirationDate
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Aantal',
                sortCol: 'quantity'
            },
            column: (item) => ({
                children: item.quantity
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Prijs p/s',
                sortCol: 'price'
            },
            column: (item) => ({
                children: item.price
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Prijs totaal'
            },
            column: (item) => ({
                children: (item.price ?? 0) * (item.quantity ?? 0)
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Winstmarge',
                sortCol: 'profitMargin'
            },
            column: (item) => ({
                children: item.profitMargin
            }),
            type: 'percentage'
        }
    ], []);

    return (
        <Table
            id={ 'example' }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: ['product'],
                filter: {
                    column: 'quantity',
                    operator: '>=',
                    value: 100
                },
                filters: [
                    {
                        displayName: 'Small price',
                        column: 'price',
                        operator: '<=',
                        value: 10
                    }
                ]
            } }
        />
    );
};

export default TableTestComponent;
