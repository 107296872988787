import { RepackingModel } from './RepackingTypes';


/**
 * total amount that is usable for repacking.
 *
 * @example all examples use usable stock from parent in pills.
 * @example bulk => 10_000 * 1 = 10_000 pills available for repacking.
 * @example package(60) => 1_000 * 60 = 60_000 pills available for repacking.
 *@example backorder -> infinite amount.
 * @returns {number}
 */
export const UsableAmountPerUnit = (usableStock: number, parentContents: number, isBackOrder: boolean): number => {
    if (isBackOrder) {
        return Infinity;
    }
    return usableStock * parentContents;
};

/**
 * total claimed number of units (pills, tablets etc.).
 *
 * @example target=package(60), target amount=10 => 10*60 = 600 pills.
 * @returns {number}
 */
export const ClaimedAmountPerUnit = (claimedAmount:number, parentContents: number):number => {
    return claimedAmount * parentContents;
};

/**
 * Total available for current repack order.
 *
 * When a repack order already exists and is edited, it already has a claim on the parent stock.
 * The amount of the record + usable in parent = available.
 * Value is in pills.
 *
 * @returns {number}
 */
export const AvailableAmountPerUnit = (usableAmountPerUnit: number, claimedAmountPerUnit: number): number => {
    return usableAmountPerUnit + claimedAmountPerUnit;
};

/**
 * Max amount the target can use.
 *
 * @returns {number}
 */
export const AvailableTargetAmount = (availableAmountPerUnit: number, targetContents: number): number => {
    return Math.floor(availableAmountPerUnit / targetContents);
};

/**
 * Amount requested for target article per amount.
 *
 * @returns {number}
 */
export const TargetAmountPerUnit = (targetAmount: number, targetContents: number): number => {
    return targetAmount * targetContents;
};


export type RepackDataItem = {
    amount: number;
    contents: number,
    amountPerUnit: number,
    usable: number,
    available: number
}
export type RepackDataType = {
    parent: RepackDataItem,
    target: RepackDataItem,
    totals: {
        amount: number,
        usable: number,
        available: number
    }
}
const RepackData = (repackOrder: RepackingModel): RepackDataType => {

    const {parentArticle, parentCharge, targetArticle} = repackOrder
    const parentContents = parentArticle?.amount??0
    const claimedAmount = repackOrder.amount ?? 0;

    const claimendAmountPerUnit = ClaimedAmountPerUnit(claimedAmount, parentContents);

    const targetContents = targetArticle?.amount??0
    const targetAmount = Math.floor(claimendAmountPerUnit /targetContents);
    const targetAmountPerUnit = TargetAmountPerUnit(targetAmount, targetContents)

    const usableStock = parentCharge?.stock?.usable_stock ?? 0;
    const usableAmountPerUnit = UsableAmountPerUnit(usableStock, parentContents, parentCharge === undefined)
    const availableAmountPerUnit = AvailableAmountPerUnit(usableAmountPerUnit, claimendAmountPerUnit);


    return {
        parent: {
            amount: claimedAmount,
            contents: parentContents,
            amountPerUnit: claimendAmountPerUnit,
            usable: usableStock,
            available: availableAmountPerUnit / parentContents
        },
        target: {
            amount: targetAmount,
            contents: targetContents,
            amountPerUnit: targetAmountPerUnit,
            usable: Math.floor(usableAmountPerUnit/targetContents),
            available: AvailableTargetAmount(availableAmountPerUnit, targetContents)
        },
        totals: {
            amount: claimendAmountPerUnit,
            usable: usableAmountPerUnit,
            available: availableAmountPerUnit
        }

    }



}
export default RepackData