import React, {FC} from "react";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import useAsyncInit from "../../lib/samfe/components/Form/Effects/useAsyncInit";
import Yup from "../../lib/samfe/components/Form/Yup";
import usePrinter from "./usePrinter";
import {PrinterDto, PrinterModel, PrinterRelationsBluePrint} from "./PrinterTypes";
import {useForm} from "../../lib/samfe/components/Form";
import {FormModal} from "../../lib/samfe/components/Modal";


const PrinterForm: FC<ExtendFormModalProps<PrinterDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    /**
     *
     */
    const shape = (): Shape<PrinterDto> => ({
        display_name: Yup.string()
            .required()
            .label('Printer Naam')
            .controlType('input')
            .inputType('text'),

        name: Yup.string()
            .required()
            .label('Interne Naam')
            .controlType('input')
            .inputType('text'),

    });


    /**
     *
     */
    const {validationSchema, setShape} = useSchema<PrinterDto>(shape());

    const initializer = async () => {
        setShape(shape())
    }

    /**
     *
     */
    const { formikConfig, formFields } = useForm<PrinterModel, PrinterDto, PrinterRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: usePrinter,
        onSuccess: onSuccess
    });


    return <FormModal
        id={id}
        resource={'Printer'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
};

export default PrinterForm;
