import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionButton, EditButton } from '../../../lib/samfe/components/Button';
import Checkbox from '../../../lib/samfe/components/Form/Generic/Checkbox';
import Input from '../../../lib/samfe/components/Form/Generic/Input';
import Jumbotron from '../../../lib/samfe/components/Jumbotron/Jumbotron';
import Modal from '../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../lib/samfe/components/Modal/LegacyFormModal';
import Alert from '../../../lib/samfe/components/Notifications/Alert';
import { sqlDateTimeToDate } from '../../../lib/samfe/modules/Parse/Date';
import CustomerForm from '../../customer/CustomerForm';
import { CustomerModel } from '../../customer/CustomerTypes';
import useCustomer from '../../customer/useCustomer';
import { SaleRowDto } from '../../sale/pivot/SaleRowTypes';
import useSaleRow from '../../sale/pivot/useSaleRow';
import { SaleDto } from '../../sale/SaleTypes';
import useSale from '../../sale/useSale';
import useWooCommerce from '../useWooCommerce';
import { WooCommerceModel } from '../WooCommerceTypes';
import useWooCommerceImport from './useWooCommerceImport';
import WooCommerceImportRowItem from './WooCommerceImportRowItem';
import { WooCommerceImportEntity } from './WooCommerceImportTypes';


const WooCommerceImportForm: FC<ExtendFormModalProps<SaleDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    type SaleRowList = {
        [sku: string]: SaleRowDto[]
    }

    const sale = useSale();
    const saleRow = useSaleRow();
    const customer = useCustomer();
    const woocommerce = useWooCommerce();
    const navigate = useNavigate();
    const { getOrder } = useWooCommerceImport();

    const [ importOrder, setImportOrder ] = useState<WooCommerceImportEntity>();
    const [ saleRows, setSaleRows ] = useState<SaleRowList>({});
    const [ currentCustomer, setCurrentCustomer ] = useState<CustomerModel>();
    const [ openCustomerEdit, setOpenCustomerEdit ] = useState(false);
    const [ navigateOnSave, setNavigateOnSave ] = useState(false);
    const [ shippingDate, setShippingDate ] = useState(sqlDateTimeToDate());

    /**
     * Init import order
     */
    useEffect(() => {
        if (!id) {
            return;
        }
        getOrder(id).then(setImportOrder);
    }, []);


    /**
     * Set current customer on order init.
     */
    useEffect(() => {
        if (importOrder?.customer) {
            setCurrentCustomer(importOrder.customer);
        }
    }, [ importOrder ]);


    /**
     * Handler for row items to pass sale rows by sku reference.
     *
     * @param {string} sku
     * @param {SaleRowDto[]} saleRowDtos
     */
    const handleRows = (sku: string, saleRowDtos: SaleRowDto[]): void => {
        saleRows[sku] = saleRowDtos;
        setSaleRows({ ...saleRows });
    };


    const roundDecimalPlaces = (value: number, precision = 2): number => {
        const multiplier = 10 ** precision;
        return Math.round(value * multiplier) / multiplier
    }

    type ReducedPrices = {subtotal: number, total: number};
    const getWcSaleDiscountInEuro = () => {
        const reducer: ReducedPrices = {subtotal: 0, total: 0};
        const reducedPrices = (importOrder?.json.line_items??[]).reduce((acc, current): ReducedPrices => ({
            subtotal: acc.subtotal += parseFloat(`${current.subtotal??0}`),
            total: acc.total += parseFloat(`${current.total??0}`)
        }), reducer);

        const subtotal = roundDecimalPlaces(reducedPrices.subtotal) // before discount
        const total = roundDecimalPlaces(reducedPrices.total) // after discount
        return roundDecimalPlaces(subtotal - total)
    }

    /**
     * Create sale for importable sale rows.
     *
     * @returns {Promise<void>}
     */
    const handleSubmit = async(): Promise<void> => {

        if (Object.keys(saleRows).length === 0) {
            // @todo error handling
            return;
        }

        const isBackorder = Object
            .entries(saleRows)
            .find(
                ([ _, rows ]) => rows.find(row => row.status === 'backorder') !== undefined
            ) !== undefined;

        const payload: SaleDto = {
            customer_id: currentCustomer?.id,
            wc_import_id: id,
            wc_sale_id: importOrder?.json.id,
            ordered_at: importOrder?.json.date_created,
            shipped_at: shippingDate,
            discount: getWcSaleDiscountInEuro(),
            status: isBackorder ?'backorder' :'to send'
        };

        if (importOrder?.json.customer_note) {
            payload.customer_note = importOrder?.json.customer_note;
        }

        await sale.create(payload).then(res => {
            const saleId = res?.id;
            saleRow.setSaleId(saleId);
        });
    };


    /**
     * Sale rows are excluding tax
     * Create sale rows associated with previous created sale and update woocommerce import item as processed.
     *
     * @returns {Promise<WooCommerceModel| undefined>}
     */
    const handleSaleRowsSubmit = async(): Promise<WooCommerceModel|undefined> => {
        const saleId = saleRow.saleId;
        for (const [ _, saleRowDtos ] of Object.entries(saleRows)) {
            for (const saleRowDto of saleRowDtos) {
                await saleRow.create({
                    ...saleRowDto,
                    sale_id: saleId
                });
            }
        }

        return await woocommerce.update(id, {
            status: 'processed'
        });
    };


    /**
     * Sale ID listener, if set -> submit rows.
     */
    useEffect(() => {
        const saleId = saleRow.saleId;
        if (!saleId) {
            // @todo error handling
            return;
        }

        handleSaleRowsSubmit().then(() => {
            if (navigateOnSave) {
                navigate(`/sales/${ saleId }`);
                return;
            }
            setOpen(false);
            onSuccess?.(id);
        });
    }, [ saleRow.saleId ]);


    /**
     * Action buttons in footer of modal.
     *
     * @returns {JSX.Element}
     */
    const footerEl = (): JSX.Element => <div className={ 'relative w-full' }>
        <div className="mt-5 sm:mt-4 flex flex-row-reverse float-right">
            <ActionButton icon={ '' } text={ 'Importeren' } disabled={currentCustomer?.number == undefined} onClick={ handleSubmit }/>
        </div>
        <div className={ 'absolute left-0 bottom-2' }>
            <Checkbox
                label={ 'Order bekijken na opslaan' }
                name={ '' }
                checked={ navigateOnSave }
                onChange={ setNavigateOnSave }
            />
        </div>
    </div>;


    return <Modal
        open={ open }
        setOpen={ setOpen }
        title={ `WooCommerce order ${ importOrder?.wcOrderId ?? '' } importeren` }
        className={ 'min-w-[80vw]' }
        footer={ footerEl() }
    >
        <>
           <Alert type={ 'warning' } message={ `${(importOrder?.ctx ?? []).includes('new_customer') ? 'Nieuwe' : 'Bestaande'} klant` }/>

            <Jumbotron
                title={ <>
                    Klantgegevens
                    <EditButton
                        style={ 'tertiary' }
                        small={ true }
                        iconClassName={ '!mt-[0.125rem] !-ml-2 !text-[1.5rem]' }
                        onClick={ () => setOpenCustomerEdit(true) }
                    />
                </> }

                description={ <>
                    <h3 className="truncate text-sm font-medium text-gray-900">
                        { currentCustomer?.company }
                    </h3>
                    <p className="mt-1 truncate text-sm text-gray-500">
                        { currentCustomer?.shipping_address_1 } { currentCustomer?.shipping_address_2 }
                    </p>
                    <p className="mt-1 truncate text-sm text-gray-500">
                        { currentCustomer?.shipping_postcode } { currentCustomer?.shipping_city } <br/>
                        { currentCustomer?.shipping_country }
                    </p>
                    <p className="mt-1 truncate text-sm text-gray-500">
                        { currentCustomer?.number ? 'Klantnummer: ' + currentCustomer?.number : <Alert type={ 'warning' } message={ `Onbekend klantnummer, haal deze uit Snelstart, bewerk de klant en importeer dan pas de order.` }/> }
                    </p>
                    { openCustomerEdit && <CustomerForm
                        open={ openCustomerEdit }
                        setOpen={ setOpenCustomerEdit }
                        id={ currentCustomer?.id }
                        onSuccess={ (id) => customer.getItem(id).then(setCurrentCustomer) }
                    /> }
                </> }
            />

            <div className="grid grid-cols-3 mt-4">
                <div className="col-span-1">
                    <Input
                        label={ 'Verzend datum' }
                        name={ 'shipping_date'}
                        type={'date'}
                        value={shippingDate}
                        onChange={(v) => setShippingDate(v as string)}
                    />
                </div>
                <div className="col-span-2"></div>
            </div>


            <table className="min-w-full divide-y divide-gray-300 mt-4">
                <thead className={ 'sticky min-w-full top-0 bg-white z-10' }>
                <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-aqua sm:pl-0">
                        Product
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-aqua">
                        SKU
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-aqua">
                        Artikel
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-aqua">
                        Aantal
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-aqua">
                        Charge
                    </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                { importOrder?.import.map((item) => <WooCommerceImportRowItem
                    key={ item.wcData.id }
                    customerNumber={importOrder?.customer.number}
                    item={ item }
                    handleRows={ handleRows }
                />) }
                </tbody>
            </table>
        </>
    </Modal>;
};

export default WooCommerceImportForm;
