import React, {useEffect, useState} from "react";
import {HealthModel} from "../../resources/health/HealthTypes";
import useHealth from "../../resources/health/useHealth";
import BasePage from "../../templates/pages/BasePage";

const StatusPage: React.FC = (): JSX.Element => {

    
    const healthz = useHealth();

    /**
     *
     */
    const [currentHealthz, setCurrentHealthz] = useState<HealthModel|undefined>(undefined);

    useEffect(() => {
        healthz.getItem(undefined, {}, true).then(currentHealthz => setCurrentHealthz(currentHealthz))
    }, []);



    return <BasePage title={'Status'}  breadcrumb={'Status'} >
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Applicatie Informatie</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Proces status overzichten.</p>
            </div>


            <dl className="pt-4 pb-2 sm:pt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 text-md font-bold text-graphite">

                <dt className={'ml-11'}>Service</dt>
                <dd className="sm:mt-0 sm:col-span-2">Status</dd>
            </dl>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">


                <dl className="sm:divide-y sm:divide-gray-200">
                    {Object.entries(currentHealthz??{}).map(([key, value] ) =>
                        <div key={key} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">

                            <dt className="text-md font-medium text-graphite">
                                <span className={`material-icons text-white p-2 mr-3 text-lg rounded-full ${value === "Healthy" ? 'bg-green-700' : (value === "Broken" ? 'bg-red-700' : 'bg-orange-400')}`}>
                                    {value === "Healthy" ? 'done' : (value === "Broken" ? 'close' : 'priority_high')}
                                </span>
                                <span className={'relative top-1 '}>{key}</span>
                            </dt>
                            <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-2">{value}</dd>
                        </div>
                    )}
                </dl>

            </div>
        </div>
    </BasePage>
}
export default StatusPage;