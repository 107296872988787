import { sortByDate } from './Date';


/**
 * Sort object array by property asc/desc.
 *
 * Return function is suitable for Array.sort() API.
 *
 * @template T
 * @template K
 * @param {T[]} arr
 * @param {K} key
 * @param {'ASC'|'DESC'} sortDir
 * @returns {(a: T, b: T) => number}
 * @constructor
 */
export const SortObjectArrayByDate = <
    T extends object,
    K extends keyof T,
    V extends T&string
>(
    arr: T[]|undefined,
    key: K,
    sortDir: 'ASC'|'DESC' = 'ASC'
): T[] => (arr ?? []).sort((a: T, b: T): number => sortByDate(
    a[key] as V,
    b[key] as V,
    sortDir
));

/**
 * Sort object array by key asc/desc.
 *
 * Figures out the following:
 * numbers, booleans, euros strings, usd strings, dates, any sting (locale compare)
 * @template T type of object.
 * @template K key of object
 * @param {T[] | undefined} arr Object array
 * @param {K} key Object key
 * @param {'ASC' | 'DESC'} sortDir
 * @returns {T[]} Sorted object array
 * @constructor
 */
export const SortObjectArray = <
    T extends object,
    K extends keyof T,
    V extends T&string
>(
    arr: T[]|undefined,
    key: K,
    sortDir: 'ASC'|'DESC' = 'ASC'
): T[] => (arr ?? []).sort((a: T, b: T): number => {

    const first = a[key] as V;
    const second = b[key] as V;

    if (first === second) {
        return 0;
    }

    // Init test/return values.
    let returnValue: number;
    const strTestFirst: string = `${ first }`.trim();
    const strTestSecond: string = `${ second }`.trim();

    /**
     * Flip the switch if sort is descending (double negative is positive).
     * @type {1|-1}
     */
    const order: 1|-1 = sortDir === 'ASC' ?1 :-1;

    /**
     * Returns negative or positive number based on test result.
     * @param {boolean} valid
     * @returns {number}
     */
    const testValues = (valid: boolean): number => valid ?(-1 * order) :(1 * order);

    /**
     * Euro string to number
     * @param {string} str
     * @returns {number}
     */
    const euroStringToNumber = (str: string): number => Number(
        str.replace('€', '')
            .replace('.', '')
            .replace(',', '.')
            .replace(' ', '')
    );

    /**
     * USD string to number
     * @param {string} str
     * @returns {number}
     */
    const usdStringToNumber = (str: string): number => Number(
        str.replace('$', '')
            .replace(',', '')
            .replace(' ', '')
    );

    // Boolean
    if (typeof first === 'boolean') {
        returnValue = testValues(first);

        // Number
    } else if (!isNaN(Number(strTestFirst))) {
        returnValue = testValues(Number(strTestFirst)<Number(strTestSecond));

        // Date
    } else if (!isNaN(Date.parse(strTestFirst))) {
        returnValue = sortByDate(strTestFirst, strTestSecond, sortDir);

        // Euro -> '€1,00' | '€1.000.000,00' | '€ 10,00' | '€ 1.000,00'
    } else if (strTestFirst.match(/^€\s?(\d+\.?)+,\d{2}/gm)) {
        returnValue = testValues(euroStringToNumber(strTestFirst)<euroStringToNumber(strTestSecond));

        // USD -> '$1.00' | '$1,000,000.00' | '$ 10.00' | '$ 1,000.00'
    } else if (strTestFirst.match(/^\$\s?(\d+,?)+\.\d{2}/gm)) {
        returnValue = testValues(usdStringToNumber(strTestFirst)<usdStringToNumber(strTestSecond));
    }

    // string any
    else {
        returnValue = strTestFirst.localeCompare(strTestSecond);
    }

    return returnValue;
});



export const HighestValueOfObjectArray = <T extends object, K extends keyof T>(items: T[], key: K): number => {
    if (items.length === 0) {
        return 0;
    }
    const reducer = (acc: number, current: T): number => {
        const currentVal = current[key] as unknown as number|undefined;
        if (typeof currentVal !== 'number') {
            return 0;
        }
        return currentVal > acc ? currentVal : acc;
    }
    return items.reduce(reducer, 0);
}