import React, {FC} from "react";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import useAsyncInit from "../../lib/samfe/components/Form/Effects/useAsyncInit";
import Yup from "../../lib/samfe/components/Form/Yup";
import useExcipient from "./useExcipient";
import {ExcipientDto, ExcipientModel, ExcipientRelationsBluePrint} from "./ExcipientTypes";
import {useForm} from "../../lib/samfe/components/Form";
import {FormModal} from "../../lib/samfe/components/Modal";


const ExcipientForm: FC<ExtendFormModalProps<ExcipientDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    /**
     *
     */
    const shape = (): Shape<ExcipientDto> => ({
        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text'),

    });


    /**
     *
     */
    const {validationSchema, setShape} = useSchema<ExcipientDto>(shape());

    const initializer = async () => {
        setShape(shape())
    }

    /**
     *
     */
    const { formikConfig, formFields } = useForm<ExcipientModel, ExcipientDto, ExcipientRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useExcipient,
        onSuccess: onSuccess
    });


    return <FormModal
        id={id}
        resource={'Hulpstof functie'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
};

export default ExcipientForm;
