import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import Modalfy from '../../../../lib/samfe/components/Modal/Modalfy';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import RepackingReceipt from '../../../repacking/RepackingReceipt';
import { RepackingModel } from '../../../repacking/RepackingTypes';
import useRepackingPivotless from '../../../repacking/useRepackingPivotless';
import { AssessmentHistoryChargeFragment } from '../../types/AssessmentTypes';

// @todo repack support
const ChargeOriginColumn: FC<{
    purchaseId?: number|null,
    producerName?: string|null
    repackId?: number|null,
    parentCharge?: AssessmentHistoryChargeFragment,
    showLarge?: boolean
}> = ({
    purchaseId,
    producerName,
    repackId,
    parentCharge,
    showLarge = false
}) => {

    const repackHook = useRepackingPivotless();
    const [ currentRepackOrder, setCurrentRepackOrder ] = useState<RepackingModel>();
    const [ repackOpen, setRepackOpen ] = useState(false);


    const handleAssessmentClick = () => {
        if (!repackId) {
            return;
        }
        repackHook.getItem(repackId, {
            with: [
                'parentArticle.product.productType',
                'parentArticle.package.parts',
                'parentCharge.chargeLocations.location.group',
                'targetArticle.package.parts',
                'targetCharge.chargeLocations.location.group'
            ]
        }).then(rpOrder => {
            setCurrentRepackOrder(rpOrder);
            setRepackOpen(true);
        });
    };

    return (
        <div className={classNames(!showLarge && 'mt-0.5')}>
            { purchaseId && <>
                <span className={ classNames('block font-medium', showLarge ?'text-md' :'text-xs') }>
                    Inkooporder [<Link to={ `/purchases/${ purchaseId }` } className={ 'underline' }>{ purchaseId }</Link>]
                </span>
                <span className={ classNames('block font-light', showLarge ?'text-md' :'text-xs') }>
                    { producerName }
                </span>
            </> }

            { repackId && <>
                <span className={ classNames('block font-medium', showLarge ?'text-md' :'text-xs') }>
                    Uitvulopdracht [<button className={ 'underline' } onClick={ handleAssessmentClick }>{ repackId }</button>]
                </span>
                { parentCharge && <span className={ classNames('block font-light', showLarge ?'text-md' :'text-xs -mt-1') }>
                    Bron: { parentCharge.batchcode }
                </span> }
            </> }

            { currentRepackOrder && repackOpen && <Modalfy
                omitCloseWarningModal={ true }
                open={ repackOpen }
                setOpen={ setRepackOpen }
            >
                <RepackingReceipt
                    repackOrder={ currentRepackOrder }
                    isInline={ false }
                    onClose={ () => setRepackOpen(false) }
                />
            </Modalfy> }
        </div>
    );
};

export default ChargeOriginColumn;