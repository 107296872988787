import React, { FC, ReactNode } from 'react';


type Props = {
    children: ReactNode
}

const FormControl: FC<Props> = ({ children }): JSX.Element => {
    return <div className={ 'my-4' }>
        { children }
    </div>;
};

export default FormControl;