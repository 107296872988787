import React, { FC } from 'react';
import Pill from '../../../lib/samfe/components/Pill/Pill';


type Props = {
    isApproved: boolean
}
const AssessmentStatusPill: FC<Props> = ({
    isApproved
}) => {
    return (
        <Pill
            title={ isApproved ?'Geborgd' :'Niet geborgd' }
            state={ isApproved ?'success' :'danger' }
        />
    );
};

export default AssessmentStatusPill;
