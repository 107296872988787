import React, {FC} from "react";
import useAsyncInit from '../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, {Shape} from "../../../lib/samfe/components/Form/Effects/useSchema";
import {AttributeOptionDto, AttributeOptionModel, AttributeOptionRelationsBluePrint} from "./AttributeOptionTypes";
import Yup from "../../../lib/samfe/components/Form/Yup";
import {ExtendFormModalProps} from "../../../lib/samfe/components/Modal/FormModal/FormModal";
import {useForm} from "../../../lib/samfe/components/Form";
import {FormModal} from "../../../lib/samfe/components/Modal";
import useAttributeOption from "./useAttributeOption";


/**
 *
 * @var {number | undefined} parentId attribute id
 * @var {number | undefined} id option id
 */
const AttributeOptionForm: FC<ExtendFormModalProps<AttributeOptionDto>> = ({ parentId, id, open, setOpen, onSuccess }): JSX.Element => {

    /**
     *
     */
    const shape = (): Shape<AttributeOptionDto> => ({
        name: Yup.string()
            .required()
            .label('Waarde')
            .controlType('input')
            .inputType('text'),

        attribute_id: Yup.number()
            .label('Producttype')
            .hidden(true)
            .controlType('input')
            .inputType('number'),
    });


    /**
     *
     */
    const {validationSchema} = useSchema<AttributeOptionDto>(shape());

    const initializer = async () => {}

    /**
     *
     */
    const { formikConfig, formFields } = useForm<AttributeOptionModel, AttributeOptionDto, AttributeOptionRelationsBluePrint>({
        id,
        parentId,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useAttributeOption,
        onSuccess: onSuccess,
        morphPayload: (_, dto) => ({
            ...dto,
            attribute_id: parentId
        })
    });


    return <FormModal
        id={id}
        parentId={parentId}
        resource={'Producteigenschap'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
};

export default AttributeOptionForm;
