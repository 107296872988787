import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useFormikContext} from "formik";


const useChecked = (name:string, initialState?: boolean): [boolean|undefined, Dispatch<SetStateAction<boolean|undefined>>] => {
    const [checked, setChecked] = useState<boolean|undefined>(initialState !== undefined ? initialState: false);
    const formikCtx = useFormikContext();
    useEffect(() => {formikCtx.setFieldValue(name, checked !== undefined ? checked : false )}, [checked]);
    return [checked, setChecked];
};
export default useChecked;
