import { useEffect, useState } from 'react';

type Dimension = {
    width: number,
    height: number
}

const getDocumentDimensions = (): Dimension => ({
    width: document.body.clientWidth,
    height: document.body.clientHeight
})


const useDocumentDimensions = (): {
    dimension: Dimension,
    refreshDimension: () => void
} => {

    const [ dimension, setDimension ] = useState<Dimension>(getDocumentDimensions());

    useEffect(() => {
        const listener = () => {
            setDimension(getDocumentDimensions())
        }

        window.addEventListener('resize', listener)
        setTimeout(() => setDimension(getDocumentDimensions()), 100)
        return () => {
            window.removeEventListener('resize', listener)
        }
    }, []);

    const refreshDimension = () => {
        setDimension(getDocumentDimensions())
    }

    return { dimension, refreshDimension };
};

export default useDocumentDimensions;
