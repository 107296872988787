import React, {FC} from "react";
import IndexPage from "../../templates/pages/IndexPage";
import IngredientForm from "./IngredientForm";
import IngredientTable from "./IngredientTable";



const IngredientIndex: FC = (): JSX.Element => <IndexPage
    title={"Ingrediënten"}
    Table={IngredientTable}
    FormModal={IngredientForm}
/>
export default IngredientIndex;