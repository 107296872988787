import React, { useEffect, useState } from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import DynamicSearch from '../../../../lib/samfe/components/Form/Field/SelectSearch/DynamicSearch';
import FormModal, { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { ChargeLocationDto, ChargeLocationModel, LocationSearchResult } from '../../../location/ChargeLocationTypes';
import { getFullLocation } from '../../../location/LocationFunctions';
import { ChargeLocationPivotMoveModel, useChargeLocation, useChargeLocationPivotMove } from '../../../location/useLocation';


/**
 * @var {number|undefined} parentId = Charge ID
 * @var {number|undefined} id = charge location id
 */
const ChargeLocationMoveForm: React.FC<ExtendFormModalProps<ChargeLocationDto>> = ({ id, parentId, open, setOpen, onSuccess }): JSX.Element => {


    // @todo get initial charge locations

    const chargeLocationHook = useChargeLocation();
    const chargeLocationPivotMove = useChargeLocationPivotMove(parentId);
    const [ legacyChargeLocation, setLegacyChargeLocation ] = useState<ChargeLocationModel>();
    const [ initialSelectedOption, setInitialSelectedOption ] = useState<LocationSearchResult>();
    const [ selectedOption, setSelectedOption ] = useState<LocationSearchResult>();

    const shape = (): Shape<{}> => ({});

    const { validationSchema, setShape } = useSchema<{}>(shape());


    useEffect(() => {
        setShape(shape());
    }, []);

    const initializer = async() => {

        await chargeLocationHook.getItem(id, {
            with: [ 'location.group' ]
        }).then(cl => {
            if (!cl?.location_id || !cl?.location?.group_id) {
                setLegacyChargeLocation(cl);
                return;
            }
            const initialOption = {
                display: getFullLocation(cl),
                location_id: cl?.location_id,
                level: cl?.level,
                group_id: cl?.location?.group_id
            };

            setSelectedOption(initialOption);
            setInitialSelectedOption(initialOption);
        });
        setShape(shape());
    };

    const { formikConfig, formFields } = useForm<ChargeLocationPivotMoveModel, Partial<ChargeLocationPivotMoveModel>, ''>({
        validationSchema: validationSchema,
        staticFormMethod: 'PUT',
        useHttpHook: () => chargeLocationPivotMove,
        skipInitialFetch: true,
        onSuccess,
        initializer,
        initialized: useAsyncInit(initializer, open),
        morphPayload: () => {
            return {
                current_charge_location_id: id,
                new_location_id: selectedOption?.location_id!,
                new_level: selectedOption!.level === undefined ?null :selectedOption!.level
            };
        }
    });

    return <FormModal
        id={ parentId }
        operationName={'aanpassen'}
        resource={ 'Locatie' }
        hideCreateAnotherOne={ true }
        open={ open }
        canSave={ selectedOption != undefined }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        htmlBeforeForm={ <div className={ 'text-graphite mb-4' }>

            <p className={ 'font-semibold' }>Huidige locatie</p>
            { legacyChargeLocation != undefined && <>
                <p>
                    { getFullLocation(legacyChargeLocation) }
                </p>
                <p className={ 'text-fire text-sm' }>
                    Dit is een oude locatie, alleen nieuwe locaties kunnen gekozen worden.
                </p>
            </> }

            { initialSelectedOption && <span>{ initialSelectedOption.display }</span> }

        </div> }
        htmlAfterForm={ <DynamicSearch label={ 'Nieuwe locatie' } singular={ true } config={
            {
                searchResource: 'search/locations',
                values: selectedOption ?[ selectedOption ] :[],
                setValues: (options => {
                    if (options.length>0) {
                        setSelectedOption(options[0]);
                    }
                }),
                initialDisplay: selectedOption?.display
            }
        }/> }/>;
};

export default ChargeLocationMoveForm;