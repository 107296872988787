import useAxios from '../../lib/samfe/modules/Http/useAxios';
import useHttp from "../../lib/samfe/modules/Http/useHttp";
import {SaleDto, SaleModel, SaleRelationsBluePrint} from "./SaleTypes";

const useSale = () => {

    const axios = useAxios();

    const saleHttp = useHttp<SaleModel, SaleDto, SaleRelationsBluePrint>({
        endpoint: 'sales'
    });

    const markAsSent = async (saleId: number) => {
        await axios.post(`sales/${saleId}/sent`, {})
    }

    return {
        ...saleHttp,
        markAsSent
    }
}

export default useSale;