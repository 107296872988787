import React, { FC } from 'react';
import IndexPage from '../../templates/pages/IndexPage';
import CategoryForm from './CategoryForm';
import CategoryTable from './CategoryTable';


const CategoryIndex: FC = (): JSX.Element => <IndexPage
    title={ 'Grondstof categorieën' }
    Table={ CategoryTable }
    FormModal={ CategoryForm }
/>;
export default CategoryIndex;