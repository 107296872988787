import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import {ProducerDto, ProducerModel, ProducerRelationsBluePrint} from "./ProducerTypes";
import {FormModal} from "../../lib/samfe/components/Modal";
import React from "react";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import Yup from "../../lib/samfe/components/Form/Yup";
import {useForm} from "../../lib/samfe/components/Form";
import useAsyncInit from "../../lib/samfe/components/Form/Effects/useAsyncInit";
import useProducer from "./useProducer";

const ProducerForm: React.FC<ExtendFormModalProps<ProducerDto>> = ({id, open, setOpen, onSuccess}): JSX.Element => {

    const producer = useProducer();

    const shape = (initialProducer?: ProducerModel): Shape<ProducerDto> => ({

        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text'),

        number: Yup.string()
            .required()
            .label('Nummer')
            .controlType('input')
            .inputType('text'),

        contact_person: Yup.string()
            .required()
            .label('Contact persoon')
            .inputType('text')
            .controlType('input'),

        address: Yup.string()
            .required()
            .label('Address')
            .inputType('text')
            .controlType('input'),

        zipcode: Yup.string()
            .required()
            .label('Postcode')
            .inputType('text')
            .controlType('input'),

        city: Yup.string()
            .required()
            .label('Stad')
            .inputType('text')
            .controlType('input'),

        phone: Yup.string()
            .label('Telefoonnummer')
            .inputType('text')
            .controlType('input'),

        email: Yup.string()
            .required()
            .label('Email')
            .inputType('text')
            .controlType('input'),

        email_alt: Yup.string()
            .label('Alternatieve email')
            .inputType('text')
            .controlType('input'),

        website: Yup.string()
            .label('Website')
            .inputType('text')
            .controlType('input'),

        comments: Yup.string()
            .label('Notitie')
            .controlType('textArea'),

        active: Yup.boolean()
            .label('Is actief')
            .controlType('checkbox')
            .defaultValue(initialProducer ? [1, true].includes(initialProducer?.active??false): true)
    });


    const {validationSchema, setShape} = useSchema<ProducerDto>(shape());

    const initializer = async () => {
        const initialProducer = await producer.getItem(id);
        setShape(shape(initialProducer))
    }

    const {formikConfig, formFields} = useForm<ProducerModel, ProducerDto, ProducerRelationsBluePrint>({
        id,
        validationSchema: validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useProducer,
        onSuccess,
        morphPayload: (formikValues, dto) => {
            const active = (dto.active ?? formikValues.active);
            return {
                ...dto,
                active: (active === 1 || active === true) ?1 :0
            }
        }
    });

    return <FormModal
        id={id}
        resource={'Producent'}
        open={open }
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
}

export default ProducerForm;