import { ProductTypeModel } from './ProductTypeTypes';


export const getProductTypeUnit = (productType?: ProductTypeModel, isPlural: boolean = false): string => {

    if (!productType) {
        return isPlural ? 'stuks' : 'stuk';
    }

    if ((productType.name ??'onbekend').toLowerCase() === 'onbekend') {
        return isPlural ? 'stuks' : 'stuk';
    }

    return isPlural
           ? (productType.plural ??'').toLowerCase()
           : (productType.name ??'').toLowerCase();
};