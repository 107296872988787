import {
    ArticleProducerDto, ArticleProducerModel,
    ArticleProducerRelationsBluePrint
} from "../../../article/pivot/ArticleProducer/ArticleProducerTypes";
import useHttp, {UseHttp} from "../../../../lib/samfe/modules/Http/useHttp";

const useProducerArticle = (producerId?: number): UseHttp<ArticleProducerModel, ArticleProducerDto, ArticleProducerRelationsBluePrint> => useHttp<ArticleProducerModel, ArticleProducerDto, ArticleProducerRelationsBluePrint>({
    endpoint: `producers/${producerId}/articles`
});
export default useProducerArticle;
