import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import React, { SVGProps } from 'react';
import { classNames } from '../../modules/Parse/String';


export type AlertProps = {
    type: 'info'|'warning',
    message: string|JSX.Element,
    className?: string
}

const Alert: React.FC<AlertProps> = ({ type, message, className }): JSX.Element => {

    type AlertStyle = {
        bg: string,
        border: string,
        icon: (props: SVGProps<SVGSVGElement>&{ title?: string; titleId?: string; }) => JSX.Element,
        text: string
    }

    const warningStyle: AlertStyle = {
        bg: 'yellow-300/[30%]',
        border: 'carrot',
        text: 'carrot-active',
        icon: ExclamationTriangleIcon
    };

    const infoStyle: AlertStyle = {
        bg: 'blue-300/25',
        border: 'blue-900',
        text: 'blue-900',
        icon: InformationCircleIcon
    };

    const style: AlertStyle = type === 'info' ?infoStyle :warningStyle;

    /**
     * Dirty css class declaration
     * Sonar and Qodana will complain
     * @fixme classname persistence when dynamic declared
     */
    const elDescriber = <div className={ 'alert border-carrot bg-yellow-300/[30%] text-carrot-active border-blue-900 bg-blue-300/25 text-blue-900 ' }></div>;

    return <div className={ classNames(`border-l-4 border-${ style.border } bg-${ style.bg } p-2 mb-4 rounded-md shadow-sm drop-shadow-sm`, className) }>
        <div className="flex">
            <div className="flex-shrink-0">
                <style.icon className={ `h-6 w-6 text-${ style.text }` } aria-hidden="true"/>
            </div>
            <div className={ `ml-2 text-md font-semibold text-${ style.text }` }>
                { message }
            </div>
        </div>
    </div>;
};
export default Alert;