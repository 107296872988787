import React, { FC } from 'react';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import BaseElementTable from '../../../element/BaseElementTable';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const RiskElementTable: FC<Props> = ({ parentId }): JSX.Element => {

    return <BaseElementTable
        forType={ 'reference' }
        extraRelations={['elementRisks']}
        extraFilter={ {
            column: 'elementRisks.risk_id',
            operator: '=',
            value: parentId
        } }
    />;
};

export default RiskElementTable;