import React, { FC } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import PrinterForm from '../printer/PrinterForm';
import usePrinter from '../printer/usePrinter';
import { PrinterJobDto, PrinterJobModel, PrinterJobRelationsBluePrint } from './PrinterJobTypes';
import usePrinterJob from './usePrinterJob';


const PrinterJobForm: FC<ExtendFormModalProps<PrinterJobDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    const printerJob = usePrinterJob();

    /**
     *
     */
    const shape = (initialRecord?: PrinterJobModel): Shape<PrinterJobDto> => ({
        printer_id: Yup.number()
            .label('Printer')
            .required()
            .controlType('selectSearch')
            .selectSearchConfig({
                useHook: usePrinter,
                expectsInitialModel: id !== undefined,
                initialModel: initialRecord?.printer,
                searchOptions: {
                    valueCol: 'id',
                    searchCols: [ 'display_name' ],
                    displayName: (model) => `${ model.display_name }`,
                    limit: 'all',
                    filter: 'archived=0', // @review monkey patch for limit all
                    FormModal: PrinterForm
                }
            }),

        name: Yup.string()
            .required()
            .label('Taaknaam')
            .controlType('input')
            .inputType('text'),
        template: Yup.string()
            .required()
            .label('Gebruik Omschrijving (API)')
            .controlType('input')
            .inputType('text')

    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<PrinterJobDto>(shape());
    const initializer = async() => {
        const initialRecord = await printerJob.getItem(id, { with: [ 'printer' ] });

        setShape(shape(initialRecord));
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<PrinterJobModel, PrinterJobDto, PrinterJobRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: usePrinterJob,
        onSuccess: onSuccess
    });


    return <FormModal
        id={ id }
        resource={ 'PrinterJob' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default PrinterJobForm;
