import { useEffect, useMemo, useState } from 'react';
import { useHttpEvent } from '../../../events/Http.event';
import useAsyncMemo from '../../../lib/samfe/hooks/useAsyncMemo';
import { RequestParams } from '../../../lib/samfe/modules/Http/useHttp';
import { useTableContext } from '../providers/Table.provider';
import useTableData from './useTableData';
import useTablePagination from './useTablePagination';


const useTableHttp = <T extends object, R extends string>() => {

    const { http, table: {staticData} } = useTableContext<T, R>();

    const tableDataState = useTableData<T, R>();
    const [ pagination, setPagination ] = useTablePagination<T, R>();

    const httpDispatched = useHttpEvent();

    const [ shouldPaginateRefresh, setShouldPaginateRefresh ] = useState(false);
    useEffect(() => {
        setShouldPaginateRefresh(true);
    }, [
        pagination.perPage,
        pagination.currentPage,
        pagination.sortDir,
        pagination.sortCol,
        pagination.search,
        pagination.filter
    ]);

    useEffect(() => {
        if (shouldPaginateRefresh) {
            setShouldPaginateRefresh(false);
        }
    }, [ shouldPaginateRefresh ]);

    const shouldRefresh = useMemo(() => {
        return shouldPaginateRefresh || httpDispatched != undefined;
    }, [ shouldPaginateRefresh, httpDispatched ]);


    const setTableData = useMemo(() => {
        return tableDataState[1];
    }, [ tableDataState ]);


    const httpHook = useMemo(() => {
        return http.hook;
    }, [ http.hook ]);

    const searchCols = useMemo(() => {
        return http.searchCols ?? [];
    }, [ http.searchCols ]);

    const httpWith = useMemo(() => {
        return http.with ?? [];
    }, [ http.with ]);

    const withCount = useMemo(() => {
        return http.withCount ?? [];
    }, [ http.withCount ]);

    /**
     *
     */
    const alterCurrentPage = (): { isAltered: boolean, currentPage: number } => {

        const totalItems = pagination.totalItems;
        const perPage = pagination.perPage;

        const pages = Math.ceil(totalItems / perPage);
        const maxPage = pages - 1<=0 ?0 :pages - 1;

        let isAltered = false;
        let currentPage = pagination.currentPage;
        if (currentPage>maxPage) {
            currentPage = totalItems == 0 ?0 :maxPage;
            isAltered = true;
        }
        return {
            currentPage,
            isAltered
        };
    };


    const data = useAsyncMemo(async(): Promise<T[]> => {
        if (staticData != undefined) {
            return staticData
        }
        if (!shouldRefresh) {
            return data;
        }
        console.log('========== START ==========');
        console.log('Table data called', { pagination });

        if (!pagination) {
            console.log('Pagination not initialized, returning empty array.');
            console.log('========== DONE ==========');
            return [];
        }

        const { currentPage, isAltered } = alterCurrentPage();
        if (isAltered) {
            console.warn('Altering pagination', { oldCurrentPage: pagination.currentPage, newCurrentPage: currentPage });
            setPagination({
                ...pagination,
                currentPage
            });
            return data;
        }


        const search = (pagination.search ?? '').replaceAll(' ', '') !== '' ?pagination.search :undefined;
        const filter = pagination.filter != 'archived=false' ?pagination.filter :undefined;

        let currentPageParam = pagination.currentPage;
        let limitParam: number|'all' = pagination.perPage;
        if (http.showAll === true) {
            currentPageParam = 0;
            limitParam = -1;
        }

        const params = {
            // Pagination
            page: currentPageParam,
            limit: limitParam,

            // Sorting
            orderBy: pagination.sortCol,
            order: pagination.sortDir,
            sortCount: pagination.sortCount,

            // Filtering
            filter: filter,
            whereCount: http.whereCount,
            doesntHave: http.doesntHave,
            whereIn: http.whereIn,

            // Searching
            search: search,
            // @todo remove http support completely, everything through searchCols
            relationSearch: undefined,
            searchCols: searchCols,

            // Select/relations
            select: http.select,
            with: httpWith,
            withCount: withCount
            //debug: '',
        } as RequestParams<Partial<T>, R>;

        const res = await httpHook.getList(params);
        console.log('API call', { res, params });
        console.log('========== DONE ==========');

        setTableData(res);
        return res;
    }, [ shouldRefresh ], []);

    /**
     * Fallback for checking if current page > max page, will only kick in when detected after api call.
     * Will trigger a new api call for retrieving data.
     */
    const [ alteredByDataCall, setAlteredByDataCall ] = useState(false);
    const [ doubleCheck, setDoubleCheck ] = useState(false);
    useEffect(() => {
        if (alteredByDataCall && !doubleCheck) {
            setAlteredByDataCall(false);
            return;
        }
        const { currentPage, isAltered } = alterCurrentPage();
        if (isAltered) {
            console.warn('Altering pagination after api call to adjust', { oldCurrentPage: pagination.currentPage, newCurrentPage: currentPage });
            setPagination({
                ...pagination,
                currentPage
            });
            setDoubleCheck(false);
        } else {
            setDoubleCheck(true);
        }
        setAlteredByDataCall(true);
    }, [ pagination.totalItems ]);


    return data;
};

export default useTableHttp;
