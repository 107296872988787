import React, { ReactNode } from 'react';
import {classNames} from "../../modules/Parse/String";

type ButtonStyle = 'primary'|'secondary'|'tertiary'

type BaseButtonProps = {
    style?: ButtonStyle // @feature TPGA-1470
    small?: boolean | (() => boolean)
}

export type ButtonProps = BaseButtonProps & Omit<JSX.IntrinsicElements['button'], 'style'> & {
    iconClassName?: string,
    text?: ReactNode
}


type Props = ButtonProps & {
    bgColor: {
        regular: string,
        hover: string,
        active: string
    }
    textColor: string,
    icon: JSX.Element|string
};


/**
 *
 */
const Button: React.FC<Props> = ({
    style,
    small,
    bgColor,
    textColor,
    text,
    icon,
    children,
    ...props
}):JSX.Element => {

    const buttonStyle = (): string => {
        const primary = `bg-${bgColor.regular} text-${textColor} py-2 hover:bg-${bgColor.hover} active:bg-${bgColor.active}`;
        switch (style) {
            case 'primary'  : return primary;
            case 'secondary': return `bg-transparant border-2 border-${bgColor.regular} text-${bgColor.regular} py-1.5 hover:text-${bgColor.hover} active:text-${bgColor.active} hover:border-${bgColor.hover} active:border-${bgColor.active} -mt-[2px]`;
            case 'tertiary' : return `text-${bgColor.regular} underline decoration-[0.1rem] underline-offset-4 hover:text-${bgColor.hover} active:text-${bgColor.active}`;
            default         : return primary;
        }
    }

    /**
     *
     */
    return (
        <button
            {...props}
            onClick={!props.disabled ? props.onClick : () => null}
            className={classNames(
                buttonStyle(),
                'rounded-[7.5px] font-medium px-4',
                small === true ? 'w-10' : 'w-max min-w-24',
                style !== 'tertiary' ? 'shadow-sm hover:shadow-md active:shadow-none':'',
                props.disabled && 'cursor-not-allowed hover:shadow-none ' + (style !== 'tertiary' ?'bg-gray-400 hover:bg-gray-400 active:bg-gray-400 text-white hover:shadow-none' :'text-gray-400 hover:text-gray-400 active:text-gray-400'),
                props.className ?? ''
            )}
        >{children ?? (small ? icon : text)}</button>
    );

}
export default Button;
