import { DevSupport } from '@react-buddy/ide-toolbox';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/sass/index.scss';
import App from './App';
import reportWebVitals from './config/reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import {PRODUCTION_MODE} from "./config/config";
import SamfeProvider from './lib/samfe/Samfe';
import { ComponentPreviews, useInitial } from './lib/samfe/vendor/Jetbrains/ReactBuddy';
import './config/pdfWorkerSetup';


export const DevelopmentMode = () => (
    <DevSupport
        ComponentPreviews={ComponentPreviews}
        useInitialHook={useInitial}
    >
        <App />
    </DevSupport>
)


// @info strict mode renders app twice
// This won't happen with production build
// Thus a boolean should switch between development and production
export const AppRoot = () => (
    <CookiesProvider>
        <SamfeProvider>
            <BrowserRouter>
                {PRODUCTION_MODE ? <App /> : <DevelopmentMode />}
            </BrowserRouter>
        </SamfeProvider>
    </CookiesProvider>
);

const ProductionMode = () => (
    <React.StrictMode>
        <AppRoot />
    </React.StrictMode>
);



ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
    .render(PRODUCTION_MODE ? <ProductionMode /> : <AppRoot />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
