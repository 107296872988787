import React, {useEffect, useState} from "react";
import {ActionButton, CancelButton, SaveButton} from "../../lib/samfe/components/Button";
import Modal from "../../lib/samfe/components/Modal";
import SelectSearch from "../../lib/samfe/components/Form/Field/SelectSearch/SelectSearch";
import useArticle from "../../resources/article/useArticle";
import {ArticleModel} from "../../resources/article/ArticleTypes";


const TestForm = () => {

    const article = useArticle();
    const [openForm, setOpenForm] = useState(false);
    const [initialArticle, setInitialArticle] = useState<ArticleModel|undefined>(undefined);
    const [currentArticle, setCurrentArticle] = useState<ArticleModel|undefined>(undefined);

    useEffect(() => {
        article.getList({limit: 1}).then(res => res.length > 0 && setInitialArticle(res[0]))
    }, []);


    return <>
        <ActionButton text={'Form open'} icon={''} onClick={() => setOpenForm(true)} />

        <Modal
            title={'test form'}
            footer={<>
                <SaveButton onClick={() => {}} />
                <CancelButton style={"tertiary"} onClick={() => setOpenForm(false)} />
            </>}
            open={openForm}
            setOpen={setOpenForm}
        >
            <>
                {currentArticle && `Current article is ${currentArticle.number}`}

                <SelectSearch
                    label={'Artikel'}
                    omitFormik={true}
                    name={'article'}
                    config={{
                        parentId: 1,
                        initialModel: initialArticle,
                        useHook: useArticle,
                        onChange: model => setCurrentArticle(model),
                        searchOptions: {
                            relations: ['product'],
                            filter: 'active=1',
                            displayName: (model) => `${model.number}`,
                            searchCols: ['number'],
                            valueCol: 'id',
                        },
                    }}
                />
            </>
        </Modal>


    </>
}

export default TestForm;