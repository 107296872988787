import { useMemo } from 'react';
import { AssessmentHistory, AssessmentHistoryAssessmentFragment } from '../types/AssessmentTypes';


export const assessmentIsApproved = (item: AssessmentHistory): boolean => {
    const isApproved = (assessmentFragment : AssessmentHistoryAssessmentFragment) => {
        return assessmentFragment.approved_at != null || assessmentFragment.status == 'approved'
    }

    if (isApproved(item.current.assessment)) {
        return true
    }

    if (item.parent != null && isApproved(item.parent.assessment)) {
        return true
    }

    if (item.frequency == 0) {
        return true
    }

    return item.previous != null && isApproved(item.previous.assessment);
}

const useAssessmentIsApproved = (item: AssessmentHistory) => {
    return useMemo(() => assessmentIsApproved(item), [ item ]);
};

export default useAssessmentIsApproved;
