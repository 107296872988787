import React, {Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {classNames} from "../../modules/Parse/String";
import {boolean, number} from "yup";

type ColorItem = {
    default: string,
    active: string,
    hover: string,
}

export type ActionItem = {
    title: string,
    iconName: string,
    callBack: (id?: number) => void | Promise<void>,
    background?: Partial<ColorItem>,
    text?: Partial<ColorItem>
    active?: (id?: number) => boolean
}

type Props = {
    id?: number,
    rowActions: ActionItem[],
    forTable?: boolean,
    disabled?: boolean
}

const ActionMenu = ({id, rowActions, forTable = false, disabled = false}: Props) => (
    <div className={'relative'}>
        <Menu as="div" className="absolute text-left contents z-0">
            <div>
                <Menu.Button
                    className="flex items-center text-gray-900 hover:text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-sky float-right">
                    <span className="sr-only">Open options</span>
                    <span
                        className={classNames(
                            "select-none material-icons mt-1 whitespace-nowrap",
                            forTable
                                ? "border border-aqua rounded pt-[0.15rem] pb-[0.1rem] px-[0.2rem] text-[1rem] font-bold"
                                : "text-xl font-bold"
                        )}>
                        {forTable ? "settings" : "more_vert"}
                    </span>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="z-20 origin-top-right absolute right-6 top-1 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div className="py-1">
                        {rowActions.filter(action => action.active !== undefined ? action.active(id) : true).map((action, i) =>
                            <Menu.Item key={i}>
                                <button
                                    onClick={async () => await action.callBack(id)}
                                    disabled={disabled === true}
                                    className={classNames('group flex items-center px-4 py-2 text-sm w-full',
                                        disabled ? 'text-gray-900' :
                                            action.text
                                                ? `${action.text.default ?? 'text-gray-700'} hover:${action.text.hover ?? 'text-gray-900'} active:${action.text.active}`
                                                : 'text-gray-700 hover:text-gray-900',

                                        disabled ? 'cursor-not-allowed bg-gray-100' :
                                            action.background
                                                ? `${action.background.default} hover:${action.background.hover ?? 'bg-gray-50'} active:${action.background.active}`
                                                : 'hover:bg-gray-50',
                                    )}
                                >
                                    <span
                                        className="material-icons mt-0 whitespace-nowrap mr-4">{action.iconName}</span>
                                    {action.title}
                                </button>
                            </Menu.Item>
                        )}
                    </div>

                </Menu.Items>
            </Transition>
        </Menu>
    </div>
);
export default ActionMenu;