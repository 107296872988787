import { useMeasure } from '@uidotdev/usehooks';
import React, { CSSProperties, useMemo, useRef } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import Disclosure from '../../lib/samfe/components/Disclosure/Disclosure';
import { usePage } from '../../providers/PageProvider';
import DataTable from './DataTable';
import TableProvider from './providers/Table.provider';
import FilterSection from './sections/Filter.section';
import PaginationSection from './sections/Pagination.section';
import { TableProps } from './types/Table';


const Table = <T extends object, R extends string>({
    notification,
    forms,
    htmlBefore,
    ...props
}: TableProps<T, R, Partial<T>>): JSX.Element => {

    const pageCtx = usePage();
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const { xs, sm, md } = useMediaQuery();


    const pageType = useMemo(() => {
        return pageCtx.type;
    }, [ pageCtx.type ]);

    const hasCreateButton = useMemo(() => {
        if (!forms?.createPivot) {
            return false;
        }
        return forms?.createPivot?.hide == true;
    }, [ forms?.createPivot ]);


    const [ notificationRef, notificationDimensions ] = useMeasure();
    const notificationHeight = useMemo(() => {
        return (notificationDimensions.height ?? 0) / 16; // in rems
    }, [ notificationDimensions.height ]);

    const [ htmlBeforeRef, htmlBeforeDimensions ] = useMeasure();
    const htmlBeforeHeight = useMemo(() => {
        return (htmlBeforeDimensions.height ?? 0) / 16; // in rems
    }, [ htmlBeforeDimensions.height ]);

    const tableOffsetTop = useMemo(() => {
        return notificationHeight + htmlBeforeHeight
    }, [ notificationHeight, htmlBeforeHeight ]);

    const minOffset = useMemo(() => {
        // @todo show with create button, currently 0...
        if (pageType == 'show') {
            return (hasCreateButton ?0 :31) + tableOffsetTop;
        }
        return (hasCreateButton ?28 :23) + tableOffsetTop;
    }, [ pageType, hasCreateButton, tableOffsetTop ]);

    const xsStyle: CSSProperties = useMemo(() => {
        if (!xs.inRange) {return {};}
        if (pageType == 'show') {

            const offset = (hasCreateButton ?0 :31) + tableOffsetTop;
            return { maxHeight: `calc(100vh - ${ offset }rem` } as CSSProperties;
        }
        const offset = (hasCreateButton ?27 :23) + tableOffsetTop;
        return { maxHeight: `calc(100vh - ${ offset }rem)` } as CSSProperties;
    }, [ xs.inRange, hasCreateButton, pageType, tableOffsetTop ]);


    const smStyle: CSSProperties = useMemo(() => {
        if (!sm.inRange) {return {};}
        // @todo show with create button, currently 0...
        if (pageType == 'show') {
            const offset = (hasCreateButton ?0 :31) + tableOffsetTop;
            return { maxHeight: `calc(100vh - ${ offset }rem` } as CSSProperties;
        }
        const offset = (hasCreateButton ?28 :23) + tableOffsetTop;
        return { maxHeight: `calc(100vh - ${ offset }rem)` } as CSSProperties;
    }, [ sm.inRange, hasCreateButton, pageType, tableOffsetTop ]);


    const mdStyle: CSSProperties = useMemo(() => {
        if (!md.equalOrGreaterThan) {return {};}
        if (pageType == 'show') {
            const offset = 24 + tableOffsetTop;
            return { maxHeight: `calc(100vh - ${ offset }rem` } as CSSProperties;
        }
        const offset = 20 + tableOffsetTop;
        return { maxHeight: `calc(100vh - ${ offset }rem)` } as CSSProperties;
    }, [ md.equalOrGreaterThan, pageType, tableOffsetTop ]);


    return (
        <TableProvider tableContainerRef={ tableContainerRef } forms={ forms } { ...props }>
            {htmlBefore && <div ref={htmlBeforeRef}>{htmlBefore}</div>}
            { notification && <Disclosure ref={ notificationRef } { ...notification } contentClassName={ '!pt-0 pb-8' }>{ notification.children }</Disclosure> }
            <div className={ 'absolute -left-4 sm:-left-10 lg:-left-12 -right-4 lg:right-0 w-screen 2xl:w-[calc(100vw-18rem)]' }>
                <FilterSection/>
                <div
                    ref={ tableContainerRef }
                    className={ 'overflow-auto scroll-smooth min-h-[50vh]' }
                    style={ {
                        ...xsStyle,
                        ...smStyle,
                        ...mdStyle,
                        minHeight: `calc(100vh - ${minOffset}rem)`
                    } }
                >
                    <DataTable/>
                </div>
                <PaginationSection/>
            </div>
        </TableProvider>
    );
};

export default Table;
