import React from 'react';


const PaginationDividerComponent = () => {
    return (
        <div className={ 'relative rounded-md text-xs w-8 h-8 cursor-default text-center bg-milk-hover' }>
            <span className={ 'absolute material-icons p-0 left-0 right-0 top-2.5 text-gray-400' }>more_horiz</span>
        </div>
    );
};

export default PaginationDividerComponent;
