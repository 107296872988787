import React from "react";
import useHttp from "../../../../lib/samfe/modules/Http/useHttp";
import {
    ElementRiskAssuranceDto,
    ElementRiskAssuranceModel,
    ElementRiskAssuranceRelationsBluePrint
} from "./ElementRiskAssuranceTypes";


const useElementRiskAssurance = (id?: number) => useHttp<ElementRiskAssuranceModel, ElementRiskAssuranceDto, ElementRiskAssuranceRelationsBluePrint>({
    endpoint: `elements/${id}/risk-assurances`
});
export default useElementRiskAssurance;
