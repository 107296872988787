import React, { useMemo } from 'react';
import Input from '../../../../lib/samfe/components/Form/Generic/Input';
import useTablePagination from '../../hooks/useTablePagination';
import useTableScrollToTop from '../../hooks/useTableScrollToTop';
import { useTableContext } from '../../providers/Table.provider';


const SearchInputComponent = <T extends object, R extends string>() => {

    const [pagination, setPagination] = useTablePagination<T, R>()
    const scrollToTop = useTableScrollToTop()
    const {http: {searchCols}} = useTableContext<T, R>()

    const currentSearchValue = useMemo(() => {
        return pagination.search
    }, [pagination.search]);

    const handleChange = (value: string) => {
        setPagination({
            ...pagination,
            search: value
        })
        scrollToTop()
    }

    return (
        <div className={''}>
            { (searchCols?.length??0) > 0 && <Input
                label={ '' }
                name={ '' }
                value={ currentSearchValue }
                onChange={ (v) => handleChange(`${ v }`) }
                type={ 'text' }
                className={ 'py-[0.275rem] mt-4 md:!my-0 !h-[2rem] !pt-1 !text-sm !w-full md:!w-[14rem]' }
                placeholder={ 'Zoekterm' }
            /> }
        </div>
    );
};

export default SearchInputComponent;
