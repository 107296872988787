import { useEffect, useMemo, useState } from 'react';

/**
 *
 */
type BreakpointKey = 'xs'|'sm'|'md'|'lg'|'xl'|'xxl'


/**
 *
 */
type BreakPoints = {
    [K in BreakpointKey]: number
}

const breakPoints: BreakPoints = {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536
};


/**
 *
 */
type BreakPointItem = {
    minWidth: number,
    maxWidth: number,
    isSmallerThan: boolean,
    isLargerThan: boolean,
    equalOrGreaterThan: boolean,
    inRange: boolean
}

const getBreakPointRange = (minWidth: number, maxWidth: number, currentWidth: number): BreakPointItem => {
    const equalOrGreaterThan = currentWidth>=minWidth;
    const equalOrSmallerThan = currentWidth<=maxWidth;
    return {
        minWidth,
        maxWidth,
        equalOrGreaterThan,
        isSmallerThan: currentWidth<minWidth,
        isLargerThan: currentWidth>maxWidth,
        inRange: equalOrGreaterThan && equalOrSmallerThan
    };
};


/**
 *
 */
type MediaQuery = {
    [K in BreakpointKey]: BreakPointItem;
};

const useMediaQuery = (): MediaQuery => {

    const [ screenWidth, setScreenWidth ] = useState(window.document.body.clientWidth);
    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const currentWidth = entries[0].target.clientWidth;
            if (currentWidth != screenWidth) {
                setScreenWidth(currentWidth);
            }
        });
        observer.observe(window.document.body);
        return () => observer.unobserve(window.document.body);
    }, []);


    return useMemo((): MediaQuery => ({
        xs: getBreakPointRange(breakPoints.xs, breakPoints.sm - 1, screenWidth),
        sm: getBreakPointRange(breakPoints.sm, breakPoints.md - 1, screenWidth),
        md: getBreakPointRange(breakPoints.md, breakPoints.lg - 1, screenWidth),
        lg: getBreakPointRange(breakPoints.lg, breakPoints.xl - 1, screenWidth),
        xl: getBreakPointRange(breakPoints.xl, breakPoints.xxl - 1, screenWidth),
        xxl: getBreakPointRange(breakPoints.xxl, Infinity, screenWidth)
    }), [ screenWidth ]);
};

export default useMediaQuery;
