import React from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { filterIds } from '../../../../lib/samfe/modules/Parse/Url';
import { modelNotArchived } from '../../../../lib/samfe/types/ModelTypes';
import StandardForm from '../../../standard/StandardForm';
import useStandard from '../../../standard/useStandard';
import useProducer from '../../useProducer';
import { ProducerStandardDto, ProducerStandardModel, ProducerStandardRelationsBluePrint } from './ProducerStandardTypes';
import useProducerStandard from './useProducerStandard';


const ProducerStandardForm: React.FC<ExtendFormModalProps<ProducerStandardDto>> = ({ id, open, setOpen, parentId, onSuccess }): JSX.Element => {

    const producer = useProducer();


    const shape = (occupiedStandardIds: number[] = []): Shape<ProducerStandardDto> => ({
        producer_id: Yup.number()
            .controlType('input')
            .inputType('hidden'),

        standard_id: Yup.number()
            .required()
            .hidden(id !== undefined)
            .label('Standaard')
            .controlType('selectSearch')
            .selectSearchConfig({
                useHook: useStandard,
                expectsInitialModel: false,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    limit: 'all',
                    displayName: model => `${ model.name }`,
                    filter: filterIds({ ids: occupiedStandardIds, exclude: true }) ?? 'archived=0',
                    FormModal: StandardForm
                }
            })
    });

    const { setShape, validationSchema } = useSchema<ProducerStandardDto>(shape());

    const initializer = async() => {
        const occupiedStandardIds: number[] = (await producer.getItem(parentId, { with: [ 'producerStandards' ] })
            .then(producer => (producer?.producerStandards ?? [])
                .filter(ps => modelNotArchived(ps.archived))))
            .map(ps => ps.standard_id!);

        setShape(shape(occupiedStandardIds));
    };

    const { formikConfig, formFields } = useForm<ProducerStandardModel, ProducerStandardDto, ProducerStandardRelationsBluePrint>({
        validationSchema: validationSchema,
        onSuccess,
        useHttpHook: useProducerStandard,
        parentId,
        id,
        initializer,
        initialized: useAsyncInit(initializer, open),

        morphPayload: (_, dto) => {
            if (dto.producer_id === undefined) {
                dto.producer_id = parentId;
            }
            return dto;
        }
    });

    return <FormModal
        id={ id }
        parentId={ parentId }
        resource={ 'Producent Standard' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default ProducerStandardForm;
