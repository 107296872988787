import queryString from 'query-string';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Pagination } from '../../../lib/samfe/modules/Http/usePagination';
import { DefaultTableFilter, TableFilterType } from '../types/TableFilter';
import { getTableFilterValue } from './useTableFilter';


type Config<T> = {
    sortCol: keyof T|undefined,
    sortDir: 'ASC'|'DESC'|undefined,
    defaultFilter: DefaultTableFilter<T>|undefined,
    filters: TableFilterType<T>[],
    showAll: boolean
}

const useTableUriPagination = <T, >(
    title: string,
    config: Config<T>
): [ Pagination<T>, (pagination: Pagination<T>) => void ] => {

    /**
     *
     */
    const [ searchParams, setSearchParams ] = useSearchParams();

    /**
     *
     */
    const tableId: string = useMemo(() => {
        return `table-data-${ encodeURI(title) }`;
    }, [ title ]);

    /**
     *
     */
    const uriEncoded: string|null = useMemo(() => {
        return searchParams.get(tableId);
    }, [ searchParams, tableId ]);

    /**
     *
     */
    const defaultFilter: string|undefined = useMemo(() => {
        let defaultGlobalFilter = config.defaultFilter ?getTableFilterValue(config.defaultFilter) :undefined
        let appendFilter: string|undefined = undefined
        if (config.filters.length > 0) {
            const defaultProvidedFilter = config.filters.find(filter => filter.default === true)
            if (defaultProvidedFilter != undefined) {
                appendFilter = getTableFilterValue(defaultProvidedFilter)
            }
        }

        let filter: string|undefined = undefined
        if (defaultGlobalFilter != undefined && appendFilter != undefined) {
            filter = `${defaultGlobalFilter},${appendFilter}`
        } else if (defaultGlobalFilter != undefined) {
            filter = defaultGlobalFilter
        } else if (appendFilter != undefined) {
            filter = appendFilter
        }

        return filter;
    }, [ config.defaultFilter, config.filters ]);

    /**
     *
     */
    const defaultPagination: Pagination<T> = useMemo(() => ({
        currentPage: 0,
        perPage: config.showAll ? -1: 25,
        sortCol: config.sortCol,
        sortDir: config.sortDir ?? 'ASC',
        filter: defaultFilter,
        totalItems: 1
    }), [ config.sortCol, config.sortDir, defaultFilter, config.showAll ]);

    /**
     *
     */
    const setUriPagination = useCallback((pagination: Pagination<T>): void => {
        searchParams.set(tableId, queryString.stringify(pagination, { encode: false }));
        setSearchParams(searchParams,{ replace: true });
    }, [ setSearchParams, tableId, searchParams ]);

    /**
     *
     */
    const uriPagination: Pagination<T> = useMemo(() => {
        if (!uriEncoded) {
            return defaultPagination;
        }
        return queryString.parse(uriEncoded, {
            types: {
                currentPage: 'number',
                perPage: 'number',
                sortCol: 'string',
                sortCount: 'string',
                sortDir: 'string',
                search: 'string',
                filter: 'string',
                totalItems: 'number'
            }
        }) as unknown as Pagination<T>;
    }, [ uriEncoded, defaultPagination ]);

    return [ uriPagination, setUriPagination ];
};

export default useTableUriPagination;
