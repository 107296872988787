import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { ActionButton, CancelButton, SaveButton } from '../Button';
import { ModalState } from './Modal';


type Props = ModalState&{
    title: JSX.Element|string,
    text: JSX.Element|string,
    confirmCallback: () => void,
    cancelCallback?: () => void
}
const InfoModal: FC<Props> = ({ open, setOpen, confirmCallback, cancelCallback, title, text }): JSX.Element => {

    const [ internalOpen, setInternalOpen ] = useState(open);
    const [ show, setShow ] = useState(open);
    useEffect(() => {
        setInternalOpen(open)
    }, [ open ]);

    useEffect(() => {
        if (internalOpen) {
            setShow(true)
            return;
        }
        setTimeout(() => {
            setShow(false);
        }, 350);
    }, [internalOpen]);

    useEffect(() => {
        if (!show) {
            setOpen(false)
        }
    }, [show]);


    const handleClose = () => {
        setInternalOpen(false);
    };

    const handleConfirm = () => {
        confirmCallback();
        handleClose();
    };

    const handleCancel = () => {
        cancelCallback?.()
        handleClose();
    };


    const handleEscape = useCallback((event: KeyboardEvent) => {
        const { key } = event;
        if(key === 'Escape'){
            handleCancel();
        }
    }, []);


    useEffect(() => {
        window.addEventListener("keydown", handleEscape);
        return () => {
            window.removeEventListener("keydown", handleEscape);
        };
    }, []);


    return (
        <>
            { show && <Transition.Root show={ internalOpen } appear={ true } as={ Fragment }>
                <Dialog as="div" className="absolute left-0 right-0 top-0 bottom-0 z-50" onClose={ () => {} }>
                    <Transition.Child
                        as={ Fragment }
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gradient-to-br from-aqua/50 to-aqua/10 backdrop-blur-[0.0416rem] transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={ Fragment }
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-aqua-hover/10 sm:mx-0 sm:h-10 sm:w-10">
                                            <QuestionMarkCircleIcon className="h-6 w-6 text-aqua" aria-hidden="true"/>
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                { title }
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    { text }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <ActionButton icon={''} text={'Bevestigen'} onClick={ handleConfirm } className={ 'sm:ml-3' }/>
                                        <CancelButton type={ 'button' } onClick={ handleCancel } style={ 'tertiary' } className={ 'mt-3 sm:mt-0' }/>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            }
        </>
    );
};

export default InfoModal;