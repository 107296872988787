import { useMemo } from 'react';
import { useTableContext } from '../providers/Table.provider';


const useTableData = <T extends object, R extends string>() => {
    const ctx = useTableContext<T, R>();
    return useMemo(() => ctx.http.dataState, [ ctx.http.dataState ]);
};

export default useTableData;
