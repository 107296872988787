import React, { useMemo } from 'react';
import Select from '../../../../lib/samfe/components/Form/Generic/Select/Select';
import { SelectOption } from '../../../../lib/samfe/components/Form/Generic/Select/useSelect';
import { thousandSeparator } from '../../../../lib/samfe/modules/Parse/Number';
import useTablePagination from '../../hooks/useTablePagination';
import useTableScrollToTop from '../../hooks/useTableScrollToTop';
import { useTableContext } from '../../providers/Table.provider';


const itemsPerPage = [ 10, 25, 50, 100 ];

const PerPageSelectComponent = <T extends object, R extends string>() => {

    const { http } = useTableContext<T, R>();
    const scrollToTop = useTableScrollToTop()

    const [ pagination, setPagination ] = useTablePagination<T, R>();

    const totalItems: number = useMemo(() => {
        return pagination.totalItems;
    }, [ pagination.totalItems ]);

    const perPage: number = useMemo(() => {
        return pagination.perPage;
    }, [ pagination.perPage ]);

    const showAll = useMemo(() => {
        return http.showAll ?? false
    }, [http.showAll]);

    const onChange = (option: SelectOption): void => {
        setPagination({
            ...pagination,
            perPage:  option.value as number
        });
        scrollToTop()
    };

    return (
        <>

            {showAll && <p>Alle resultaten&ensp;<small>({ thousandSeparator(totalItems) } totaal)</small></p>}
            { !showAll && <Select
                buttonClassName={ '!text-sm' }
                className={ '!-top-[10rem]' }
                label={ <>Resultaten per pagina&ensp;<small>({ thousandSeparator(totalItems) } totaal)</small></> }
                name={ 'paginate' }
                onChange={ onChange }
                options={
                    itemsPerPage.map(perPageItem => ({
                        displayName: perPageItem,
                        value: perPageItem,
                        selected: perPage === perPageItem
                    }))
                }
            /> }
        </>
    );
};

export default PerPageSelectComponent;
