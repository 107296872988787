import { ElementRiskReviewModel } from '../../../elementRiskReview/ElementRiskReviewTypes';
import {ProductModel} from "../../ProductTypes";
import {ElementModel} from "../../../element/ElementTypes";
import {SoftDeleteModel} from "../../../../lib/samfe/types/ModelTypes";
import {MetricUnit} from "../../../../lib/samfe/modules/Parse/Metric";

export type CompositionProductRelationsBluePrint =
    'element'
    |'element.compound'
    |'element.excipient'
    |'element.risks'
    |'element.category'
    |'product.productType'
    |'elementRiskReviews'
    ;

export const compositionProductUnits: MetricUnit[] = ['mcg', "mg", "g", "ml"]

export type CompositionProductFields = {
    element_id: number,
    product_id: number,
    amount: number,
    unit: MetricUnit,
    order: number,
}

export type CompositionProductDto = Partial<CompositionProductFields>

export type CompositionProductRelations = {
    product: ProductModel,
    element: ElementModel,
    elementRiskReviews: ElementRiskReviewModel[]
}

export type CompositionProductModel = SoftDeleteModel & Partial<CompositionProductRelations> & CompositionProductDto
