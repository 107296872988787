import { useMemo } from 'react';
import DeArchiveModal from '../../../lib/samfe/components/Modal/DeArchiveModal';
import { useTableContext } from '../providers/Table.provider';
import { TableArchiveFormInternalProps } from '../types/TableForm';
import useTablePagination from './useTablePagination';


const useTableDeArchive = <T extends object, K extends keyof T, R extends string, V = T[K]>() => {

    const [ pagination ] = useTablePagination();
    const { forms, http } = useTableContext<T, R>();

    const hasArchivedResources = useMemo(() => {
        if (!forms?.archive ) {
            return false;
        }
        return (pagination.filter ?? '').includes('archived=true') || (pagination.filter ?? '').includes('archived!=false');
    }, [ pagination.filter, forms?.archive ]);


    const deArchiveConfig: TableArchiveFormInternalProps<T, K, V>|undefined = useMemo(() => {
        if (!forms?.archive?.id || http.disableDeArchiving == true) {
            return undefined;
        }
        return {
            ...forms?.archive,
            isArchive: true,
            Form: DeArchiveModal // @todo de archive modal,
        };
    }, [ forms?.archive, http.disableDeArchiving ]);

    return {
        hasArchivedResources,
        deArchiveConfig
    };
};

export default useTableDeArchive;
