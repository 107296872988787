import React, { FC, useEffect, useState } from 'react';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import { batchCodeLabel } from '../charge/ChargeFunctions';
import { ChargeModel } from '../charge/ChargeTypes';
import useCharge from '../charge/useCharge';
import {
    ProfitLossCauseDisplayName,
    ProfitLossCauses,
    ProfitLossDto,
    ProfitLossModel,
    ProfitLossRelationsBluePrint
} from "./ProfitLossTypes";
import useProfitLoss from "./useProfitLoss";
import useSchema, {Shape} from "../../lib/samfe/components/Form/Effects/useSchema";
import Yup from "../../lib/samfe/components/Form/Yup";
import {ExtendFormModalProps} from "../../lib/samfe/components/Modal/FormModal/FormModal";
import {useForm} from "../../lib/samfe/components/Form";
import {FormModal} from "../../lib/samfe/components/Modal";


/**
 * @var {number|undefined} parentId = Charge ID
 * @var {number|undefined} id = mutation ID
 */
const ProfitLossForm: FC<ExtendFormModalProps<ProfitLossDto>> = ({ id, open, setOpen, onSuccess, parentId }): JSX.Element => {

     const charge = useCharge();
    const [ currentCharge, setCurrentCharge ] = useState<ChargeModel>();
    useEffect(() => {
        if (!parentId) {
            setCurrentCharge(undefined)
            return;
        }
        charge.getItem(parentId).then(setCurrentCharge)
    }, [parentId]);


    /**
     *
     */
    const shape = (
        currentProfitLoss: ProfitLossModel|undefined = undefined,
    ): Shape<ProfitLossDto> => ({
        charge_id: Yup.number()
            .hidden(true)
            .label('Charge')
            .controlType('input')
            .inputType('text'),


        amount: Yup.number()
            .label('Aantal (negatief voor waste)')
            .required()
            .controlType('input')
            .inputType('number')
            .steps(1),

        cause: Yup.string()
            .required()
            .label('Oorzaak')
            .controlType('select')
            .options(ProfitLossCauses.map((cause, i) => ({
                displayName: ProfitLossCauseDisplayName(cause),
                value: cause,
                selected: currentProfitLoss?.cause !== undefined ?currentProfitLoss?.cause === cause : i === 0
            }))),

        comment: Yup.string()
            .label('Notitie')
            .description('Geef in het kort de oorzaak aan')
            .required()
            .controlType('textArea')
    });


    /**
     *
     */
    const {
        validationSchema,
         setShape
    } = useSchema<ProfitLossDto>(shape());

    const initializer = async () => {
        setShape(shape())
    }

    /**
     *
     */
    const { formikConfig, formFields } = useForm<ProfitLossModel, ProfitLossDto, ProfitLossRelationsBluePrint>({
        id,
        parentId,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useProfitLoss,
        onSuccess: onSuccess,
        morphPayload: (_, payload) => ({...payload,
            charge_id: parentId
        })
    });

    return <FormModal
        id={id}
        parentId={parentId}
        htmlBeforeForm={<>
            <h3 className={'font-medium text-aqua'}>Charge</h3>
            <p className={'text-graphite text-sm'}>{batchCodeLabel(currentCharge)}</p>
        </>}
        resource={'Voorraad mutatie'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
};

export default ProfitLossForm;
