import React, { FC } from 'react';
import { ExtendTableProps } from '../../components/table/types/Table';
import BaseChargeTable from './BaseChargeTable';


type Props = ExtendTableProps

const ChargeTable: FC<Props> = () => <BaseChargeTable/>;

export default ChargeTable;
