import React, {Ref, useEffect, useState} from "react";
import ErrorMessage from "./Caption/ErrorMessage";
import Label from "./Caption/Label";
import Input from "./Field/Input";
import Select from "./Field/Select";
import FormControl from "./FormControl";
import useForm from "./Effects/useForm";
import {Formik, Form as FormikForm, FormikValues, FormikHelpers, FormikTouched} from "formik"
import {SaveButton} from "../Button";
import {FormikProps} from "formik/dist/types";
import {FormikRef} from "./Effects/useFormikRef";
import {Schema} from "./Effects/useSchema";
import SelectSearch from "./Field/SelectSearch/SelectSearch";


export type FormikConfig<Dto> = {
    id?: number,
    initialValues?: FormikValues,
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void | Promise<any>,
    onSuccess?: (id?: number) => void,
    validationSchema: Schema<Dto>;
    validateOnChange: boolean,
    enableReinitialize: boolean,
    initialTouched?: FormikTouched<FormikValues>,
    initialized?: boolean,
    isSaved: boolean,
    setIsSaved: React.Dispatch<React.SetStateAction<boolean>>,
    initializer: () => Promise<void>|void
}


export type ExtendFormProps<Dto> = {
    parentId?: number,
    id?: number,
    onSuccess?: (id: number|undefined) => void
}

export type FormProps<Dto> = {
    formikConfig: FormikConfig<Dto>;
    formFields: JSX.Element[];
    formRef?:FormikRef<Dto>; // Update the type here
    withSaveButton?: boolean
    parentId?: number,
};

const Form = <Dto extends any>({ formikConfig, formFields, formRef, withSaveButton = false }: FormProps<Dto>): JSX.Element => {

    const [currentConfig, setCurrentConfig] = useState<FormikConfig<Dto>>(formikConfig);
    useEffect(() => {
        setCurrentConfig(formikConfig)
    }, [formikConfig]);

    const [currentValues, setCurrentValues] = useState<FormikValues>(formikConfig.initialValues ?? {});
    useEffect(() => {
        setCurrentValues(formikConfig.initialValues ?? {})
    }, [formikConfig.initialValues]);


    const [currentFormFields, setCurrentFormFields] = useState<JSX.Element[]>(formFields);
    useEffect(() => {
        setCurrentFormFields(formFields)
    }, [formFields]);

    return (
        <>
            <Formik {...currentConfig} enableReinitialize={true} initialValues={currentValues} innerRef={formRef as Ref<FormikProps<FormikValues>>}>
                <FormikForm>
                    {currentFormFields}
                    {withSaveButton && <SaveButton/>}
                </FormikForm>
            </Formik>
        </>
    );
};

export default Form;

export {
    ErrorMessage,
    Label,
    Input,
    Select,
    SelectSearch,
    FormControl,
    useForm
}
