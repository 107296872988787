import React from 'react';
import IndexPage from '../../../templates/pages/IndexPage';
import AttributeDnDTableTestComponent from './AttributeDnDTable.test.component';
import SaleTableTestComponent from './SaleTable.test.component';


const TableTestIndex = () => {
    return (
        <>
            <IndexPage
                title={ 'Test table index' }
                Table={ SaleTableTestComponent }
            />

            {/*<ButtonStyleGuide />*/ }

        </>
    );
};

export default TableTestIndex;
