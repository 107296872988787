import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import BaseArticleTable from '../../article/BaseArticleTable';


type Props = Required<Omit<ExtendTableProps, 'notification'|'htmlBefore'>>

const ProductArticleTable: FC<Props> = ({parentId}) => {
    return <BaseArticleTable productId={parentId}/>;
};

export default ProductArticleTable;