import React, { FC, useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import { classNames } from '../../../modules/Parse/String';
import DeleteIcon from '../../Icon/DeleteIcon';
import ErrorMessage from '../Caption/ErrorMessage';
import Label from '../Caption/Label';
import { InvalidField } from './GenericFieldTypes';


type Props = {
    label: string,
    initialEmails?: string[]
    onChange: (emails: string[]) => void,
    invalid?: InvalidField
}
const EmailInput: FC<Props> = ({
    label,
    initialEmails = [],
    onChange,
    invalid
}) => {

    const [ emails, setEmails ] = useState<string[]>(initialEmails);
    const [ focused, setFocused ] = useState(false);

    const handleChange = (emails: string[]) => {
        setEmails(emails);
        onChange(emails);
    };

    return <div className={ 'my-4' }>
        <Label invalid={ invalid?.isInvalid }>{ label }</Label>
        <div className="mt-2">
            <ReactMultiEmail
                className={ classNames(
                    'block w-full rounded-md border-0 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 sm:text-sm sm:leading-6',
                    focused && 'ring-1 ring-inset ring-blue-600',
                    invalid?.isInvalid ?'ring-red-700' :'ring-gray-300',
                ) }
                inputClassName={ 'border-none ring-none focus:ring-0 mx-0 px-0 my-0.5' }
                noClass={ true }
                style={ {} }
                emails={ emails }
                onChange={ handleChange }
                onFocus={ () => setFocused(true) }
                onBlur={ () => setFocused(false) }
                getLabel={ (email, index, removeEmail) => {
                    return (
                        <div data-tag={true} key={ index } className={ 'inline-flex gap-x-1 bg-milk bg-opacity-75 rounded-md px-2 py-0.5 mx-1 mt-2 content-center justify-center' }>
                            <span data-tag-item={true} className={ 'text-graphite' }>{ email }</span>
                            <button data-tag-handle={true} onClick={ () => removeEmail(index) }>
                                <DeleteIcon className={ '!text-sm text-fire !font-bold !mt-[0.2rem]' }/>
                            </button>
                        </div>
                    );
                } }
            />
            <ErrorMessage message={ invalid?.isInvalid ?invalid?.message :undefined }/>
        </div>
    </div>;
};

export default EmailInput;