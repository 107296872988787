import { AxiosResponse } from 'axios';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { ActionButton } from '../../lib/samfe/components/Button';
import useAxios from '../../lib/samfe/modules/Http/useAxios';
import { FileType } from './DocumentTypes';
import { viewPdf } from './pdfFunctions';


type CustomAction = {
    name: string,
    callback: () => void
}

export type PdfFileProps = {
    id?: number
    fileType: FileType,
    params?: string
    customFileName?: string,
    customActions?: CustomAction[]
    customJsxActions?: JSX.Element[]
}
const PdfFile: React.FC<PdfFileProps> = ({ id, fileType, customFileName, customActions, params, customJsxActions }): JSX.Element => {

    const defaultFileName = (customFileName ?? `${ fileType }${ id ?`-${ id }` :'' }`).concat('.pdf');

    const [ numPages, setNumPages ] = useState<number>();
    const [ file, setFile ] = useState<File|undefined>(undefined);
    const [ fileName, setFileName ] = useState<string>(defaultFileName);
    const [ fileBlob, setFileBlob ] = useState<Blob|undefined>(undefined);
    const { get } = useAxios();


    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }


    function loadFile(): void {
        const idArg = typeof id === 'number' ?id :'';

        let paramStr = ''
        if (params !== undefined) {
            paramStr =  `?${params}`
        }

        get<string>(`documents/${ fileType }/${ idArg }${paramStr}`, undefined, { responseType: 'blob' })
            .then((r: AxiosResponse<string, any>) => {
                const blob = new Blob([ r.data ], { type: r.headers['content-type'] });
                const file = new File([ blob ], 'test');

                setFileBlob(blob);
                setFile(file);

                const headerLine = r.headers['Content-Disposition']; // This is undefined for inline DomPDF documents
                if (headerLine !== undefined) {
                    const startFileNameIndex = headerLine.indexOf('"') + 1;
                    const endFileNameIndex = headerLine.lastIndexOf('"');
                    const name = headerLine.substring(startFileNameIndex, endFileNameIndex);
                    setFileName(name);
                } else {
                    setFileName(defaultFileName);
                }
            });
    }


    function download() {
        if (file != undefined && fileBlob != undefined) {
            const fileURL = window.URL.createObjectURL(fileBlob);

            if (!fileName) {
                window.open(fileURL);
                return;
            }

            // create <a> tag dynamically
            const fileLink = window.document.createElement('a');
            fileLink.href = fileURL;

            // it forces the name of the downloaded file
            fileLink.download = fileName;

            // triggers the click event
            fileLink.click();
        }
    }


    /**
     * For viewing uploaded files.
     *
     * @returns {void}
     */
    const viewFile = (): void => viewPdf(id, fileType);

    useEffect(() => {
        loadFile();
    }, [ document, params, id ]);


    function pages() {
        const pageList = [];

        for (let i = 1; i<=(numPages ?? 1); i++) {
            pageList.push(<Page key={i} className={ ' border border-aqua/20 drop-shadow shadow-aqua/20 shadow-md py-1 mb-2' } scale={ 1.5 } pageNumber={ i }/>);
        }

        return pageList;
    }


    return <div className={ '' }>
        <div className={ 'border-y border-y-gray-200 -mx-4 sm:-mx-10 lg:-mx-12 py-2' }>
            <div className={ 'mx-4 sm:mx-10 lg:mx-12' }>

                <ActionButton
                    text={ 'Download' }
                    icon={ '' }
                    style={ 'tertiary' }
                    onClick={ download }
                    className={ ' !w-auto' }
                />

                <ActionButton
                    text={ 'Open in nieuw tab' }
                    icon={ '' }
                    style={ 'tertiary' }
                    onClick={ viewFile }
                    className={ ' !w-auto' }
                />

                {(customActions??[]).map((action, i) => <ActionButton
                    key={i}
                    text={ action.name }
                    icon={ '' }
                    style={ 'tertiary' }
                    onClick={ action.callback }
                    className={ ' !w-auto' }
                />)}
                {customJsxActions}
            </div>
        </div>

        <div className="absolute border-fire left-0 2xl:left-[18rem] right-0">
            <div className={ 'overflow-y-scroll h-[calc(100vh-26rem)] w-full border' }>
                <Document className={ 'w-fit mx-auto pt-6 pb-4' }
                          file={ file }
                          onLoadSuccess={ onDocumentLoadSuccess }
                          loading={ 'Het bestand wordt geladen...' }
                          noData={ 'Het bestand is (nog) niet gevonden.' }
                          renderMode={ 'canvas' }
                >
                    { pages() }
                </Document>
            </div>
        </div>

    </div>;

};

export default PdfFile;